import { AppLayout } from '@/components/app-layout';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Switch } from '@/components/ui/switch';
import { apiClient } from '@/lib/api';
import { components } from '@/lib/api.types';
import { GLOBAL_TEAM_QUERY_KEY, GLOBAL_TEAMS_QUERY_KEY } from '@/lib/tanstack';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { showArticle } from '@intercom/messenger-js-sdk';
import { useQueryClient } from '@tanstack/react-query';
import { createFileRoute, useRouteContext } from '@tanstack/react-router';
import { DOCS, ROLE } from '@wire/shared';
import { TrashIcon } from 'lucide-react';
import { useState } from 'react';
import { toast } from 'sonner';
export const Route = createFileRoute('/_application/settings/containment')({
  component: ContainmentSettings
});

export default function ContainmentSettings() {
  const { team: defaultTeam } = useRouteContext({ from: '/_application' });
  const [team, setTeam] = useState<components['schemas']['Team']>(defaultTeam);
  const queryClient = useQueryClient();
  async function updateTeam(dto: components['schemas']['UpdateTeamDto']) {
    if (dto.identityAutoContainment === true) {
      dto = {
        ...dto,
        ...getIdentitySecureDefaults()
      };
    }
    if (dto.endpointAutoContainment === true) {
      dto = {
        ...dto,
        ...getEndpointSecureDefaults()
      };
    }
    const response = await apiClient.PATCH('/team', {
      body: dto
    });
    if (response.error != null) {
      toast.error('Error updating max auto containments per day');
      return;
    }
    await queryClient.invalidateQueries({
      queryKey: [GLOBAL_TEAM_QUERY_KEY]
    });
    await queryClient.invalidateQueries({
      queryKey: [GLOBAL_TEAMS_QUERY_KEY]
    });
    toast.success('Team updated');
    setTeam(response.data);
  }

  function getIdentitySecureDefaults() {
    return {
      identityAutoContainment: true,
      autoContainIdentityVIP: false
    };
  }

  function getEndpointSecureDefaults() {
    return {
      endpointAutoContainment: true,
      autoContainEndpointHVA: false,
      autoContainLSTEndpoint: true,
      autoContainUnmitigatedMalware: false
    };
  }

  async function applySecureDefaults() {
    await updateTeam({
      maxAutoContainmentsPerDay: 10,
      ...getIdentitySecureDefaults(),
      ...getEndpointSecureDefaults()
    });
  }

  return (
    <AppLayout>
      <div className="flex flex-col gap-4">
        <Card>
          <CardHeader className="flex items-start flex-col lg:flex-row lg:justify-between lg:items-center">
            <CardTitle className="flex items-center gap-1">
              General Settings{' '}
              <InformationCircleIcon
                onClick={() => showArticle(DOCS.CONTAINMENT.INTRODUCTION)}
                className="h-4 cursor-pointer w-4"
              />
            </CardTitle>
            <Button onClick={applySecureDefaults} variant="outline">
              Apply Secure Defaults
            </Button>
          </CardHeader>
          <CardContent>
            <div>
              <Label className="flex items-center gap-1">
                Max Auto Containments Per Day{' '}
                <InformationCircleIcon
                  onClick={() => showArticle(DOCS.CONTAINMENT.INTRODUCTION)}
                  className="h-4 cursor-pointer w-4"
                />
              </Label>
              <div className="flex items-center mt-1 gap-2">
                <Input
                  value={team.maxAutoContainmentsPerDay ?? ''}
                  onChange={(e) =>
                    updateTeam({
                      maxAutoContainmentsPerDay: e.target.value
                        ? parseInt(e.target.value)
                        : undefined
                    })
                  }
                  className=""
                  requiredRole={ROLE.ADMIN}
                  placeholder="10"
                  type="number"
                />
                <Button
                  onClick={async () => {
                    await updateTeam({ maxAutoContainmentsPerDay: null });
                  }}
                  variant="outline"
                >
                  <TrashIcon className="h-4 w-4" />
                </Button>
              </div>
              <p className="text-xs text-muted-foreground mt-2">
                {team.maxAutoContainmentsPerDay == undefined && (
                  <span>
                    <span className="text-red-500">Disabled</span> -{' '}
                  </span>
                )}{' '}
                Maximum number of auto containments Wirespeed can make within a
                24 hour period
              </p>
            </div>
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="flex flex-col items-start lg:flex-row lg:justify-between lg:items-center">
            <div>
              <CardTitle className="flex items-center gap-1">
                Identity Auto Containment{' '}
                <InformationCircleIcon
                  onClick={() => showArticle(DOCS.CONTAINMENT.USER)}
                  className="h-4 cursor-pointer w-4"
                />
              </CardTitle>
              <CardDescription className="text-xs">
                {!team.identityAutoContainment && (
                  <>
                    {' '}
                    <span className="text-red-500">Disabled</span> -{' '}
                  </>
                )}
                Automatically contain identities when they are compromised
              </CardDescription>
            </div>
            <Switch
              checked={team.identityAutoContainment}
              onCheckedChange={async (e) => {
                await updateTeam({ identityAutoContainment: e });
              }}
            />
          </CardHeader>
          <CardContent className="flex gap-8 flex-col">
            <div>
              <div className="flex justify-between items-center">
                <Label className="flex items-center gap-1">
                  Include VIP Users{' '}
                  <InformationCircleIcon
                    onClick={() => showArticle(DOCS.CONTAINMENT.USER)}
                    className="h-4 cursor-pointer w-4"
                  />
                </Label>
                <div className="flex items-center gap-2">
                  <Switch
                    requiredRole={ROLE.ADMIN}
                    checked={team.autoContainIdentityVIP}
                    onCheckedChange={async (e) => {
                      await updateTeam({
                        autoContainIdentityVIP: e
                      });
                    }}
                  />
                </div>
              </div>
              <p className="text-xs text-muted-foreground mt-1">
                {!team.autoContainIdentityVIP && (
                  <span>
                    <span className="text-red-500">Disabled</span> -{' '}
                  </span>
                )}{' '}
                Automatically contain users that are marked as VIPs
              </p>
            </div>
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="flex flex-col items-start lg:flex-row lg:justify-between lg:items-center">
            <div>
              <CardTitle className="flex items-center gap-1">
                Endpoint Auto Containment{' '}
                <InformationCircleIcon
                  onClick={() => showArticle(DOCS.CONTAINMENT.ENDPOINT)}
                  className="h-4 cursor-pointer w-4"
                />
              </CardTitle>
              <CardDescription className="text-xs mt-1">
                {!team.endpointAutoContainment && (
                  <>
                    {' '}
                    <span className="text-red-500">Disabled</span> -{' '}
                  </>
                )}
                Automatically contain endpoints when they are compromised
              </CardDescription>
            </div>
            <Switch
              checked={team.endpointAutoContainment}
              onCheckedChange={async (e) => {
                await updateTeam({ endpointAutoContainment: e });
              }}
            />
          </CardHeader>
          <CardContent className="flex gap-8 flex-col">
            <div>
              <div className="flex justify-between items-center">
                <Label className="flex items-center gap-1">
                  Include High Value Assets{' '}
                  <InformationCircleIcon
                    onClick={() => showArticle(DOCS.CONTAINMENT.ENDPOINT)}
                    className="h-4 cursor-pointer w-4"
                  />
                </Label>
                <div className="flex items-center gap-2">
                  <Switch
                    requiredRole={ROLE.ADMIN}
                    checked={team.autoContainEndpointHVA}
                    onCheckedChange={async (e) => {
                      await updateTeam({
                        autoContainEndpointHVA: e
                      });
                    }}
                  />
                </div>
              </div>
              <p className="text-xs text-muted-foreground mt-1">
                {!team.autoContainEndpointHVA && (
                  <span>
                    <span className="text-red-500">Disabled</span> -{' '}
                  </span>
                )}{' '}
                Automatically contain endpoints that are marked as High Value
                Assets
              </p>
            </div>
            <div>
              <div className="flex justify-between items-center">
                <Label className="flex items-center gap-1">
                  Include Servers{' '}
                  <InformationCircleIcon
                    onClick={() => showArticle(DOCS.CONTAINMENT.ENDPOINT)}
                    className="h-4 cursor-pointer w-4"
                  />
                </Label>
                <div className="flex items-center gap-2">
                  <Switch
                    requiredRole={ROLE.ADMIN}
                    checked={team.autoContainEndpointServers}
                    onCheckedChange={async (e) => {
                      await updateTeam({
                        autoContainEndpointServers: e
                      });
                    }}
                  />
                </div>
              </div>
              <p className="text-xs text-muted-foreground mt-1">
                {!team.autoContainEndpointServers && (
                  <span>
                    <span className="text-red-500">Disabled</span> -{' '}
                  </span>
                )}{' '}
                Automatically contain endpoints that are identified as servers
              </p>
            </div>
            <div>
              <div className="flex justify-between items-center">
                <Label className="flex items-center gap-1">
                  Late Stage Tools{' '}
                  <InformationCircleIcon
                    onClick={() => showArticle(DOCS.CONTAINMENT.ENDPOINT)}
                    className="h-4 cursor-pointer w-4"
                  />
                </Label>
                <div className="flex items-center gap-2">
                  <Switch
                    requiredRole={ROLE.ADMIN}
                    checked={team.autoContainLSTEndpoint}
                    onCheckedChange={async (e) => {
                      await updateTeam({
                        autoContainLSTEndpoint: e
                      });
                    }}
                  />
                </div>
              </div>
              <p className="text-xs text-muted-foreground mt-1">
                {!team.autoContainLSTEndpoint && (
                  <span>
                    <span className="text-red-500">Disabled</span> -{' '}
                  </span>
                )}{' '}
                Endpoints identified as having late stage tools running on them
              </p>
            </div>
            <div>
              <div className="flex justify-between items-center">
                <Label className="flex items-center gap-1">
                  Unmitigated Malware{' '}
                  <InformationCircleIcon
                    onClick={() => showArticle(DOCS.CONTAINMENT.ENDPOINT)}
                    className="h-4 cursor-pointer w-4"
                  />
                </Label>
                <div className="flex items-center gap-2">
                  <Switch
                    requiredRole={ROLE.ADMIN}
                    checked={team.autoContainUnmitigatedMalware}
                    onCheckedChange={async (e) => {
                      await updateTeam({
                        autoContainUnmitigatedMalware: e
                      });
                    }}
                  />
                </div>
              </div>
              <p className="text-xs text-muted-foreground mt-1">
                {!team.autoContainUnmitigatedMalware && (
                  <span>
                    <span className="text-red-500">Disabled</span> -{' '}
                  </span>
                )}{' '}
                Endpoints identified as having unmitigated malware running on
                them
              </p>
            </div>
          </CardContent>
        </Card>
      </div>
    </AppLayout>
  );
}
