import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from '@/components/ui/card';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem
} from '@/components/ui/dropdown-menu';
import { Label } from '@/components/ui/label';
import { Skeleton } from '@/components/ui/skeleton';
import { apiClient } from '@/lib/api';
import { components } from '@/lib/api.types';
import { DropdownMenuTrigger } from '@radix-ui/react-dropdown-menu';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useRouteContext } from '@tanstack/react-router';
import numeral from 'numeral';
import { useState } from 'react';
import { toast } from 'sonner';

function getIntegrationDisplay(
  platform: string,
  metadata: components['schemas']['IntegrationMetadataConfigV2'][]
) {
  const integration = metadata.find((v) => v.slug == platform);
  if (integration == null) {
    return platform;
  }
  return integration.name ?? 'N/A';
}

export default function TeamStatistics(props: {
  team: components['schemas']['Team'];
}) {
  if (props.team.serviceProvider) return;

  const { integrationMetadata } = useRouteContext({
    from: '/_application'
  });
  const [teamStatisticsDays, setTeamStatisticsDays] = useState<number | null>(
    null
  );

  const ttStats = useQuery({
    queryKey: ['TT', props.team.id, teamStatisticsDays],
    queryFn: async () => {
      const [mttr, mttv, mttd] = await Promise.all([
        apiClient.POST('/detection/mttr', {
          body: { days: teamStatisticsDays ?? undefined }
        }),
        apiClient.POST('/detection/mttv', {
          body: { days: teamStatisticsDays ?? undefined }
        }),
        apiClient.POST('/detection/mttd', {
          body: { days: teamStatisticsDays ?? undefined }
        })
      ]);
      if (mttr.error != null || mttv.error != null || mttd.error != null) {
        throw new Error('Error getting team statistics');
      }
      return { mttr: mttr.data, mttv: mttv.data, mttd: mttd.data };
    }
  });

  const { data: teamStatistics, isLoading: teamStatisticsLoading } = useQuery({
    queryKey: ['TEAM_STATISTICS', teamStatisticsDays],
    queryFn: async () => {
      const response = await apiClient.POST('/team/statistics', {
        body: { days: teamStatisticsDays ?? undefined }
      });
      if (response.error != null) {
        throw new Error('Error getting team statistics');
      }
      return response.data;
    },
    placeholderData: keepPreviousData
  });

  function exportCSV() {
    let out = 'name,value\n';
    out += `Total detections,${teamStatistics?.totalDetections}\n`;
    out += `Historic detections,${teamStatistics?.historicDetections}\n`;
    out += `Escalated detections,${teamStatistics?.escalatedDetections}\n`;
    if (props.team.testMode) {
      out += `Historic detections that would have been escalated,${teamStatistics?.potentialEscalatedDetections}\n`;
    }
    out += `Confirmed malicious detections,${teamStatistics?.confirmedMalicious}\n`;
    out += `True positive detections,${teamStatistics?.truePositiveDetections}\n`;
    out += `False positive detections,${teamStatistics?.falsePositiveDetections}\n`;
    out += `Detections resulting in chat ops,${teamStatistics?.chatOpsDetections}\n`;
    if (props.team.testMode) {
      out += `Historic detections that would have resulted in chat ops,${teamStatistics?.potentialChatOpsDetections}\n`;
    }
    out += `Detections resulting in containment,${teamStatistics?.containmentDetections}\n`;
    out += `Total events by integration,"${teamStatistics?.ocsfStatistics
      .map(
        (stat) =>
          `${getIntegrationDisplay(stat.integration.platform, integrationMetadata)},${stat.totalEvents},${numeral(stat.totalBytes).format('0,00b')}`
      )
      .join('\n')
      .replace(/"/g, '""')}"\n`;
    out += `Detections by country,"${teamStatistics?.detectionLocations
      .map((stat) => `${stat.country},${stat.count}`)
      .join('\n')
      .replace(/"/g, '""')}"\n`;
    out += `Suspicious logins by country,"${teamStatistics?.suspiciousLoginLocations
      .map((stat) => `${stat.country},${stat.count}`)
      .join('\n')
      .replace(/"/g, '""')}"\n`;
    out += `Endpoints by OS,"${teamStatistics?.operatingSystems
      .map((stat) => `${stat.operatingSystem},${stat.count}`)
      .join('\n')
      .replace(/"/g, '""')}"\n`;
    out += `MTTR,${ttStats.data?.mttr.average}\n`;
    out += `MTTD,${ttStats.data?.mttd.average}\n`;
    out += `MTTV,${ttStats.data?.mttv.average}\n`;

    const blob = new Blob([out], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `team-statistics-${props.team.name.replace(/[^a-zA-Z0-9]/g, '-')}-${new Date().toISOString()}.csv`;
    a.click();

    toast.success('Exported to CSV');
  }

  if (teamStatisticsLoading || teamStatistics == null) {
    return <Skeleton className="w-full h-96 rounded-md" />;
  }

  return (
    <Card>
      <CardHeader className="flex items-center justify-between flex-col lg:flex-row">
        <div>
          <CardTitle>Team Statistics</CardTitle>
          <CardDescription>High level statistics for your team</CardDescription>
        </div>
        <div className="flex flex-row gap-2">
          <Button variant="outline" onClick={exportCSV}>
            Export to CSV
          </Button>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline">
                {teamStatisticsDays != null
                  ? `Last ${teamStatisticsDays} Days`
                  : 'All Time'}
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem onClick={() => setTeamStatisticsDays(30)}>
                Last 30 Days
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => setTeamStatisticsDays(90)}>
                Last 90 Days
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => setTeamStatisticsDays(180)}>
                Last 180 Days
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => setTeamStatisticsDays(null)}>
                All Time
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </CardHeader>
      <CardContent>
        <div className="flex flex-col gap-4">
          <Statistics
            label="Total detections"
            statistic={teamStatistics.totalDetections}
          />
          <Statistics
            label="Historic detections"
            hide={!props.team.testMode}
            description="Previous 90 days of detections ingested when setting up a new integration"
            statistic={teamStatistics.historicDetections}
          />
          <Statistics
            label="Escalated detections"
            statistic={teamStatistics.escalatedDetections}
          />
          <Statistics
            label="Historic detections that would have been escalated"
            statistic={teamStatistics.potentialEscalatedDetections}
            hide={!props.team.testMode}
          />
          <Statistics
            label="Confirmed malicious detections"
            description="Detections that were escalated and manually closed as malicious"
            statistic={teamStatistics.confirmedMalicious}
          />
          <Statistics
            label="True positive detections"
            description="Detections that were escalated and manually closed as malicious or suspicious"
            statistic={`${teamStatistics.truePositiveDetections}`}
          />
          <Statistics
            label="False positive detections"
            description="Detections that were escalated and manually closed as benign"
            statistic={`${teamStatistics.falsePositiveDetections}`}
          />
          <Statistics
            label="Detections resulting in chat ops"
            statistic={teamStatistics.chatOpsDetections}
          />
          <Statistics
            label="Historic detections that would have resulted in chat ops"
            statistic={teamStatistics.potentialChatOpsDetections}
            hide={!props.team.testMode}
          />
          <Statistics
            label="Detections resulting in containment"
            statistic={teamStatistics.containmentDetections}
          />
          <Statistics
            label="Historic detections that would have resulted in containment"
            statistic={teamStatistics.potentialContainmentDetections}
            hide={!props.team.testMode}
          />
          {teamStatistics.ocsfStatistics.length > 0 && (
            <div className="flex flex-col space-y-2">
              <Label>Total Events by Integration</Label>
              {teamStatistics?.ocsfStatistics
                .sort((a, b) => a.totalEvents - b.totalEvents)
                .map((stat) => (
                  <div key={stat.integration.id}>
                    <span className="text-sm">
                      {getIntegrationDisplay(
                        stat.integration.platform,
                        integrationMetadata
                      )}
                      : {numeral(stat.totalEvents).format('0,0a')} (
                      {numeral(stat.totalBytes).format('0,0b')})
                    </span>
                  </div>
                ))}
            </div>
          )}

          {teamStatistics.detectionLocations.length > 0 && (
            <div className="flex flex-col space-y-2">
              <Label>Detections by Country</Label>
              <div>
                {teamStatistics?.detectionLocations.map((stat) => (
                  <div key={stat.country}>
                    <span className="text-sm">
                      <span className="font-mono">{stat.count}</span>
                      :&nbsp;
                      {stat.country}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}

          {teamStatistics.suspiciousLoginLocations.length > 0 && (
            <div className="flex flex-col space-y-2">
              <Label>Suspicious Logins by Country</Label>
              <div>
                {teamStatistics?.suspiciousLoginLocations.map((stat) => (
                  <div key={stat.country}>
                    <span className="text-sm">
                      <span className="font-mono">{stat.count}</span>
                      :&nbsp;
                      {stat.country}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}

          {teamStatistics.operatingSystems.length > 0 && (
            <div className="flex flex-col space-y-2">
              <Label>Endpoints by OS</Label>
              <div>
                {teamStatistics?.operatingSystems.map((stat) => (
                  <div key={stat.operatingSystem}>
                    <span className="text-sm">
                      <span className="font-mono">{stat.count}</span>: &nbsp;
                      {stat.operatingSystem}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}

          {!ttStats.isLoading && ttStats.data != null && (
            <>
              <Statistics
                label="Mean Time To Respond"
                description="MTTR is calculated from the time an event happens to the time the
              case is closed or the Reponded At field is set"
                statistic={
                  numeral(ttStats.data.mttr.average).format('0,00') + 'ms'
                }
              />
              <Statistics
                label="Mean Time To Detect"
                description=" MTTD is calculated from the time an event happens to the time a
              verdict is made"
                statistic={
                  numeral(ttStats.data.mttd.average).format('0,00') + 'ms'
                }
              />
              <Statistics
                label="Mean Time To Verdict"
                description="MTTV is calculated from the time Wirespeed ingests an alert to the
              time a verdict is made"
                statistic={
                  numeral(ttStats.data.mttv.average).format('0,00') + 'ms'
                }
              />
            </>
          )}
        </div>
      </CardContent>
    </Card>
  );
}

function Statistics(props: {
  label: string;
  description?: string;
  statistic: string | number;
  hide?: boolean;
}) {
  if (props.hide) return;
  return (
    <div className="flex flex-col space-y-1">
      <div>
        <Label>{props.label}</Label>
        {props.description && (
          <p className="text-xs text-muted-foreground">{props.description}</p>
        )}
      </div>
      <span className="text-sm flex flex-row gap-1 items-end font-mono">
        {props.statistic}
      </span>
    </div>
  );
}
