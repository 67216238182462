import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem
} from '@/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/components/ui/popover';
import useLocalStorageState from '@/hooks/use-local-storage-state';
import { cn } from '@/lib/utils';
import { useRouteContext } from '@tanstack/react-router';
import { Check, ChevronsUpDown } from 'lucide-react';
import * as React from 'react';
import { useEffect } from 'react';
import { TIMEZONES } from './timezones';

export function TimeZonePicker() {
  const [open, setOpen] = React.useState(false);
  const { team, user } = useRouteContext({ from: '/_application' });
  const storageKey = React.useMemo(() => {
    if (team.id == user.parentTeamId) {
      return 'timezone';
    }
    return `timezone-${team.id}`;
  }, [team.id]);
  const [timezone, setTimezone] = useLocalStorageState<string>(storageKey);

  useEffect(() => {
    if (timezone == null) {
      setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
    }
  }, []);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-auto justify-between"
        >
          {timezone
            ? TIMEZONES.find((tzOpt) => tzOpt.value === timezone)?.label
            : 'Select timezone...'}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Command defaultValue={timezone}>
          <CommandInput placeholder="Search timezone..." />
          <CommandEmpty>No timezone found.</CommandEmpty>
          <CommandGroup className="max-h-[300px] overflow-auto">
            {TIMEZONES.map((tzOpt) => (
              <CommandItem
                key={tzOpt.value}
                value={tzOpt.value + '-' + tzOpt.label}
                onSelect={() => {
                  setTimezone(tzOpt.value);
                  setOpen(false);
                }}
              >
                <Check
                  className={cn(
                    'mr-2 h-4 w-4',
                    timezone === tzOpt.value ? 'opacity-100' : 'opacity-0'
                  )}
                />
                {tzOpt.label}{' '}
                <span className="text-xs ml-1 text-muted-foreground">
                  ({tzOpt.value})
                </span>
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
