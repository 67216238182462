import { Link, createFileRoute } from '@tanstack/react-router';

import CopyToClipboard from '@/components/copy-to-clipboard';
import { Button } from '@/components/ui/button';
import { Logo } from '@/components/ui/logo';
import { apiClient } from '@/lib/api';
import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';

async function getData(id: string) {
  const response = await apiClient.GET('/auth/register/{id}/totp', {
    params: { path: { id } }
  });
  if (response.error != null) {
    throw new Error('Error getting register information');
  }
  return response.data;
}

const getOptions = (id: string) =>
  queryOptions({
    queryKey: ['register-user-mfa'],
    queryFn: () => getData(id)
  });

export const Route = createFileRoute('/_authentication/register_/$token_/mfa')({
  loader: ({ params }) => getOptions(params.token),
  component: MFAForm
});

export default function MFAForm() {
  const { token } = Route.useParams();
  const { data: registerUser } = useSuspenseQuery(getOptions(token));

  return (
    <div className="flex min-h-full flex-1 flex-col items-stretch justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <Logo words />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight ">
          Scan QR Code to set up MFA
        </h2>
      </div>
      <div className="mt-10 sm:mx-auto sm:w-full flex items-center flex-col sm:max-w-sm">
        <img src={registerUser.imageUrl} className="h-48 w-48 mx-auto" />
        <p className="text-sm text-muted-foreground">
          Or click the below code to copy
        </p>
        <code className="mt-2 text-muted-foreground">
          <CopyToClipboard text={registerUser.token} />
        </code>
        <Link
          to="/register/$token/mfa/confirm"
          className="mt-4"
          params={{ token }}
        >
          <Button>Continue</Button>
        </Link>
      </div>
    </div>
  );
}
