import { Button } from '@/components/ui/button';
import { Logo } from '@/components/ui/logo';
import { hasValidAuthToken } from '@/lib/api';
import { Link, createFileRoute, useNavigate } from '@tanstack/react-router';
import { useEffect } from 'react';

export const Route = createFileRoute('/')({
  component: Index
});

function Index() {
  const navigate = useNavigate();

  useEffect(() => {
    if (hasValidAuthToken()) {
      navigate({ to: '/dashboard' });
    }
  }, []);

  return (
    <div className="min-h-screen flex flex-col items-center justify-center min-w-screen">
      <Logo words />
      <div className="mt-8">
        <Link to="/login">
          <Button size="lg">Login</Button>
        </Link>
      </div>
    </div>
  );
}
