import { AppLayout } from '@/components/app-layout';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from '@/components/ui/card';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger
} from '@/components/ui/collapsible';
import { Combobox } from '@/components/ui/combo-box';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Separator } from '@/components/ui/separator';
import { Switch } from '@/components/ui/switch';
import { Textarea } from '@/components/ui/textarea';
import { apiClient } from '@/lib/api';
import { components } from '@/lib/api.types';
import { GLOBAL_TEAMS_QUERY_KEY, GLOBAL_TEAM_QUERY_KEY } from '@/lib/tanstack';
import { useDebounce, useIsMount } from '@/lib/utils';
import { InformationCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import { showArticle } from '@intercom/messenger-js-sdk';
import { CaretRightIcon } from '@radix-ui/react-icons';
import { useQueryClient } from '@tanstack/react-query';
import {
  createFileRoute,
  useNavigate,
  useRouteContext
} from '@tanstack/react-router';
import { Category, CategoryConfig, DOCS, ROLE } from '@wire/shared';
import { TriangleAlertIcon } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'sonner';

export const Route = createFileRoute('/_application/settings/chat-ops')({
  component: ChatOpsSettings
});

export default function ChatOpsSettings() {
  const isMount = useIsMount();
  const { team: defaultTeam } = useRouteContext({ from: '/_application' });
  const [team, setTeam] = useState(defaultTeam);
  const [welcomeMessage, setWelcomeMessage] = useState<string | undefined>(
    team.chatOpsWelcomeMessage
  );
  const [accountLockedMessage, setAccountLockedMessage] = useState<
    string | undefined
  >(team.chatOpsAccountLockedMessage);
  const [testDialogOpen, setTestDialogOpen] = useState(false);
  const [testEmail, setTestEmail] = useState('');
  const [testPhoneNumber, setTestPhoneNumber] = useState('');
  const [testCategory, setTestCategory] = useState<Category>(
    Category.IDENTITY__LOGIN
  );
  const [isTestingChatOps, setIsTestingChatOps] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [chatOpsPerDay, setChatOpsPerDay] = useState<number | undefined>(
    team.maxChatOpsPerDay
  );
  const [chatOpsEscalationDelayMinutes, setChatOpsEscalationDelayMinutes] =
    useState<number | undefined>(team.chatOpsEscalationDelayMinutes);

  const {
    debounced: debouncedWelcomeMessage,
    override: skipDebounceWelcomeMessage
  } = useDebounce(500, welcomeMessage);

  const {
    debounced: debouncedAccountLockedMessage,
    override: skipDebounceAccountLockedMessage
  } = useDebounce(500, accountLockedMessage);
  const {
    debounced: debouncedMaxChatOps,
    override: skipDebounceChatOpsPerDay
  } = useDebounce(500, chatOpsPerDay);
  const {
    debounced: debouncedChatOpsEscalationDelayMinutes,
    override: skipDebounceEscalationDelayMinutes
  } = useDebounce(500, chatOpsEscalationDelayMinutes);

  const maxChatOpsEnabled = useMemo(() => {
    return team.maxChatOpsPerDay != null;
  }, [team.maxChatOpsPerDay]);

  const welcomeMessageEnabled = useMemo(() => {
    return (
      team.chatOpsWelcomeMessage != null &&
      team.chatOpsWelcomeMessage.length > 0
    );
  }, [team.chatOpsWelcomeMessage]);

  const accountLockedMessageEnabled = useMemo(() => {
    return (
      team.chatOpsAccountLockedMessage != null &&
      team.chatOpsAccountLockedMessage.length > 0
    );
  }, [team.chatOpsAccountLockedMessage]);

  async function updateWelcomeMessage(message: string | undefined) {
    await updateTeam(
      {
        chatOpsWelcomeMessage: message
      },
      'Welcome message updated'
    );
  }

  async function updateAccountLockedMessage(message: string | undefined) {
    await updateTeam(
      { chatOpsAccountLockedMessage: message },
      'Account locked message updated'
    );
  }

  useEffect(() => {
    if (isMount) return;
    updateWelcomeMessage(debouncedWelcomeMessage);
  }, [debouncedWelcomeMessage]);

  useEffect(() => {
    if (isMount) return;
    updateAccountLockedMessage(debouncedAccountLockedMessage);
  }, [debouncedAccountLockedMessage]);

  async function updateRateLimit(val?: number) {
    await updateTeam(
      { maxChatOpsPerDay: val, disableMaxChatOps: val == null },
      val != null ? 'Rate limit updated' : 'Rate limit removed'
    );
  }

  useEffect(() => {
    if (isMount) return;
    updateRateLimit(debouncedMaxChatOps);
  }, [debouncedMaxChatOps]);

  async function updateEscalationDelay(val?: number) {
    if (val == null) {
      toast.error('Escalation delay must be present');
      return;
    }
    await updateTeam(
      { chatOpsEscalationDelayMinutes: val },
      'Escalation delay updated'
    );
  }

  useEffect(() => {
    if (isMount) return;
    setChatOpsEscalationDelayMinutes(team.chatOpsEscalationDelayMinutes);
  }, [team.chatOpsEscalationDelayMinutes]);

  useEffect(() => {
    if (isMount) return;
    setChatOpsPerDay(team.maxChatOpsPerDay);
  }, [team.maxChatOpsPerDay]);

  useEffect(() => {
    if (isMount) return;
    updateEscalationDelay(debouncedChatOpsEscalationDelayMinutes);
  }, [debouncedChatOpsEscalationDelayMinutes]);

  async function updateTeam(
    dto: components['schemas']['UpdateTeamDto'],
    success: string
  ) {
    const response = await apiClient.PATCH('/team', {
      body: dto
    });
    if (response.error != null) {
      toast.error(response.error.message);
      return;
    }
    toast.success(success);
    setTeam(response.data);
    await queryClient.invalidateQueries({ queryKey: [GLOBAL_TEAMS_QUERY_KEY] });
    await queryClient.invalidateQueries({ queryKey: [GLOBAL_TEAM_QUERY_KEY] });
  }

  async function applySecureDefaults() {
    await updateTeam(
      {
        chatOpsSecondFactor: true,
        securityChatOps: true,
        chatOpsEscalationDelayMinutes: 10,
        maxChatOpsPerDay: 10
      },
      'Secure defaults applied'
    );
  }

  async function testChatOps() {
    try {
      setIsTestingChatOps(true);
      const response = await apiClient.POST('/chat-ops/test', {
        body: {
          email: testEmail,
          phoneNumber: testPhoneNumber,
          category: testCategory
        }
      });
      if (response.error != null) {
        toast.error(response.error.message);
        return;
      }
      toast.success(`Success, check ${testEmail} for next steps`, {
        action: {
          label: 'View Case',
          onClick: () => {
            navigate({ to: '/cases' });
          }
        }
      });
      setTestDialogOpen(false);
      setTestEmail('');
      setTestPhoneNumber('');
    } catch (error) {
      toast.error('Failed to test chat ops');
    } finally {
      setIsTestingChatOps(false);
    }
  }
  const sortedDetectionSubCategories = useMemo(() => {
    // Group subcategories by their category
    const grouped = Object.values(CategoryConfig).reduce(
      (acc, config) => {
        const group = config.class;
        if (!acc[group]) {
          acc[group] = [];
        }
        acc[group].push(config.category);
        return acc;
      },
      {} as Record<string, Category[]>
    );

    // Sort categories and subcategories within each category
    return Object.entries(grouped)
      .sort(([catA], [catB]) => catA.localeCompare(catB))
      .flatMap(([_, subcategories]) =>
        subcategories.sort((a, b) =>
          CategoryConfig[a].name.localeCompare(CategoryConfig[b].name)
        )
      );
  }, []);

  return (
    <AppLayout>
      <div className="flex flex-col gap-4">
        <Card>
          <CardHeader className="flex items-start flex-col lg:flex-row lg:justify-between lg:items-center">
            <div>
              <CardTitle>Chat Ops Settings</CardTitle>
              <CardDescription>
                Limits and configurations for chat operations
              </CardDescription>
            </div>

            <div className="flex gap-2">
              <Dialog open={testDialogOpen} onOpenChange={setTestDialogOpen}>
                <DialogTrigger asChild>
                  <Button variant="outline">Test Chat Ops</Button>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>Test Chat Ops</DialogTitle>
                    <DialogDescription>
                      Simulate chat ops with an email of your choosing
                    </DialogDescription>
                  </DialogHeader>
                  <Alert variant="warning">
                    <TriangleAlertIcon className="h-4 w-4" />
                    <AlertTitle>Heads up!</AlertTitle>
                    <AlertDescription>
                      This will create a diagnostic detection that may trigger
                      escalation emails and containment actions.
                    </AlertDescription>
                  </Alert>
                  <div className="flex flex-col gap-4">
                    <div>
                      <Label htmlFor="email">Email</Label>
                      <Input
                        id="email"
                        value={testEmail}
                        onChange={(e) => setTestEmail(e.target.value)}
                        type="email"
                        required
                        placeholder="user@example.com"
                      />
                      <p className="text-xs text-muted-foreground mt-1">
                        Email address to send chat ops to. If this user already
                        exists in your directory, that user will be used and
                        containment actions may be performed.
                      </p>
                    </div>
                    <div>
                      <Label htmlFor="phoneNumber">Phone Number</Label>
                      <Input
                        id="phoneNumber"
                        value={testPhoneNumber}
                        onChange={(e) => setTestPhoneNumber(e.target.value)}
                        type="tel"
                        required
                        placeholder="+16128675309"
                      />
                      <p className="text-xs text-muted-foreground mt-1">
                        For SMS multi-factor. If the email above already exists
                        in your directory, the phone number from the directory
                        will be used instead.
                      </p>
                    </div>
                    <Collapsible>
                      <CollapsibleTrigger className="text-xs text-muted-foreground flex flex-row gap-1">
                        Advanced Settings <CaretRightIcon className="h-4 w-4" />
                      </CollapsibleTrigger>
                      <CollapsibleContent className="p-2 bg-muted rounded-md">
                        <div className="flex mt-2 flex-col gap-2">
                          <Label>Subcategory</Label>
                          <Combobox
                            placeholder="Select Subcategory"
                            defaultValue={testCategory as any}
                            emptyMessage="No Change"
                            values={Object.values(Category).map((v) => ({
                              value: v,
                              label: CategoryConfig[v].name
                            }))}
                            onSelect={(v) => setTestCategory(v as any)}
                          />

                          <p className="text-xs text-muted-foreground mt-1">
                            Choose a detection category to test chat ops with.
                            Not all categories will result in chat ops.
                          </p>
                        </div>
                      </CollapsibleContent>
                    </Collapsible>
                  </div>
                  <DialogFooter>
                    <Button
                      variant="outline"
                      onClick={() => setTestDialogOpen(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={testChatOps}
                      disabled={
                        !testEmail || !testPhoneNumber || isTestingChatOps
                      }
                    >
                      {isTestingChatOps ? 'Testing...' : 'Test'}
                    </Button>
                  </DialogFooter>
                </DialogContent>
              </Dialog>
              <Button onClick={applySecureDefaults} variant="outline">
                Apply Secure Defaults
              </Button>
            </div>
          </CardHeader>
          <CardContent className="flex flex-col gap-4">
            {/* <div>
            <div className="flex justify-between items-center">
                <Label className="flex items-center gap-1">
                  Security Team Chat Ops{" "}
                  <InformationCircleIcon
                    onClick={() => showArticle(DOCS.CHAT_OPS)}
                    className="h-4 cursor-pointer w-4"
                  />
                </Label>
                <div className="flex items-center gap-2">
                  <Switch
                    requiredRole={ROLE.ADMIN}
                    checked={team.securityChatOps}
                    onCheckedChange={async (e) => {
                      await updateSecurityChatOps(e);
                    }}
                  />
                </div>
              </div> 

              <p className="text-xs text-muted-foreground mt-1">
                {!securityChatOpsEnabled && (
                  <span>
                    <span className="text-red-500">Disabled</span> -{" "}
                  </span>
                )}{" "}
                Communicate with security team via chat integrations
              </p>
            </div>*/}
            <Setting>
              <SettingDescription>
                <Label className="flex items-center gap-1">
                  Max Messages Per Day{' '}
                  <InformationCircleIcon
                    onClick={() =>
                      showArticle(DOCS.CHAT_OPS_COMMUNICATION_PLAN)
                    }
                    className="h-4 cursor-pointer w-4"
                  />
                </Label>
                <p className="text-xs text-muted-foreground mt-1">
                  {!maxChatOpsEnabled && (
                    <span>
                      <span className="text-red-500">Disabled</span> -{' '}
                    </span>
                  )}{' '}
                  Maximum number of chat ops interactions Wirespeed can make
                  within a 24 hour period
                </p>
              </SettingDescription>
              <SettingInput>
                <div className="flex w-full items-center mt-1 gap-2">
                  <Input
                    value={chatOpsPerDay ?? ''}
                    onChange={(e) =>
                      setChatOpsPerDay(
                        e.target.value ? parseInt(e.target.value) : undefined
                      )
                    }
                    className=""
                    requiredRole={ROLE.ADMIN}
                    placeholder="10"
                    type="number"
                  />
                  <Button
                    onClick={() => {
                      setChatOpsPerDay(undefined);
                      skipDebounceChatOpsPerDay(undefined);
                    }}
                    variant="outline"
                  >
                    <TrashIcon className="h-4 w-4" />
                  </Button>
                </div>
              </SettingInput>
            </Setting>

            <Setting>
              <SettingDescription>
                <Label className="flex items-center gap-1">
                  Escalation Delay{' '}
                  <InformationCircleIcon
                    onClick={() => showArticle(DOCS.CHAT_OPS)}
                    className="h-4 cursor-pointer w-4"
                  />
                </Label>
                <p className="text-xs text-muted-foreground mt-1">
                  How long to wait without a response before continuing
                </p>
              </SettingDescription>
              <SettingInput>
                <div className="w-full flex flex-col">
                  <Input
                    value={chatOpsEscalationDelayMinutes ?? ''}
                    required
                    requiredRole={ROLE.ADMIN}
                    onChange={(e) =>
                      setChatOpsEscalationDelayMinutes(parseInt(e.target.value))
                    }
                    className="mt-1"
                    placeholder="10"
                    type="number"
                  />
                  <p className="text-xs text-muted-foreground self-end mt-1">
                    minutes
                  </p>
                </div>
              </SettingInput>
            </Setting>

            <Setting>
              <SettingDescription>
                <Label className="flex items-center gap-1">
                  VIP Chat Ops{' '}
                  <InformationCircleIcon
                    onClick={() => showArticle(DOCS.CHAT_OPS)}
                    className="h-4 cursor-pointer w-4"
                  />
                </Label>
                <p className="text-xs text-muted-foreground mt-1">
                  {!team.vipChatOps && (
                    <span>
                      <span className="text-red-500">Disabled</span> -{' '}
                    </span>
                  )}{' '}
                  Include VIPs in Chat Ops
                </p>
              </SettingDescription>
              <SettingInput>
                <Switch
                  requiredRole={ROLE.ADMIN}
                  checked={team.vipChatOps}
                  onCheckedChange={async (e) => {
                    await updateTeam({ vipChatOps: e }, 'Settings updated');
                  }}
                />
              </SettingInput>
            </Setting>
            <Setting>
              <SettingDescription>
                <Label className="flex items-center gap-1">
                  Manager Chat Ops{' '}
                  <InformationCircleIcon
                    onClick={() => showArticle(DOCS.CHAT_OPS)}
                    className="h-4 cursor-pointer w-4"
                  />
                </Label>
                <p className="text-xs text-muted-foreground mt-1">
                  {!team.managerChatOps && (
                    <span>
                      <span className="text-red-500">Disabled</span> -{' '}
                    </span>
                  )}{' '}
                  If a user does not respond, escalate to their manager instead
                </p>
              </SettingDescription>
              <SettingInput>
                <Switch
                  requiredRole={ROLE.ADMIN}
                  checked={team.managerChatOps}
                  onCheckedChange={async (e) => {
                    await updateTeam({ managerChatOps: e }, 'Settings updated');
                  }}
                />
              </SettingInput>
            </Setting>

            <Setting>
              <SettingDescription>
                <Label className="flex items-center gap-1">
                  Multi-factor Verification{' '}
                  <InformationCircleIcon
                    onClick={() => showArticle(DOCS.CHAT_OPS)}
                    className="h-4 cursor-pointer w-4"
                  />
                </Label>
                <p className="text-xs text-muted-foreground mt-1">
                  {!team.chatOpsSecondFactor && (
                    <span>
                      <span className="text-red-500">Disabled</span> -{' '}
                    </span>
                  )}{' '}
                  When the user says they performed the action, Require SMS MFA
                  or manager chat ops (if no mobile number is found) to verify
                  identity
                </p>
              </SettingDescription>
              <SettingInput>
                <Switch
                  requiredRole={ROLE.ADMIN}
                  checked={team.chatOpsSecondFactor}
                  onCheckedChange={async (e) => {
                    await updateTeam(
                      { chatOpsSecondFactor: e },
                      'Settings updated'
                    );
                  }}
                />
              </SettingInput>
            </Setting>

            <Setting>
              <SettingDescription>
                <Label className="flex items-center gap-1">
                  Welcome Message{' '}
                  <InformationCircleIcon
                    onClick={() =>
                      showArticle(DOCS.CHAT_OPS_COMMUNICATION_PLAN)
                    }
                    className="h-4 cursor-pointer w-4"
                  />
                </Label>
                <p className="text-xs text-muted-foreground mt-1">
                  {!welcomeMessageEnabled && (
                    <span>
                      <span className="text-red-500">Disabled</span> -{' '}
                    </span>
                  )}{' '}
                  Introductory message to be sent to users upon their first
                  message from Wirespeed
                </p>
              </SettingDescription>
              <SettingInput>
                <div className="flex w-full items-stretch mt-1 gap-2">
                  <Textarea
                    value={welcomeMessage ?? ''}
                    rows={4}
                    requiredRole={ROLE.ADMIN}
                    placeholder="Introduce Wirespeed to your team..."
                    onChange={(e) => setWelcomeMessage(e.target.value)}
                  />
                  <div>
                    <Button
                      className="h-full"
                      onClick={() => {
                        setWelcomeMessage(undefined);
                        skipDebounceWelcomeMessage(undefined);
                      }}
                      variant="outline"
                    >
                      <TrashIcon className="h-4 w-4" />
                    </Button>
                  </div>
                </div>
              </SettingInput>
            </Setting>

            <Setting>
              <SettingDescription>
                <Label className="flex items-center gap-1">
                  Account Locked Message{' '}
                  <InformationCircleIcon
                    onClick={() =>
                      showArticle(DOCS.CHAT_OPS_COMMUNICATION_PLAN)
                    }
                    className="h-4 cursor-pointer w-4"
                  />
                </Label>
                <p className="text-xs text-muted-foreground mt-1">
                  {!accountLockedMessageEnabled && (
                    <span>
                      <span className="text-red-500">Disabled</span> -{' '}
                    </span>
                  )}{' '}
                  Message sent when a user's account is locked in response to an
                  actionable detection
                </p>
              </SettingDescription>
              <SettingInput>
                <Textarea
                  value={accountLockedMessage ?? ''}
                  rows={4}
                  requiredRole={ROLE.ADMIN}
                  placeholder="Introduce Wirespeed to your team..."
                  onChange={(e) => setAccountLockedMessage(e.target.value)}
                />
              </SettingInput>
            </Setting>
          </CardContent>
        </Card>
      </div>
    </AppLayout>
  );
}

function Setting({ children }: { children: React.ReactNode }) {
  return (
    <>
      <div className="grid lg:grid-cols-4">{children}</div>
      <Separator />
    </>
  );
}

function SettingDescription({ children }: { children: React.ReactNode }) {
  return <div className="lg:col-span-3">{children}</div>;
}

function SettingInput({ children }: { children: React.ReactNode }) {
  return (
    <div className="flex items-center w-full lg:justify-end">{children}</div>
  );
}
