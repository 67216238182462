import { HoverCardItem } from '@/components/hover-cards/util';
import { apiClient } from '@/lib/api';
import { camelCaseToTitleCase } from '@/lib/utils';
import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';
import { useRouteContext } from '@tanstack/react-router';

export default function IntegrationHoverCard(props: { id: string }) {
  const { integrationMetadata } = useRouteContext({
    from: '/_application'
  });
  const {
    data: { integration }
  } = useSuspenseQuery(
    queryOptions({
      queryKey: ['integration', props.id],
      queryFn: async () => {
        const integrationResponse = await apiClient.GET('/integration/{id}', {
          params: { path: { id: props.id } }
        });
        if (integrationResponse.error != null) {
          throw new Error('Error getting integration information');
        }
        return {
          integration: integrationResponse.data
        };
      }
    })
  );

  return (
    <div className="flex flex-col gap-2">
      <HoverCardItem title="Platform">
        {integrationMetadata?.find((c) => c.slug == integration.platform)?.name}
      </HoverCardItem>
      {Object.entries(integration.identityFields).map(([key, value]) => (
        <HoverCardItem title={camelCaseToTitleCase(key)}>{value}</HoverCardItem>
      ))}
    </div>
  );
}
