import { AppLayout } from '@/components/app-layout';
import ManageExclusion from '@/components/manage-exclusion';
import { TableCard } from '@/components/table-card';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from '@/components/ui/card';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu';
import { Logo } from '@/components/ui/logo';
import { apiClient } from '@/lib/api';
import { components } from '@/lib/api.types';
import { localDateTime, useTimezone } from '@/lib/time';
import { getDetailsFromRaw } from '@/lib/utils';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { CheckIcon } from '@radix-ui/react-icons';
import {
  keepPreviousData,
  queryOptions,
  useQuery,
  useQueryClient,
  useSuspenseQuery
} from '@tanstack/react-query';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import {
  DETECTION_QUERY_FIELDS,
  FileRiskTypeConfig,
  QUERY_OPERATOR,
  ROLE,
  createDetectionQuery,
  getStatusConfigByStatus
} from '@wire/shared';
import { useMemo, useState } from 'react';
import { toast } from 'sonner';

export const Route = createFileRoute('/_application/assets/files/$fileId')({
  component: File,
  loader: async ({ params, context }) => {
    const response = await context.queryClient.ensureQueryData(
      getOptions(params.fileId)
    );
    context.title = response.file?.displayName;
  }
});

async function getData(fileId: string) {
  const [file] = await Promise.all([
    apiClient.GET('/file/{id}', {
      params: { path: { id: fileId } }
    })
  ]);
  if (file.error != null) {
    throw new Error('Error getting file information');
  }
  return {
    file: file.data
  };
}

export const FILE_QUERY_KEY = 'file';
const getOptions = (fileId: string) =>
  queryOptions({
    queryKey: [FILE_QUERY_KEY, fileId],
    queryFn: () => getData(fileId)
  });

async function searchCases(
  searchSettings: components['schemas']['SearchCasesDto'],
  fileId: string
) {
  const [cases] = await Promise.all([
    apiClient.POST('/cases', {
      body: { ...searchSettings, assetType: 'FILE', assetId: fileId }
    })
  ]);
  if (cases.error != null) {
    throw new Error('Error getting cases information');
  }
  return cases.data;
}
export const CASES_QUERY_KEY = 'case-settings';
const getSearchCaseOptions = (
  searchSettings: components['schemas']['SearchCasesDto'],
  fileId: string
) =>
  queryOptions({
    queryKey: [CASES_QUERY_KEY, searchSettings, fileId],
    queryFn: () => searchCases(searchSettings, fileId),
    placeholderData: keepPreviousData
  });

function File() {
  const { fileId } = Route.useParams();
  const [createExclusionDialogOpen, setCreateExclusionDialogOpen] =
    useState(false);
  const [searchSettings, setSearchSettings] = useState<
    components['schemas']['SearchCasesDto']
  >({});
  const casesQuery = useQuery(getSearchCaseOptions(searchSettings, fileId));
  const {
    data: { file }
  } = useSuspenseQuery(getOptions(fileId));
  const navigate = useNavigate();
  const { timezone } = useTimezone();
  const queryClient = useQueryClient();

  async function reenrich() {
    const response = await apiClient.POST('/file/{id}/reenrich', {
      params: { path: { id: fileId } }
    });
    if (response.error != null) {
      toast.error(response.error.message);
      return;
    }
    await queryClient.invalidateQueries({ queryKey: [FILE_QUERY_KEY, fileId] });
    toast.success('File reenriched');
  }

  const defaultExclusionQuery = useMemo(() => {
    let values: [string, string] = ['', ''];
    if (file.sha256 != null) {
      values = [DETECTION_QUERY_FIELDS.FILE_SHA256, file.sha256];
    } else if (file.sha1 != null) {
      values = [DETECTION_QUERY_FIELDS.FILE_SHA1, file.sha1];
    } else if (file.name != null) {
      values = [DETECTION_QUERY_FIELDS.FILE_NAME, file.name];
    } else {
      return '';
    }
    return createDetectionQuery(
      values[0],
      QUERY_OPERATOR.SOME_ARRAY_VALUES_EQUAL,
      values[1]
    );
  }, []);

  const metadata = useMemo(() => {
    if (file.metadata == null) return;
    const { story, ...out } = file.metadata;
    return out;
  }, [file.metadata]);

  const rawDetails = useMemo(() => {
    if (metadata == null) return 'No metadata available';
    const details = getDetailsFromRaw(metadata, timezone);
    const out = details.map((v) => (
      <div className="overflow-hidden" key={v.key}>
        <h2 className="font-semibold">{v.key}</h2>
        <span className="truncate whitespace-nowrap text-ellipsis">
          {v.value}
        </span>
      </div>
    ));
    const data = metadata.threatNames;
    out.push(
      <div className="overflow-hidden" key={'threat-name-count'}>
        <h2 className="font-semibold">Threat Engines Matched</h2>
        <span className="truncate font-mono whitespace-nowrap text-ellipsis">
          {data.filter((v) => v.name.length && !v.excluded).length}/
          {data.filter((v) => !v.excluded).length}
        </span>
      </div>
    );
    return out;
  }, [metadata]);

  return (
    <AppLayout>
      <ManageExclusion
        title="Create Exclusion"
        query={defaultExclusionQuery}
        name={file.name}
        providedDetectionSid
        detectionSid={casesQuery.data?.data[0]?.detectionSids[0]}
        description="Automatically close future detections that match this query"
        open={createExclusionDialogOpen}
        onClose={() => setCreateExclusionDialogOpen(false)}
      />
      <div className="flex flex-col gap-4">
        {!file.enrichedViaIntegration && (
          <Alert variant="warning">
            <ExclamationTriangleIcon className="h-4 w-4" />
            <AlertTitle>Heads up!</AlertTitle>
            <AlertDescription>
              We were unable to enrich this file with our enrichment
              integrations. We are taking the source integration's information
              as authoritative. This may increase false positives.
            </AlertDescription>
          </Alert>
        )}
        <Card>
          <CardHeader className="bg-muted/50 space-y-0 lg:items-top mb-4 flex flex-col gap-4 lg:flex-row lg:justify-between">
            <div className="flex flex-col justify-center">
              <CardTitle className="break-all">
                {file.path}
                {file.path != null
                  ? file.path?.includes('/')
                    ? '/'
                    : '\\'
                  : ''}
                {file.name}
              </CardTitle>
              <CardDescription className="mt-1 hyphens-auto break-words">
                {file.metadata?.story != null ? file.metadata.story : 'File'}
              </CardDescription>
            </div>
            <DropdownMenu requiredRole={ROLE.ANALYST}>
              <DropdownMenuTrigger asChild>
                <Button>Actions</Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="max-w-64">
                <DropdownMenuItem onClick={reenrich}>
                  <div>
                    <h4 className="font-semibold">Reenrich</h4>
                    <p className="text-muted-foreground">
                      Update file enrichments to determine malware, late stage
                      tools, etc...
                    </p>
                  </div>
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => setCreateExclusionDialogOpen(true)}
                >
                  <div>
                    <h4 className="font-semibold">Create Exclusion</h4>
                    <p className="text-muted-foreground">
                      Automatically ignore detections that are associated with
                      this file
                    </p>
                  </div>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </CardHeader>
          <CardContent className="grid grid-cols-1 lg:grid-cols-3 overflow-auto gap-x-8 gap-y-2">
            <div>
              <h2 className="font-semibold">Created At</h2>
              <p className="text-sm">
                {localDateTime(file.createdAt, timezone)}
              </p>
            </div>
            <div>
              <h2 className="font-semibold">SHA1</h2>
              <p className="text-sm">{file.sha1 ?? '-'}</p>
            </div>
            <div>
              <h2 className="font-semibold">SHA256</h2>
              <p className="text-sm break-all">{file.sha256 ?? '-'}</p>
            </div>
            {rawDetails}

            {file.toolName && (
              <div>
                <h2 className="font-semibold">Tool Name</h2>
                <p className="text-sm">{file.toolName}</p>
              </div>
            )}
            {file.lateStageTool && (
              <div>
                <h2 className="font-semibold">Late Stage Tool</h2>
                <p className="text-sm">
                  <CheckIcon className="text-green-500 h-6 w-6" />
                </p>
              </div>
            )}
            {file.remoteManagementTool && (
              <div>
                <h2 className="font-semibold">Remote Management Tool</h2>
                <p className="text-sm">
                  <CheckIcon className="text-green-500 h-6 w-6" />
                </p>
              </div>
            )}
            {file.infoStealer && (
              <div>
                <h2 className="font-semibold">Info Stealer</h2>
                <p className="text-sm">
                  <CheckIcon className="text-green-500 h-6 w-6" />
                </p>
              </div>
            )}
            {file.ransomware && (
              <div>
                <h2 className="font-semibold">Ransomware</h2>
                <p className="text-sm">
                  <CheckIcon className="text-green-500 h-6 w-6" />
                </p>
              </div>
            )}
            {file.malware && (
              <div>
                <h2 className="font-semibold">Malware</h2>
                <p className="text-sm">
                  <CheckIcon className="text-green-500 h-6 w-6" />
                </p>
              </div>
            )}
            {file.nuisance && (
              <div>
                <h2 className="font-semibold">Nuisance</h2>
                <p className="text-sm">
                  <CheckIcon className="text-green-500 h-6 w-6" />
                </p>
              </div>
            )}
            {file.liveOffTheLand && (
              <div>
                <h2 className="font-semibold">Live Off The Land</h2>
                <p className="text-sm">
                  <CheckIcon className="text-green-500 h-6 w-6" />
                </p>
              </div>
            )}
            {file.benign && (
              <div>
                <h2 className="font-semibold">Benign</h2>
                <p className="text-sm">
                  <CheckIcon className="text-green-500 h-6 w-6" />
                </p>
              </div>
            )}
            {file.cryptoMiner && (
              <div>
                <h2 className="font-semibold">Crypto Miner</h2>
                <p className="text-sm">
                  <CheckIcon className="text-green-500 h-6 w-6" />
                </p>
              </div>
            )}
            {file.fileRisk && (
              <div>
                <h2 className="flex items-center gap-1 font-semibold">
                  <span>Wirespeed Verdict</span>
                  <Logo />
                </h2>
                <p className="text-sm">
                  {FileRiskTypeConfig[file.fileRisk].name}
                </p>
              </div>
            )}
          </CardContent>
        </Card>
        <TableCard
          onClick={(row) =>
            navigate({
              to: '/cases/$caseId',
              params: { caseId: row.id }
            })
          }
          query={casesQuery}
          onUpdate={(settings) =>
            setSearchSettings({ ...searchSettings, ...settings })
          }
          headers={[
            {
              display: 'ID',
              key: 'sid',
              sortable: true
            },
            {
              display: 'Name',
              key: 'name',
              sortable: true
            },
            {
              display: 'Status',
              key: 'status',
              format: (value) => getStatusConfigByStatus(value)?.display
            },
            {
              display: 'Created At',
              key: 'firstDetectionSourceDetectedAt',
              sortable: true,
              format(value) {
                return localDateTime(value, timezone);
              }
            }
          ]}
        >
          <CardHeader className="bg-muted/50 mb-4">
            <CardTitle>Related Cases</CardTitle>
            <CardDescription>
              Cases that this file has been associated with
            </CardDescription>
          </CardHeader>
        </TableCard>
      </div>
    </AppLayout>
  );
}
