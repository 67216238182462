import { cn } from '@/lib/utils';
import { PropsWithChildren } from 'react';

export function AppLayout(props: PropsWithChildren<{ className?: string }>) {
  return (
    <div
      className={cn(
        'p-4 flex-1 flex flex-col relative h-full',
        props.className
      )}
    >
      {props.children}
    </div>
  );
}
