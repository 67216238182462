import { createFileRoute, useNavigate } from '@tanstack/react-router';

import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { apiClient } from '@/lib/api';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { z } from 'zod';

import { Logo } from '@/components/ui/logo';

export const Route = createFileRoute('/_authentication/forgot_/$token')({
  component: ForgotPassword
});

const formSchema = z
  .object({
    password: z.string().min(12).max(256),
    confirmPassword: z.string().min(12).max(256)
  })
  .refine((input) => input.password == input.confirmPassword, {
    message: 'Passwords do not match',
    path: ['confirmPassword']
  });

function ForgotPassword() {
  const navigate = useNavigate();
  const { token } = Route.useParams();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      password: '',
      confirmPassword: ''
    }
  });

  async function onSubmit(values: z.infer<typeof formSchema>) {
    const response = await apiClient.POST('/auth/forgot/reset', {
      body: { password: values.password, token: token }
    });

    if (response.error != null) {
      toast.error(response.error.message);
      return;
    }
    toast.success('Password reset, please log in.');
    await navigate({ to: '/login' });
  }

  return (
    <div className="flex min-h-full flex-1 flex-col items-stretch justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <Logo words />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight ">
          Reset password
        </h2>
      </div>
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Password</FormLabel>
                  <FormControl>
                    <Input
                      autoFocus
                      type="password"
                      placeholder="Password"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="confirmPassword"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Confirm Password</FormLabel>
                  <FormControl>
                    <Input
                      type="password"
                      placeholder="Confirm Password"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button
              disabled={
                !form.formState.isValid ||
                form.formState.isSubmitting ||
                form.formState.isSubmitSuccessful
              }
              type="submit"
            >
              Reset
            </Button>
          </form>
        </Form>
      </div>
    </div>
  );
}
