import {
  useCategorizationSamples,
  useCategorizationTemplate
} from '@/components/admin-settings/categorization/categorization.util';
import { AppLayout } from '@/components/app-layout';
import { IntegrationLogo } from '@/components/settings/add-integration';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from '@/components/ui/card';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu';
import { apiClient } from '@/lib/api';
import { Link, createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_application/admin/categorization/')({
  component: RouteComponent
});

function RouteComponent() {
  const { integrationMetadata } = Route.useRouteContext();
  const { template, refetch } = useCategorizationTemplate();
  const { samples } = useCategorizationSamples();

  async function addIntegration(integration: string) {
    template.integrations.push({ platform: integration as any, groups: [] });
    const response = await apiClient.PATCH('/categorization/categorizers', {
      body: template
    });
    await refetch();
  }

  return (
    <>
      <AppLayout>
        <Card>
          <CardHeader className="flex flex-row justify-between">
            <div>
              <CardTitle>Categorization Integrations</CardTitle>
              <CardDescription>
                Configure the categorization integrations for your organization.
              </CardDescription>
            </div>
            <div>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button>Add Integration</Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="max-h-[500px] overflow-y-auto">
                  {integrationMetadata
                    .filter(
                      (v) =>
                        !template.integrations.some((i) => i.platform == v.slug)
                    )
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((integration) => (
                      <DropdownMenuItem
                        onClick={() => addIntegration(integration.slug)}
                        key={integration.slug}
                      >
                        {integration.name}
                      </DropdownMenuItem>
                    ))}
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </CardHeader>
          <CardContent className="flex flex-wrap gap-2">
            {template.integrations
              .sort((a, b) => a.platform.localeCompare(b.platform))
              .map((v) => {
                // Calculate total samples for this integration
                const totalSamples = samples.filter((sample) =>
                  v.groups.some((group) =>
                    group.rules.some((rule) => rule.id === sample.ruleId)
                  )
                ).length;

                return (
                  <Link
                    to="/admin/categorization/$integration"
                    params={{ integration: v.platform }}
                    key={v.platform}
                  >
                    <Button
                      variant="outline"
                      className="flex h-auto flex-row gap-2"
                    >
                      <IntegrationLogo
                        config={integrationMetadata.find(
                          (i) => i.slug == v.platform
                        )}
                        className="h-4 w-4"
                      />
                      <div className="flex flex-col items-start">
                        <span>
                          {
                            integrationMetadata.find(
                              (i) => i.slug == v.platform
                            )?.name
                          }
                        </span>
                        <span className="text-xs text-muted-foreground">
                          {v.groups.length} group
                          {v.groups.length !== 1 ? 's' : ''} •{' '}
                          {v.groups.reduce(
                            (acc, group) => acc + (group.rules?.length || 0),
                            0
                          )}{' '}
                          rule
                          {v.groups.reduce(
                            (acc, group) => acc + (group.rules?.length || 0),
                            0
                          ) !== 1
                            ? 's'
                            : ''}{' '}
                          • {totalSamples} sample{totalSamples !== 1 ? 's' : ''}
                        </span>
                      </div>
                    </Button>
                  </Link>
                );
              })}
          </CardContent>
        </Card>
      </AppLayout>
    </>
  );
}
