'use client';
import NotificationSettings from '@/components/settings/notifications';
import { getNotificationQueryOptions } from '@/components/settings/notifications.options';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_application/settings/notifications')({
  loader: async ({ context }) => {
    await context.queryClient.ensureQueryData(getNotificationQueryOptions());
  },
  component: NotificationSettings
});
