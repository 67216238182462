import { HoverCardItem } from '@/components/hover-cards/util';
import { apiClient } from '@/lib/api';
import { components } from '@/lib/api.types';
import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';
import { Link } from '@tanstack/react-router';
import { useMemo } from 'react';

export default function IPAddressHoverCard(props: { id: string }) {
  const { data } = useSuspenseQuery(
    queryOptions({
      queryKey: ['ipAddress', props.id],
      queryFn: async () => {
        const response = await apiClient.GET('/ip/{id}', {
          params: { path: { id: props.id } }
        });
        if (response.error != null) {
          throw new Error('Error getting IP address information');
        }
        let location: components['schemas']['Location'] | null = null;
        if (response.data.locationId != null) {
          const locationResponse = await apiClient.GET('/location/{id}', {
            params: { path: { id: response.data.locationId } }
          });
          if (locationResponse.error == null) {
            location = locationResponse.data;
          }
        }
        return { ...response.data, location };
      }
    })
  );

  const privacyServices = useMemo(() => {
    const services = ['hosting', 'proxy', 'tor', 'vpn'] as const;
    return services
      .filter((service) => data.metadata?.privacy?.[service])
      .map((service) => (
        <HoverCardItem
          boolean
          key={service}
          title={
            service == 'vpn'
              ? 'VPN'
              : service.charAt(0).toUpperCase() + service.slice(1)
          }
        >
          {data.metadata?.privacy?.[service]}
        </HoverCardItem>
      ));
  }, [data]);

  return (
    <div className="flex flex-col gap-2">
      <HoverCardItem title="IP Address">
        {data.ipv4 ?? data.ipv6 ?? '-'}
      </HoverCardItem>
      <HoverCardItem title="Location">
        <Link
          to="/assets/locations/$locationId"
          params={{ locationId: data.locationId ?? '' }}
        >
          {data.location?.displayName ?? '-'}
        </Link>
      </HoverCardItem>
      {privacyServices}
    </div>
  );
}
