import { NavSidebar } from '@/components/nav/nav-sidebar';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator
} from '@/components/ui/breadcrumb';
import { Logo } from '@/components/ui/logo';
import { Separator } from '@/components/ui/separator';
import {
  SidebarInset,
  SidebarProvider,
  SidebarTrigger
} from '@/components/ui/sidebar';
import { Skeleton } from '@/components/ui/skeleton';
import { apiClient, hasValidAuthToken } from '@/lib/api';
import { components } from '@/lib/api.types';
import { queryClient } from '@/lib/tanstack';
import { showArticle } from '@intercom/messenger-js-sdk';
import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';
import {
  Link,
  Outlet,
  SearchSchemaInput,
  createFileRoute,
  redirect,
  useRouteContext,
  useRouterState
} from '@tanstack/react-router';
import { DOCS } from '@wire/shared';
import React, { Suspense, useMemo } from 'react';

// This is a simple way to add breadcrumbs for each root route
// Otherwise we'd have to add layouts and a bunch of other stuff
const breadCrumbMap: Record<string, string> = {
  '/cases': 'Cases',
  '/events': 'Events',
  '/dashboard': 'Dashboard',
  '/assets/users': 'Directory Users',
  '/assets/endpoints': 'Endpoints',
  '/assets/locations': 'Locations',
  '/assets/processes': 'Processes',
  '/assets/ips': 'IP Addresses',
  '/assets/domains': 'Domains',
  '/assets/files': 'Files',
  '/assets/user-agents': 'User Agents',
  '/settings/chat-ops': 'Chat Ops',
  '/settings/containment': 'Containment',
  '/settings/automation': 'Exclusions',
  '/settings/integrations': 'Integrations',
  '/settings/verdicts': 'Verdicts'
};

export const NAVIGATION_ASSET_COUNTS = 'navigation-asset-counts';
const getCountOptions = () =>
  queryOptions({
    queryKey: [NAVIGATION_ASSET_COUNTS],
    queryFn: () => getAssetCounts(),
    refetchInterval: 60_000
  });

async function getAssetCounts() {
  const response = await apiClient.GET('/asset/counts');
  if (response.error != null || response.data == null) {
    throw new Error('Error getting asset counts');
  }
  return response.data;
}

export type WSPDURLParams = components['schemas']['PaginationDto'];

export const Route = createFileRoute('/_application')({
  component: LayoutComponent,
  validateSearch: (
    search: WSPDURLParams & { switchTeamId?: string } & SearchSchemaInput
  ) => {
    return {
      switchTeamId: search.switchTeamId,
      page:
        search.page != null
          ? parseInt(search.page as unknown as string)
          : undefined,
      size:
        search.size != null
          ? parseInt(search.size as unknown as string)
          : undefined,
      search: search.search,
      filter: search.filter,
      orderBy: search.orderBy,
      orderDir: search.orderDir
    };
  },
  beforeLoad: ({ location, search, context }) => {
    if (search.switchTeamId != null) {
      const searchParams = new URLSearchParams(location.searchStr);
      searchParams.delete('switchTeamId');
      if (context.user != null && context.user.teamId != search.switchTeamId) {
        throw redirect({
          to: '/switch',
          search: {
            from: context.user.teamId,
            to: search.switchTeamId,
            redirect:
              searchParams.get('redirect') ??
              location.pathname + '?' + searchParams.toString()
          }
        });
      } else {
        // Remove the query param from the URL
        throw redirect({
          to: '.',
          search: { ...location.search, switchTeamId: undefined }
        });
      }
    }
    if (!hasValidAuthToken()) {
      throw redirect({
        to: '/login',
        search: { redirect: location.href }
      });
    }
    if (location.pathname.startsWith('/switch')) {
      return;
    }
    if (context.user.superAdmin && location.pathname.startsWith('/admin')) {
      return;
    }
  },
  loader: async ({ context, location }) => {
    const match = Object.entries(breadCrumbMap).find(([path, title]) =>
      location.pathname.startsWith(path)
    );
    context.path = match?.[0];
    context.title = match?.[1];
    await queryClient.ensureQueryData(getCountOptions());
  }
});

function LayoutComponent() {
  const matches = useRouterState({ select: (s) => s.matches });
  const { team } = useRouteContext({ from: '/_application' });
  const { data: assetCounts } = useSuspenseQuery(getCountOptions());
  const breadcrumbs = useMemo(() => {
    return matches
      .filter(
        (match) =>
          match.context.title ||
          (match.context.titles != null && match.context.titles.length)
      )
      .flatMap(({ pathname, context }) => {
        if (context.titles != null) {
          return context.titles.map((title: string, i: number) => ({
            title,
            path: context.paths?.[i] ?? pathname
          }));
        }
        return [
          {
            title: context.title,
            path: context.path ?? pathname
          }
        ];
      });
  }, [matches]);
  return (
    <div className="min-h-screen flex w-screen  flex-col">
      <SidebarProvider>
        <NavSidebar assetCounts={assetCounts} />
        <SidebarInset className="bg-muted/40 flex-1 overflow-hidden dark:bg-background">
          <Suspense
            fallback={
              <div className="flex-1 w-full h-full p-4 flex flex-col items-center justify-center">
                <Skeleton className="w-full flex-1 rounded-md" />
              </div>
            }
          >
            <div className="flex h-screen flex-col">
              <header className="flex bg-sidebar border-b h-16 shrink-0 items-center gap-2 transition-[width,height] ease-linear group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-12">
                <div className="flex justify-between w-full items-center gap-2 px-4">
                  <div className="flex items-center gap-2">
                    <SidebarTrigger className="-ml-1" />
                    <Separator orientation="vertical" className="mr-2 h-4" />
                    <Breadcrumb>
                      <BreadcrumbList>
                        {breadcrumbs?.map((crumb, i) => (
                          <React.Fragment key={crumb.path}>
                            <BreadcrumbItem
                              key={crumb.path}
                              className="hidden md:block"
                            >
                              {i === breadcrumbs.length - 1 ? (
                                <BreadcrumbPage>
                                  <span className="max-w-[250px] truncate">
                                    {crumb.title}
                                  </span>
                                </BreadcrumbPage>
                              ) : (
                                <Link to={crumb.path}>
                                  <span className="max-w-[250px] truncate">
                                    {crumb.title}
                                  </span>
                                </Link>
                              )}
                            </BreadcrumbItem>
                            {i < breadcrumbs.length - 1 && (
                              <BreadcrumbSeparator
                                key={'separator-' + i}
                                className="hidden md:block"
                              />
                            )}
                          </React.Fragment>
                        ))}
                      </BreadcrumbList>
                    </Breadcrumb>
                  </div>
                  <div className="flex items-center gap-4">
                    {team.testMode && (
                      <div
                        onClick={() => showArticle(DOCS.TEAM)}
                        className="hover:bg-muted cursor-pointer shadow-sm border border-input h-9 rounded-md p-2 text-xs font-semibold flex-shrink-0 items-center flex text-amber-500"
                      >
                        Test Mode
                      </div>
                    )}
                    <Link to="/dashboard">
                      <Logo className="h-6 m-0 w-auto" words />
                    </Link>
                  </div>
                </div>
              </header>
              <div className="overflow-auto h-full">
                <Outlet />
              </div>
            </div>
          </Suspense>
        </SidebarInset>
      </SidebarProvider>
    </div>
  );
}
