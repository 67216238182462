import { TableCard } from '@/components/table-card';
import { CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { apiClient, formatSearchQuery } from '@/lib/api';
import { components } from '@/lib/api.types';
import { getTimeZoneAbbreviation, useTimezone } from '@/lib/time';
import {
  keepPreviousData,
  queryOptions,
  useQuery,
  useQueryClient
} from '@tanstack/react-query';
import {
  createFileRoute,
  useNavigate,
  useRouteContext,
  useSearch
} from '@tanstack/react-router';
import { ROLE } from '@wire/shared';
import { useState } from 'react';
import { toast } from 'sonner';

export const Route = createFileRoute('/_application/assets/endpoints/')({
  component: Assets,
  loaderDeps: ({ search }) => {
    return {
      search
    };
  },
  loader: ({ context, deps, route }) => {
    context.queryClient.ensureQueryData(getAssetOptions(deps.search));
    context.queryClient.ensureQueryData(getHVAOptions());
  }
});

function getAssetOptions(
  settings: components['schemas']['PaginationDto'] = {}
) {
  return queryOptions({
    queryKey: [ASSETS_QUERY_KEY, settings],
    queryFn: () => getAssets(settings as any),
    placeholderData: keepPreviousData
  });
}

function getHVAOptions(settings: components['schemas']['PaginationDto'] = {}) {
  return queryOptions({
    queryKey: [HVA_AUTOMATIONS_KEY, settings],
    queryFn: () => getHVAAutomations(settings),
    placeholderData: keepPreviousData
  });
}

async function getHVAAutomations(
  searchSettings: components['schemas']['PaginationDto'] = {}
) {
  const response = await apiClient.POST('/endpoint/hva/automation', {
    body: searchSettings
  });

  if (response.error != null) {
    throw new Error('Error getting HVA automations');
  }

  return response.data;
}

async function getAssets(
  searchSettings: components['schemas']['EndpointSearchDto'] = {
    filter: 'name'
  }
) {
  const search = formatSearchQuery(searchSettings.search);
  const response = await apiClient.POST('/endpoint', {
    body: {
      ...searchSettings,
      search
    }
  });

  if (response.error != null) {
    throw new Error('Error getting assets');
  }

  return response.data;
}
const HVA_AUTOMATIONS_KEY = 'settings-hva-automations';
const ASSETS_QUERY_KEY = 'settings-assets';

export default function Assets() {
  const queryClient = useQueryClient();
  const urlSearch = useSearch({ from: '/_application/assets/endpoints/' });
  const { integrationMetadata } = useRouteContext({ from: '/_application' });
  const [assetSearchSettings, setAssetSearchSettings] = useState<
    components['schemas']['EndpointSearchDto']
  >({
    ...urlSearch,
    filter: urlSearch.filter as any
  });
  const navigate = useNavigate();
  const [hvaAutomationSearchSettings, setHVAAutomationSearchSettings] =
    useState<components['schemas']['PaginationDto']>();
  const { timezone } = useTimezone();
  const assetQuery = useQuery(getAssetOptions(assetSearchSettings));
  const hvaAutomationsQuery = useQuery(
    getHVAOptions(hvaAutomationSearchSettings)
  );

  async function deleteHVAAutomation(id: string) {
    const response = await apiClient.DELETE('/endpoint/hva/automation/{id}', {
      params: { path: { id } }
    });

    if (response.error != null) {
      toast.error('Error deleting automation');
      return;
    }

    await queryClient.invalidateQueries({ queryKey: [HVA_AUTOMATIONS_KEY] });
    toast.warning('Automation deleted');
  }

  function updateAssetSearchSettings(
    settings: components['schemas']['EndpointSearchDto']
  ) {
    setAssetSearchSettings({
      ...assetSearchSettings,
      ...settings,
      filter: settings.filter ?? assetSearchSettings?.filter ?? 'name'
    });
  }

  async function updateAsset(
    id: string,
    dto: components['schemas']['UpdateEndpointDto']
  ) {
    const response = await apiClient.PATCH('/endpoint/{id}', {
      params: {
        path: { id }
      },
      body: dto
    });

    if (response.error != null) {
      return toast.error('Error updating asset');
    }

    await queryClient.invalidateQueries({
      queryKey: [ASSETS_QUERY_KEY]
    });
    toast.success('Asset updated');
  }

  async function addHVAAutomation() {
    if (
      assetSearchSettings == null ||
      assetSearchSettings.filter == null ||
      assetSearchSettings.search == null
    ) {
      toast.error('Please select search field and search');
      return;
    }
    const search = formatSearchQuery(assetSearchSettings.search);

    const response = await apiClient.PUT('/endpoint/hva/automation', {
      body: {
        search: search,
        field: assetSearchSettings.filter
      }
    });

    if (response.error != null) {
      if (response.error.statusCode == 409) {
        toast.error('HVA automation already exists');
      } else {
        toast.error('Error creating HVA automation');
      }
      return;
    }

    await queryClient.invalidateQueries({
      queryKey: [ASSETS_QUERY_KEY]
    });
    await queryClient.invalidateQueries({
      queryKey: [HVA_AUTOMATIONS_KEY]
    });
    toast.success('HVA Automation created');
  }

  return (
    <div className="flex flex-col gap-4">
      <TableCard
        embedded
        onUpdate={(v) => updateAssetSearchSettings(v as any)}
        query={assetQuery}
        searchable
        storeStateInUrl
        onClickNavigate={(row) => ({
          to: '/assets/endpoints/$endpointId',
          params: { endpointId: row.id }
        })}
        searchFilter={{
          label: 'Search Field',
          placeholder: 'Select field',
          defaultValue: 'name',
          values: [
            { key: 'name', display: 'Name' },
            { key: 'ip', display: 'IP Address' }
          ]
        }}
        headers={[
          { display: 'Name', key: 'name', sortable: true },
          { display: 'Live', key: 'live', sortable: true },
          { display: 'Private IP', key: 'privateIpAddress', sortable: true },
          {
            display: 'Public IP',
            key: 'id',
            truncate: 256,
            format(value, row) {
              let trunc = '';
              let ips = row.publicIPs ?? [];
              if (ips.length > 3) {
                trunc = '...';
                ips = ips.slice(0, 3);
              }
              return ips.map((v) => v.ipv4 ?? v.ipv6).join(', ') + trunc;
            }
          },
          { display: 'OS', key: 'operatingSystem', sortable: true },
          { display: 'Server', key: 'server', sortable: true },
          { display: 'Workstation', key: 'workstation', sortable: true },
          { display: 'HVA', key: 'hva', sortable: true },
          {
            display: `First Seen (${getTimeZoneAbbreviation(timezone)})`,
            key: 'createdAt',
            sortable: true,
            dateTime: true
          },
          {
            display: `Updated At (${getTimeZoneAbbreviation(timezone)})`,
            key: 'updatedAt',
            sortable: true,
            dateTime: true
          },
          {
            display: 'HVA Locked',
            key: 'hvaOverriddenByUser',
            info: ` A user has manually updated the HVA status of this row, therefore it is excluded from all automations. To remove this lock, select the 3-dot menu and click "Disable HVA Lock".`
          },
          {
            display: 'Source',
            key: 'integrationPlatform',
            format: (v) => integrationMetadata.find((i) => i.slug === v)?.name
          }
        ]}
        rowActions={[
          {
            name: (row) => (row.hva ? `Disable HVA` : 'Enable HVA'),
            onClick: (row) => updateAsset(row.id, { hva: !row.hva }),

            requiredRole: ROLE.ANALYST
          },
          {
            name: (row) =>
              row.hvaOverriddenByUser ? `Disable HVA Lock` : 'Enable HVA Lock',

            requiredRole: ROLE.ANALYST,
            onClick: (row) =>
              updateAsset(row.id, {
                hvaOverriddenByUser: !row.hvaOverriddenByUser
              })
          }
        ]}
        tableActions={[
          {
            display: 'Add New HVA Automation',
            onClick: addHVAAutomation,
            requiredRole: ROLE.ANALYST,
            showOnSearch: true
          }
        ]}
      >
        <CardHeader>
          <div className="flex  gap-4 items-start lg:items-center flex-col lg:flex-row justify-between">
            <div className="flex flex-col gap-2">
              <CardTitle>Endpoints</CardTitle>
              <CardDescription>
                All endpoints ingested from your detection integrations. Use{' '}
                <kbd>*</kbd> for a wildcard, <kbd>"quotes"</kbd> for exact
                match, or <kbd>_</kbd> for single character wildcard. IP address
                searches require valid IP or CIDR addresses.
              </CardDescription>
            </div>
          </div>
        </CardHeader>
      </TableCard>
      <TableCard
        embedded
        onUpdate={setHVAAutomationSearchSettings}
        query={hvaAutomationsQuery}
        searchable
        headers={[
          { display: 'Search Field', key: 'searchField' },
          { display: 'Search', key: 'search' }
        ]}
        rowActions={[
          {
            name: 'Delete Automation',
            onClick: (row) => deleteHVAAutomation(row.id),
            confirm: true,
            requiredRole: ROLE.ANALYST,
            confirmMessage:
              'Are you sure you want to delete this HVA Automation? This will also remove any HVAs identified from this automation.'
          }
        ]}
      >
        <CardHeader>
          <div className="flex items-start space-y-4 lg:space-y-0 lg:items-center flex-col lg:flex-row justify-between">
            <div>
              <CardTitle>High Value Asset Automations</CardTitle>
              <CardDescription>
                Search queries to automatically identify HVAs in your
                organization. To create a new automation, issue a search above
                and select 'Create Automation'.
              </CardDescription>
            </div>
          </div>
        </CardHeader>
      </TableCard>
    </div>
  );
}
