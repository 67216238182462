import { AppLayout } from '@/components/app-layout';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from '@/components/ui/card';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog';
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger
} from '@/components/ui/hover-card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Logo } from '@/components/ui/logo';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/ui/select';
import { Separator } from '@/components/ui/separator';
import { Textarea } from '@/components/ui/textarea';
import { apiClient } from '@/lib/api';
import { components } from '@/lib/api.types';
import { cn, useDebounce } from '@/lib/utils';
import { ExclamationTriangleIcon } from '@radix-ui/react-icons';
import { useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import {
  Category,
  CategoryClassConfig,
  CategoryConfig,
  CategoryRule,
  Verdict,
  VerdictConfig,
  oxfordComma
} from '@wire/shared';
import { PencilIcon } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'sonner';

export const Route = createFileRoute(
  '/_application/settings/verdicts/category/$category'
)({
  component: RouteComponent,
  loader: ({ context, params }) => {
    const category = params.category.toUpperCase() as unknown as Category;
    const categoryConfig = CategoryConfig[category];
    const groupConfig = CategoryClassConfig[categoryConfig.class];
    context.titles = [groupConfig.name, categoryConfig.name];
    context.paths = [
      `/settings/verdicts/class/${categoryConfig.class.toLowerCase()}`
    ];
  }
});

type VerdictRule = components['schemas']['VerdictRule'];

export async function createRuleFromWspdRule(rule: VerdictRule) {
  const response = await apiClient.PUT('/verdict/rule', {
    body: {
      category: rule.category,
      wspdRule: rule.wspdRule as CategoryRule,
      name: rule.name,
      description: rule.description
    }
  });
  if (response.error != null) {
    toast.error(response.error.message);
  }
  return response.data!.id!;
}

interface RuleItemProps {
  rule: VerdictRule;
  index: number;
  total: number;
  onDelete: () => void;
  onSelect: () => void;
}

function RuleItem({ rule, index, total, onDelete, onSelect }: RuleItemProps) {
  const outcomes = useMemo(() => {
    const parts: string[] = [];
    if (rule.chatOps) {
      parts.push('Chat ops');
    }
    if (rule.containUser) {
      parts.push('Contain User');
    }
    if (rule.containEndpoint) {
      parts.push('Contain Endpoint');
    }
    if (rule.close) {
      parts.push('Close');
    }
    if (rule.escalate) {
      parts.push('Escalate');
    }
    if (rule.disabled) {
      parts.push('Skip');
    }
    return oxfordComma(parts);
  }, [rule]);

  return (
    <div className="border-b flex flex-col ">
      <div className="flex flex-col">
        <div className="grid grid-cols-1 rounded-md p-2 lg:grid-cols-2 gap-2">
          <div className="flex flex-col">
            <div className="text-xs text-primary">
              {index === 0 ? 'if' : rule.default ? 'else' : 'else if'}
            </div>
            <div className="text-xs">{rule.description}</div>
          </div>

          <div>
            <div className="text-xs text-primary">then</div>
            <div className="text-xs">{outcomes}</div>
          </div>
        </div>
        <div className="flex p-2 mt-2 flex-row justify-end gap-2 text-xs">
          <button
            onClick={onSelect}
            className="p-1 border rounded-md flex justify-center text-muted-foreground hover:bg-muted"
          >
            <PencilIcon className="h-4 w-4" />
          </button>
          {!rule.id && (
            <HoverCard openDelay={0}>
              <HoverCardTrigger className="p-1 border rounded-md cursor-pointer flex justify-center items-center">
                <Logo className="h-4 w-4" />
              </HoverCardTrigger>
              <HoverCardContent>
                This rule is managed by Wirespeed
              </HoverCardContent>
            </HoverCard>
          )}
          {rule.id && (
            <HoverCard openDelay={0}>
              <HoverCardTrigger className="p-1 border rounded-md cursor-pointer flex justify-center items-center">
                <ExclamationTriangleIcon className="h-4 w-4 text-destructive" />
              </HoverCardTrigger>
              <HoverCardContent>
                This is a Wirespeed managed rule that you have overridden and
                goes against Wirespeed's default secure settings.
                <Button
                  variant="outlineDestructive"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onDelete();
                  }}
                >
                  Reset
                </Button>
              </HoverCardContent>
            </HoverCard>
          )}
        </div>
        <div
          className={cn(
            'text-xs transition-all mt-1 text-muted-foreground flex overflow-hidden flex-row',
            {
              'group-hover:h-6 h-0':
                (!rule.default && index > 0) ||
                (!rule.default && index < total - 1) ||
                (rule.id && !rule.wspdRule) ||
                (rule.id && rule.wspdRule)
            }
          )}
        >
          {rule.id && !rule.wspdRule && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onDelete();
              }}
              className="p-1 rounded-md flex justify-center flex-1 hover:bg-background"
            >
              Delete
            </button>
          )}
          {rule.id && rule.wspdRule && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onDelete();
              }}
              className="p-1 rounded-md flex justify-center flex-1 hover:bg-background"
            >
              Reset
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

interface SectionProps {
  title: string;
  description: React.ReactNode;
  children: React.ReactNode;
}

function Section({ title, description, children }: SectionProps) {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
      <div>
        <h3 className="font-medium">{title}</h3>
        <p className="text-sm text-muted-foreground">{description}</p>
      </div>
      <div className="flex flex-col gap-2">{children}</div>
    </div>
  );
}

function RouteComponent() {
  const { category: categoryLower } = Route.useParams();
  const category = categoryLower.toUpperCase() as unknown as Category;
  const [showCreate, setShowCreate] = useState(false);
  const [selectedRuleKey, setSelectedRuleKey] = useState<string | null>(null);

  const categoryConfig = useMemo(() => {
    return CategoryConfig[category];
  }, [category]);

  const { data: rules, refetch: refetchRules } = useSuspenseQuery({
    queryKey: ['verdict-rules', category],
    queryFn: async () => {
      const response = await apiClient.GET('/verdict/rules/{category}', {
        params: {
          path: {
            category
          }
        }
      });
      return response.data!;
    }
  });

  const selectedRule = useMemo(() => {
    return rules.rules.find(
      (r) => r.id === selectedRuleKey || r.wspdRule === selectedRuleKey
    );
  }, [rules.rules, selectedRuleKey]);

  async function deleteRule(rule: VerdictRule) {
    if (!rule.id) {
      return;
    }
    await apiClient.DELETE('/verdict/rule/{id}', {
      params: {
        path: { id: rule.id }
      }
    });
    await refetchRules();
    if (rule.wspdRule) {
      toast.success('Rule reset');
    } else {
      toast.success('Rule deleted');
    }
  }

  return (
    <AppLayout>
      {selectedRule != null && (
        <EditRuleDialog
          refetch={async () => {
            await refetchRules({});
          }}
          close={() => setSelectedRuleKey(null)}
          rule={selectedRule}
        />
      )}

      <div className="flex flex-col gap-4">
        <Card>
          <CardHeader className="flex flex-col items-start">
            <CardTitle>{categoryConfig.name} Verdict Settings</CardTitle>
            <CardDescription>{categoryConfig.description}</CardDescription>
          </CardHeader>
          <CardContent className="flex flex-col gap-4">
            <Section
              title="Verdicts"
              description={
                <div className="flex flex-col gap-4">
                  <div>
                    When {categoryConfig.name.toLowerCase()} detections are
                    discovered, Wirespeed needs to determine what to do with
                    them. We call these <b>Verdicts</b>.
                  </div>
                  <div>
                    When a new {categoryConfig.name.toLowerCase()} detection is
                    ingested by Wirespeed, each verdict is evaluated and if it
                    matches, the actions from that verdict are executed. If no
                    rules for that verdict match, the next verdict is evaluated.
                    This continues until a verdict is found or the default
                    verdict is reached.
                  </div>
                  <div>
                    Wirespeed ships with secure defaults for all detections and
                    we encourage you to open a support ticket before making any
                    significant changes here, as it may result in increased
                    false positives and negatives.
                  </div>
                </div>
              }
            >
              {rules.rules.map((rule, idx) => (
                <RuleItem
                  key={rule.id || idx}
                  rule={rule}
                  index={idx}
                  total={rules.rules.length}
                  onDelete={() => deleteRule(rule)}
                  onSelect={() =>
                    setSelectedRuleKey((rule.id ?? rule.wspdRule)!)
                  }
                />
              ))}
            </Section>
          </CardContent>
        </Card>
      </div>
    </AppLayout>
  );
}

interface EditRuleDialogProps {
  close: () => void;
  rule: VerdictRule;
  refetch: () => Promise<void>;
}

function EditRuleDialog(props: EditRuleDialogProps) {
  const disabled = props.rule?.managedByParent ?? false;
  const key = props.rule?.wspdRule ?? props.rule?.id;
  const [name, setName] = useState(props.rule?.name ?? '');
  const [description, setDescription] = useState(props.rule?.description ?? '');
  const debouncedName = useDebounce(500, name);
  const debouncedDescription = useDebounce(500, description);

  useEffect(() => {
    const update: Partial<components['schemas']['UpdateVerdictRuleDto']> = {};
    if (debouncedName.debounced !== props.rule.name) {
      update.name = debouncedName.debounced;
    }
    if (debouncedDescription.debounced !== props.rule.description) {
      update.description = debouncedDescription.debounced;
    }
    if (Object.keys(update).length > 0) {
      updateSetting(update);
    }
  }, [debouncedName.debounced, debouncedDescription.debounced]);

  async function updateSetting(
    dto: Partial<components['schemas']['UpdateVerdictRuleDto']>
  ) {
    let id: string = props.rule.id!;
    if (props.rule.managedByWspd) {
      const response = await createRuleFromWspdRule(props.rule);
      id = response;
    }
    // TODO: Way too many hardcoded fields here
    let showSuccess = true;

    if (dto.disabled) {
      dto.close = false;
      dto.chatOps = false;
      dto.escalate = false;
      dto.containUser = false;
      dto.containEndpoint = false;
    }
    if (dto.close) {
      dto.chatOps = false;
      dto.disabled = false;
      dto.escalate = false;
    }
    if (dto.escalate) {
      dto.close = false;
      dto.chatOps = false;
      dto.disabled = false;
    }
    if (dto.chatOps === false) {
      dto.chatOpsMFA = false;
      dto.managerChatOps = false;
      dto.vipChatOps = false;
    }
    if (dto.chatOps) {
      dto.chatOpsMFA = true;
      dto.close = false;
    }

    if (Object.entries(dto).some(([k, v]) => k !== 'disabled' && v)) {
      dto.disabled = false;
    }
    if (
      Object.values(dto).every((v) => v === false) &&
      Object.entries(props.rule)
        .filter(([k, v]) => typeof v == 'boolean')
        .every(([k, v]) => v === false || dto[k as keyof typeof dto] === false)
    ) {
      toast.warning(
        'You must select at least one option, defaulting to escalate'
      );
      showSuccess = false;
      dto.escalate = true;
    }
    if (props.rule.managedByWspd || props.rule.managedByParent) {
      dto = {
        close: props.rule.close,
        containUser: props.rule.containUser,
        containEndpoint: props.rule.containEndpoint,
        escalate: props.rule.escalate,
        chatOps: props.rule.chatOps,
        chatOpsMFA: props.rule.chatOpsMFA,
        managerChatOps: props.rule.managerChatOps,
        vipChatOps: props.rule.vipChatOps,
        ...dto
      };
    }
    await apiClient.PATCH('/verdict/rule/{id}', {
      params: {
        path: { id }
      },
      body: {
        ...dto
      }
    });
    await props.refetch();
    if (showSuccess) {
      toast.success('Setting updated');
    }
  }

  return (
    <Dialog open={props.rule != null} onOpenChange={(o) => !o && props.close()}>
      <DialogContent className="max-w-none lg:max-w-3xl">
        <DialogHeader>
          <DialogTitle>Edit {props.rule?.name}</DialogTitle>
          <DialogDescription>Edit the settings for this rule</DialogDescription>
        </DialogHeader>
        <div className="flex flex-col gap-3">
          <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-1">
              <Label>Name</Label>
              <Input value={name} onChange={(e) => setName(e.target.value)} />
            </div>
            <div className="flex flex-col gap-1">
              <Label>Description</Label>
              <Textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          </div>
          <Separator />
          <div>
            <h3 className="font-medium">Verdict</h3>
            <p className="text-sm text-muted-foreground">
              Should this rule result in a specific verdict? If unsure, leave as
              suspicious.
            </p>
            <div className="flex mt-2 flex-col items-start gap-1">
              <Select
                value={props.rule.verdict}
                onValueChange={(value) =>
                  updateSetting({
                    verdict: value as Verdict
                  })
                }
              >
                <SelectTrigger className="w-fit">
                  <SelectValue placeholder="Select a verdict" />
                </SelectTrigger>
                <SelectContent>
                  {Object.values(Verdict).map((v) => (
                    <SelectItem key={v} value={v}>
                      {VerdictConfig[v].display}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>
          <Separator />
          <div>
            <h3 className="font-medium">Response</h3>
            <p className="text-sm text-muted-foreground">
              When this verdict is matched, what response actions would you like
              to perform?
            </p>
            <div className="flex flex-col mt-2">
              <CheckboxLabelWithInformation
                message="Contain the users associated with this detection"
                label="Contain User"
                key={`contain-user-${key}`}
                checked={props.rule.containUser}
                disabled={disabled}
                onSelect={(checked) =>
                  updateSetting({ containUser: checked == true })
                }
              />
              <CheckboxLabelWithInformation
                message="Contain the endpoints associated with this detection"
                label="Contain Endpoint"
                key={`contain-endpoint-${key}`}
                checked={props.rule.containEndpoint}
                disabled={disabled}
                onSelect={(checked) =>
                  updateSetting({ containEndpoint: checked == true })
                }
              />
              <CheckboxLabelWithInformation
                message="Escalate this detection to your security team"
                label="Escalate"
                key={`escalate-${key}`}
                checked={props.rule.escalate}
                disabled={disabled}
                onSelect={(checked) =>
                  updateSetting({ escalate: checked == true })
                }
              />
              <CheckboxLabelWithInformation
                message="Close this detection and do not allow any further actions"
                label="Close"
                key={`close-${key}`}
                checked={props.rule.close}
                disabled={disabled}
                onSelect={(checked) =>
                  updateSetting({ close: checked == true })
                }
              />
              <CheckboxLabelWithInformation
                message={
                  props.rule.default
                    ? 'You cannot disable the default rule'
                    : 'Disable this setting'
                }
                className={cn({
                  'text-muted-foreground cursor-not-allowed': props.rule.default
                })}
                label="Disabled"
                key={`disabled-${key}`}
                checked={props.rule.disabled}
                disabled={disabled || props.rule.default}
                onSelect={(checked) =>
                  updateSetting({ disabled: checked == true })
                }
              />
              <CheckboxLabelWithInformation
                message="Allow Wirespeed to chat ops users in your organization for this detection"
                label="Chat Ops"
                key={`chat-ops-${key}`}
                checked={props.rule.chatOps}
                disabled={disabled}
                onSelect={(checked) =>
                  updateSetting({ chatOps: checked == true })
                }
              />
            </div>
          </div>

          {props.rule.chatOps && (
            <>
              <Separator />
              <div className="flex flex-col gap-2">
                <h3 className="font-medium">Chat Ops Settings</h3>
                <div className="flex flex-col">
                  <CheckboxLabelWithInformation
                    message="If a user claims that they did perform an activity, require SMS MFA to verify their identity"
                    label="Chat Ops MFA"
                    key={`chat-ops-mfa-${key}`}
                    checked={props.rule.chatOpsMFA}
                    disabled={disabled}
                    onSelect={(checked) =>
                      updateSetting({ chatOpsMFA: checked == true })
                    }
                  />
                  <CheckboxLabelWithInformation
                    message="If a user does not respond or is unable to MFA, escalate to their manager"
                    label="Manager Chat Ops"
                    key={`manager-chat-ops-${key}`}
                    checked={props.rule.managerChatOps}
                    disabled={disabled}
                    onSelect={(checked) =>
                      updateSetting({ managerChatOps: checked == true })
                    }
                  />
                  <CheckboxLabelWithInformation
                    message="Allow Wirespeed to chat ops VIPs in your organization for this detection"
                    label="VIP Chat Ops"
                    key={`vip-chat-ops-${key}`}
                    checked={props.rule.vipChatOps}
                    disabled={disabled}
                    onSelect={(checked) =>
                      updateSetting({ vipChatOps: checked == true })
                    }
                  />
                  <div className="flex flex-col ml-6 items-start mt-4 gap-1">
                    <Label>Timeout Verdict</Label>
                    <p className="text-sm text-muted-foreground">
                      If a user does not respond before the chat ops timeout,
                      continue with this verdict
                    </p>
                    <Select
                      value={props.rule.chatOpsTimeoutVerdict}
                      onValueChange={(value) =>
                        updateSetting({
                          chatOpsTimeoutVerdict: value as Verdict
                        })
                      }
                    >
                      <SelectTrigger className="w-fit">
                        <SelectValue placeholder="Select a verdict" />
                      </SelectTrigger>
                      <SelectContent>
                        {Object.values(Verdict).map((v) => (
                          <SelectItem key={v} value={v}>
                            {VerdictConfig[v].display}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}

function CheckboxLabelWithInformation(props: {
  message: string;
  label: string;
  onSelect: (selected: boolean) => void;
  className?: string;
  disabled?: boolean;
  key?: string;
  checked?: boolean;
}) {
  return (
    <div
      onClick={() => props.onSelect(!props.checked)}
      className="flex rounded-md p-2 flex-col cursor-pointer hover:bg-primary/20 items-start gap-1"
    >
      <div className="flex flex-row w-full items-center gap-1">
        <Checkbox
          id={props.key}
          checked={props.checked}
          disabled={props.disabled}
          onCheckedChange={(checked) => props.onSelect(checked == true)}
        />
        <Label
          className={cn('cursor-pointer', props.className)}
          htmlFor={props.key}
        >
          {props.label}
        </Label>
      </div>
      <p className="text-sm ml-5 text-muted-foreground">{props.message}</p>
    </div>
  );
}
