import * as React from 'react';

import { cn } from '@/lib/utils';
import { useRouteContext } from '@tanstack/react-router';
import { ROLE, userHasRole } from '@wire/shared';

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const Textarea = React.forwardRef<
  HTMLTextAreaElement,
  TextareaProps & { requiredRole?: ROLE }
>(({ className, requiredRole, ...props }, ref) => {
  const context = useRouteContext({ from: '__root__' });
  if (
    context.user != null &&
    requiredRole != null &&
    !userHasRole(context.user.role, requiredRole)
  ) {
    props.disabled = true;
  }
  return (
    <textarea
      className={cn(
        'flex min-h-[60px] w-full rounded-md border border-input bg-transparent px-3 py-2 text-sm shadow-sm placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50',
        className
      )}
      ref={ref}
      {...props}
    />
  );
});
Textarea.displayName = 'Textarea';

export { Textarea };
