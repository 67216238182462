import { AppLayout } from '@/components/app-layout';
import CopyToClipboard from '@/components/copy-to-clipboard';
import AddTeamMember from '@/components/settings/add-team-member';
import DeleteTeam from '@/components/settings/delete-team';
import { TableCard } from '@/components/table-card';
import TeamStatistics from '@/components/team-statistics';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/ui/select';
import { Switch } from '@/components/ui/switch';
import { TemplateStringInput } from '@/components/ui/template-string-input';
import { apiClient, setImpersonatedAPIToken } from '@/lib/api';
import { components } from '@/lib/api.types';
import { GLOBAL_TEAM_QUERY_KEY } from '@/lib/tanstack';
import { localDateTime, useTimezone } from '@/lib/time';
import { useDebounce } from '@/lib/utils';
import { InformationCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import { showArticle } from '@intercom/messenger-js-sdk';
import {
  keepPreviousData,
  useQuery,
  useQueryClient
} from '@tanstack/react-query';
import { useRouteContext, useRouter } from '@tanstack/react-router';
import {
  DOCS,
  ROLE,
  ROLE_CONFIG,
  getDisplayFromTemplateString
} from '@wire/shared';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'sonner';

export const SETTINGS_TEAM_MEMBERS_QUERY = 'settings-team-members';

async function getTeamMembers(search: components['schemas']['PaginationDto']) {
  const response = await apiClient.POST('/team/users', { body: search });
  if (response.error != null) {
    throw new Error('Error getting team members');
  }
  return response.data;
}

async function loadSampleCase() {
  const response = await apiClient.GET('/cases/example');
  if (response.error != null) {
    throw new Error('Error getting sample case');
  }
  return response.data;
}

export default function TeamSettings() {
  const [searchSettings, setSearchSettings] = useState<
    components['schemas']['PaginationDto']
  >({});
  const [sampleCase, setSampleCase] = useState<components['schemas']['Case']>();
  const { team } = useRouteContext({ from: '/_application' });
  const [teamName, setTeamName] = useState(team.name);
  const [teamInbox, setTeamInbox] = useState<string | null | undefined>(
    team.escalationEmail
  );
  const { timezone } = useTimezone();

  const [teamSubjectLine, setTeamSubjectLine] = useState<
    string | null | undefined
  >(team.escalationSubjectLine);
  const { debounced: debouncedTeamSubjectLine } = useDebounce(
    750,
    teamSubjectLine
  );
  const [testMode, setTestMode] = useState(team.testMode);
  const [richCaseNotifications, setRichCaseNotifications] = useState(
    team.richCaseNotifications
  );
  const { user } = useRouteContext({ from: '/_application' });
  const { debounced: debouncedTeamName } = useDebounce(250, teamName);
  const {
    debounced: debouncedTeamInbox,
    override: overrideDebouncedTeamInbox
  } = useDebounce(750, teamInbox);
  const queryClient = useQueryClient();
  const router = useRouter();
  const teamMemberQuery = useQuery({
    queryKey: [SETTINGS_TEAM_MEMBERS_QUERY, searchSettings],
    queryFn: () => getTeamMembers(searchSettings),
    placeholderData: keepPreviousData
  });

  useEffect(() => {
    if (debouncedTeamSubjectLine != team.escalationSubjectLine) {
      void updateTeam({ escalationSubjectLine: debouncedTeamSubjectLine });
    }
  }, [debouncedTeamSubjectLine]);

  useEffect(() => {
    setTeamSubjectLine(team.escalationSubjectLine);
  }, [team.escalationSubjectLine]);

  const subjectLinePreview = useMemo(() => {
    if (sampleCase == null || teamSubjectLine == null) {
      return null;
    }
    return getDisplayFromTemplateString(teamSubjectLine, sampleCase);
  }, [sampleCase, teamSubjectLine]);

  useEffect(function initSampleCase() {
    void loadSampleCase().then(setSampleCase);
  }, []);

  async function toggleTestMode() {
    const response = await apiClient.PATCH('/team', {
      body: { testMode: !testMode }
    });

    if (response.error != null) {
      toast.error(response.error.message);
      return;
    }
    toast.success('Team updated');
    // Refresh all data
    await queryClient.invalidateQueries({ queryKey: [] });
    await router.invalidate();
    setTestMode(!testMode);
  }

  async function updateTeam(dto: components['schemas']['UpdateTeamDto']) {
    const response = await apiClient.PATCH('/team', {
      body: dto
    });
    if (response.error != null) {
      toast.error(response.error.message);
      return;
    }
    toast.success('Team updated');
    await queryClient.invalidateQueries({
      queryKey: [GLOBAL_TEAM_QUERY_KEY]
    });
  }

  useEffect(() => {
    if (debouncedTeamName != team.name) {
      void updateTeam({ name: debouncedTeamName });
    }
  }, [debouncedTeamName]);

  useEffect(() => {
    if (debouncedTeamInbox != team.escalationEmail) {
      void updateTeam({ escalationEmail: debouncedTeamInbox });
    }
  }, [debouncedTeamInbox]);

  async function reinviteUser(id: string) {
    const response = await apiClient.PUT('/team/reinvite/{userId}', {
      params: { path: { userId: id } }
    });
    if (response.error != null) {
      toast.error(response.error.message);
      return;
    }
    toast.success(
      'Credentials have been reset and the user has been sent an email to register new credentials'
    );
    await queryClient.invalidateQueries({
      queryKey: [SETTINGS_TEAM_MEMBERS_QUERY]
    });
  }

  async function lockUser(id: string) {
    const response = await apiClient.PATCH('/users/{id}/lock', {
      params: { path: { id } }
    });
    if (response.error != null) {
      toast.error(response.error.message);
      return;
    }
    toast.warning('User locked');
    await queryClient.invalidateQueries({
      queryKey: [SETTINGS_TEAM_MEMBERS_QUERY]
    });
  }

  async function unlockUser(id: string) {
    const response = await apiClient.PATCH('/users/{id}/unlock', {
      params: { path: { id } }
    });
    if (response.error != null) {
      toast.error(response.error.message);
      return;
    }
    toast.warning('User unlocked');
    await queryClient.invalidateQueries({
      queryKey: [SETTINGS_TEAM_MEMBERS_QUERY]
    });
  }

  async function updateUserRole(
    id: string,
    dto: components['schemas']['UpdateUserRoleDto']
  ) {
    const response = await apiClient.PATCH('/users/{id}/role', {
      params: { path: { id } },
      body: dto
    });
    if (response.error != null) {
      toast.error(response.error.message);
      return;
    }
    toast.warning(
      'Role updated, user will need to log out and back in for UI changes to take effect.'
    );
    await queryClient.invalidateQueries({
      queryKey: [SETTINGS_TEAM_MEMBERS_QUERY]
    });
  }

  async function impersonateUser(user: components['schemas']['User']) {
    const response = await apiClient.POST('/admin/impersonate/{userId}', {
      params: { path: { userId: user.id } }
    });
    if (response.error != null) {
      toast.error(response.error.message);
      return;
    }
    setImpersonatedAPIToken(response.data.accessToken);
    toast.success('Impersonated user');
    window.location.reload();
  }

  async function deleteUser({ id }: { id: string }) {
    const toastId = toast.loading('Deleting user...');
    const response = await apiClient.DELETE('/users/{id}', {
      params: { path: { id } }
    });
    toast.dismiss(toastId);
    if (response.error != null) {
      toast.error('Error deleting user');
    } else {
      toast.warning('User deleted');
    }
    await queryClient.invalidateQueries({
      queryKey: [SETTINGS_TEAM_MEMBERS_QUERY]
    });
  }

  return (
    <AppLayout>
      <div className="flex flex-col gap-4">
        <Card>
          <CardHeader className="flex lg:items-center justify-between lg:flex-row">
            <CardTitle>Team Settings</CardTitle>
            {user.superAdmin && (
              <div className="flex flex-row flex-wrap gap-2">
                <DeleteTeam />
              </div>
            )}
          </CardHeader>
          <CardContent>
            <div className="flex flex-col gap-8">
              <div className="grid grid-cols-1 lg:grid-cols-2">
                <div className="flex flex-col space-y-2">
                  <Label>ID</Label>
                  <CopyToClipboard className="text-sm" text={team.id} />
                </div>
                <div className="flex flex-col space-y-2">
                  <Label>Created At</Label>
                  <span className="text-sm">
                    {localDateTime(team.createdAt, timezone)}
                  </span>
                </div>
              </div>
              <div className="flex flex-col space-y-2">
                <Label>Name</Label>
                <Input
                  placeholder="Team name"
                  value={teamName}
                  onChange={(e) => setTeamName(e.target.value)}
                />
              </div>
              <div className="flex flex-row gap-8 flex-wrap">
                {!team.serviceProvider && (
                  <div className="flex flex-col space-y-2">
                    <Label className="flex items-center gap-1">
                      Test Mode{' '}
                      <InformationCircleIcon
                        onClick={() => showArticle(DOCS.TEAM)}
                        className="h-4 w-4 cursor-pointer"
                      />
                    </Label>
                    <Switch
                      requiredRole={ROLE.ADMIN}
                      checked={testMode}
                      onCheckedChange={(e) => toggleTestMode()}
                    />
                  </div>
                )}
                <div className="flex flex-col space-y-2">
                  <Label className="flex items-center gap-1">
                    Rich Case Email Notifications{' '}
                    <InformationCircleIcon
                      onClick={() => showArticle(DOCS.TEAM)}
                      className="h-4 w-4 cursor-pointer"
                    />
                  </Label>
                  <Switch
                    requiredRole={ROLE.ADMIN}
                    checked={richCaseNotifications}
                    onCheckedChange={(e) => setRichCaseNotifications(e)}
                  />
                </div>
              </div>
              <div className="flex flex-col space-y-2">
                <Label className="flex items-center gap-1">
                  Team Inbox{' '}
                  <InformationCircleIcon
                    onClick={() => showArticle(DOCS.TEAM)}
                    className="h-4 w-4 cursor-pointer"
                  />
                </Label>
                <div className="flex flex-row gap-2 items-center">
                  <Input
                    placeholder="Team inbox"
                    value={teamInbox ?? ''}
                    onChange={(e) => setTeamInbox(e.target.value)}
                  />
                  <Button
                    onClick={() => {
                      setTeamInbox(null);
                      overrideDebouncedTeamInbox(null);
                    }}
                    variant="outline"
                  >
                    <TrashIcon className="h-4 w-4" />
                  </Button>
                </div>
                <p className="text-xs text-muted-foreground">
                  Optional email to forward all case escalations to
                </p>
              </div>
              <div className="flex flex-col space-y-2">
                <Label className="flex items-center gap-1">
                  Subject Line{' '}
                  <InformationCircleIcon
                    onClick={() => showArticle(DOCS.TEAM)}
                    className="h-4 w-4 cursor-pointer"
                  />
                </Label>
                <div className="flex flex-row gap-2 items-center">
                  <TemplateStringInput
                    inputObject={sampleCase}
                    value={teamSubjectLine ?? ''}
                    onChange={(e) => setTeamSubjectLine(e)}
                  />
                </div>
                <p className="text-xs text-muted-foreground">
                  {subjectLinePreview ?? 'No preview available'}
                </p>
              </div>
            </div>
          </CardContent>
        </Card>

        <TableCard
          query={teamMemberQuery}
          onUpdate={setSearchSettings}
          searchable
          headers={[
            { key: 'fullName', display: 'Name' },
            { key: 'email', display: 'Email' },
            { key: 'locked', display: 'Locked' },
            {
              key: 'role',
              display: 'Role',
              format(value, row) {
                return (
                  <Select
                    requiredRole={ROLE.ADMIN}
                    defaultValue={row.role}
                    onValueChange={(value) =>
                      updateUserRole(row.id, { role: value as ROLE })
                    }
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Role">
                        {ROLE_CONFIG[row.role].name}
                      </SelectValue>
                    </SelectTrigger>
                    <SelectContent>
                      {Object.values(ROLE).map((role) => (
                        <SelectItem key={role} value={role}>
                          {ROLE_CONFIG[role].name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                );
              }
            }
          ]}
          rowActions={[
            {
              name: 'Reset Credentials',
              requiredRole: ROLE.ADMIN,
              confirm: true,
              confirmMessage: (row) =>
                `Are you sure you want to reset the credentials for ${row.email}?`,
              onClick: (row) => reinviteUser(row.id)
            },
            {
              name: (row) => (row.locked ? 'Unlock User' : 'Lock User'),
              requiredRole: ROLE.ADMIN,
              confirm: true,
              confirmMessage: (row) =>
                row.locked
                  ? `Are you sure you want to unlock ${row.email}?`
                  : `Are you sure you want to lock ${row.email}?`,
              onClick: async (row) =>
                row.locked ? unlockUser(row.id) : lockUser(row.id)
            },
            {
              name: 'Delete User',
              requiredRole: ROLE.ADMIN,
              confirm: true,
              confirmMessage: (row) =>
                `Are you sure you want to delete ${row.email}?`,
              onClick: deleteUser
            },
            {
              name: 'Impersonate User',
              requiredSuperAdmin: true,
              onClick: impersonateUser,
              shouldDisplay(row) {
                return row.id != user.id;
              }
            }
          ]}
        >
          <CardHeader className="flex items-center flex-row justify-between">
            <CardTitle>Team Members</CardTitle>
            <AddTeamMember />
          </CardHeader>
        </TableCard>
        <TeamStatistics team={team} />
      </div>
    </AppLayout>
  );
}
