'use client';

import { MoreHorizontal } from 'lucide-react';

import {
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem
} from '@/components/ui/sidebar';
import { apiClient, switchTeam } from '@/lib/api';
import { components } from '@/lib/api.types';
import { localDateTimeBuilder, useTimezone } from '@/lib/time';
import { cn } from '@/lib/utils';
import { queryOptions, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  Link,
  useLocation,
  useNavigate,
  useRouteContext,
  useRouter
} from '@tanstack/react-router';
import { Category, Verdict, getCategoryTitleWithVerdict } from '@wire/shared';

async function getData() {
  const cases = await apiClient.POST('/cases', {
    body: {
      statuses: ['ESCALATED'],
      page: 1,
      size: 5,
      hideDemoClients: true,
      orderBy: 'createdAt',
      orderDir: 'desc'
    }
  });

  if (cases.error != null) {
    throw new Error('Error getting cases information');
  }
  return cases.data;
}

export const RECENT_CASES_QUERY_KEY = 'recent-cases';
export const getRecentCasesOptions = () =>
  queryOptions({
    queryKey: [RECENT_CASES_QUERY_KEY],
    queryFn: () => getData()
  });

export function NavCases() {
  const casesQuery = useQuery(getRecentCasesOptions());
  const navigate = useNavigate();
  const router = useRouter();
  const queryClient = useQueryClient();
  const location = useLocation();
  const { team } = useRouteContext({ from: '/_application' });
  const { timezone } = useTimezone();
  if (casesQuery.isLoading) return;
  if (casesQuery.data?.totalCount == 0) return;

  const renderCaseContent = (item: components['schemas']['Case']) => (
    <div className="flex flex-col w-full">
      <div className="flex justify-between gap-2">
        <div className="text-xs font-bold text-destructive">{item.sid}</div>
        <div className="text-xs text-muted-foreground">
          {localDateTimeBuilder(
            item.firstDetectionSourceDetectedAt,
            timezone
          ).fromNow()}
        </div>
      </div>
      <div
        className={cn('line-clamp-1', {
          'font-bold': location.pathname.startsWith(`/cases/${item.id}`)
        })}
      >
        {getCategoryTitleWithVerdict(
          item.categories[0] as Category,
          item.verdict as Verdict
        )}
      </div>
      <span className="text-xs line-clamp-1 text-muted-foreground">
        {item.name}
      </span>
      {team.serviceProvider && (
        <span className="text-xs line-clamp-1 text-muted-foreground">
          {item.teamName}
        </span>
      )}
    </div>
  );

  return (
    <SidebarGroup className="group-data-[collapsible=icon]:hidden">
      <SidebarGroupLabel className="flex items-center gap-2">
        <span>Escalated Cases</span>
        <span className="relative flex h-3 w-3">
          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-destructive/90 opacity-75"></span>
          <span className="relative inline-flex rounded-full h-3 w-3 bg-destructive"></span>
        </span>
      </SidebarGroupLabel>
      <SidebarMenu>
        {casesQuery.data?.data.map((item) => (
          <SidebarMenuItem className="w-full" key={item.id}>
            <SidebarMenuButton asChild>
              {team.serviceProvider ? (
                <a
                  href={`/cases/${item.id}`}
                  onClick={(e) => {
                    if (e.ctrlKey || e.metaKey) return;
                    e.preventDefault();
                    void switchTeam(item.teamId, navigate, `/cases/${item.id}`);
                  }}
                  className={cn('h-auto p-1 border rounded-md')}
                >
                  {renderCaseContent(item)}
                </a>
              ) : (
                <Link
                  to="/cases/$caseId"
                  params={{ caseId: item.id }}
                  className={cn('h-auto p-1 border rounded-md')}
                >
                  {renderCaseContent(item)}
                </Link>
              )}
            </SidebarMenuButton>
          </SidebarMenuItem>
        ))}
        {casesQuery.data != null && casesQuery.data.totalCount > 5 && (
          <SidebarMenuItem>
            <SidebarMenuButton
              className="text-sidebar-foreground/70"
              onClick={() =>
                navigate({ to: '/cases', search: { filter: 'ESCALATED' } })
              }
            >
              <MoreHorizontal className="text-sidebar-foreground/70" />
              <span>View {casesQuery.data.totalCount - 5} More</span>
            </SidebarMenuButton>
          </SidebarMenuItem>
        )}
      </SidebarMenu>
    </SidebarGroup>
  );
}
