import { routeTree } from '@/routeTree.gen';
import { ParseRoute } from '@tanstack/react-router';
import { ENTITY_TYPE } from '@wire/shared';

export const entityRouteMappings: Record<
  ENTITY_TYPE,
  { to: ParseRoute<typeof routeTree>['fullPath']; paramKey?: string }
> = {
  [ENTITY_TYPE.CASE]: { to: '/cases/$caseId', paramKey: 'caseId' },
  [ENTITY_TYPE.TEAM]: { to: '/settings/team' },
  [ENTITY_TYPE.EXCLUSION]: { to: '/settings/automation' },
  [ENTITY_TYPE.FILE]: { to: '/assets/files/$fileId', paramKey: 'fileId' },
  [ENTITY_TYPE.DOMAIN]: {
    to: '/assets/domains/$domainId',
    paramKey: 'domainId'
  },
  [ENTITY_TYPE.IP_ADDRESS]: { to: '/assets/ips/$ipId', paramKey: 'ipId' },
  [ENTITY_TYPE.LOCATION]: {
    to: '/assets/locations/$locationId',
    paramKey: 'locationId'
  },
  [ENTITY_TYPE.PROCESS]: {
    to: '/assets/processes/$processId',
    paramKey: 'processId'
  },
  [ENTITY_TYPE.ENDPOINT]: {
    to: '/assets/endpoints/$endpointId',
    paramKey: 'endpointId'
  },
  [ENTITY_TYPE.INTEGRATION]: {
    to: '/settings/integrations/$integrationId',
    paramKey: 'integrationId'
  },
  [ENTITY_TYPE.USER]: { to: '/assets/users/$userId', paramKey: 'userId' },
  [ENTITY_TYPE.DETECTION]: {
    to: '/detections/$detectionId',
    paramKey: 'detectionId'
  },
  [ENTITY_TYPE.USER_AGENT]: {
    to: '/assets/user-agents/$userAgentId',
    paramKey: 'userAgentId'
  }
};
