import { IntegrationConfigurationModalProps } from '@/components/integration/configuration/configuration-modal';
import { Button } from '@/components/ui/button';
import { Combobox } from '@/components/ui/combo-box';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog';
import { Label } from '@/components/ui/label';
import { Skeleton } from '@/components/ui/skeleton';
import { apiClient } from '@/lib/api';
import { components } from '@/lib/api.types';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'sonner';

export default function ConnectwisePSAConfiguration(
  props: IntegrationConfigurationModalProps
) {
  const { integration } = props;
  const [config, setConfig] = useState<
    components['schemas']['ConnectWiseConfigurationDto']
  >({} as components['schemas']['ConnectWiseConfigurationDto']);
  const configurationQuery = useQuery({
    queryKey: ['integration', integration.id, 'connectwise-psa'],
    queryFn: async () => {
      const response = await apiClient.GET(
        '/integration/{integrationId}/connectwise-psa',
        {
          params: {
            path: { integrationId: integration.id }
          }
        }
      );
      if (response.error != null) {
        throw response.error;
      }
      return response.data;
    }
  });

  useEffect(() => {
    if (configurationQuery.data != null) {
      setConfig(configurationQuery.data);
    }
  }, [configurationQuery.data]);

  const canSave = useMemo(() => {
    return (
      config?.ticketCompanyId != null &&
      config?.ticketBoardId != null &&
      config?.ticketTypeId != null &&
      config?.ticketPriorityId != null &&
      config?.ticketStatusId != null &&
      config?.ticketPreventedPriorityId != null &&
      config?.ticketPreventedStatusId != null &&
      config?.ticketContainedPriorityId != null &&
      config?.ticketContainedStatusId != null
    );
  }, [config]);

  async function saveConfig() {
    if (config == null || !canSave) {
      return;
    }
    const response = await apiClient.PUT(
      '/integration/{integrationId}/connectwise-psa',
      { params: { path: { integrationId: integration.id } }, body: config }
    );
    if (response.error != null) {
      toast.error(response.error.message);
      return;
    }

    toast.success('Configuration saved');
    props.onClose();
  }

  function updateConfig(
    value: Partial<components['schemas']['ConnectWiseConfigurationDto']>
  ) {
    setConfig((config) => ({ ...config, ...value }));
  }

  if (configurationQuery.isLoading) {
    return <Skeleton className="h-48 rounded-md w-full" />;
  }

  return (
    <Dialog open onOpenChange={(open) => !open && props.onClose()}>
      <DialogContent className="max-w-2xl">
        <DialogHeader>
          <DialogTitle>Connectwise PSA Configuration</DialogTitle>
          <DialogDescription>
            Configure your Connectwise PSA integration.
          </DialogDescription>
        </DialogHeader>
        <div className="flex flex-col gap-4">
          <CompanySelector
            integrationId={integration.id}
            companyId={config?.ticketCompanyId}
            onSelect={(value) => updateConfig({ ticketCompanyId: value })}
          />
          <BoardSelector
            integrationId={integration.id}
            boardId={config?.ticketBoardId}
            onSelect={(value) => updateConfig({ ticketBoardId: value })}
          />
          {config?.ticketBoardId != null && (
            <TicketTypeSelector
              integrationId={integration.id}
              boardId={config?.ticketBoardId}
              ticketTypeId={config?.ticketTypeId}
              onSelect={(value) => updateConfig({ ticketTypeId: value })}
            />
          )}
          {config.ticketTypeId != null && (
            <>
              <div>
                <Label>Ticket Status and Priority</Label>
                <PriorityStatusOptions
                  integrationId={integration.id}
                  boardId={config?.ticketBoardId}
                  label="When Wirespeed escalates a case"
                  priorityId={config?.ticketPriorityId}
                  statusId={config?.ticketStatusId}
                  nonNullable
                  onPrioritySelect={(value) =>
                    updateConfig({ ticketPriorityId: value! })
                  }
                  onStatusSelect={(value) =>
                    updateConfig({ ticketStatusId: value! })
                  }
                />
              </div>

              <PriorityStatusOptions
                integrationId={integration.id}
                boardId={config?.ticketBoardId}
                label="but the detection was prevented or mitigated at the source"
                priorityId={config?.ticketPreventedPriorityId}
                nonNullable
                statusId={config?.ticketPreventedStatusId}
                onPrioritySelect={(value) =>
                  updateConfig({ ticketPreventedPriorityId: value! })
                }
                onStatusSelect={(value) =>
                  updateConfig({ ticketPreventedStatusId: value! })
                }
              />

              <PriorityStatusOptions
                integrationId={integration.id}
                boardId={config?.ticketBoardId}
                label="When containment has been performed"
                priorityId={config?.ticketContainedPriorityId}
                statusId={config?.ticketContainedStatusId}
                nonNullable
                onPrioritySelect={(value) =>
                  updateConfig({ ticketContainedPriorityId: value! })
                }
                onStatusSelect={(value) =>
                  updateConfig({ ticketContainedStatusId: value! })
                }
              />

              <PriorityStatusOptions
                integrationId={integration.id}
                boardId={config?.ticketBoardId}
                label="When the case has been marked as responded to"
                priorityId={config?.ticketRespondedPriorityId}
                statusId={config?.ticketRespondedStatusId}
                onPrioritySelect={(value) =>
                  updateConfig({ ticketRespondedPriorityId: value })
                }
                onStatusSelect={(value) =>
                  updateConfig({ ticketRespondedStatusId: value })
                }
              />

              <PriorityStatusOptions
                integrationId={integration.id}
                boardId={config?.ticketBoardId}
                label="When the case is closed"
                priorityId={config?.ticketClosedPriorityId}
                statusId={config?.ticketClosedStatusId}
                onPrioritySelect={(value) =>
                  updateConfig({ ticketClosedPriorityId: value })
                }
                onStatusSelect={(value) =>
                  updateConfig({ ticketClosedStatusId: value })
                }
              />
            </>
          )}

          <Button onClick={saveConfig} type="submit" disabled={!canSave}>
            Save
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

function CompanySelector(props: {
  integrationId: string;
  companyId?: string;
  onSelect: (companyId: string) => void;
}) {
  const companies = useQuery({
    queryKey: ['integration', props.integrationId, 'companies'],
    queryFn: async () => {
      const response = await apiClient.GET(
        '/integration/{integrationId}/connectwise-psa/companies',
        {
          params: { path: { integrationId: props.integrationId } }
        }
      );
      if (response.error != null) {
        toast.error(response.error.message);
        return [];
      }
      return response.data;
    }
  });

  if (companies.isLoading) {
    return <Skeleton className="h-12 rounded-md w-full" />;
  }

  return (
    <div className="flex flex-col gap-2">
      <Label>Company</Label>
      <Combobox
        values={
          companies.data?.map((company) => {
            return {
              label: company.name,
              value: company.id.toString()
            };
          }) ?? []
        }
        value={props.companyId}
        onSelect={(value) => props.onSelect(value)}
        placeholder="Select a company"
        emptyMessage="No companies found"
      />
    </div>
  );
}

function BoardSelector(props: {
  integrationId: string;
  boardId?: string;
  onSelect: (boardId: string) => void;
}) {
  const boards = useQuery({
    queryKey: ['integration', props.integrationId, 'boards'],
    queryFn: async () => {
      const response = await apiClient.GET(
        '/integration/{integrationId}/connectwise-psa/boards',
        {
          params: { path: { integrationId: props.integrationId } }
        }
      );
      if (response.error != null) {
        toast.error(response.error.message);
        return [];
      }
      return response.data;
    }
  });

  if (boards.isLoading) {
    return <Skeleton className="h-12 rounded-md w-full" />;
  }

  return (
    <div className="flex flex-col gap-2">
      <Label>Board</Label>
      <Combobox
        values={
          boards.data?.map((board) => {
            return {
              label: board.name,
              value: board.id.toString()
            };
          }) ?? []
        }
        value={props.boardId}
        onSelect={(value) => props.onSelect(value)}
        placeholder="Select a board"
        emptyMessage="No boards found"
      />
    </div>
  );
}

function TicketTypeSelector(props: {
  integrationId: string;
  boardId: string;
  ticketTypeId?: string;
  onSelect: (ticketTypeId: string) => void;
}) {
  const ticketTypes = useQuery({
    queryKey: [
      'integration',
      props.integrationId,
      'ticketTypes',
      props.boardId
    ],
    queryFn: async () => {
      const response = await apiClient.GET(
        '/integration/{integrationId}/connectwise-psa/boards/{boardId}/ticketTypes',
        {
          params: {
            path: { integrationId: props.integrationId, boardId: props.boardId }
          }
        }
      );
      if (response.error != null) {
        toast.error(response.error.message);
        return [];
      }
      return response.data;
    }
  });

  if (ticketTypes.isLoading) {
    return <Skeleton className="h-12 rounded-md w-full" />;
  }

  return (
    <div className="flex flex-col gap-2">
      <Label>Ticket Type</Label>
      <Combobox
        values={
          ticketTypes.data?.map((ticketType) => {
            return {
              label: ticketType.name,
              value: ticketType.id.toString()
            };
          }) ?? []
        }
        value={props.ticketTypeId}
        onSelect={(value) => props.onSelect(value)}
        placeholder="Select a ticket type"
        emptyMessage="No ticket types found"
      />
    </div>
  );
}

function PriorityStatusOptions(props: {
  integrationId: string;
  boardId: string;
  label: string;
  priorityId?: string | null;
  nonNullable?: boolean;
  statusId?: string | null;
  onPrioritySelect: (priorityId: string | null) => void;
  onStatusSelect: (statusId: string | null) => void;
}) {
  const statuses = useQuery({
    queryKey: ['integration', props.integrationId, 'statuses', props.boardId],
    queryFn: async () => {
      const response = await apiClient.GET(
        '/integration/{integrationId}/connectwise-psa/boards/{boardId}/statuses',
        {
          params: {
            path: { integrationId: props.integrationId, boardId: props.boardId }
          }
        }
      );
      if (response.error != null) {
        toast.error(response.error.message);
        return [];
      }
      return response.data;
    }
  });

  const priorities = useQuery({
    queryKey: ['integration', props.integrationId, 'priorities'],
    queryFn: async () => {
      const response = await apiClient.GET(
        '/integration/{integrationId}/connectwise-psa/priorities',
        {
          params: {
            path: { integrationId: props.integrationId }
          }
        }
      );
      if (response.error != null) {
        toast.error(response.error.message);
        return [];
      }
      return response.data;
    }
  });

  const NONE_VALUE = 'wspd-none';
  const statusValues = useMemo(() => {
    const values: { label: string; value: string }[] = [];
    if (!props.nonNullable) {
      values.push({ label: 'No change', value: NONE_VALUE });
    }
    values.push(
      ...(statuses.data?.map((status) => {
        return {
          label: status.name,
          value: status.id.toString()
        };
      }) ?? [])
    );

    return values;
  }, [statuses.data]);

  const priorityValues = useMemo(() => {
    const values: { label: string; value: string }[] = [];
    if (!props.nonNullable) {
      values.push({ label: 'No change', value: NONE_VALUE });
    }
    values.push(
      ...(priorities.data?.map((priority) => {
        return {
          label: priority.name,
          value: priority.id.toString()
        };
      }) ?? [])
    );
    return values;
  }, [priorities.data]);

  if (statuses.isLoading || priorities.isLoading) {
    return (
      <div className="flex flex-col lg:flex-row gap-2">
        <Skeleton className="h-12 rounded-md w-full" />
        <Skeleton className="h-12 rounded-md w-full" />
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full">
      <div className="text-muted-foreground text-xs">{props.label}</div>
      <div className="flex flex-col flex-1 w-full lg:flex-row gap-2">
        <div className="flex-1">
          <Label>Status</Label>
          <Combobox
            values={statusValues}
            value={props.statusId}
            onSelect={(value) =>
              value == NONE_VALUE
                ? props.onStatusSelect(null)
                : props.onStatusSelect(value)
            }
            placeholder="Select a status"
            emptyMessage="No statuses found"
          />
        </div>
        <div className="flex-1">
          <Label>Priority</Label>
          <Combobox
            values={priorityValues}
            value={props.priorityId}
            onSelect={(value) =>
              value == NONE_VALUE
                ? props.onPrioritySelect(null)
                : props.onPrioritySelect(value)
            }
            placeholder="Select a priority"
            emptyMessage="No priorities found"
          />
        </div>
      </div>
    </div>
  );
}
