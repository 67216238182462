import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandList,
  CommandTextAreaBlank
} from '@/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/components/ui/popover';
import { cn, useDebounce } from '@/lib/utils';
import {
  DOC_LINKS,
  LOGICAL_OPERATORS,
  OPERATION_PART_TYPE,
  OPERATORS,
  evaluateQuery,
  getAllPaths,
  getQueryError,
  hasSomeArrayOperator,
  validateQuery
} from '@wire/shared';
import { useEffect, useMemo, useRef, useState } from 'react';

export default function JSONSearchBuilder({
  data,
  onResultChange,
  onQueryChange,
  defaultQuery,
  rows
}: {
  data?: Record<string, any> | null;
  onResultChange?: (result: boolean) => void;
  onQueryChange?: (query: string) => void;
  defaultQuery?: string;
  rows?: number;
}) {
  const [userSearch, setUserSearch] = useState(defaultQuery ?? '');
  const [cursorPosition, setCursorPosition] = useState(0);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const allPaths = useMemo(() => getAllPaths(data), [data]);
  const debouncedUserSearch = useDebounce(500, userSearch);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setUserSearch(defaultQuery ?? '');
  }, [defaultQuery]);

  useEffect(() => {
    if (userSearch == defaultQuery) return;
    onQueryChange?.(userSearch);
    if (error != null && validateQuery(userSearch)) {
      setError(undefined);
    }
  }, [userSearch]);

  useEffect(() => {
    if (debouncedUserSearch.debounced == null) return;
    if (!validateQuery(debouncedUserSearch.debounced)) {
      setError(getQueryError(debouncedUserSearch.debounced));
    } else {
      setError(undefined);
    }
  }, [debouncedUserSearch.debounced, data]);

  /**
   * Is the cursor currently after the beginning of a quoted string?
   */
  const inQuotes = useMemo(() => {
    let quotes = false;
    let index = cursorPosition;
    while (index > 0) {
      const char = userSearch[index - 1];
      if (char === '"') quotes = !quotes;
      index--;
    }
    return quotes;
  }, [userSearch, cursorPosition]);

  /**
   * Gets the current operation at the cursor position by parsing the search text.
   * An operation consists of a property, operator, and value (e.g. "name=John")
   *
   * @param search - The search text to parse, defaults to userSearch state
   * @param cursorPos - The cursor position to check, defaults to cursorPosition state
   * @returns Object containing the parsed operation details
   */
  function getOperation(
    search: string = userSearch,
    cursorPos: number = cursorPosition
  ) {
    // Initialize empty result
    const emptyResult = {
      operation: '',
      property: '',
      value: '',
      parenBefore: false,
      parenAfter: false,
      operator: '',
      cursorStart: cursorPos,
      cursorEnd: cursorPos
    };

    // Handle empty or space-only operations early
    if (!search.trim()) return emptyResult;

    // Find operation boundaries
    let start = cursorPos;
    let end = cursorPos;
    let positionInQuotes =
      inQuotes || (cursorPos > 0 && search[cursorPos - 1] === '"');

    // Search backwards
    while (start > 0 && (positionInQuotes || search[start - 1] !== ' ')) {
      start--;
      if (search[start] === '"') {
        positionInQuotes = !positionInQuotes;
      }
    }

    // Search forwards
    positionInQuotes = inQuotes;
    while (end < search.length && (positionInQuotes || search[end] !== ' ')) {
      if (search[end] === '"') {
        positionInQuotes = !positionInQuotes;
      }
      end++;
    }

    const operation = search.slice(start, end);

    // Return empty result for space-terminated operations outside quotes
    if (operation.endsWith(' ') && !inQuotes) {
      return { ...emptyResult, cursorStart: start, cursorEnd: end };
    }

    // Parse operation parts
    const operationWithoutParens = operation.replace(/[()]/g, '');
    const parts = operationWithoutParens.split(
      new RegExp(`(${OPERATORS.map((op) => op.operator).join('|')})`)
    );

    return {
      operation: operationWithoutParens,
      property: parts[0] || '',
      operator: parts[1] || '',
      value: parts[2] || '',
      parenBefore: operation.startsWith('('),
      parenAfter: operation.endsWith(')'),
      cursorStart: start,
      cursorEnd: end
    };
  }

  /**
   * What part of the operation is the cursor currently over?
   * @returns
   */
  function getCurrentPartType(): OPERATION_PART_TYPE {
    // If no search text, return property type
    if (!userSearch || userSearch.trim() == '') return 'PROPERTY';

    // If we're in quotes, we can always assume they're typing a value
    if (inQuotes) return 'VALUE';

    // If cursor is directly after a quote, show value
    if (userSearch[cursorPosition - 1] === '"') return 'VALUE';

    // Get the operation at cursor position
    const { operation, cursorStart, property, value } = getOperation();
    const previousValue = userSearch
      .slice(0, cursorStart - 1)
      .trim()
      .split(' ')
      .reverse()[0];

    // If they move their cursor in between some operations and want to add a new operation
    if (
      operation.trim() == '' &&
      cursorStart != 0 &&
      !LOGICAL_OPERATORS.some((v) =>
        v.toLowerCase().includes(previousValue.toLowerCase())
      )
    ) {
      return 'LOGICAL_OPERATOR';
    }

    // If it looks like they're beginning to type a logical operator and the previous value isn't a logical operator, show the logical operator part type
    if (
      LOGICAL_OPERATORS.some((v) =>
        v.toLowerCase().startsWith(operation.toLowerCase())
      ) &&
      !LOGICAL_OPERATORS.some((v) =>
        v.toLowerCase().includes(previousValue.toLowerCase())
      )
    ) {
      return 'LOGICAL_OPERATOR';
    }

    // If the entire operation is currently just a property, show the operator part type
    if (
      allPaths.includes(operation) &&
      cursorPosition === cursorStart + property.length
    )
      return 'OPERATOR';

    // Find operator position in operation
    const operatorPattern = OPERATORS.map((op) => op.operator).join('|');
    const operatorMatch = operation.match(new RegExp(`(${operatorPattern})`));
    if (operatorMatch == null) return 'PROPERTY';

    const operatorIndex = operatorMatch.index!;
    const operatorEndIndex = operatorIndex + operatorMatch[0].length;

    // Determine part type based on cursor position within operation
    const relativePos = cursorPosition - cursorStart;
    if (relativePos <= operatorIndex) return 'PROPERTY';
    if (
      relativePos < operatorEndIndex ||
      (value != null && value.trim() != '' && relativePos <= operatorEndIndex)
    ) {
      return 'OPERATOR';
    }
    return 'VALUE';
  }

  const currentPartType = useMemo(() => getCurrentPartType(), [cursorPosition]);
  const currentOperation = useMemo(() => getOperation(), [cursorPosition]);
  /**
   * Update the property at the cursor position or add if cursor position is end of input
   * @param selected The selected property/operator to insert
   */
  function selectProperty(selected: string) {
    const {
      property,
      operator,
      value,
      parenBefore,
      parenAfter,
      cursorStart,
      cursorEnd
    } = getOperation();

    let endPosition = cursorEnd;
    let newSearch = userSearch;

    const buildSearchString = (parts: string[]) => parts.join('');

    switch (currentPartType) {
      case 'PROPERTY': {
        endPosition = cursorStart + selected.length + (parenBefore ? 1 : 0);
        newSearch = buildSearchString([
          userSearch.slice(0, cursorStart),
          parenBefore ? '(' : '',
          selected,
          operator ?? '',
          value ?? '',
          parenAfter ? ')' : '',
          userSearch.slice(cursorEnd)
        ]);
        break;
      }
      case 'OPERATOR': {
        endPosition = cursorStart + property.length + selected.length;
        newSearch = buildSearchString([
          userSearch.slice(0, cursorStart),
          parenBefore ? '(' : '',
          property ?? '',
          selected,
          value ?? '',
          parenAfter ? ')' : '',
          userSearch.slice(cursorEnd)
        ]);
        break;
      }
      case 'LOGICAL_OPERATOR': {
        endPosition = cursorStart + selected.length + 1;
        newSearch = buildSearchString([
          userSearch.slice(0, cursorStart),
          selected,
          userSearch.slice(cursorEnd, cursorEnd + 1) === ' ' ? '' : ' ',
          userSearch.slice(cursorEnd)
        ]);
        break;
      }
      default:
        break;
    }

    setUserSearch(newSearch);

    // Update cursor position after state update
    requestAnimationFrame(() => {
      if (inputRef.current != null) {
        inputRef.current.focus();
        inputRef.current.setSelectionRange(endPosition, endPosition);
        setCursorPosition(endPosition);
      }
    });
  }

  const properties: { name: string; description?: string }[] = useMemo(() => {
    if (inQuotes) return [];

    // Show all matches if the user moves their cursor over an already completed property so they can edit it
    // If they begin editing that property, we should start filtering then
    const showAll =
      cursorPosition < userSearch.length &&
      allPaths.includes(currentOperation.property);
    if (currentPartType == 'LOGICAL_OPERATOR') {
      return LOGICAL_OPERATORS.map((op) => ({ name: op })).filter(
        (v) =>
          v.name
            .toLowerCase()
            .includes(currentOperation.property.toLowerCase()) || showAll
      );
    }
    if (currentPartType == 'PROPERTY') {
      return allPaths
        .map((path) => ({ name: path }))
        .filter(
          (v) =>
            v.name
              .toLowerCase()
              .includes(currentOperation.property.toLowerCase()) || showAll
        );
    }
    if (currentPartType == 'OPERATOR') {
      return OPERATORS.map((op) => ({
        name: op.operator,
        description: op.name
      })).filter(
        (v) =>
          v.name
            .toLowerCase()
            .includes(currentOperation.operator.toLowerCase()) || showAll
      );
    }
    return [];
  }, [inQuotes, currentPartType, cursorPosition, currentOperation]);

  const queryResult = useMemo(() => {
    if (data == null) return false;
    return evaluateQuery(userSearch, data);
  }, [userSearch, data]);

  useEffect(() => {
    onResultChange?.(queryResult);
  }, [queryResult]);

  const warning = useMemo(() => {
    if (error != null) return error;
    if (hasSomeArrayOperator(userSearch)) {
      return (
        <>
          Partial array operators may result in unexpected behavior, please see
          the documentation{' '}
          <a
            className="underline"
            href={DOC_LINKS.EXCLUSIONS_ARRAY}
            target="_blank"
          >
            here
          </a>
          .
        </>
      );
    }
    return;
  }, [userSearch, error]);

  return (
    <Popover open={open}>
      <Command shouldFilter={false}>
        <div>
          <PopoverTrigger asChild>
            <CommandTextAreaBlank
              ref={inputRef}
              onBlur={() => setOpen(false)}
              onFocusCapture={() => setOpen(true)}
              placeholder="Enter search query"
              rows={rows ?? 6}
              value={userSearch}
              onChange={(e) => setUserSearch(e.target.value)}
              className="border font-mono rounded-md px-2 shadow-sm"
              onKeyUp={(e) =>
                setCursorPosition(e.currentTarget.selectionStart ?? 0)
              }
              onClick={(e) => {
                setOpen(true);
                setCursorPosition(e.currentTarget.selectionStart ?? 0);
              }}
            />
          </PopoverTrigger>

          {warning != null && (
            <p className="text-xs mt-1 text-red-500">{warning}</p>
          )}
        </div>
        <PopoverContent
          noPortal
          sideOffset={5}
          onOpenAutoFocus={(e) => e.preventDefault()}
          align="start"
          className={cn('p-0 z-[1000]', {
            hidden: properties.length == 0
          })}
        >
          <CommandList>
            <CommandGroup className="overflow-hidden">
              <CommandEmpty></CommandEmpty>
              {properties.map((property) => (
                <CommandItem
                  onFocusCapture={(e) => e.preventDefault()}
                  onSelect={() => selectProperty(property.name)}
                  className="flex overflow-hidden flex-col items-start gap-1"
                  key={property.name}
                >
                  <div className="break-all">{property.name}</div>
                  {property.description && (
                    <div className="text-xs text-muted-foreground">
                      {property.description}
                    </div>
                  )}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </PopoverContent>
      </Command>
    </Popover>
  );
}
