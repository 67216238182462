import { hasValidAuthToken } from '@/lib/api';
import { Outlet, createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_onboarding')({
  component: LayoutComponent,
  beforeLoad: ({ location }) => {
    if (!hasValidAuthToken()) {
      throw redirect({
        to: '/login',
        search: { redirect: location.pathname }
      });
    }
  }
});

function LayoutComponent() {
  return (
    <div className="bg-muted/40 min-h-screen">
      <Outlet />
    </div>
  );
}
