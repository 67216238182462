import { HoverCardItem } from '@/components/hover-cards/util';
import { Logo } from '@/components/ui/logo';
import { apiClient } from '@/lib/api';
import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';

export default function UserAgentHoverCard(props: { id: string }) {
  const { data } = useSuspenseQuery(
    queryOptions({
      queryKey: ['user-agent-hover', props.id],
      queryFn: async () => {
        const response = await apiClient.GET('/user-agent/{id}', {
          params: { path: { id: props.id } }
        });
        if (response.error != null) {
          throw new Error('Error getting user agent information');
        }
        return response.data;
      }
    })
  );

  return (
    <div className="flex flex-col gap-2">
      <HoverCardItem title="User Agent">{data.userAgent}</HoverCardItem>
      <HoverCardItem
        title={
          <div className="flex items-center gap-1">
            <span>Browser</span>
            <Logo className="h-4 w-4" />
          </div>
        }
      >
        {data.browserName}
      </HoverCardItem>
      <HoverCardItem title="Browser Version">
        {data.browserVersion}
      </HoverCardItem>
      <HoverCardItem title="OS">{data.osName}</HoverCardItem>
      <HoverCardItem title="OS Version">{data.osVersion}</HoverCardItem>
      <HoverCardItem title="Device Model">{data.deviceModel}</HoverCardItem>
      <HoverCardItem title="Device Vendor">{data.deviceVendor}</HoverCardItem>
      <HoverCardItem title="CPU Architecture">
        {data.cpuArchitecture}
      </HoverCardItem>
      <HoverCardItem title="Engine">{data.engineName}</HoverCardItem>
      <HoverCardItem title="Engine Version">{data.engineVersion}</HoverCardItem>
      {data.userAgentAlt != null && (
        <HoverCardItem title="User Agent Alt">
          {data.userAgentAlt}
        </HoverCardItem>
      )}
    </div>
  );
}
