import { AppLayout } from '@/components/app-layout';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from '@/components/ui/card';
import { Link, createFileRoute } from '@tanstack/react-router';
import { CategoryClass, CategoryClassConfig } from '@wire/shared';
import {
  CloudIcon,
  DatabaseIcon,
  EllipsisIcon,
  LaptopIcon,
  ListCheckIcon,
  MailIcon,
  NetworkIcon,
  ShieldUserIcon
} from 'lucide-react';
import { ReactNode } from 'react';
export const UICategoryConfig: Record<CategoryClass, { icon: ReactNode }> = {
  [CategoryClass.ENDPOINT]: {
    icon: <LaptopIcon />
  },
  [CategoryClass.IDENTITY]: {
    icon: <ShieldUserIcon />
  },
  [CategoryClass.CLOUD]: {
    icon: <CloudIcon />
  },
  [CategoryClass.EMAIL]: {
    icon: <MailIcon />
  },
  [CategoryClass.NETWORK]: {
    icon: <NetworkIcon />
  },
  [CategoryClass.DATA]: {
    icon: <DatabaseIcon />
  },
  [CategoryClass.POSTURE]: {
    icon: <ListCheckIcon />
  },
  [CategoryClass.OTHER]: {
    icon: <EllipsisIcon />
  }
};
export const Route = createFileRoute('/_application/settings/verdicts/')({
  component: RouteComponent
});

function RouteComponent() {
  return (
    <AppLayout>
      <Card>
        <CardHeader>
          <CardTitle>Verdicts</CardTitle>
          <CardDescription>
            Control how Wirespeed determines what actions to take when a
            detection is discovered in your environment.
          </CardDescription>
        </CardHeader>
        <CardContent className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          {Object.entries(UICategoryConfig).map(([group, config]) => {
            const classConfig = CategoryClassConfig[group as CategoryClass];
            return (
              <Link
                to="/settings/verdicts/class/$class"
                params={{ class: group.toLowerCase() }}
              >
                <div className="p-4 hover:bg-primary/20 rounded-md" key={group}>
                  <h3 className="text-lg flex flex-row items-center gap-2 font-medium">
                    {config.icon} {classConfig.name}
                  </h3>
                  <p className="text-sm text-muted-foreground">
                    {classConfig.description}
                  </p>
                </div>
              </Link>
            );
          })}
        </CardContent>
      </Card>
    </AppLayout>
  );
}
