export * from './notification';

export enum Verdict {
  MALICIOUS = 'MALICIOUS',
  SUSPICIOUS = 'SUSPICIOUS',
  BENIGN = 'BENIGN'
}
export const VERDICT_CATEGORIES = Object.values(Verdict);

interface IVerdictConfig {
  display: string;
  type: Verdict;
}

export enum IntegrationLogType {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info'
}

interface IIntegrationLogTypeConfig {
  display: string;
  severity: number;
  type: IntegrationLogType;
}

export const IntegrationLogTypeConfig: {
  [key in IntegrationLogType]: IIntegrationLogTypeConfig;
} = {
  [IntegrationLogType.ERROR]: {
    display: 'Error',
    severity: 1,
    type: IntegrationLogType.ERROR
  },
  [IntegrationLogType.WARNING]: {
    display: 'Warning',
    severity: 2,
    type: IntegrationLogType.WARNING
  },
  [IntegrationLogType.INFO]: {
    display: 'Info',
    severity: 3,
    type: IntegrationLogType.INFO
  }
};
export enum ENTITY_TYPE {
  CASE = 'CASE',
  TEAM = 'TEAM',
  FILE = 'FILE',
  DOMAIN = 'DOMAIN',
  IP_ADDRESS = 'IP_ADDRESS',
  USER_AGENT = 'USER_AGENT',
  LOCATION = 'LOCATION',
  PROCESS = 'PROCESS',
  ENDPOINT = 'ENDPOINT',
  INTEGRATION = 'INTEGRATION',
  USER = 'USER',
  DETECTION = 'DETECTION',
  EXCLUSION = 'EXCLUSION'
}

interface IEntityTypeConfig {
  display: string;
  type: ENTITY_TYPE;
}

export const EntityTypeConfig: {
  [key in ENTITY_TYPE]: IEntityTypeConfig;
} = {
  [ENTITY_TYPE.CASE]: {
    display: 'Case',
    type: ENTITY_TYPE.CASE
  },
  [ENTITY_TYPE.TEAM]: {
    display: 'Team',
    type: ENTITY_TYPE.TEAM
  },
  [ENTITY_TYPE.FILE]: {
    display: 'File',
    type: ENTITY_TYPE.FILE
  },
  [ENTITY_TYPE.DOMAIN]: {
    display: 'Domain',
    type: ENTITY_TYPE.DOMAIN
  },
  [ENTITY_TYPE.IP_ADDRESS]: {
    display: 'IP Address',
    type: ENTITY_TYPE.IP_ADDRESS
  },
  [ENTITY_TYPE.USER_AGENT]: {
    display: 'User Agent',
    type: ENTITY_TYPE.USER_AGENT
  },
  [ENTITY_TYPE.LOCATION]: {
    display: 'Location',
    type: ENTITY_TYPE.LOCATION
  },
  [ENTITY_TYPE.PROCESS]: {
    display: 'Process',
    type: ENTITY_TYPE.PROCESS
  },
  [ENTITY_TYPE.ENDPOINT]: {
    display: 'Endpoint',
    type: ENTITY_TYPE.ENDPOINT
  },
  [ENTITY_TYPE.INTEGRATION]: {
    display: 'Integration',
    type: ENTITY_TYPE.INTEGRATION
  },
  [ENTITY_TYPE.USER]: {
    display: 'User',
    type: ENTITY_TYPE.USER
  },
  [ENTITY_TYPE.DETECTION]: {
    display: 'Detection',
    type: ENTITY_TYPE.DETECTION
  },
  [ENTITY_TYPE.EXCLUSION]: {
    display: 'Exclusion',
    type: ENTITY_TYPE.EXCLUSION
  }
};

export const VerdictConfig: {
  [key in Verdict]: IVerdictConfig;
} = {
  [Verdict.MALICIOUS]: {
    display: 'Malicious',
    type: Verdict.MALICIOUS
  },
  [Verdict.SUSPICIOUS]: {
    display: 'Suspicious',
    type: Verdict.SUSPICIOUS
  },
  [Verdict.BENIGN]: {
    display: 'Benign',
    type: Verdict.BENIGN
  }
};

export function getVerdictConfigByVerdict(verdict: null | string | Verdict) {
  if (verdict == null) return VerdictConfig[Verdict.SUSPICIOUS];
  return VerdictConfig[verdict as Verdict];
}

export enum VerdictAction {
  CONTAIN_USER = 'CONTAIN_USER',
  CONTAIN_ENDPOINT = 'CONTAIN_ENDPOINT',
  CHAT_OPS = 'CHAT_OPS',
  ESCALATE = 'ESCALATE',
  COMPLETE = 'COMPLETE'
}

interface IVerdictActionConfig {
  display: string;
  type: VerdictAction;
}

export const VerdictActionConfig: {
  [key in VerdictAction]: IVerdictActionConfig;
} = {
  [VerdictAction.CONTAIN_USER]: {
    display: 'Contain User',
    type: VerdictAction.CONTAIN_USER
  },
  [VerdictAction.CONTAIN_ENDPOINT]: {
    display: 'Contain Endpoint',
    type: VerdictAction.CONTAIN_ENDPOINT
  },
  [VerdictAction.CHAT_OPS]: {
    display: 'Chat Ops',
    type: VerdictAction.CHAT_OPS
  },
  [VerdictAction.ESCALATE]: {
    display: 'Escalate',
    type: VerdictAction.ESCALATE
  },
  [VerdictAction.COMPLETE]: {
    display: 'Complete',
    type: VerdictAction.COMPLETE
  }
};

export function getVerdictActionConfigByVerdictValue(
  verdictNextStep: string | VerdictAction
) {
  return VerdictActionConfig[verdictNextStep as VerdictAction];
}

export enum Status {
  NEW = 'NEW',
  PROCESSING = 'PROCESSING',
  ESCALATED = 'ESCALATED',
  CLOSED = 'CLOSED'
}

interface IStatusConfig {
  display: string;
  type: Status;
}

export const StatusConfig: {
  [key in Status]: IStatusConfig;
} = {
  [Status.NEW]: {
    display: 'New',
    type: Status.NEW
  },
  [Status.PROCESSING]: {
    display: 'Processing',
    type: Status.PROCESSING
  },
  [Status.ESCALATED]: {
    display: 'Escalated',
    type: Status.ESCALATED
  },
  [Status.CLOSED]: {
    display: 'Closed',
    type: Status.CLOSED
  }
};

export function getStatusConfigByStatus(status: string | Status) {
  return StatusConfig[status as Status];
}

export enum QueueStatus {
  completed = 'completed',
  failed = 'failed',
  delayed = 'delayed',
  active = 'active',
  waiting = 'waiting',
  prioritized = 'prioritized',
  paused = 'paused',
  repeat = 'repeat',
  waiting_children = 'waiting-children'
}

export type Nullable<T> = T | null | undefined;

export type IPInfoMetadata = {
  ip: string;
  hostname: string;
  city: string;
  region: string;
  org?: string;
  country: string;
  loc: string;
  postal: string;
  timezone: string;
  ofac: boolean;
  adversarial: boolean;
  asn?: {
    asn: string;
    name: string;
    domain: string;
    route: string;
    type: string;
  };
  company?: {
    name: string;
    domain: string;
    type: string;
  };
  privacy?: {
    vpn: boolean;
    proxy: boolean;
    tor: boolean;
    relay: boolean;
    hosting: boolean;
    service: string;
  };
  abuse?: {
    address: string;
    country: string;
    email: string;
    name: string;
    network: string;
    phone: string;
    ofac: boolean;
    adversarial: boolean;
  };
  domains?: {
    ip: string;
    total: number;
    domains: Array<string>;
  };
};

export type ChatOpsMessagePart =
  | {
      type: 'text';
      message: string;
      id?: string;
    }
  | {
      type: 'title';
      message: string;
      id?: string;
    }
  | {
      type: 'input';
      id?: string;
      value?: string;
      placeholder: string;
    }
  | {
      type: 'buttons';
      id?: string;
      buttons: {
        text: string;
        type: 'url' | 'action';
        value: string;
        id?: string;
      }[];
    };

export enum AQL_DEFECT_REASON {
  SHOULD_HAVE_BEEN_ESCALATED = 'SHOULD_HAVE_BEEN_ESCALATED',
  SHOULD_NOT_HAVE_BEEN_ESCALATED = 'SHOULD_NOT_HAVE_BEEN_ESCALATED',
  INCORRECT_CATEGORY = 'INCORRECT_CATEGORY',
  INCORRECT_DESCRIPTION = 'INCORRECT_DESCRIPTION',
  INCORRECT_TIMELINE = 'INCORRECT_TIMELINE',
  INCORRECT_NEXT_STEPS = 'INCORRECT_NEXT_STEPS'
}

export enum AQL_QUESTION {
  ESCALATED_CORRECTLY = 'ESCALATED_CORRECTLY',
  CATEGORIZED_CORRECTLY = 'CATEGORIZED_CORRECTLY',
  DESCRIPTION_CORRECT = 'DESCRIPTION_CORRECT',
  NEXT_STEPS_CORRECT = 'NEXT_STEPS_CORRECT',
  TIMELINE_CORRECT = 'TIMELINE_CORRECT'
}

export type TAQLQuestionConfig = {
  question: AQL_QUESTION;
  text: string;
  reasons: AQL_DEFECT_REASON[];
};

type TAQLDefectConfig = {
  defect: AQL_DEFECT_REASON;
  text: string;
  defectLevel: DefectLevel;
};

export enum DefectLevel {
  NONE = 'NONE',
  CRITICAL = 'CRITICAL',
  MAJOR = 'MAJOR',
  MINOR = 'MINOR'
}

export function getMaxDefectLevel(
  defectLevels: Nullable<DefectLevel | undefined>[]
) {
  const max = defectLevels
    .filter(Boolean)
    .map((v) => getDefectLevelConfig(v!))
    .sort((a, b) => (a.ordinal < b.ordinal ? 1 : -1))[0];
  return max?.type ?? DefectLevel.NONE;
}

interface DefectLevelConfig {
  display: string;
  type: DefectLevel;
  ordinal: number; // Bigger is worse
}

export const defectLevelConfig: DefectLevelConfig[] = [
  { display: 'None', type: DefectLevel.NONE, ordinal: 0 },
  { display: 'Minor', type: DefectLevel.MINOR, ordinal: 1 },
  { display: 'Major', type: DefectLevel.MAJOR, ordinal: 2 },
  { display: 'Critical', type: DefectLevel.CRITICAL, ordinal: 3 }
];

export function getDefectLevelConfig(
  defectLevel: DefectLevel
): DefectLevelConfig {
  return defectLevelConfig.find((config) => config.type === defectLevel)!;
}

export const AQLDefectReasonConfig: Record<
  AQL_DEFECT_REASON,
  TAQLDefectConfig
> = {
  [AQL_DEFECT_REASON.SHOULD_HAVE_BEEN_ESCALATED]: {
    defect: AQL_DEFECT_REASON.SHOULD_HAVE_BEEN_ESCALATED,
    text: 'The detection should have been escalated',
    defectLevel: DefectLevel.CRITICAL
  },

  [AQL_DEFECT_REASON.SHOULD_NOT_HAVE_BEEN_ESCALATED]: {
    defect: AQL_DEFECT_REASON.SHOULD_NOT_HAVE_BEEN_ESCALATED,
    text: 'The detection should not have been escalated',
    defectLevel: DefectLevel.MAJOR
  },
  [AQL_DEFECT_REASON.INCORRECT_CATEGORY]: {
    defect: AQL_DEFECT_REASON.INCORRECT_CATEGORY,
    text: 'The detection was categorized incorrectly',
    defectLevel: DefectLevel.MAJOR
  },
  [AQL_DEFECT_REASON.INCORRECT_DESCRIPTION]: {
    defect: AQL_DEFECT_REASON.INCORRECT_DESCRIPTION,
    text: 'The detection description was incorrect',
    defectLevel: DefectLevel.MINOR
  },
  [AQL_DEFECT_REASON.INCORRECT_NEXT_STEPS]: {
    defect: AQL_DEFECT_REASON.INCORRECT_NEXT_STEPS,
    text: 'The detection next steps were incorrect',
    defectLevel: DefectLevel.MINOR
  },
  [AQL_DEFECT_REASON.INCORRECT_TIMELINE]: {
    defect: AQL_DEFECT_REASON.INCORRECT_TIMELINE,
    text: 'The detection timeline was incorrect',
    defectLevel: DefectLevel.MINOR
  }
};

export const AQLQuestionConfig: Record<AQL_QUESTION, TAQLQuestionConfig> = {
  [AQL_QUESTION.ESCALATED_CORRECTLY]: {
    question: AQL_QUESTION.ESCALATED_CORRECTLY,
    text: 'Was the detection escalated correctly?',
    reasons: [
      AQL_DEFECT_REASON.SHOULD_HAVE_BEEN_ESCALATED,
      AQL_DEFECT_REASON.SHOULD_NOT_HAVE_BEEN_ESCALATED
    ]
  },
  [AQL_QUESTION.CATEGORIZED_CORRECTLY]: {
    question: AQL_QUESTION.CATEGORIZED_CORRECTLY,
    text: 'Was the detection categorized correctly?',
    reasons: [AQL_DEFECT_REASON.INCORRECT_CATEGORY]
  },
  [AQL_QUESTION.DESCRIPTION_CORRECT]: {
    question: AQL_QUESTION.DESCRIPTION_CORRECT,
    text: 'Was the detection description correct?',
    reasons: [AQL_DEFECT_REASON.INCORRECT_DESCRIPTION]
  },
  [AQL_QUESTION.NEXT_STEPS_CORRECT]: {
    question: AQL_QUESTION.NEXT_STEPS_CORRECT,
    text: 'Were the detection next steps correct?',
    reasons: [AQL_DEFECT_REASON.INCORRECT_NEXT_STEPS]
  },
  [AQL_QUESTION.TIMELINE_CORRECT]: {
    question: AQL_QUESTION.TIMELINE_CORRECT,
    text: 'Was the detection timeline correct?',
    reasons: [AQL_DEFECT_REASON.INCORRECT_TIMELINE]
  }
};

export enum ROLE {
  ADMIN = 'ADMIN',
  ANALYST = 'ANALYST',
  VIEWER = 'VIEWER'
}

export enum FileRiskType {
  BENIGN = 'BENIGN',
  INFO_STEALER = 'INFO_STEALER',
  RANSOMWARE = 'RANSOMWARE',
  MALWARE = 'MALWARE',
  LATE_STAGE = 'LATE_STAGE',
  NUISANCE = 'NUISANCE',
  LIVE_OFF_THE_LAND = 'LIVE_OFF_THE_LAND',
  REMOTE_MANAGEMENT = 'REMOTE_MANAGEMENT',
  UNKNOWN = 'UNKNOWN'
}

export const FileRiskTypeConfig = {
  [FileRiskType.BENIGN]: {
    name: 'Benign',
    type: FileRiskType.BENIGN
  },
  [FileRiskType.INFO_STEALER]: {
    name: 'Info Stealer',
    type: FileRiskType.INFO_STEALER
  },
  [FileRiskType.RANSOMWARE]: {
    name: 'Ransomware',
    type: FileRiskType.RANSOMWARE
  },
  [FileRiskType.MALWARE]: {
    name: 'Malware',
    type: FileRiskType.MALWARE
  },
  [FileRiskType.LATE_STAGE]: {
    name: 'Late Stage Tool',
    type: FileRiskType.LATE_STAGE
  },
  [FileRiskType.NUISANCE]: {
    name: 'Nuisance',
    type: FileRiskType.NUISANCE
  },
  [FileRiskType.LIVE_OFF_THE_LAND]: {
    name: 'Live Off The Land',
    type: FileRiskType.LIVE_OFF_THE_LAND
  },
  [FileRiskType.REMOTE_MANAGEMENT]: {
    name: 'Remote Management Tool',
    type: FileRiskType.REMOTE_MANAGEMENT
  },
  [FileRiskType.UNKNOWN]: {
    name: 'Unknown',
    type: FileRiskType.UNKNOWN
  }
};

export enum AlertStatus {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE',
  N_A = 'N/A'
}

export function getAlertStatusDisplay(status: AlertStatus) {
  switch (status) {
    case AlertStatus.NEW:
      return 'New';
    case AlertStatus.IN_PROGRESS:
      return 'In Progress';
    case AlertStatus.COMPLETE:
      return 'Complete';
    case AlertStatus.N_A:
      return 'N/A';
  }
}

export enum DirectoryUserTag {
  VIP = 'VIP',
  ADMIN = 'ADMIN',
  TECHNICAL = 'TECHNICAL',
  FINANCIAL = 'FINANCIAL'
}

export const DirectoryUserTagConfig = {
  [DirectoryUserTag.VIP]: {
    name: 'VIP',
    type: DirectoryUserTag.VIP
  },
  [DirectoryUserTag.ADMIN]: {
    name: 'Admin',
    type: DirectoryUserTag.ADMIN
  },
  [DirectoryUserTag.TECHNICAL]: {
    name: 'Technical',
    type: DirectoryUserTag.TECHNICAL
  },
  [DirectoryUserTag.FINANCIAL]: {
    name: 'Financial',
    type: DirectoryUserTag.FINANCIAL
  }
};
