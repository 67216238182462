import {
  useCategorizationSamples,
  useCategorizationTemplate
} from '@/components/admin-settings/categorization/categorization.util';
import { SampleEditor } from '@/components/admin-settings/categorization/sample-editor';
import { AppLayout } from '@/components/app-layout';
import { AdvancedQueryBuilder } from '@/components/manage-exclusion';
import { IntegrationLogo } from '@/components/settings/add-integration';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle
} from '@/components/ui/card';
import { Checkbox } from '@/components/ui/checkbox';
import { Combobox } from '@/components/ui/combo-box';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';
import { apiClient } from '@/lib/api';
import { components } from '@/lib/api.types';
import { cn } from '@/lib/utils';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, arrayMove, useSortable } from '@dnd-kit/sortable';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { CategoryClassConfig, CategoryConfig } from '@wire/shared';
import { GripVerticalIcon } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'sonner';

export const Route = createFileRoute(
  '/_application/admin/categorization/$integration_/$group'
)({
  component: RouteComponent
});

function RouteComponent() {
  const { template, refetch } = useCategorizationTemplate();
  const { samples } = useCategorizationSamples();
  const { integration, group } = Route.useParams();
  const navigate = useNavigate();
  const { integrationMetadata } = Route.useRouteContext();
  const [isEditGroupDialogOpen, setIsEditGroupDialogOpen] = useState(false);
  const [isCreateRuleDialogOpen, setIsCreateRuleDialogOpen] = useState(false);
  const [isEditRuleDialogOpen, setIsEditRuleDialogOpen] = useState(false);
  const [editingRule, setEditingRule] = useState<
    components['schemas']['CategorizationRule'] | null
  >(null);
  const [rules, setRules] = useState<
    components['schemas']['CategorizationRule'][]
  >([]);
  const [currentSample, setCurrentSample] = useState<
    components['schemas']['Sample'] | null
  >(null);

  const integrationConfig = useMemo(() => {
    return integrationMetadata.find((i) => i.slug === integration);
  }, [integration, integrationMetadata]);
  const categorizationConfig = useMemo(() => {
    return template.integrations.find((i) => i.platform === integration);
  }, [integration, template]);
  const groupConfig = useMemo(() => {
    return categorizationConfig?.groups.find((g) => g.id === group)!;
  }, [group, categorizationConfig]);

  useEffect(() => {
    setRules(groupConfig.rules ?? []);
  }, [groupConfig]);

  const [createRuleForm, setCreateRuleForm] = useState<
    Partial<components['schemas']['CategorizationRule']>
  >({
    description: '',
    category: undefined,
    prevented: undefined,
    rule: '',
    id: crypto.randomUUID()
  });

  const [editGroupForm, setEditGroupForm] = useState<
    Partial<components['schemas']['CategorizationGroup']>
  >({
    id: groupConfig.id,
    name: groupConfig.name,
    description: groupConfig.description || '',
    actionSlug: groupConfig.actionSlug || undefined,
    filter: groupConfig.filter || undefined,
    prevented: groupConfig.prevented || undefined
  });

  const [editRuleForm, setEditRuleForm] = useState<
    Partial<components['schemas']['CategorizationRule']>
  >({
    description: '',
    category: undefined,
    prevented: undefined,
    rule: ''
  });

  useEffect(() => {
    setEditGroupForm({
      id: groupConfig.id,
      name: groupConfig.name,
      description: groupConfig.description || '',
      actionSlug: groupConfig.actionSlug || undefined,
      filter: groupConfig.filter || undefined,
      prevented: groupConfig.prevented || undefined
    });
  }, [groupConfig]);

  async function updateGroupConfig(
    config: Partial<components['schemas']['CategorizationGroup']>
  ) {
    const newConfig = { ...groupConfig, ...config };

    const newTemplate = {
      ...template,
      integrations: template.integrations.map((item) =>
        item.platform !== integration
          ? item
          : {
              ...item,
              groups: item.groups.map((g) => (g.id === group ? newConfig : g))
            }
      )
    };

    const response = await apiClient.PATCH('/categorization/categorizers', {
      body: newTemplate
    });
    if (response.error != null) {
      toast.error(response.error.message);
    } else {
      await refetch();
    }
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await updateGroupConfig(editGroupForm);

    // If ID changed, update the URL
    if (editGroupForm.id !== group) {
      navigate({
        to: '/admin/categorization/$integration/$group',
        params: { integration: integration, group: editGroupForm.id! }
      });
    }

    setIsEditGroupDialogOpen(false);
  };
  async function createRule(
    rule: Partial<components['schemas']['CategorizationRule']>
  ) {
    const newRule = {
      ...rule,
      id: createRuleForm.id
    } as components['schemas']['CategorizationRule'];

    // Find the target integration
    const targetIntegration = template.integrations.find(
      (item) => item.platform === integration
    );
    if (!targetIntegration) return;

    // Find the target group
    const targetGroup = targetIntegration.groups.find((g) => g.id === group);
    if (!targetGroup) return;

    // Determine where to insert the new rule
    const rules = targetGroup.rules || [];
    const lastRule = rules[rules.length - 1];
    const updatedRules =
      lastRule && !lastRule.rule
        ? [...rules.slice(0, -1), newRule, lastRule] // Insert before empty last rule
        : [...rules, newRule]; // Append to end

    // Create updated group
    const updatedGroup = {
      ...targetGroup,
      rules: updatedRules
    };

    // Create updated template
    const newTemplate = {
      ...template,
      integrations: template.integrations.map((item) =>
        item.platform === integration
          ? {
              ...item,
              groups: item.groups.map((g) =>
                g.id === group ? updatedGroup : g
              )
            }
          : item
      )
    };

    const response = await apiClient.PATCH('/categorization/categorizers', {
      body: newTemplate
    });
    if (response.error != null) {
      toast.error(response.error.message);
    } else {
      await refetch();
    }

    setIsCreateRuleDialogOpen(false);
    setCreateRuleForm({
      description: '',
      category: undefined,
      prevented: undefined,
      rule: '',
      id: crypto.randomUUID()
    });
  }

  async function updateRule(
    rule: Partial<components['schemas']['CategorizationRule']>
  ) {
    if (!editingRule) return;

    const newTemplate = {
      ...template,
      integrations: template.integrations.map((item) =>
        item.platform !== integration
          ? item
          : {
              ...item,
              groups: item.groups.map((g) =>
                g.id === group
                  ? {
                      ...g,
                      rules: (g.rules || []).map((r) =>
                        r.id === editingRule.id ? { ...r, ...rule } : r
                      )
                    }
                  : g
              )
            }
      )
    };

    const response = await apiClient.PATCH('/categorization/categorizers', {
      body: newTemplate
    });
    if (response.error != null) {
      toast.error(response.error.message);
    } else {
      await refetch();
    }

    setIsEditRuleDialogOpen(false);
    setEditingRule(null);
    setEditRuleForm({
      description: undefined,
      category: undefined,
      prevented: undefined,
      rule: ''
    });
  }

  const handleCreateRuleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (createRuleForm.category == null) {
      toast.error('Category is required');
      return;
    }
    const newRule = {
      ...createRuleForm,
      id: crypto.randomUUID()
    } as components['schemas']['CategorizationRule'];

    // First create the rule
    await createRule(newRule);

    // Reset the form and samples
    setCreateRuleForm({
      description: '',
      category: undefined,
      prevented: undefined,
      rule: '',
      id: crypto.randomUUID()
    });
    setIsCreateRuleDialogOpen(false);
  };

  const handleEditRuleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await updateRule(editRuleForm);
  };

  const handleEditRule = (
    rule: components['schemas']['CategorizationRule']
  ) => {
    setEditingRule(rule);
    setEditRuleForm({
      description: rule.description,
      category: rule.category,
      prevented: rule.prevented,
      noRecategorization: rule.noRecategorization,
      rule: rule.rule
    });
    setIsEditRuleDialogOpen(true);
  };

  async function deleteGroup() {
    // First delete the group from the template
    const newTemplate = {
      ...template,
      integrations: template.integrations.map((item) => ({
        ...item,
        groups: item.groups.filter((g) => g.id !== group)
      }))
    };

    const templateResponse = await apiClient.PATCH(
      '/categorization/categorizers',
      {
        body: newTemplate
      }
    );
    if (templateResponse.error != null) {
      toast.error(templateResponse.error.message);
      return;
    }

    // Then delete all samples for rules in this group
    const groupRules = groupConfig.rules || [];
    const updatedSamples = samples.filter(
      (s) => !groupRules.some((r) => r.id === s.ruleId)
    );
    const samplesResponse = await apiClient.PATCH('/categorization/samples', {
      body: updatedSamples
    });
    if (samplesResponse.error != null) {
      toast.error(samplesResponse.error.message);
      return;
    }

    await refetch();
    navigate({
      to: '/admin/categorization/$integration',
      params: { integration }
    });
  }

  async function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = rules.findIndex((r) => r.id === active.id);
      const newIndex = rules.findIndex((r) => r.id === over?.id);

      if (oldIndex !== undefined && newIndex !== undefined) {
        const newRules = arrayMove(rules, oldIndex, newIndex);
        setRules(newRules);
        await updateGroupConfig({ rules: newRules });
      }
    }
  }

  async function deleteRule(ruleId: string) {
    // First delete the rule from the template
    const newTemplate = {
      ...template,
      integrations: template.integrations.map((item) =>
        item.platform !== integration
          ? item
          : {
              ...item,
              groups: item.groups.map((g) =>
                g.id === group
                  ? {
                      ...g,
                      rules: (g.rules || []).filter((r) => r.id !== ruleId)
                    }
                  : g
              )
            }
      )
    };

    const templateResponse = await apiClient.PATCH(
      '/categorization/categorizers',
      {
        body: newTemplate
      }
    );
    if (templateResponse.error != null) {
      toast.error(templateResponse.error.message);
      return;
    }

    // Then delete all samples for this rule
    const updatedSamples = samples.filter((s) => s.ruleId !== ruleId);
    const samplesResponse = await apiClient.PATCH('/categorization/samples', {
      body: updatedSamples
    });
    if (samplesResponse.error != null) {
      toast.error(samplesResponse.error.message);
      return;
    }

    await refetch();
    setEditingRule(null);
    setIsEditRuleDialogOpen(false);
  }

  return (
    <AppLayout>
      <Dialog
        open={isEditGroupDialogOpen}
        onOpenChange={setIsEditGroupDialogOpen}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Edit Group</DialogTitle>
            <DialogDescription>
              Make changes to the group configuration here.
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid gap-2">
              <Label htmlFor="id">ID</Label>
              <Input
                id="id"
                value={editGroupForm.id}
                onChange={(e) =>
                  setEditGroupForm({ ...editGroupForm, id: e.target.value })
                }
              />
            </div>
            <div className="grid gap-2">
              <Label htmlFor="name">Name</Label>
              <Input
                id="name"
                value={editGroupForm.name}
                onChange={(e) =>
                  setEditGroupForm({ ...editGroupForm, name: e.target.value })
                }
              />
            </div>
            <div className="grid gap-2">
              <Label htmlFor="description">Description</Label>
              <Textarea
                id="description"
                value={editGroupForm.description}
                onChange={(e) =>
                  setEditGroupForm({
                    ...editGroupForm,
                    description: e.target.value
                  })
                }
              />
            </div>
            <div className="grid gap-2">
              <Label htmlFor="actionSlug">Action (optional)</Label>
              <Input
                id="actionSlug"
                className="font-mono"
                value={editGroupForm.actionSlug}
                onChange={(e) =>
                  setEditGroupForm({
                    ...editGroupForm,
                    actionSlug: e.target.value
                  })
                }
              />
            </div>
            <div className="grid gap-2">
              <Label htmlFor="filter">Filter (optional)</Label>
              <Textarea
                id="filter"
                className="font-mono"
                value={editGroupForm.filter}
                onChange={(e) =>
                  setEditGroupForm({
                    ...editGroupForm,
                    filter: e.target.value
                  })
                }
              />
            </div>
            <div className="grid gap-2">
              <Label htmlFor="prevented">Prevented (optional)</Label>
              <Textarea
                id="prevented"
                className="font-mono"
                value={editGroupForm.prevented}
                onChange={(e) =>
                  setEditGroupForm({
                    ...editGroupForm,
                    prevented: e.target.value
                  })
                }
              />
            </div>
          </div>
          <DialogFooter>
            <Button variant="destructive" onClick={deleteGroup}>
              Delete Group
            </Button>
            <Button onClick={handleSubmit} type="submit">
              Save changes
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog
        open={isCreateRuleDialogOpen}
        onOpenChange={setIsCreateRuleDialogOpen}
      >
        <DialogContent className="max-w-[80vw]">
          <DialogHeader>
            <DialogTitle>Create Rule</DialogTitle>
            <DialogDescription>
              Add a new categorization rule to this group.
            </DialogDescription>
          </DialogHeader>
          <div className="grid overflow-y-hidden lg:grid-cols-2 gap-4 py-4">
            <div className="flex flex-col gap-2">
              <div className="grid gap-2">
                <div className="flex flex-col gap-2 lg:flex-row lg:items-center lg:justify-between">
                  <Label htmlFor="rule-category">Category</Label>
                  <div className="flex flex-row gap-1 text-xs items-center">
                    <Label htmlFor="noRecategorization" className="text-xs">
                      No recategorization
                    </Label>
                    <Checkbox
                      id="noRecategorization"
                      checked={createRuleForm.noRecategorization}
                      onCheckedChange={(checked) =>
                        setCreateRuleForm({
                          ...createRuleForm,
                          noRecategorization: checked == true
                        })
                      }
                    />
                  </div>
                </div>
                <Combobox
                  modal
                  className="items-stretch"
                  value={createRuleForm.category}
                  values={Object.values(CategoryConfig).map((c) => ({
                    label: (
                      <div className="flex flex-row items-center gap-2">
                        <div>{c.name}</div>-
                        <div className="text-xs text-muted-foreground">
                          {CategoryClassConfig[c.class].name}
                        </div>
                      </div>
                    ),
                    value: c.category
                  }))}
                  placeholder="Select a category"
                  emptyMessage="No categories found"
                  onSelect={(value) =>
                    setCreateRuleForm({
                      ...createRuleForm,
                      category: value as any
                    })
                  }
                />
              </div>

              <div className="grid gap-2">
                <AdvancedQueryBuilder
                  query={createRuleForm.rule}
                  sample={currentSample?.doc}
                  setQuery={(e) =>
                    setCreateRuleForm({
                      ...createRuleForm,
                      rule: e
                    })
                  }
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="rule-description">Description (optional)</Label>
                <Input
                  id="rule-description"
                  value={createRuleForm.description}
                  onChange={(e) =>
                    setCreateRuleForm({
                      ...createRuleForm,
                      description: e.target.value
                    })
                  }
                />
              </div>
            </div>
            <div className="flex flex-col flex-1 gap-2">
              <SampleEditor
                ruleId={createRuleForm.id ?? ''}
                onSampleSelect={setCurrentSample}
              />
            </div>
          </div>
          <DialogFooter>
            <Button onClick={handleCreateRuleSubmit} type="submit">
              Create Rule
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog
        open={isEditRuleDialogOpen}
        onOpenChange={setIsEditRuleDialogOpen}
      >
        <DialogContent className="max-w-[80vw]">
          <DialogHeader>
            <DialogTitle>Edit Rule</DialogTitle>
            <DialogDescription>
              Make changes to the rule configuration here.
            </DialogDescription>
          </DialogHeader>
          <div className="grid overflow-y-hidden lg:grid-cols-2 gap-4 py-4">
            <div className="flex flex-col gap-2">
              <div className="grid gap-2">
                <div className="flex flex-col gap-2 lg:flex-row lg:items-center lg:justify-between">
                  <Label htmlFor="edit-rule-category">Category</Label>
                  <div className="flex flex-row gap-1 text-xs items-center">
                    <Label htmlFor="noRecategorization" className="text-xs">
                      No recategorization
                    </Label>
                    <Checkbox
                      id="noRecategorization"
                      checked={editRuleForm.noRecategorization}
                      onCheckedChange={(checked) =>
                        setEditRuleForm({
                          ...editRuleForm,
                          noRecategorization: checked == true
                        })
                      }
                    />
                  </div>
                </div>
                <Combobox
                  modal
                  className="items-stretch"
                  value={editRuleForm.category}
                  values={Object.values(CategoryConfig).map((c) => ({
                    label: (
                      <div className="flex flex-row items-center gap-2">
                        <div>{c.name}</div>-
                        <div className="text-xs text-muted-foreground">
                          {CategoryClassConfig[c.class].name}
                        </div>
                      </div>
                    ),
                    value: c.category
                  }))}
                  placeholder="Select a category"
                  emptyMessage="No categories found"
                  onSelect={(value) =>
                    setEditRuleForm({
                      ...editRuleForm,
                      category: value as any
                    })
                  }
                />
              </div>
              <div className="grid gap-2">
                <AdvancedQueryBuilder
                  query={editRuleForm.rule}
                  sample={currentSample?.doc}
                  setQuery={(e) =>
                    setEditRuleForm({
                      ...editRuleForm,
                      rule: e
                    })
                  }
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="edit-rule-prevented">
                  Prevented (optional)
                </Label>
                <Textarea
                  id="edit-rule-prevented"
                  className="font-mono"
                  value={editRuleForm.prevented}
                  onChange={(e) =>
                    setEditRuleForm({
                      ...editRuleForm,
                      prevented: e.target.value
                    })
                  }
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="edit-rule-description">
                  Description (optional)
                </Label>
                <Input
                  id="edit-rule-description"
                  value={editRuleForm.description}
                  onChange={(e) =>
                    setEditRuleForm({
                      ...editRuleForm,
                      description: e.target.value
                    })
                  }
                />
              </div>
              <div className="flex justify-end flex-row gap-2">
                <Button size="sm" onClick={handleEditRuleSubmit} type="submit">
                  Save changes
                </Button>
                <Button
                  size="sm"
                  variant="destructive"
                  onClick={() => deleteRule(editingRule?.id!)}
                >
                  Delete
                </Button>
              </div>
            </div>
            <div className="flex flex-col flex-1 gap-2">
              <SampleEditor
                ruleId={editingRule?.id ?? ''}
                onSampleSelect={setCurrentSample}
              />
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Card>
        <CardHeader className="flex flex-row justify-between">
          <div className="w-full">
            <CardTitle className="flex flex-row gap-2">
              <IntegrationLogo config={integrationConfig} />
              {integrationConfig?.name} - {groupConfig?.name}
            </CardTitle>
            <CardDescription>{groupConfig?.description}</CardDescription>
            {groupConfig.filter != null && groupConfig.filter.length > 0 && (
              <div className="flex flex-row text-muted-foreground text-sm">
                Filter:&nbsp;
                <div className="font-mono">{groupConfig.filter}</div>
              </div>
            )}
            {groupConfig.prevented != null &&
              groupConfig.prevented.length > 0 && (
                <div className="flex flex-row text-muted-foreground text-sm">
                  Prevented:&nbsp;
                  <div className="font-mono">{groupConfig.prevented}</div>
                </div>
              )}
            {groupConfig.actionSlug != null &&
              groupConfig.actionSlug.length > 0 && (
                <div className="flex flex-row text-muted-foreground text-sm">
                  Action:&nbsp;
                  <div className="font-mono">{groupConfig.actionSlug}</div>
                </div>
              )}
          </div>
          <div className="flex flex-row gap-2">
            <Button onClick={() => setIsCreateRuleDialogOpen(true)}>
              Add Rule
            </Button>
            <Button
              variant="outline"
              onClick={() => setIsEditGroupDialogOpen(true)}
            >
              Edit
            </Button>
          </div>
        </CardHeader>
        <div className="p-6">
          <h3 className="text-lg font-semibold mb-4">Rules</h3>
          <div className="flex flex-col gap-1">
            <DndContext onDragEnd={handleDragEnd}>
              <SortableContext items={rules}>
                {rules.map((rule) => (
                  <SortableItem
                    key={rule.id}
                    id={rule.id}
                    numberSamples={
                      samples.filter((s) => s.ruleId === rule.id).length
                    }
                    rule={rule}
                    handleEditRule={handleEditRule}
                  />
                ))}
              </SortableContext>
            </DndContext>
            {(!rules || rules.length === 0) && (
              <p className="text-muted-foreground">No rules configured yet.</p>
            )}
          </div>
        </div>
      </Card>
    </AppLayout>
  );
}

function SortableItem({
  rule,
  id,
  handleEditRule,
  numberSamples
}: {
  id: string;
  rule: components['schemas']['CategorizationRule'];
  handleEditRule: (rule: components['schemas']['CategorizationRule']) => void;
  numberSamples: number;
}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: id });
  const style = {
    transform: transform
      ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
      : '',
    transition
  };

  return (
    <div
      className="p-2 border bg-background hover:bg-muted cursor-pointer flex flex-row justify-between items-center rounded-md"
      ref={setNodeRef}
      onClick={() => handleEditRule(rule)}
      style={style}
    >
      <div className="flex flex-row items-center flex-1 gap-2">
        <GripVerticalIcon
          {...attributes}
          {...listeners}
          className="text-muted-foreground cursor-grab focus:outline-none"
        />
        <div className="flex items-center text-xs lg:flex-row lg:justify-between flex-1">
          <div className="flex font-mono flex-row gap-2" key={rule.id}>
            <div>{rule.rule ? rule.rule : 'Default'}</div>
          </div>
          <div
            className={cn('text-muted-foreground', {
              'text-destructive': numberSamples == 0 && rule.rule
            })}
          >
            {CategoryConfig[rule.category]?.name}
          </div>
        </div>
      </div>
    </div>
  );
}
