import { useCategorizationTemplate } from '@/components/admin-settings/categorization/categorization.util';
import { AppLayout } from '@/components/app-layout';
import { IntegrationLogo } from '@/components/settings/add-integration';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from '@/components/ui/card';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';
import { apiClient } from '@/lib/api';
import { components } from '@/lib/api.types';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, arrayMove, useSortable } from '@dnd-kit/sortable';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { GripVerticalIcon } from 'lucide-react';
import { ReactNode, useEffect, useMemo, useState } from 'react';

export const Route = createFileRoute(
  '/_application/admin/categorization/$integration'
)({
  component: RouteComponent
});

function RouteComponent() {
  const { template, refetch } = useCategorizationTemplate();
  const { integration } = Route.useParams();
  const navigate = useNavigate();
  const { integrationMetadata } = Route.useRouteContext();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [createForm, setCreateForm] = useState({
    id: '',
    name: '',
    description: '',
    filter: '',
    actionSlug: ''
  });
  const [idError, setIdError] = useState<string | null>(null);
  const [groups, setGroups] = useState<
    components['schemas']['CategorizationGroup'][]
  >([]);

  const integrationConfig = useMemo(() => {
    return integrationMetadata.find((i) => i.slug === integration);
  }, [integration, integrationMetadata]);
  const categorizationConfig = useMemo(() => {
    return template.integrations.find((i) => i.platform === integration);
  }, [integration, template]);

  // Initialize groups state when categorizationConfig changes
  useEffect(() => {
    setGroups(categorizationConfig?.groups ?? []);
  }, [categorizationConfig]);

  // Convert name to snake case for ID
  const generateId = (name: string) => {
    return name
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '_')
      .replace(/(^_|_$)/g, '');
  };

  // Validate ID uniqueness
  const validateId = (id: string) => {
    if (!id) {
      setIdError('ID is required');
      return false;
    }
    if (categorizationConfig?.groups.some((g) => g.id === id)) {
      setIdError('This ID is already in use');
      return false;
    }
    setIdError(null);
    return true;
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value;
    setCreateForm((prev) => ({
      ...prev,
      name,
      // Only auto-generate ID if it's empty or matches the previous name's generated ID
      id:
        prev.id === '' || prev.id === generateId(prev.name)
          ? generateId(name)
          : prev.id
    }));
  };

  const handleIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const id = e.target.value;
    setCreateForm((prev) => ({ ...prev, id }));
    validateId(id);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateId(createForm.id)) return;

    const newGroup: components['schemas']['CategorizationGroup'] = {
      id: createForm.id,
      name: createForm.name,
      description: createForm.description,
      filter: createForm.filter,
      actionSlug: createForm.actionSlug,
      rules: []
    };

    const newTemplate = {
      ...template,
      integrations: template.integrations.map((item) =>
        item.platform !== integration
          ? item
          : {
              ...item,
              groups: [...item.groups, newGroup]
            }
      )
    };

    await apiClient.PATCH('/categorization/categorizers', {
      body: newTemplate
    });
    await refetch();

    // Navigate to the new group
    navigate({
      to: '/admin/categorization/$integration/$group',
      params: { integration, group: createForm.id }
    });

    setIsDialogOpen(false);
  };

  async function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = groups.findIndex((g) => g.id === active.id);
      const newIndex = groups.findIndex((g) => g.id === over?.id);

      if (oldIndex !== undefined && newIndex !== undefined) {
        const newGroups = arrayMove(groups, oldIndex, newIndex);
        setGroups(newGroups);

        const newTemplate = {
          ...template,
          integrations: template.integrations.map((item) =>
            item.platform !== integration
              ? item
              : {
                  ...item,
                  groups: newGroups
                }
          )
        };

        await apiClient.PATCH('/categorization/categorizers', {
          body: newTemplate
        });
        await refetch();
      }
    }
  }

  return (
    <AppLayout>
      <Card>
        <CardHeader className="flex flex-row justify-between">
          <div>
            <CardTitle className="flex flex-row gap-2">
              <IntegrationLogo config={integrationConfig} />
              {integrationConfig?.name}
            </CardTitle>
            <CardDescription>
              Manage the categorization groups for {integrationConfig?.name}.
            </CardDescription>
          </div>
          <div>
            <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
              <DialogTrigger asChild>
                <Button>Add Group</Button>
              </DialogTrigger>
              <DialogContent>
                <form onSubmit={handleSubmit}>
                  <DialogHeader>
                    <DialogTitle>Create New Group</DialogTitle>
                    <DialogDescription>
                      Create a new categorization group for{' '}
                      {integrationConfig?.name}.
                    </DialogDescription>
                  </DialogHeader>
                  <div className="grid gap-4 py-4">
                    <div className="grid gap-2">
                      <Label htmlFor="name">Name</Label>
                      <Input
                        id="name"
                        value={createForm.name}
                        onChange={handleNameChange}
                        placeholder="Enter group name"
                      />
                    </div>
                    <div className="grid gap-2">
                      <Label htmlFor="description">Description</Label>
                      <Textarea
                        id="description"
                        value={createForm.description}
                        onChange={(e) =>
                          setCreateForm({
                            ...createForm,
                            description: e.target.value
                          })
                        }
                        placeholder="Enter group description"
                      />
                    </div>
                    <div className="grid gap-2">
                      <Label htmlFor="actionSlug">Action (optional)</Label>
                      <Input
                        id="actionSlug"
                        value={createForm.actionSlug}
                        className="font-mono"
                        onChange={(e) =>
                          setCreateForm({
                            ...createForm,
                            actionSlug: e.target.value
                          })
                        }
                        placeholder="Enter action slug"
                      />
                    </div>
                    <div className="grid gap-2">
                      <Label htmlFor="filter">Filter (optional)</Label>
                      <Textarea
                        id="filter"
                        value={createForm.filter}
                        className="font-mono"
                        onChange={(e) =>
                          setCreateForm({
                            ...createForm,
                            filter: e.target.value
                          })
                        }
                        placeholder="Enter filter"
                      />
                    </div>
                    <div className="grid gap-2">
                      <Label htmlFor="id" className="text-muted-foreground">
                        ID
                      </Label>
                      <Input
                        id="id"
                        value={createForm.id}
                        onChange={handleIdChange}
                        className={idError ? 'border-destructive' : ''}
                      />
                      {idError && (
                        <p className="text-sm text-destructive">{idError}</p>
                      )}
                    </div>
                  </div>
                  <DialogFooter>
                    <Button type="submit" disabled={!!idError}>
                      Create Group
                    </Button>
                  </DialogFooter>
                </form>
              </DialogContent>
            </Dialog>
          </div>
        </CardHeader>
        <CardContent>
          <div className="flex flex-col gap-1">
            <DndContext onDragEnd={handleDragEnd}>
              <SortableContext items={groups}>
                {groups.map((group) => (
                  <SortableGroupItem
                    key={group.id}
                    id={group.id}
                    group={group}
                    integration={integration}
                  />
                ))}
              </SortableContext>
            </DndContext>
            {(!groups || groups.length === 0) && (
              <p className="text-muted-foreground">No groups configured yet.</p>
            )}
          </div>
        </CardContent>
      </Card>
    </AppLayout>
  );
}

function SortableGroupItem({
  group,
  id,
  integration
}: {
  id: string;
  group: components['schemas']['CategorizationGroup'];
  integration: string;
}) {
  const navigate = useNavigate();
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: id });
  const style = {
    transform: transform
      ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
      : '',
    transition
  };

  const description = useMemo(() => {
    const out: ReactNode[] = [];
    if (!group.filter && !group.actionSlug) {
      return (
        <div className="text-sm font-mono text-muted-foreground">
          Matches all
        </div>
      );
    }
    return (
      <>
        {
          <div className="text-sm font-mono text-muted-foreground">
            Filter: {group.filter ? group.filter : '-'}
          </div>
        }
        {group.actionSlug && (
          <div className="text-sm font-mono text-muted-foreground">
            Action: {group.actionSlug ?? '-'}
          </div>
        )}
      </>
    );
  }, [group]);

  return (
    <div
      onClick={() =>
        navigate({
          to: '/admin/categorization/$integration/$group',
          params: { integration, group: group.id }
        })
      }
      className="p-2 border bg-background hover:bg-muted cursor-pointer flex flex-row justify-between items-center rounded-md"
      ref={setNodeRef}
      style={style}
    >
      <div className="flex flex-row items-center flex-1 gap-2">
        <GripVerticalIcon
          {...attributes}
          {...listeners}
          className="text-muted-foreground cursor-grab focus:outline-none"
        />
        <div className="flex flex-col">
          <div className="font-medium">{group.name}</div>
          <div className="text-sm font-mono text-muted-foreground">
            {description}
          </div>
        </div>
      </div>
    </div>
  );
}
