import { TableCard } from '@/components/table-card';
import {
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from '@/components/ui/card';
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger
} from '@/components/ui/hover-card';
import { Label } from '@/components/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/ui/select';
import { Switch } from '@/components/ui/switch';
import { apiClient, formatSearchQuery } from '@/lib/api';
import { components } from '@/lib/api.types';
import { getTimeZoneAbbreviation, useTimezone } from '@/lib/time';
import {
  keepPreviousData,
  queryOptions,
  useQuery,
  useQueryClient
} from '@tanstack/react-query';
import {
  createFileRoute,
  useNavigate,
  useRouteContext,
  useSearch
} from '@tanstack/react-router';
import { DirectoryUserTag, DirectoryUserTagConfig, ROLE } from '@wire/shared';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';
export const Route = createFileRoute('/_application/assets/users/')({
  component: Users,
  loaderDeps: ({ search }) => {
    return {
      search
    };
  },
  loader: ({ context, deps }) => {
    context.queryClient.ensureQueryData(getAutomationsOptions());
    context.queryClient.ensureQueryData(
      getDirectoryUsersOptions({
        ...deps.search,
        hideDetectionUsers: true
      } as components['schemas']['DirectoryUserSearchDto'])
    );
  }
});

function getDirectoryUsersOptions(
  settings: components['schemas']['DirectoryUserSearchDto'] = {}
) {
  return queryOptions({
    queryKey: [DIRECTORY_USERS_QUERY_KEY, settings],
    queryFn: () => getDirectoryUsers(settings as any),
    placeholderData: keepPreviousData
  });
}

function getAutomationsOptions(
  settings: components['schemas']['PaginationDto'] = {}
) {
  return queryOptions({
    queryKey: [AUTOMATIONS_KEY, settings],
    queryFn: () => getAutomations(settings),
    placeholderData: keepPreviousData
  });
}

async function getAutomations(
  searchSettings: components['schemas']['PaginationDto'] = {}
) {
  const response = await apiClient.POST('/directory/automation', {
    body: searchSettings
  });

  if (response.error != null) {
    throw new Error('Error getting automations');
  }

  return response.data;
}
async function getDirectoryUsers(
  searchSettings: components['schemas']['DirectoryUserSearchDto'] = {
    filter: 'email'
  }
) {
  const search = formatSearchQuery(searchSettings.search);
  const response = await apiClient.POST('/directory', {
    body: { ...searchSettings, search }
  });

  if (response.error != null) {
    throw new Error('Error getting directory users');
  }

  return response.data;
}

const AUTOMATIONS_KEY = 'directory-user-automations';
const DIRECTORY_USERS_QUERY_KEY = 'settings-directory-users';

export default function Users() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const urlSearch = useSearch({ from: '/_application/assets/users/' });
  const { integrationMetadata } = useRouteContext({ from: '/_application' });
  const [directoryUserSearchSettings, setDirectoryUserSearchSettings] =
    useState<components['schemas']['DirectoryUserSearchDto']>(urlSearch as any);
  const [hideDetectionUsers, setHideDetectionUsers] = useState(true);

  const [automationSearchSettings, setAutomationSearchSettings] =
    useState<components['schemas']['PaginationDto']>();
  const directoryUsersQuery = useQuery(
    getDirectoryUsersOptions(directoryUserSearchSettings)
  );
  const automationsQuery = useQuery(
    getAutomationsOptions(automationSearchSettings)
  );
  const { timezone } = useTimezone();
  useEffect(() => {
    setDirectoryUserSearchSettings({
      ...directoryUserSearchSettings,
      hideDetectionUsers
    });
  }, [hideDetectionUsers]);

  async function deleteAutomation(id: string) {
    const response = await apiClient.DELETE('/directory/automation/{id}', {
      params: { path: { id } }
    });

    if (response.error != null) {
      toast.error('Error deleting automation');
      return;
    }

    await queryClient.invalidateQueries({
      queryKey: [AUTOMATIONS_KEY]
    });
    toast.warning('Automation deleted');
  }

  function updateDirectoryUserSearchSettings(
    settings: components['schemas']['DirectoryUserSearchDto']
  ) {
    setDirectoryUserSearchSettings({
      ...directoryUserSearchSettings,
      ...settings,
      filter:
        (settings.filter as any) ??
        directoryUserSearchSettings?.filter ??
        'email'
    });
  }

  async function tagUser(
    id: string,
    tag: components['schemas']['TagUserDto']['tag']
  ) {
    const response = await apiClient.PUT('/directory/{id}/tag', {
      params: { path: { id } },
      body: { tag }
    });
    if (response.error != null) {
      return toast.error('Error tagging user');
    }
    await queryClient.invalidateQueries({
      queryKey: [DIRECTORY_USERS_QUERY_KEY]
    });
    toast.success('User tagged');
  }

  async function untagUser(
    id: string,
    tag: components['schemas']['TagUserDto']['tag']
  ) {
    const response = await apiClient.DELETE('/directory/{id}/tag', {
      params: { path: { id } },
      body: { tag }
    });
    if (response.error != null) {
      return toast.error('Error untagging user');
    }
    await queryClient.invalidateQueries({
      queryKey: [DIRECTORY_USERS_QUERY_KEY]
    });
    toast.warning('User untagged');
  }

  async function addAutomation(
    tag: components['schemas']['TagUserDto']['tag']
  ) {
    if (
      directoryUserSearchSettings == null ||
      directoryUserSearchSettings.filter == null ||
      directoryUserSearchSettings.search == null
    ) {
      toast.error('Please select search field and search');
      return;
    }
    const search = formatSearchQuery(directoryUserSearchSettings.search);

    const response = await apiClient.PUT('/directory/automation', {
      body: {
        search: search!,
        field: directoryUserSearchSettings.filter,
        tag
      }
    });

    if (response.error != null) {
      if (response.error.statusCode == 409) {
        toast.error('Automation already exists');
      } else {
        toast.error('Error creating automation');
      }
      return;
    }

    await queryClient.invalidateQueries({
      queryKey: [DIRECTORY_USERS_QUERY_KEY]
    });
    await queryClient.invalidateQueries({
      queryKey: [AUTOMATIONS_KEY]
    });
    toast.success('Automation created');
  }

  return (
    <div>
      <TableCard
        embedded
        onUpdate={updateDirectoryUserSearchSettings as any}
        query={directoryUsersQuery}
        storeStateInUrl
        searchable
        tableActions={[
          {
            display: 'Add New VIP Automation',
            onClick: () => addAutomation('VIP'),
            requiredRole: ROLE.ANALYST,
            showOnSearch: true
          },
          {
            display: 'Add New Technical User Automation',
            onClick: () => addAutomation('TECHNICAL'),
            requiredRole: ROLE.ANALYST,
            showOnSearch: true
          },
          {
            display: 'Add New Administrator User Automation',
            onClick: () => addAutomation('ADMIN'),
            requiredRole: ROLE.ANALYST,
            showOnSearch: true
          },
          {
            display: 'Add New Financial User Automation',
            onClick: () => addAutomation('FINANCIAL'),
            requiredRole: ROLE.ANALYST,
            showOnSearch: true
          }
        ]}
        compact
        onClickNavigate={(row) => ({
          to: '/assets/users/$userId',
          params: { userId: row.id }
        })}
        searchFilter={{
          label: 'Search Field',
          placeholder: 'Select field',
          defaultValue: 'email',
          values: [
            { key: 'name', display: 'Name' },
            { key: 'email', display: 'Email' },
            { key: 'title', display: 'Job Title' },
            { key: 'department', display: 'Department' },
            { key: 'role', display: 'Role' }
          ]
        }}
        headers={[
          { display: 'Name', key: 'name', sortable: true },
          { display: 'Username', key: 'username', sortable: true },
          { display: 'Email', key: 'email', sortable: true },
          { display: 'Title', key: 'title', sortable: true },
          {
            display: 'Roles',
            key: 'roles',
            truncate: 60,
            hover: true
          },
          { display: 'Department', key: 'department', sortable: true },
          { display: 'VIP', key: 'vip' },
          { display: 'Technical', key: 'technical' },
          { display: 'Administrator', key: 'administrator' },
          { display: 'Financial', key: 'financial' },
          {
            display: 'Source',
            key: 'integrationPlatform',
            format: (v) =>
              integrationMetadata.find((i) => i.slug === v)?.name ?? '-'
          },
          {
            display: `First Seen (${getTimeZoneAbbreviation(timezone)})`,
            key: 'createdAt',
            sortable: true,
            dateTime: true
          },
          {
            display: `Updated At (${getTimeZoneAbbreviation(timezone)})`,
            key: 'updatedAt',
            sortable: true,
            dateTime: true
          }
        ]}
        rowActions={[
          {
            name: (row) => (row.vip ? `Disable VIP` : 'Enable VIP'),
            onClick: (row) =>
              row.vip ? tagUser(row.id, 'VIP') : tagUser(row.id, 'VIP')
          },
          {
            name: (row) => (row.vip ? `Disable Financial` : 'Enable Financial'),
            onClick: (row) =>
              row.financial
                ? tagUser(row.id, 'FINANCIAL')
                : tagUser(row.id, 'FINANCIAL')
          },
          {
            name: (row) =>
              row.technical ? `Disable Technical` : 'Enable Technical',
            onClick: (row) =>
              row.technical
                ? untagUser(row.id, 'TECHNICAL')
                : untagUser(row.id, 'TECHNICAL')
          },
          {
            name: (row) =>
              row.administrator
                ? `Disable Administrator`
                : 'Enable Administrator',
            onClick: (row) =>
              row.administrator
                ? untagUser(row.id, 'ADMIN')
                : untagUser(row.id, 'ADMIN')
          }
        ]}
      >
        <CardHeader>
          <div className="flex  gap-4 items-start lg:items-center flex-col lg:flex-row justify-between">
            <div className="flex flex-col gap-2">
              <CardTitle>Directory Users</CardTitle>
              <CardDescription>
                All users ingested from your directory integrations. Use{' '}
                <kbd>*</kbd> for a wildcard, <kbd>"quotes"</kbd> for exact
                match, or <kbd>_</kbd> for single character wildcard.
              </CardDescription>
            </div>
          </div>
        </CardHeader>
        <CardContent className="pb-2">
          <div className="flex w-full  lg:items-center flex-col gap-2 lg:flex-row">
            <div>
              <Select
                onValueChange={(v) => {
                  updateDirectoryUserSearchSettings({
                    tags: v == 'any' ? undefined : ([v] as DirectoryUserTag[])
                  });
                }}
              >
                <SelectTrigger className="h-full bg-background font-medium">
                  <SelectValue key="select-value" placeholder="Tag" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem key="any" value="any">
                    Any
                  </SelectItem>
                  {Object.values(DirectoryUserTagConfig).map((v) => (
                    <SelectItem key={v.name} value={v.type}>
                      {v.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="border rounded-md p-2 shadow-sm">
              <HoverCard openDelay={100}>
                <HoverCardTrigger className="flex flex-row items-center gap-1">
                  <Label
                    htmlFor="hide-detection-users"
                    className="cursor-pointer"
                  >
                    Show Detection Users
                  </Label>
                  <Switch
                    id="hide-detection-users"
                    checked={!hideDetectionUsers}
                    onCheckedChange={(v) => setHideDetectionUsers(!v)}
                  />
                </HoverCardTrigger>
                <HoverCardContent className="text-xs">
                  Show users that were involved in a detection but not available
                  in your directories.
                </HoverCardContent>
              </HoverCard>
            </div>
          </div>
        </CardContent>
      </TableCard>
      <div className="border-t ">
        <TableCard
          embedded
          onUpdate={setAutomationSearchSettings}
          query={automationsQuery}
          searchable
          headers={[
            {
              display: 'Tag',
              key: 'tag',
              format: (value) =>
                DirectoryUserTagConfig[value as DirectoryUserTag]?.name
            },
            { display: 'Search Field', key: 'searchField' },
            { display: 'Search', key: 'search' }
          ]}
          rowActions={[
            {
              name: 'Delete Automation',
              onClick: (row) => deleteAutomation(row.id),
              confirm: true,
              confirmMessage:
                'Are you sure you want to delete this user automation? This will remove all users identified from this automation.'
            }
          ]}
        >
          <CardHeader>
            <div className="flex items-start space-y-4 lg:space-y-0 lg:items-center flex-col lg:flex-row justify-between">
              <div>
                <CardTitle>User Automations</CardTitle>
                <CardDescription>
                  Search queries to automatically tag users in your
                  organization. To create a new automation, issue a search on
                  the Users table above and select Actions &gt; Add New
                  Automation.
                </CardDescription>
              </div>
            </div>
          </CardHeader>
        </TableCard>
      </div>
    </div>
  );
}
