import { useRouteContext } from '@tanstack/react-router';
import { differenceInMilliseconds, differenceInSeconds } from 'date-fns';
import moment from 'moment-timezone';
import { useMemo } from 'react';

function verifyDate(val: string | number) {
  const date = moment(val);
  if (date.isValid()) {
    return true;
  }
  return false;
}

interface DateTimeOptions {
  format?: string;
}

const DefaultDateTimeOptions: DateTimeOptions = {
  format: 'MMMM Do YYYY @ h:mm:ss a'
};

export function localDateTime(
  val: string | number,
  timezone: string,
  opts: DateTimeOptions = DefaultDateTimeOptions
) {
  if (!verifyDate(val)) {
    return 'N/A';
  }

  const offset = getUTCOffset(timezone);
  return moment.utc(val).utcOffset(offset).local(true).format(opts.format);
}

export function utcDateTime(
  val: string | number,
  opts: DateTimeOptions = DefaultDateTimeOptions
) {
  if (!verifyDate(val)) {
    return 'N/A';
  }
  const out = moment.utc(val).format(opts.format);
  return `${out} UTC`;
}

export function localDateTimeBuilder(val: string | number, timezone: string) {
  const date = moment(val);
  if (date.isValid()) {
    const offset = getUTCOffset(timezone);
    return moment.utc(val).utcOffset(offset).local(true);
  }
  return moment('NaN');
}

export function utcDateTimeBuilder(val: string | number) {
  const date = moment(val);
  if (date.isValid()) {
    return moment.utc(val);
  }
  return moment('NaN');
}

export function useTimezone() {
  const { team } = useRouteContext({ from: '/_application' });
  const timezone = useMemo(() => {
    let match = localStorage.getItem(`timezone-${team.id}`);
    if (match) {
      match = JSON.parse(match);
    }
    if (!match) {
      match = localStorage.getItem('timezone');
      if (match) {
        match = JSON.parse(match);
      }
    }
    const timezone = match ?? Intl.DateTimeFormat().resolvedOptions().timeZone;
    return {
      timezone
    };
  }, [team.id]);
  return timezone;
}

export function getTimeZoneAbbreviation(timeZone: string) {
  const formatter = new Intl.DateTimeFormat('en-US', {
    timeZone,
    timeZoneName: 'short'
  });
  const parts = formatter.formatToParts(new Date());
  const abbr = parts.find((part) => part.type === 'timeZoneName')?.value;
  return abbr || 'Unknown';
}

function getUTCOffset(timeZone: string) {
  if (timeZone.startsWith('UTC') || timeZone.startsWith('GMT')) {
    return timeZone.split(/[+-]/)[1];
  }
  const now = new Date();
  const formatter = new Intl.DateTimeFormat('en-US', {
    timeZone,
    timeZoneName: 'longOffset'
  });

  const parts = formatter.formatToParts(now);
  const offsetPart = parts.find((part) => part.type === 'timeZoneName')?.value;

  if (!offsetPart) return 'Unknown';
  // Convert offset string (e.g., "UTC−05:00") to minutes
  const match = offsetPart.match(/([+-]\d{2}):(\d{2})/);
  if (!match) return 0; // UTC (no offset)

  const [, hours, minutes] = match.map(Number);
  return hours * 60 + minutes;
}

export function calculateTTRSeconds(start: string, end: string) {
  return differenceInSeconds(end, start);
}

export function calculateTTRMilliseconds(start?: string, end?: string) {
  if (!start || !end) return;
  return differenceInMilliseconds(end, start);
}
