import { apiClient, getTeamToken, switchTeamWithToken } from '@/lib/api';

import { Input } from '@/components/ui/input';
import { DialogTrigger } from '@radix-ui/react-dialog';
import { useQueryClient } from '@tanstack/react-query';
import {
  useNavigate,
  useRouteContext,
  useRouter
} from '@tanstack/react-router';
import { ROLE } from '@wire/shared';
import { useMemo, useState } from 'react';
import { toast } from 'sonner';
import { Button } from '../ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '../ui/dialog';

export default function DeleteTeam() {
  const [open, setOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const navigate = useNavigate();
  const router = useRouter();
  const queryClient = useQueryClient();
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const { team, teams, user } = useRouteContext({ from: '/_application' });
  const requiredConfirmationMessage = useMemo(
    () => `Permanently delete ${team.name}`,
    [team]
  );

  async function deleteTeam() {
    if (team.id == user.parentTeamId) {
      toast.error('Cannot delete parent team');
      return;
    }
    if (confirmationMessage !== requiredConfirmationMessage) {
      toast.error('Incorrect confirmation message');
      return;
    }
    setDeleting(true);
    // Get the team token so we can switch back to it after deleting
    const teamToken = await getTeamToken(user.parentTeamId);
    if (teamToken == null) {
      toast.error('Error deleting team');
      setDeleting(false);
      return;
    }
    const response = await apiClient.DELETE('/admin/team/{id}', {
      params: {
        path: {
          id: team.id
        }
      }
    });
    if (response.error != null) {
      toast.error('Error deleting team');

      setDeleting(false);
    } else {
      toast.success('Team deleted, switching to parent team');
      await switchTeamWithToken(teamToken, navigate, '/dashboard');
    }
  }

  return (
    <Dialog requiredRole={ROLE.ADMIN} open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outlineDestructive">Delete Team</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          {' '}
          <DialogTitle>Delete Team</DialogTitle>
          <DialogDescription>
            Permanently delete team and all associated data.
          </DialogDescription>
        </DialogHeader>
        <p className="text-sm">
          Type{' '}
          <code className="p-1 bg-muted text-sm">
            {requiredConfirmationMessage}
          </code>{' '}
          to delete the team.
        </p>
        <Input
          value={confirmationMessage}
          onChange={(e) => setConfirmationMessage(e.target.value)}
          placeholder="Confirm here"
        />
        <DialogFooter>
          <Button
            disabled={deleting}
            onClick={deleteTeam}
            variant="destructive"
          >
            Delete Team
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
