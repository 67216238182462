import { AppLayout } from '@/components/app-layout';
import ApiKeys from '@/components/settings/api-keys';
import { TimeZonePicker } from '@/components/settings/timezone-picker';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle
} from '@/components/ui/card';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { apiClient, logout } from '@/lib/api';
import { getProfileSettingsOptions } from '@/routes/_application/settings/profile';
import { zodResolver } from '@hookform/resolvers/zod';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useNavigate, useRouteContext } from '@tanstack/react-router';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { z } from 'zod';

const userSchema = z.object({
  firstName: z.string().min(1, {}).max(512),
  lastName: z.string().min(1, {}).max(512),
  email: z.string().email().max(512)
});

export default function ProfileSettings() {
  const { data: user } = useSuspenseQuery(getProfileSettingsOptions());
  const { team } = useRouteContext({ from: '/_application' });
  const [password, setPassword] = useState<string>('');
  const navigate = useNavigate();
  const [passwordError, setPasswordError] = useState<string>();
  useEffect(() => {
    userForm.setValue('firstName', user.firstName ?? '');
    userForm.setValue('lastName', user.lastName ?? '');
    userForm.setValue('email', user.email ?? '');
  }, []);

  const userForm = useForm<z.infer<typeof userSchema>>({
    resolver: zodResolver(userSchema),
    defaultValues: {
      firstName: user.firstName ?? '',
      lastName: user.lastName ?? '',
      email: user.email ?? ''
    }
  });

  async function onUserSubmit(values: z.infer<typeof userSchema>) {
    const response = await apiClient.PATCH('/users', {
      body: { firstName: values.firstName, lastName: values.lastName }
    });
    if (response.error != null) {
      toast.error(response.error.message);
      return;
    }
    toast.success('Profile updated');
  }

  async function resetMFA() {
    if (password == null) {
      setPasswordError('Password required');
      return;
    }
    const response = await apiClient.POST('/auth/reset/mfa', {
      body: { password }
    });

    if (response.error != null) {
      setPasswordError('Incorrect password');
      return;
    }
    toast.success('Password correct, redirecting...');
    logout(false);
    await navigate({
      to: '/register/$token/mfa',
      params: { token: response.data.token }
    });
  }

  const timezonePickerSublabel = useMemo(() => {
    if (user.parentTeamId == team.id && team.serviceProvider) {
      return 'This will be your default across all teams';
    } else if (user.parentTeamId != team.id) {
      return `Default timezone for ${team.name}. To set a global default, switch to your parent team.`;
    }
    return;
  }, [user.parentTeamId, team.id, team.serviceProvider]);

  return (
    <AppLayout>
      <div className="flex flex-col gap-4">
        <Card>
          <CardHeader>
            <CardTitle>Account Settings</CardTitle>
          </CardHeader>
          <CardContent>
            <Form {...userForm}>
              <form
                onSubmit={userForm.handleSubmit(onUserSubmit)}
                className="col-span-5 space-y-8"
              >
                <div className="flex flex-col lg:flex-row gap-4">
                  <FormField
                    control={userForm.control}
                    name="firstName"
                    render={({ field }) => (
                      <FormItem className="flex-1">
                        <FormLabel>First Name</FormLabel>
                        <FormControl>
                          <Input placeholder="John" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={userForm.control}
                    name="lastName"
                    render={({ field }) => (
                      <FormItem className="flex-1">
                        <FormLabel>Last Name</FormLabel>
                        <FormControl>
                          <Input placeholder="Smith" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="flex flex-col gap-2 items-start">
                  <Label>Timezone</Label>
                  <TimeZonePicker />
                  {timezonePickerSublabel ? (
                    <p className="text-xs text-muted-foreground">
                      {timezonePickerSublabel}
                    </p>
                  ) : null}
                </div>
                <FormField
                  control={userForm.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Email</FormLabel>
                      <FormControl>
                        <Input
                          type="email"
                          disabled
                          placeholder="jake@wirespeed.co"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className="flex gap-4 justify-end">
                  <Button
                    disabled={
                      !userForm.formState.isValid ||
                      userForm.formState.isSubmitting
                    }
                    variant="outline"
                    type="submit"
                  >
                    Update
                  </Button>
                </div>
              </form>
            </Form>
          </CardContent>
          <CardFooter className="border-t">
            <div className="mx-auto mt-4 gap-4 flex w-full justify-end">
              <Dialog>
                <DialogTrigger asChild>
                  <Button variant="outlineDestructive">Reset MFA</Button>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>Reset MFA</DialogTitle>
                    <DialogDescription>
                      Confirming your password below will remove your current
                      MFA tokens and allow you to register a new one. Failure to
                      complete that registration will result in you being locked
                      out of your account.
                    </DialogDescription>
                  </DialogHeader>
                  <div className="">
                    <form
                      className="w-full flex flex-col gap-2 items-start"
                      onSubmit={(e) => e.preventDefault()}
                    >
                      <Label>Confirm Password</Label>
                      <Input
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                      />
                      {passwordError && (
                        <p className="text-red-500 text-sm">{passwordError}</p>
                      )}
                      <Button onClick={resetMFA} className="self-end mt-4">
                        Reset MFA
                      </Button>
                    </form>
                  </div>
                </DialogContent>
              </Dialog>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  logout();
                }}
                variant="destructive"
              >
                Log out
              </Button>
            </div>
          </CardFooter>
        </Card>
        <ApiKeys />
      </div>
    </AppLayout>
  );
}
