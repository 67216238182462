import { EventsTable } from '@/components/events/events-table';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_application/events/')({
  component: IntegrationSearch
});

function IntegrationSearch() {
  return <EventsTable inlineCard />;
}
