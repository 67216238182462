import { containsCidr } from 'cidr-tools';
import isCidr from 'is-cidr';
import { isIP } from 'is-ip';
import moment from 'moment';
import { oxfordComma } from '../..';

export enum QUERY_OPERATOR {
  EQUALS = '=',
  NOT_EQUALS = '!=',
  GREATER_THAN_EQUALS = '>=',
  LESS_THAN_EQUALS = '<=',
  GREATER_THAN = '>',
  LESS_THAN = '<',
  CONTAINS = '~',
  NOT_CONTAINS = '!~',
  NO_ARRAY_VALUES_EQUAL = '@!=',
  NO_ARRAY_VALUES_CONTAIN = '@!~',
  SOME_ARRAY_VALUES_EQUAL = '@~=',
  SOME_ARRAY_VALUES_CONTAIN = '@~~',
  ALL_ARRAY_VALUES_CONTAIN = '@~',
  ALL_ARRAY_VALUES_EQUAL = '@='
}

export const OPERATORS = [
  { operator: QUERY_OPERATOR.EQUALS, name: 'equals' },
  { operator: QUERY_OPERATOR.NOT_EQUALS, name: 'not equals' },
  {
    operator: QUERY_OPERATOR.GREATER_THAN_EQUALS,
    name: 'greater than or equals'
  },
  { operator: QUERY_OPERATOR.LESS_THAN_EQUALS, name: 'less than or equals' },
  { operator: QUERY_OPERATOR.GREATER_THAN, name: 'greater than' },
  { operator: QUERY_OPERATOR.LESS_THAN, name: 'less than' },
  { operator: QUERY_OPERATOR.CONTAINS, name: 'contains' },
  { operator: QUERY_OPERATOR.NOT_CONTAINS, name: 'does not contain' },
  {
    operator: QUERY_OPERATOR.NO_ARRAY_VALUES_EQUAL,
    name: 'no array values equal'
  },
  {
    operator: QUERY_OPERATOR.NO_ARRAY_VALUES_CONTAIN,
    name: 'no array values contain'
  },
  {
    operator: QUERY_OPERATOR.SOME_ARRAY_VALUES_EQUAL,
    name: 'some array values equal'
  },
  {
    operator: QUERY_OPERATOR.SOME_ARRAY_VALUES_CONTAIN,
    name: 'some array values contain'
  },
  {
    operator: QUERY_OPERATOR.ALL_ARRAY_VALUES_EQUAL,
    name: 'all array values equal'
  },
  {
    operator: QUERY_OPERATOR.ALL_ARRAY_VALUES_CONTAIN,
    name: 'all array values contain'
  }
];

export enum LOGICAL_OPERATOR {
  AND = 'AND',
  OR = 'OR'
}

export const LOGICAL_OPERATORS = [LOGICAL_OPERATOR.AND, LOGICAL_OPERATOR.OR];

export type OPERATION_PART_TYPE =
  | 'PROPERTY'
  | 'OPERATOR'
  | 'VALUE'
  | 'LOGICAL_OPERATOR';

/**
 *
 * @param template A string like "Hello world `teamName`" that will replce all backticks with nested object properties from `data`
 * @param data
 * @returns
 */
export function getDisplayFromTemplateString(template: string, data: any) {
  let out = '';
  const parts = template.split(/(`[^`]*`)/);
  for (const part of parts) {
    if (part.startsWith('`') && part.endsWith('`')) {
      out += getDisplayNestedValue(data, part.slice(1, -1)) as string;
    } else {
      out += part;
    }
  }
  return out;
}

/**
 * Basically `getNestedValue` except if it hits an array, it'll return the first array value
 * Also allows fallback operators like `ip.ipv4 || ip.ipv6` and will take whatever the first non-null value is
 * @param obj
 * @param path
 * @param raw
 * @returns
 */
export function getDisplayNestedValue(
  obj: any,
  path: string | undefined
): string | any[] {
  let paths = [path];
  if (path?.includes('||')) {
    paths = path.split('||').map((v) => v.trim());
  }

  for (const path of paths) {
    const match = getDisplayNestedValueForSinglePath(obj, path);
    if (match != null && match != 'unknown' && match != 'undefined') {
      return match;
    }
  }
  return 'unknown';
}
function getDisplayNestedValueForSinglePath(
  obj: any,
  path: string | undefined,
  raw: boolean = false
): any {
  let value = obj;
  if (path != null) {
    value = getNestedValue(obj, path);
  }

  // Handle null/undefined
  if (value == null) {
    return raw ? value : 'unknown';
  }

  // Handle strings
  if (typeof value === 'string') {
    return value;
  }

  // Handle arrays
  if (Array.isArray(value)) {
    if (value.length === 0) {
      return raw ? value : 'unknown';
    }

    if (raw) {
      return value;
    }
    if (isObjectLike(value[0])) {
      return oxfordComma(
        value.flatMap((item: any) =>
          getDisplayNestedValueForSinglePath(item, undefined, true)
        )
      );
    }

    return oxfordComma(value.filter((v) => v != null).map(String));
  }

  // Handle objects
  if (isObjectLike(value)) {
    const trueProps = Object.entries(value)
      .filter(([_, val]) => val === true)
      .map(([key]) => key);

    if (trueProps.length === 0) {
      return raw ? trueProps : 'unknown';
    }

    return raw ? trueProps : oxfordComma(trueProps);
  }

  // Handle other values
  return JSON.stringify(value);
}

export function getNestedValue(obj: any, path: string): any {
  const parts = path.split('.');
  let current = obj;

  for (let i = 0; i < parts.length; i++) {
    if (current === null || current === undefined) {
      return undefined;
    }

    const part = parts[i];
    if (Array.isArray(current)) {
      return current
        .map((item: any) => {
          const remainingPath = parts.slice(i).join('.');
          if (remainingPath) {
            return getNestedValue(item, remainingPath);
          }
          return item;
        })
        .filter((v) => v != null);
    }

    if (current instanceof Map) {
      current = current.get(part);
    } else {
      current = current[part];
    }
  }

  return current;
}

export type Operation = {
  field: string;
  operator: string;
  value: string;
  hasQuotes?: boolean;
};

export type QueryNode = {
  type: 'operation' | 'group';
  operation?: Operation;
  logicalOperator?: string;
  children?: QueryNode[];
  hasParens?: boolean;
};
export function validateQuery(query: string): boolean {
  try {
    if (query == 'true' || query == 'false') {
      return true;
    }
    const tokens = query.match(getQueryTokenRegex()) ?? [];

    // Empty query is invalid
    if (tokens.length === 0) {
      return false;
    }

    if (getQueryError(query) != null) {
      return false;
    }

    const position = 0;
    let parenCount = 0;
    let expectingOperation = true; // Track if we expect an operation next

    // Validate parentheses matching and operator placement
    for (const token of tokens) {
      if (token === '(') {
        parenCount++;
      } else if (token === ')') {
        parenCount--;
        if (parenCount < 0) return false;
      } else if (LOGICAL_OPERATORS.includes(token.toUpperCase() as any)) {
        if (expectingOperation) return false; // Can't have consecutive logical operators
        expectingOperation = true;
      } else {
        // Must be an operation (field operator value)
        expectingOperation = false;
      }
    }

    // Query can't end with a logical operator
    if (expectingOperation) return false;

    if (parenCount !== 0) return false;

    // Try parsing the query - if it succeeds, the query is valid
    tokenizeQuery(query);
    return true;
  } catch (e) {
    return false;
  }
}

export function stringifyQuery(node: QueryNode): string {
  if (node.type === 'operation' && node.operation != null) {
    const { field, operator, value, hasQuotes } = node.operation;
    // Handle values that need quotes
    const formattedValue = hasQuotes ? `"${value}"` : value;
    return `${field}${operator}${formattedValue}`;
  }

  if (node.type === 'group' && node.children != null) {
    const childStrings = node.children.map(stringifyQuery);

    // Don't add parentheses for single child without logical operator
    if (childStrings.length === 1 && !node.logicalOperator && !node.hasParens) {
      return childStrings[0];
    }

    // Join children with logical operator if present
    const joined = node.logicalOperator
      ? childStrings.join(` ${node.logicalOperator} `)
      : childStrings.join(' ');

    return node.hasParens ? `(${joined})` : joined;
  }

  return '';
}

export function hasSomeArrayOperator(query: string | QueryNode): boolean {
  let tokens: QueryNode;
  if (typeof query === 'string') {
    tokens = tokenizeQuery(query);
  } else {
    tokens = query;
  }
  return (
    ((tokens.type == 'operation' &&
      tokens.operation?.operator.includes('@~')) ||
      tokens.children?.some((token) => hasSomeArrayOperator(token))) ??
    false
  );
}

const OPERATOR_PATTERN = OPERATORS.map((op) =>
  op.operator.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
).join('|');
const QUERY_TOKEN_REGEX = `(\\w+(?:\\.\\w+)*(${OPERATOR_PATTERN})(?:[\\w-:.\\\\]+|"[^"]*"|'[^']*'|\\/[^\\/]+\\/[gi]*)|\\(|\\)|AND|OR|NOT)`;

function getQueryTokenRegex() {
  return new RegExp(QUERY_TOKEN_REGEX, 'gi');
}

export function getQueryError(query: string): string | undefined {
  const regex = getQueryTokenRegex();
  // Verify entire query string was matched
  let reconstructed = '';
  let lastIndex = 0;
  let match;
  regex.lastIndex = 0;

  while ((match = regex.exec(query)) !== null) {
    if (match.index > lastIndex) {
      const unmatched = query.slice(lastIndex, match.index).trim();
      if (unmatched) {
        return `Unrecognized token "${unmatched}"`;
      }
    }
    reconstructed += match[0];
    lastIndex = regex.lastIndex;
  }

  if (lastIndex < query.length) {
    const remaining = query.slice(lastIndex).trim();
    if (remaining) {
      return `Unrecognized token "${remaining}"`;
    }
  }
  return;
}

export function tokenizeQuery(query: string): QueryNode {
  const tokens = query.match(getQueryTokenRegex()) ?? [];
  let position = 0;
  function parseGroup(hasParens = false): QueryNode {
    const node: QueryNode = {
      type: 'group',
      children: [],
      hasParens
    };

    while (position < tokens.length) {
      const token = tokens[position];
      if (token === '(') {
        position++;
        node.children?.push(parseGroup(true));
      } else if (token === ')') {
        position++;
        return node;
      } else if (LOGICAL_OPERATORS.includes(token.toUpperCase() as any)) {
        position++;
        node.logicalOperator = token;
      } else {
        const operation = parseOperation(token);
        if (operation != null) {
          node.children?.push({
            type: 'operation',
            operation
          });
        }
        position++;
      }
    }
    return node;
  }

  function parseOperation(token: string): Operation | null {
    const operatorPattern = OPERATORS.map((op) =>
      op.operator.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    ).join('|');
    const match = token.match(
      new RegExp(`(\\w+(?:\\.\\w+)*)(${operatorPattern})(.+)`)
    );
    if (match == null) return null;

    const [, field, operator, value] = match;
    const trimmedValue = value.trim();
    const hasQuotes = /^["']/.test(trimmedValue) && /["']$/.test(trimmedValue);
    return {
      field,
      operator,
      value: hasQuotes ? trimmedValue.slice(1, -1) : trimmedValue,
      hasQuotes
    };
  }

  return parseGroup();
}

export function evaluateQuery(
  query: string,
  data: Record<string, any>
): boolean {
  if (query == null || query.length == 0) return false;
  if (query == 'true' || query == 'false') {
    return query == 'true';
  }
  const queryTree = tokenizeQuery(query);
  function evaluateNode(node: QueryNode): boolean {
    if (node.type === 'operation' && node.operation != null) {
      return evaluateCondition(node.operation);
    }
    if (node.children == null || node.children.length === 0) {
      return false;
    }

    const results = node.children.map(evaluateNode);

    if (!node.logicalOperator || results.length === 1) {
      return results[0];
    }

    if ((node.logicalOperator.toUpperCase() as any) === LOGICAL_OPERATOR.AND) {
      return results.every(Boolean);
    }
    if ((node.logicalOperator.toUpperCase() as any) === LOGICAL_OPERATOR.OR) {
      return results.some(Boolean);
    }

    return false;
  }

  function evaluateCondition(operation: Operation): boolean {
    const { field, operator, value } = operation;
    let fieldValue: any = getNestedValue(data, field);
    if (
      fieldValue === undefined ||
      (typeof fieldValue == 'string' && fieldValue.length == 0) ||
      (Array.isArray(fieldValue) && fieldValue.length == 0) ||
      (isObjectLike(fieldValue) && Object.keys(fieldValue).length == 0)
    ) {
      if (value == 'null') {
        return true;
      }
      return false;
    }
    let parsedValue: string | number | boolean = value;
    if (
      !isNaN(parseFloat(value)) &&
      !isDateString(value) &&
      !isIP(value) &&
      /^[\d.]+$/.test(value)
    ) {
      parsedValue = parseFloat(value);
    }

    if (typeof fieldValue === 'number' && typeof parsedValue === 'number') {
      fieldValue = parseFloat(fieldValue as any);
    } else if (isDateString(fieldValue) && isDateString(parsedValue)) {
      fieldValue = moment(fieldValue).milliseconds();
      parsedValue = moment(parsedValue).milliseconds();
    }
    if (fieldValue === 'true' || fieldValue === 'false') {
      fieldValue = fieldValue == 'true';
    }
    if (parsedValue == 'true' || parsedValue == 'false') {
      parsedValue = parsedValue == 'true';
    }

    return compareValues(fieldValue, operator, parsedValue);
  }

  function isCIDRAddress(value: string): boolean {
    const val = isCidr(value);
    return val == 4 || val == 6;
  }

  function compareValues(
    fieldValue: any,
    operator: string,
    parsedValue: any
  ): boolean {
    if (parsedValue == 'null') {
      parsedValue = null;
    }
    if (operator.startsWith('@') && !Array.isArray(fieldValue)) {
      return false;
    }
    if (operator == '~' && isCIDRAddress(parsedValue)) {
      return containsCidr(parsedValue, fieldValue);
    }

    if (fieldValue != null && fieldValue.replace != null) {
      fieldValue = fieldValue.replace(/\\?"/g, '');
    }
    if (parsedValue != null && parsedValue.replace != null) {
      parsedValue = parsedValue.replace(/\\?"/g, '');
    }

    const originalFieldValue = fieldValue;
    const originalParsedValue = parsedValue;
    if (typeof fieldValue == 'string') {
      fieldValue = fieldValue.toLowerCase();
    }
    if (typeof parsedValue == 'string') {
      parsedValue = parsedValue.toLowerCase();
    }

    switch (operator) {
      case '=':
        if (Array.isArray(fieldValue)) {
          return fieldValue.every((item) =>
            compareValues(item, '=', parsedValue)
          );
        }
        if (typeof fieldValue == 'object' && fieldValue != null) {
          return Object.values(fieldValue).every((v) =>
            compareValues(v, '=', parsedValue)
          );
        }
        if (/\/.*\/[gi]*/.test(parsedValue)) {
          const { regex, flags } =
            /\/(?<regex>.*)\/(?<flags>.*)/.exec(originalParsedValue)?.groups ??
            {};
          return new RegExp(regex, flags).test(originalFieldValue);
        }
        return fieldValue == parsedValue;
      case '!=':
        if (Array.isArray(fieldValue)) {
          return fieldValue.every((item) =>
            compareValues(item, '!=', parsedValue)
          );
        }
        if (typeof fieldValue == 'object' && fieldValue != null) {
          return Object.values(fieldValue).every((v) =>
            compareValues(v, '!=', parsedValue)
          );
        }
        if (/\/.*\/[gi]+/.test(parsedValue)) {
          return !new RegExp(parsedValue).test(fieldValue);
        }
        return fieldValue != parsedValue;
      case '~':
        if (Array.isArray(fieldValue)) {
          return fieldValue.every((item) =>
            compareValues(item, '~', parsedValue)
          );
        }
        if (typeof fieldValue == 'object' && fieldValue != null) {
          return Object.values(fieldValue).some((v) =>
            compareValues(v, '~', parsedValue)
          );
        }
        return fieldValue.toString().includes(parsedValue.toString());
      case '!~':
        if (Array.isArray(fieldValue)) {
          return fieldValue.every((item) =>
            compareValues(item, '!~', parsedValue)
          );
        }
        if (typeof fieldValue == 'object' && fieldValue != null) {
          return Object.values(fieldValue).every((v) =>
            compareValues(v, '!~', parsedValue)
          );
        }
        return !compareValues(fieldValue, '~', parsedValue);
      case '>':
        if (Array.isArray(fieldValue)) {
          return fieldValue.every((item) =>
            compareValues(item, '>', parsedValue)
          );
        }
        if (typeof fieldValue == 'object' && fieldValue != null) {
          return Object.values(fieldValue).every((v) =>
            compareValues(v, '>', parsedValue)
          );
        }
        return fieldValue > parsedValue;
      case '<':
        if (Array.isArray(fieldValue)) {
          return fieldValue.every((item) =>
            compareValues(item, '<', parsedValue)
          );
        }
        if (typeof fieldValue == 'object' && fieldValue != null) {
          return Object.values(fieldValue).every((v) =>
            compareValues(v, '<', parsedValue)
          );
        }
        return fieldValue < parsedValue;
      case '>=':
        if (Array.isArray(fieldValue)) {
          return fieldValue.some((item) =>
            compareValues(item, '>=', parsedValue)
          );
        }
        if (typeof fieldValue == 'object' && fieldValue != null) {
          return Object.values(fieldValue).some((v) =>
            compareValues(v, '>=', parsedValue)
          );
        }
        return fieldValue >= parsedValue;
      case '<=':
        if (Array.isArray(fieldValue)) {
          return fieldValue.some((item) =>
            compareValues(item, '<=', parsedValue)
          );
        }
        if (typeof fieldValue == 'object' && fieldValue != null) {
          return Object.values(fieldValue).some((v) =>
            compareValues(v, '<=', parsedValue)
          );
        }
        return fieldValue <= parsedValue;
      case '@=':
        return fieldValue.every((item: any) =>
          compareValues(item, '=', parsedValue)
        );
      case '@~':
        return fieldValue.some((item: any) =>
          compareValues(item, '~', parsedValue)
        );
      case '@!=':
        return fieldValue.every((item: any) =>
          compareValues(item, '!=', parsedValue)
        );
      case '@~=':
        return fieldValue.some((item: any) =>
          compareValues(item, '=', parsedValue)
        );
      case '@~~':
        return fieldValue.some((item: any) =>
          compareValues(item, '~', parsedValue)
        );
      case '@!~':
        return fieldValue.some((item: any) =>
          compareValues(item, '!~', parsedValue)
        );
      default:
        return false;
    }
  }

  return evaluateNode(queryTree);
}

export function getAllPaths(obj: any, parentPath = ''): string[] {
  let paths: string[] = [];

  for (const key in obj) {
    const currentPath = parentPath ? `${parentPath}.${key}` : key;
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      if (Array.isArray(obj[key])) {
        if (obj[key].length == 0) {
          continue;
        }
        // Handle arrays by checking first element's structure
        if (obj[key].length > 0 && isObjectLike(obj[key][0])) {
          const arrayPaths = getAllPaths(obj[key][0], currentPath);
          paths = [...paths, currentPath, ...arrayPaths];
        } else {
          paths.push(currentPath);
        }
      } else {
        // Handle nested objects
        if (isObjectLike(obj[key])) {
          paths = [...paths, ...getAllPaths(obj[key], currentPath)];
        } else {
          paths.push(currentPath);
        }
      }
    } else {
      paths.push(currentPath);
    }
  }

  return paths;
}

function isObjectLike(value: any) {
  return Object.prototype.toString.call(value) === '[object Object]';
}

export function isDateString(value: any) {
  return (
    typeof value == 'string' &&
    // ISO 8601 Regex
    (/(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/.test(
      value
    ) ||
      // Postgres Timestamp Regex
      /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}\.\d{3}[+-]\d{2}$/.test(value))
  );
}

export function createDetectionQuery(
  property: string,
  operator: QUERY_OPERATOR,
  value: string
) {
  return `${property}${operator}${value.includes(' ') ? `"${value}"` : value}`;
}

export const DETECTION_QUERY_FIELDS = {
  FILE_SHA256: 'files.sha256',
  FILE_SHA1: 'files.sha1',
  FILE_NAME: 'files.name',
  FILE_PATH: 'files.path',
  USER_EMAIL: 'directory.email',
  ENDPOINT_ID: 'endpoint.id',
  ENDPOINT_HOSTNAME: 'endpoint.hostname',
  USER_USERNAME: 'directory.username',
  LOCATION_CITY: 'locations.city',
  LOCATION_STATE: 'locations.state',
  LOCATION_ID: 'locations.id',
  PROCESS_COMMAND: 'processes.command',
  USER_AGENT: 'userAgents.userAgent',
  PROCESS_SHA256: 'processes.sha256',
  PROCESS_SHA1: 'processes.sha1',
  IP_IPV4: 'ips.ipv4',
  IP_IPV6: 'ips.ipv6',
  DOMAIN_NAME: 'domains.name'
};
