import { HoverCardItem } from '@/components/hover-cards/util';
import { Button } from '@/components/ui/button';
import { apiClient } from '@/lib/api';
import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';
import { Link } from '@tanstack/react-router';
import { toast } from 'sonner';

export default function EndpointHoverCard(props: { id: string }) {
  const { data } = useSuspenseQuery(
    queryOptions({
      queryKey: ['endpoint', props.id],
      queryFn: async () => {
        const response = await apiClient.GET('/endpoint/{id}', {
          params: { path: { id: props.id } }
        });
        if (response.error != null) {
          throw new Error('Error getting endpoint information');
        }
        return response.data;
      }
    })
  );

  async function contain() {
    const response = await apiClient.POST('/asset/endpoint/{id}/contain', {
      params: { path: { id: props.id } },
      body: {}
    });
    if (response.error != null) {
      toast.error(response.error.message);
      return;
    }
    toast.success('Endpoint contained');
  }

  async function uncontain() {
    const response = await apiClient.POST('/asset/endpoint/{id}/uncontain', {
      params: { path: { id: props.id } },
      body: {}
    });
    if (response.error != null) {
      toast.error(response.error.message);
      return;
    }
    toast.success('Endpoint uncontained');
  }

  return (
    <div className="flex flex-col gap-2">
      <HoverCardItem title="Hostname">{data.name ?? '-'}</HoverCardItem>
      <HoverCardItem title="IP Address">
        {data.publicIPs?.map((v, i) => (
          <span key={v.id}>
            {i > 0 && ', '}
            <Link key={v.id} to="/assets/ips/$ipId" params={{ ipId: v.id }}>
              {v.ipv4 ?? v.ipv6}
            </Link>
          </span>
        )) ?? '-'}
      </HoverCardItem>
      <HoverCardItem title="Operating System">
        {data.operatingSystem ?? '-'}
      </HoverCardItem>
      <HoverCardItem date title="Live">
        {data.live}
      </HoverCardItem>
      <HoverCardItem boolean title="Contained">
        {data.contained}
      </HoverCardItem>
      <div className="flex gap-2">
        <Button size="sm" variant="outlineDestructive" onClick={contain}>
          Contain
        </Button>
        <Button size="sm" variant="outline" onClick={uncontain}>
          Uncontain
        </Button>
      </div>
    </div>
  );
}
