import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from '@/components/ui/card';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger
} from '@/components/ui/collapsible';
import { apiClient } from '@/lib/api';
import { components } from '@/lib/api.types';
import { cn } from '@/lib/utils';
import hljs from 'highlight.js/lib/core';
import jsonLang from 'highlight.js/lib/languages/json';
hljs.registerLanguage('json', jsonLang);

import { Badge } from '@/components/ui/badge';
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent
} from '@/components/ui/chart';
import { Checkbox } from '@/components/ui/checkbox';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu';
import { Input } from '@/components/ui/input';
import { LogoSVG } from '@/components/ui/logo';
import { Separator } from '@/components/ui/separator';
import { Skeleton } from '@/components/ui/skeleton';
import { getTimeZoneAbbreviation, useTimezone } from '@/lib/time';
import {
  keepPreviousData,
  queryOptions,
  useQuery,
  useSuspenseQuery
} from '@tanstack/react-query';
import copy from 'copy-to-clipboard';
import {
  ArrowLeft,
  ArrowRight,
  ChevronDownIcon,
  ChevronRightIcon,
  Loader2Icon
} from 'lucide-react';
import moment from 'moment';
import { useTheme } from 'next-themes';
import numeral from 'numeral';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Bar, BarChart, CartesianGrid } from 'recharts';
import { toast } from 'sonner';

export const OCSF_SEARCH_QUERY_KEY = 'INTEGRATION_SEARCH';

const chartConfig = {
  events: {
    label: 'Events',
    color: 'hsl(var(--chart-1))'
  }
} satisfies ChartConfig;

async function searchOCSF(dto: components['schemas']['OCSFSearchDto']) {
  const response = await apiClient.POST('/ocsf', {
    body: dto
  });
  if (response.error != null) {
    throw new Error(response.error.message);
  }
  return response.data;
}

const getSearchQueryOptions = (
  settings: components['schemas']['OCSFSearchDto'] = {}
) => {
  return queryOptions({
    queryKey: [OCSF_SEARCH_QUERY_KEY, settings],
    queryFn: () => searchOCSF(settings),
    refetchOnWindowFocus: false,
    retry: false,
    placeholderData: keepPreviousData
  });
};

const getOCSFSchemaOptions = () => {
  return queryOptions({
    queryKey: ['ocsf-schema'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const response = await apiClient.GET('/ocsf/schema');
      if (response.error != null) {
        throw new Error(response.error.message);
      }
      return response.data;
    }
  });
};

export function EventsTable(props: {
  defaultSearch?: string;
  description?: string;
  inlineCard?: boolean;
}) {
  const [searchInput, setSearchInput] = useState<string>(
    props.defaultSearch ?? ''
  );
  const theme = useTheme();
  const [searchSettings, setSearchSettings] = useState<
    components['schemas']['OCSFSearchDto']
  >({
    page: 1,
    size: 100
  });
  const {
    data: { schema }
  } = useSuspenseQuery(getOCSFSchemaOptions());
  const {
    data: logData,
    isLoading: isLogLoading,
    isFetched,
    isFetching,
    error,
    refetch
  } = useQuery(getSearchQueryOptions(searchSettings));

  const { data: chartData, isLoading: isChartLoading } = useQuery(
    queryOptions({
      queryKey: ['ocsf-time', searchSettings.search ?? ''],
      placeholderData: keepPreviousData,
      refetchOnWindowFocus: false,
      queryFn: async () => {
        const response = await apiClient.POST('/ocsf/time', {
          body: searchSettings
        });
        if (response.error != null) {
          throw new Error(response.error.message);
        }
        return response.data;
      }
    })
  );

  useEffect(() => {
    if (searchInput.length > 0) {
      void executeSearch();
    }
  }, []);

  const executeSearch = useCallback(
    async (input = searchInput) => {
      if (input == searchSettings.search) {
        return await refetch();
      }
      setSearchSettings((prev) => ({
        ...prev,
        search: input,
        page: 1 // Reset to first page on new search
      }));
    },
    [searchInput, searchSettings.search]
  );

  function updateFacet(field: string, value: string, checked: boolean) {
    let facetQuery = `${field} = "${value}"`;
    if (checked) {
      if (searchInput.length) {
        facetQuery = `${searchInput} AND ${facetQuery}`;
      }
    } else {
      facetQuery = searchInput.replace(/(AND )?${facetQuery}/, '');
    }
    setSearchInput(facetQuery);
    void executeSearch(facetQuery);
  }

  return (
    <Card
      className={cn({
        'rounded-none shadow-none border-none': props.inlineCard
      })}
    >
      <CardHeader className={cn({ 'bg-muted/50 mb-4': !props.inlineCard })}>
        <CardTitle>
          Events <Badge>Beta</Badge>
        </CardTitle>
        <CardDescription>
          {props.description ?? 'Search across all of your event sources'}
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-2">
        <div className="flex relative flex-row gap-2 justify-center">
          <Input
            value={searchInput}
            placeholder="Search emails, hashes, IPs, or start typing to select a field"
            onChange={(e) => setSearchInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !isFetching) {
                executeSearch();
              }
            }}
          />

          <Button disabled={isFetching} onClick={() => executeSearch()}>
            {isFetching ? (
              <span className="flex flex-row items-center gap-1">
                <Loader2Icon className="w-4 h-4 animate-spin" />
                Search
              </span>
            ) : (
              <>
                <LogoSVG className="fill-white h-4 w-4 mr-1" />
                Search
              </>
            )}
          </Button>
        </div>
        <div className="flex flex-row flex-wrap gap-2">
          <FacetGroup
            field="category_name"
            display="Category Name"
            search={searchSettings.search ?? ''}
            onSelect={updateFacet}
          />
          <FacetGroup
            field="class_name"
            display="Class Name"
            search={searchSettings.search ?? ''}
            onSelect={updateFacet}
          />
          <FacetGroup
            field="metadata.product.vendor_name"
            display="Vendor"
            search={searchSettings.search ?? ''}
            onSelect={updateFacet}
          />
          <FacetGroup
            field="activity_name"
            display="Activity"
            search={searchSettings.search ?? ''}
            onSelect={updateFacet}
          />
          <FacetGroup
            field="status"
            display="Status"
            search={searchSettings.search ?? ''}
            onSelect={updateFacet}
          />
        </div>
        {error != null && (
          <div className="text-red-500 text-xs">{error.message}</div>
        )}
        {props.inlineCard && (
          <>
            {' '}
            <>
              {isChartLoading && (
                <Skeleton className="h-24 w-full border rounded-md px-2" />
              )}
            </>
            <>
              {!isChartLoading && (
                <ChartContainer
                  config={chartConfig}
                  className="aspect-auto h-24 rounded-md pt-1 mt-2 border w-full"
                >
                  <BarChart
                    accessibilityLayer
                    data={(chartData?.data ?? []).map((item) => ({
                      ...item,
                      date: new Date(item.day)
                    }))}
                    height={96}
                    margin={{
                      left: 12,
                      right: 12
                    }}
                  >
                    <CartesianGrid vertical={false} />

                    <ChartTooltip
                      content={
                        <ChartTooltipContent
                          className="w-[150px]"
                          nameKey="events"
                          labelFormatter={(value, payload) => {
                            return moment(payload[0].payload.day).format(
                              'MM/DD/YYYY'
                            );
                          }}
                        />
                      }
                    />
                    <Bar dataKey="events" fill="hsl(var(--chart-1))" />
                  </BarChart>
                </ChartContainer>
              )}
            </>
          </>
        )}
        <Separator />
        <div className="flex flex-col col-span-5 gap-2">
          {isLogLoading && (
            <Skeleton className="h-96 w-full border rounded-md px-2" />
          )}
          {!isLogLoading && (
            <IntegrationSearchTable
              inlineCard={props.inlineCard}
              data={(logData?.data as any) ?? []}
            />
          )}
          {logData?.data.length == 0 && (
            <div className="text-xs text-muted-foreground">
              No results found
            </div>
          )}
          <div className="flex justify-end flex-row gap-2">
            <Button
              onClick={() =>
                setSearchSettings((prev) => ({
                  ...prev,
                  page: Math.max((prev.page ?? 0) - 1, 1)
                }))
              }
              disabled={searchSettings.page == 1 || isFetching}
              variant="outline"
              size="icon"
            >
              <ArrowLeft className="h-4 w-4" />
            </Button>
            <Button
              onClick={() =>
                setSearchSettings((prev) => ({
                  ...prev,
                  page: (prev.page ?? 0) + 1
                }))
              }
              disabled={
                (logData?.data.length ?? 0) < (searchSettings.size ?? 10) ||
                isFetching
              }
              variant="outline"
              size="icon"
            >
              <ArrowRight className="h-4 w-4" />
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

function IntegrationSearchTable(props: {
  data: any[];
  inlineCard?: boolean;
}) {
  return (
    <div className={cn('text-xs flex flex-col gap-2', {})}>
      <div
        className={cn(
          'whitespace-wrap font-mono flex flex-col break-all overflow-x-hidden overflow-y-scroll p-1',
          {
            'max-h-[400px] overflow-y-auto': !props.inlineCard
          }
        )}
      >
        {props.data.map((item) => (
          <LogEntry key={item.id} item={item} />
        ))}
      </div>
    </div>
  );
}

function LogEntry(props: { item: any }) {
  const { timezone } = useTimezone();
  const [open, setOpen] = useState<boolean>(false);
  const observables: { name: string; value: string; type: string }[] =
    props.item.observables ?? [];
  const previewText = useMemo(() => {
    const parts: Set<String> = new Set();
    for (let i = 0; i < observables.length; i++) {
      const observable = observables[i];
      if (observable.value != null) {
        parts.add(`${observable.type}: ${observable.value}`);
      }
    }
    return (
      Array.from(parts)
        // Show the shorter strings first
        .sort((a, b) => a.length - b.length)
        .join(' | ')
    );
  }, [observables]);

  const { isLoading, data: eventData } = useQuery(
    queryOptions({
      queryKey: ['ocsf-event', props.item.id, open],
      retry: 3,
      queryFn: async () => {
        if (open == false) {
          return null;
        }
        const response = await apiClient.GET('/ocsf/event/{id}', {
          params: {
            path: {
              id: props.item.id
            },
            query: {
              time: props.item.time,
              integration_id: props.item.integration_id,
              class_uid: props.item.class_uid
            }
          }
        });
        if (response.error != null) {
          toast.error(response.error.message);
          throw new Error(response.error.message);
        }
        return response.data.data;
      }
    })
  );

  const highlightedJson = useMemo(() => {
    if (eventData == null) return '';
    return hljs.highlight(JSON.stringify(eventData, null, 2), {
      language: 'json'
    }).value;
  }, [eventData]);

  const vendorName = useMemo(() => {
    return props.item.metadata?.product?.vendor_name ?? 'Unknown Vendor';
  }, [props.item]);
  const className = useMemo(() => {
    return props.item.class_name ?? 'Unknown Class';
  }, [props.item]);
  return (
    <Collapsible
      className="overflow-x-hidden flex-shrink-0"
      open={open}
      onOpenChange={setOpen}
    >
      <CollapsibleTrigger>
        <div className="flex flex-wrap lg:flex-nowrap py-1 group hover:bg-muted flex-row gap-1">
          <div className="hidden group-hover:block">
            <ChevronRightIcon
              className={cn('w-4 h-4 text-muted-foreground', {
                'rotate-90': open
              })}
            />
          </div>
          <span className="text-muted-foreground flex-shrink-0">
            {props.item?.time != null && props.item?.time != '0'
              ? moment
                  .utc(props.item.time)
                  .local()
                  .format('MM/DD/YYYY hh:mm:ss a') +
                ` ${getTimeZoneAbbreviation(timezone)}`
              : '00/00/0000 00:00:00 AM'}
          </span>

          <span className="text-orange-500 flex-shrink-0">[{vendorName}]</span>
          <span className="text-blue-500 flex-shrink-0">[{className}]</span>
          <div className="lg:line-clamp-1 text-ellipsis">{previewText}</div>
        </div>
      </CollapsibleTrigger>
      <CollapsibleContent className="overflow-hidden">
        {isLoading ? (
          <Skeleton className="h-96 w-full border rounded-md" />
        ) : (
          <pre className="text-xs bg-muted p-1 relative rounded-md overflow-x-auto">
            <div
              className="p-1"
              dangerouslySetInnerHTML={{ __html: highlightedJson }}
            ></div>
            <div
              onClick={() => {
                copy(JSON.stringify(eventData, null, 2));
                toast.success('Copied to clipboard');
              }}
              className="p-2 self-start hover:underline text-muted-foreground text-xs cursor-pointer"
            >
              Copy to Clipboard
            </div>
          </pre>
        )}
      </CollapsibleContent>
    </Collapsible>
  );
}

function FacetGroup(props: {
  field: string;
  search: string;
  display: string;
  onSelect: (field: string, value: string, checked: boolean) => void;
}) {
  const { data, isLoading } = useQuery(
    queryOptions({
      queryKey: ['facet', props.field, props.search],
      placeholderData: keepPreviousData,
      refetchOnWindowFocus: false,
      queryFn: async () => {
        const response = await apiClient.POST('/ocsf/facets', {
          body: {
            field: props.field,
            search: props.search
          }
        });
        if (response.error != null) {
          throw new Error(response.error.message);
        }
        return response.data;
      }
    })
  );

  if (isLoading)
    return (
      <Skeleton className="h-4 w-24 rounded-full px-1 border-primary border" />
    );

  return (
    <div className="flex flex-col flex-shrink-0 gap-2">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <div className="text-xs cursor-pointer flex flex-row items-center gap-0.5 rounded-xl px-1 border-primary border">
            <span>{props.display}</span>
            <ChevronDownIcon className="h-3 w-3" />
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="max-w-none">
          <div className="flex flex-col p-2 gap-2">
            {data?.data.length == 0 && (
              <div className="text-xs text-muted-foreground">
                No results found
              </div>
            )}
            {data?.data.map((facet) => (
              <Facet
                field={props.field}
                key={facet.value}
                search={props.search}
                value={facet.value}
                count={facet.count}
                onSelect={props.onSelect}
              />
            ))}
          </div>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}

function Facet(props: {
  field: string;
  value: string;
  count: number;
  search: string;
  onSelect: (field: string, value: string, checked: boolean) => void;
}) {
  const disabled = useMemo(() => {
    return (
      props.search.includes(`${props.field} = `) &&
      !props.search.includes(`${props.field} = "${props.value}"`)
    );
  }, [props.search]);

  const value = useMemo(() => {
    if (
      props.value == null ||
      (typeof props.value === 'string' && props.value.length == 0)
    ) {
      return 'N/A';
    }
    return props.value;
  }, [props.value]);
  return (
    <div
      className={cn('flex flex-1 text-xs flex-row gap-1', {
        'text-muted-foreground': disabled
      })}
    >
      <Checkbox
        id={`${props.field}-${props.value}`}
        disabled={disabled}
        checked={props.search.includes(`${props.field} = "${props.value}"`)}
        onCheckedChange={(checked) => {
          props.onSelect(props.field, props.value, Boolean(checked));
        }}
      />
      <label
        className="flex justify-between flex-1 flex-row gap-1"
        htmlFor={`${props.field}-${props.value}`}
      >
        <div className="flex-1">{value}</div>
        <div>{numeral(props.count).format('0,0a')}</div>
      </label>
    </div>
  );
}
