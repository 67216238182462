'use client';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu';
import {
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuBadge,
  SidebarMenuButton,
  SidebarMenuItem
} from '@/components/ui/sidebar';
import { components } from '@/lib/api.types';
import { cn } from '@/lib/utils';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import {
  onUnreadCountChange,
  showMessages,
  showSpace
} from '@intercom/messenger-js-sdk';
import { Link, useLocation, useRouteContext } from '@tanstack/react-router';
import {
  ArrowLeftRightIcon,
  BookOpenIcon,
  Briefcase,
  BuildingIcon,
  Compass,
  ComputerIcon,
  DatabaseZap,
  FileCogIcon,
  FileIcon,
  Glasses,
  Globe,
  HomeIcon,
  LifeBuoy,
  ListIcon,
  LockIcon,
  type LucideIcon,
  MapPin,
  MessageCircle,
  MessageCircleQuestion,
  NetworkIcon,
  PlusIcon,
  ScaleIcon,
  ServerIcon,
  ShieldCheck,
  SortAscIcon,
  Terminal,
  UserIcon,
  Users
} from 'lucide-react';
import numeral from 'numeral';
import { ReactNode, useEffect, useMemo, useState } from 'react';

interface NavItemBase {
  title: string;
  url?: string;
  onClick?: () => void;
  icon?: LucideIcon;
  badge?: ReactNode;
  items?: NavItemBase[];
}

interface NavItem extends NavItemBase {
  items?: NavItemBase[];
}

const AdminMenuItems: NavItemBase = {
  title: 'Admin',
  icon: LockIcon,
  items: [
    {
      title: 'Queues',
      url: '/admin/queues',
      icon: ListIcon
    },
    {
      title: 'Integrations',
      url: '/admin/integrations',
      icon: ArrowLeftRightIcon
    },
    {
      title: 'Categorization',
      url: '/admin/categorization',
      icon: SortAscIcon
    },
    {
      title: 'Add New Team',
      url: '/admin/team',
      icon: PlusIcon
    },
    {
      title: 'Extraction Queue',
      url: '/admin/extraction-queue',
      icon: ListIcon
    },
    {
      title: 'AQL',
      url: '/admin/aql',
      icon: Glasses
    },
    {
      title: "Tim's Shack",
      url: '/admin/tim',
      icon: UserIcon
    },
    {
      title: 'File Enrichment',
      url: '/admin/file-enrichment',
      icon: FileCogIcon
    }
  ]
};

const formatCount = (count: number | undefined) => {
  if (count == null || count == 0) {
    return '-';
  }
  return numeral(count).format('0,0a');
};

export function NavMainItems(props: {
  assetCounts: components['schemas']['AssetCounts'];
}) {
  const location = useLocation();
  const { team, user } = useRouteContext({ from: '/_application' });
  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(function initUnreadCount() {
    onUnreadCountChange((count: number) => {
      setUnreadCount(count);
    });
  }, []);

  const supportItems = useMemo(() => {
    const out: NavItem[] = [
      {
        onClick: () => showMessages(),
        icon: MessageCircleQuestion,
        title: 'Chat',
        badge: unreadCount > 0 ? unreadCount : undefined
      },
      {
        title: 'Documentation',
        onClick: () => showSpace('help'),
        icon: BookOpenIcon
      },
      {
        title: 'Onboarding',
        onClick: () => showSpace('tasks'),
        icon: LifeBuoy
      }
    ];

    if (user.superAdmin) {
      out.push(AdminMenuItems);
    }
    return out;
  }, [unreadCount, user.superAdmin]);

  const items: NavItem[] = useMemo(() => {
    let out: NavItem[] = [];
    if (team.serviceProvider) {
      const automationItems = [
        {
          title: 'Exclusions',
          url: '/settings/automation',
          icon: ExclamationTriangleIcon
        },
        {
          title: 'Integrations',
          url: '/settings/integrations',
          icon: ArrowLeftRightIcon
        }
      ];
      if (user.superAdmin) {
        automationItems.push({
          title: 'Verdicts',
          url: '/settings/verdicts',
          icon: ScaleIcon
        });
      }
      out = [
        {
          title: 'Platform',
          items: [
            { title: 'Home', url: '/dashboard', icon: HomeIcon },
            { title: 'Cases', url: '/cases', icon: Briefcase },
            { title: 'Clients', url: '/clients', icon: BuildingIcon },
            { title: 'Team', url: '/settings/team', icon: Users }
          ]
        },
        {
          title: 'Automation',
          items: automationItems
        },
        {
          title: 'Support',
          items: supportItems
        }
      ];
    } else {
      const automationItems = [
        {
          title: 'Chat Ops',
          url: '/settings/chat-ops',
          icon: MessageCircle
        },
        {
          title: 'Containment',
          url: '/settings/containment',
          icon: ShieldCheck
        },
        {
          title: 'Exclusions',
          url: '/settings/automation',
          icon: ExclamationTriangleIcon
        },
        {
          title: 'Integrations',
          url: '/settings/integrations',
          icon: ArrowLeftRightIcon
        }
      ];
      if (user.superAdmin) {
        automationItems.push({
          title: 'Verdicts',
          url: '/settings/verdicts',
          icon: ScaleIcon
        });
      }
      out = [
        {
          title: 'Platform',
          items: [
            { title: 'Home', url: '/dashboard', icon: HomeIcon },
            { title: 'Cases', url: '/cases', icon: Briefcase },
            { title: 'Events', url: '/events', icon: DatabaseZap },
            { title: 'Team', url: '/settings/team', icon: Users }
          ]
        },
        {
          title: 'Assets',
          icon: ServerIcon,
          items: [
            {
              title: 'Users',
              url: '/assets/users',
              icon: UserIcon,
              badge: formatCount(props.assetCounts?.users)
            },
            {
              title: 'Endpoints',
              url: '/assets/endpoints',
              icon: ComputerIcon,
              badge: formatCount(props.assetCounts?.endpoints)
            },
            {
              title: 'Files',
              url: '/assets/files',
              icon: FileIcon,
              badge: formatCount(props.assetCounts?.files)
            },
            {
              title: 'Locations',
              url: '/assets/locations',
              icon: MapPin,
              badge: formatCount(props.assetCounts?.locations)
            },
            {
              title: 'IP Addresses',
              url: '/assets/ips',
              icon: NetworkIcon,
              badge: formatCount(props.assetCounts?.ips)
            },
            {
              title: 'Domains',
              url: '/assets/domains',
              icon: Globe,
              badge: formatCount(props.assetCounts?.domains)
            },
            {
              title: 'Processes',
              url: '/assets/processes',
              icon: Terminal,
              badge: formatCount(props.assetCounts?.processes)
            },
            {
              title: 'User Agents',
              url: '/assets/user-agents',
              icon: Compass,
              badge: formatCount(props.assetCounts?.userAgents)
            }
          ]
        },
        {
          title: 'Automation',
          items: automationItems
        },
        {
          title: 'Support',
          items: supportItems
        }
      ];
    }
    return out;
  }, [team, user, props.assetCounts, supportItems]);
  const renderMenuButton = (item: NavItemBase) => {
    const content = (
      <>
        {item.icon != null && <item.icon />}
        <span>{item.title}</span>
      </>
    );

    if (item.onClick != null) {
      return (
        <div className="cursor-pointer" onClick={item.onClick}>
          {content}
        </div>
      );
    }

    if (item.items != null && item.items.length > 0) {
      return (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <SidebarMenuButton className="w-full">{content}</SidebarMenuButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="start" side="right">
            {item.items.map(renderDropdownItem)}
          </DropdownMenuContent>
        </DropdownMenu>
      );
    }

    return <Link to={item.url}>{content}</Link>;
  };

  const renderMenuItem = (item: NavItemBase) => (
    <SidebarMenuItem
      className={cn('rounded-md', {
        'bg-primary/10': item.url && location.pathname.startsWith(item.url)
      })}
      key={item.title}
    >
      <SidebarMenuButton
        className={cn('font-medium', {
          'font-bold': item.url && location.pathname.startsWith(item.url)
        })}
        tooltip={item.title}
        asChild
      >
        {renderMenuButton(item)}
      </SidebarMenuButton>
      {item.badge != null && (
        <SidebarMenuBadge className="ml-2">{item.badge}</SidebarMenuBadge>
      )}
    </SidebarMenuItem>
  );
  const renderDropdownItem = (item: NavItemBase) => {
    const content = (
      <div className="flex items-center gap-2 w-full">
        {item.icon != null && <item.icon className="size-4" />}
        <span>{item.title}</span>
      </div>
    );

    return (
      <DropdownMenuItem className="cursor-pointer" key={item.title} asChild>
        {item.url ? (
          <Link to={item.url}>{content}</Link>
        ) : (
          <div onClick={item.onClick}>{content}</div>
        )}
      </DropdownMenuItem>
    );
  };

  return (
    <SidebarMenu key="main-items">
      {items.map((item) => {
        if (item.items?.length == 0) {
          return (
            <SidebarGroup key={item.title}>
              <SidebarMenuItem>
                <SidebarMenuButton asChild tooltip={item.title}>
                  {renderMenuButton(item)}
                </SidebarMenuButton>
              </SidebarMenuItem>
            </SidebarGroup>
          );
        }

        return (
          <SidebarGroup key={item.title}>
            <SidebarGroupLabel>{item.title}</SidebarGroupLabel>
            <SidebarGroupContent>
              {item.items?.map(renderMenuItem)}
            </SidebarGroupContent>
          </SidebarGroup>
        );
      })}
    </SidebarMenu>
  );
}
