import { apiClient } from '@/lib/api';
import { useSuspenseQuery } from '@tanstack/react-query';

export function useCategorizationTemplate() {
  let { data: template, refetch } = useSuspenseQuery({
    queryKey: ['categorizers'],
    queryFn: async () => {
      const resonse = await apiClient.GET('/categorization/categorizers');
      return resonse.data!;
    }
  });

  return { template, refetch };
}

export function useCategorizationSamples() {
  const { data: samples, refetch } = useSuspenseQuery({
    queryKey: ['categorization', 'samples'],
    queryFn: async () => {
      const response = await apiClient.GET('/categorization/samples');
      return response.data ?? [];
    }
  });

  return { samples, refetch };
}
