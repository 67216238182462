import { useCategorizationSamples } from '@/components/admin-settings/categorization/categorization.util';
import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';
import { apiClient } from '@/lib/api';
import { components } from '@/lib/api.types';
import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'sonner';

type Sample = components['schemas']['Sample'];

interface SampleEditorProps {
  ruleId: string;
  onSampleSelect?: (sample: Sample) => void;
}

export function SampleEditor({ ruleId, onSampleSelect }: SampleEditorProps) {
  const { samples: allSamples, refetch: refetchSamples } =
    useCategorizationSamples();

  const filteredSamples = useMemo(
    () => allSamples.filter((sample) => sample.ruleId === ruleId),
    [allSamples, ruleId]
  );

  const [currentIndex, setCurrentIndex] = useState(0);
  const [hasChanges, setHasChanges] = useState(false);
  const [isNewSample, setIsNewSample] = useState(filteredSamples.length === 0);
  const [currentSampleDoc, setCurrentSampleDoc] = useState<string>(
    JSON.stringify(filteredSamples[0]?.doc ?? {}, null, 2)
  );

  const handleSampleUpdate = useCallback(
    async (updatedSamples: Sample[]): Promise<boolean> => {
      const response = await apiClient.PATCH('/categorization/samples', {
        body: updatedSamples
      });

      if (response.error) {
        toast.error(response.error.message);
        return false;
      }

      await refetchSamples();
      return true;
    },
    [refetchSamples]
  );

  const handleSave = useCallback(async () => {
    const selected = filteredSamples[currentIndex];
    const sample: Sample = {
      id: isNewSample ? crypto.randomUUID() : (selected?.id ?? ''),
      doc: JSON.parse(currentSampleDoc),
      ruleId
    };

    const updatedSamples = isNewSample
      ? [...allSamples, sample]
      : allSamples.map((s) => (s.id === sample.id ? sample : s));

    const success = await handleSampleUpdate(updatedSamples);
    if (success && isNewSample) {
      onSampleSelect?.(sample);
      // Not -1 because we want to show the new sample, but filtered samples isn't reflected the new one yet
      setCurrentIndex(filteredSamples.length);
    }
  }, [
    allSamples,
    filteredSamples,
    currentIndex,
    currentSampleDoc,
    isNewSample,
    handleSampleUpdate,
    onSampleSelect,
    refetchSamples,
    ruleId
  ]);

  const handleDelete = useCallback(async () => {
    if (isNewSample) {
      setCurrentIndex(0);
      return;
    }

    const updatedSamples = allSamples.filter(
      (s) => s.id !== filteredSamples[currentIndex].id
    );

    const success = await handleSampleUpdate(updatedSamples);
    if (success) {
      setCurrentIndex(0);
    }
  }, [
    allSamples,
    currentIndex,
    isNewSample,
    filteredSamples,
    onSampleSelect,
    refetchSamples
  ]);

  // Navigation handlers
  const handlePreviousSample = () => {
    const newIndex =
      currentIndex > 0 ? currentIndex - 1 : filteredSamples.length - 1;
    setCurrentIndex(newIndex);
  };

  const handleNextSample = () => {
    const newIndex =
      currentIndex < filteredSamples.length - 1 ? currentIndex + 1 : 0;
    setCurrentIndex(newIndex);
  };

  const handleNewSample = () => {
    setHasChanges(true);
    setIsNewSample(true);
    setCurrentSampleDoc(JSON.stringify({}, null, 2));
  };

  useEffect(() => {
    const doc =
      filteredSamples.length > 0 ? filteredSamples[currentIndex]?.doc : {};
    setHasChanges(false);
    setIsNewSample(filteredSamples.length === 0);
    setCurrentSampleDoc(JSON.stringify(doc, null, 2));
    onSampleSelect?.(filteredSamples[currentIndex]);
  }, [currentIndex, filteredSamples]);

  return (
    <div className="flex flex-col flex-1 gap-2">
      <div className="flex flex-row items-center justify-between">
        <Label htmlFor="rule-sample">Sample</Label>
      </div>
      <Textarea
        id="rule-sample"
        className="font-mono max-h-[512px] h-auto border rounded-md p-2 flex-1 overflow-y-auto"
        value={currentSampleDoc}
        onChange={(e) => {
          setCurrentSampleDoc(e.target.value);
          setHasChanges(true);
        }}
      />
      <div className="flex flex-row justify-between items-center gap-2">
        <div className="flex flex-row items-center gap-2">
          {filteredSamples.length > 0 && !isNewSample && (
            <>
              <Button
                variant="outline"
                size="icon"
                onClick={handlePreviousSample}
              >
                <ChevronLeftIcon className="h-4 w-4" />
              </Button>
              <span className="text-sm text-muted-foreground">
                {currentIndex + 1} / {filteredSamples.length}
              </span>
              <Button variant="outline" size="icon" onClick={handleNextSample}>
                <ChevronRightIcon className="h-4 w-4" />
              </Button>
            </>
          )}
        </div>
        <div className="flex flex-row gap-2">
          {hasChanges && (
            <Button size="sm" onClick={handleSave}>
              Save Sample
            </Button>
          )}
          <Button
            disabled={isNewSample}
            variant="outline"
            size="sm"
            onClick={handleNewSample}
          >
            New Sample
          </Button>
          {(!isNewSample || (isNewSample && filteredSamples.length > 0)) && (
            <Button variant="destructive" size="sm" onClick={handleDelete}>
              Delete Sample
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
