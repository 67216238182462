import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@/components/ui/tooltip';
import { localDateTime } from '@/lib/time';
import {
  CheckIcon,
  MagnifyingGlassIcon,
  XMarkIcon
} from '@heroicons/react/24/outline';
import { DialogTrigger } from '@radix-ui/react-dialog';
import { type ClassValue, clsx } from 'clsx';
import moment from 'moment';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function pluralize(
  count: number,
  singular: string,
  plural: string = `${singular}s`
) {
  return count == 1 ? singular : plural;
}

// Typically used to detect the first render of a component so we can skip it in useEffect
export const useIsMount = () => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};

export function useDebounce<T>(delay: number, value?: T) {
  const [debouncedValue, setDebouncedValue] = useState<T | undefined>(value);
  const [debouncing, setDebouncing] = useState(false);

  useEffect(() => {
    if (value == debouncedValue) return;
    setDebouncing(true);
    const handler = setTimeout(() => {
      if (value != debouncedValue) {
        setDebouncedValue(value ?? undefined);
      }
      setDebouncing(false);
    }, delay);

    return () => {
      setDebouncing(false);
      clearTimeout(handler);
    };
  }, [value, delay]);

  function override(val?: T) {
    setDebouncedValue(val);
  }

  return { debounced: debouncedValue, debouncing, override };
}

export function dashCaseToTitleCase(s: string) {
  return s
    .replace(/[-_]/g, ' ')
    .replace(
      /\w\S*/g,
      (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
    );
}

export function capitalizeFirst(str: string) {
  return str[0].toUpperCase() + str.slice(1).toLowerCase();
}

export function ellipsisTruncate(str: string, length: number) {
  if (str.length > length) {
    return str.slice(0, length) + '...';
  }
  return str;
}

export function getDetailsFromRaw(raw: object, timezone: string) {
  const out: { key: string; value: ReactNode }[] = [];
  for (const [key, value] of Object.entries(raw)) {
    if (value == null) continue;
    if (Array.isArray(value) && value.length == 0) continue;
    let parsedValue = value;
    if (
      typeof value == 'string' &&
      (value.startsWith('{') || value.startsWith('['))
    ) {
      parsedValue = safeJSONParse(value);
    }
    if (typeof parsedValue == 'object') {
      out.push({
        key: key.includes('_')
          ? snakeCaseToTitleCase(key)
          : camelCaseToTitleCase(key),
        value: (
          <Dialog>
            <DialogTrigger asChild>
              <Button size="icon" variant="outline">
                <MagnifyingGlassIcon className="h-4 w-4" />
              </Button>
            </DialogTrigger>
            <DialogContent className="max-h-[80vh] w-auto min-w-[256px] max-w-[80vw] flex flex-col overflow-hidden">
              <DialogHeader>
                <DialogTitle>{camelCaseToTitleCase(key)}</DialogTitle>
              </DialogHeader>
              <div className="overflow-auto">
                <UnicodeSafeJSONPre json={parsedValue} />
              </div>
            </DialogContent>
          </Dialog>
        )
      });
    } else {
      out.push(getCaseDetailValue(key, value, timezone));
    }
  }
  return out;
}

// Highlights any malicious characters so the user isn't tricked by them
const UnicodeSafeJSONPre = (props: { json: object }) => {
  const isNonStandardChar = (char: string) => {
    const code = char.charCodeAt(0);
    return code > 127 || (code < 32 && code !== 10 && code !== 13);
  };

  const renderCharacters = () => {
    return JSON.stringify(props.json, null, 2)
      .split('')
      .map((char, index) => {
        if (isNonStandardChar(char)) {
          return (
            <span key={index} className="bg-red-500">
              [U+{char.charCodeAt(0).toString(16).toUpperCase()}]
            </span>
          );
        }
        return <span key={index}>{char}</span>;
      });
  };
  return (
    <pre className="whitespace-pre-nowrap text-left">{renderCharacters()}</pre>
  );
};

const acronyms = [
  'API',
  'IP',
  'ID',
  'URL',
  'HTML',
  'JSON',
  'XML',
  'TOR',
  'VPN'
];

export function hyphenCaseToTitleCase(s: string) {
  return s
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

export function camelCaseToTitleCase(s: string) {
  if (s.includes(' ')) return s;
  return s
    .replace(/([A-Z]+)([A-Z][a-z])/g, '$1 $2') // handle consecutive caps followed by lowercase
    .replace(/([a-z])([A-Z])/g, '$1 $2') // handle camelCase
    .replace(/([A-Z]+)(?=[A-Z][a-z]|\d|\W|$)/g, '$1 ')
    .trim()
    .split(' ')
    .map((word) => {
      if (acronyms.includes(word)) {
        return word.toUpperCase();
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(' ');
}

export function snakeCaseToTitleCase(s: string) {
  if (s.includes(' ')) return s;
  return s
    .split('_')
    .map((word) => {
      if (acronyms.includes(word.toUpperCase())) {
        return word.toUpperCase();
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(' ');
}

function getCaseDetailValue(key: string, value: any, timezone: string) {
  key = key.includes('_')
    ? snakeCaseToTitleCase(key)
    : camelCaseToTitleCase(key);

  // Handle boolean values
  if (typeof value === 'boolean') {
    return {
      key,
      value: value ? (
        <CheckIcon className="text-green-500 h-6 w-6" />
      ) : (
        <XMarkIcon className="text-red-500 h-6 w-6" />
      )
    };
  }

  // Handle date/time values
  const isTimeField =
    key.toLowerCase().includes('time') && key.toLowerCase() !== 'timezone';
  const isDateField = key.toLowerCase().includes('date');

  // We get all sorts of fields, so at least make sure it's a real date and from the past decade
  if (
    (isTimeField || isDateField) &&
    moment(value).isValid() &&
    moment(value).isAfter(moment().subtract(10, 'years'))
  ) {
    const formattedDate = localDateTime(value, timezone);
    if (formattedDate != null) {
      value = formattedDate;
    }
  }

  // Add tooltip for long values or transformed values
  const needsTooltip = typeof value === 'string' && value.length > 256;

  return {
    key,
    value: needsTooltip ? (
      <TooltipProvider>
        <Tooltip delayDuration={100}>
          <TooltipTrigger className="lg:text-right max-w-[512px] text-left break-all">
            {value}
          </TooltipTrigger>
          <TooltipContent className="max-w-[400px] text-md w-auto whitespace-normal">
            {value}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    ) : (
      value
    )
  };
}

export function isUUID(val: string) {
  return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
    val
  );
}

export function safeJSONParse(str?: string) {
  let parsed;
  if (str == null) return;

  try {
    parsed = JSON.parse(str);
  } catch (error) {
    console.error(error);
  }

  return parsed;
}

export function compareObjects(obj1: any, obj2: any): boolean {
  const keys1 = Object.keys(obj1).sort();
  const keys2 = Object.keys(obj2).sort();

  if (keys1.length !== keys2.length) {
    return false;
  }

  return keys1.every((key) => {
    const val1 = obj1[key];
    const val2 = obj2[key];

    if (val1 === val2) {
      return true;
    }

    if (typeof val1 === 'object' && typeof val2 === 'object') {
      if (val1 === null || val2 === null) {
        return val1 === val2;
      }
      return compareObjects(val1, val2);
    }

    return false;
  });
}
