'use client';

import { cn } from '@/lib/utils';
import { getAllPaths } from '@wire/shared';
import { Check } from 'lucide-react';
import * as React from 'react';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandInputBlank,
  CommandItem,
  CommandList
} from './command';
import { Popover, PopoverContent, PopoverTrigger } from './popover';

interface TemplateStringInputProps
  extends Omit<React.ComponentProps<typeof CommandInput>, 'onChange'> {
  onChange?: (value: string) => void;
  inputObject?: object | null;
}

export function TemplateStringInput({
  className,
  onChange,
  inputObject,
  value,
  ...props
}: TemplateStringInputProps) {
  const [inputValue, setInputValue] = React.useState((value as string) || '');
  const [cursorPosition, setCursorPosition] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);

  // Get all possible paths from the detection object
  const paths = React.useMemo(() => {
    if (inputObject == null) return [];
    return getAllPaths(inputObject);
  }, [inputObject]);

  React.useEffect(() => {
    setInputValue(value ?? '');
  }, [value]);

  // Get the current backtick context
  const getBacktickContext = (value: string, position: number) => {
    const beforeCursor = value.slice(0, position);
    const afterCursor = value.slice(position);
    const lastBacktickBefore = beforeCursor.lastIndexOf('`');
    const nextBacktickAfter = afterCursor.indexOf('`');

    // Check if cursor is right after a closing backtick (including whitespace)
    if (position > 0) {
      // Count backticks before cursor to determine if this is a closing backtick
      const backticksBefore = beforeCursor.split('`').length - 1;
      if (backticksBefore % 2 === 0) return null; // Even number means we're after a closing backtick
    }

    if (lastBacktickBefore === -1) return null;
    if (nextBacktickAfter === -1) {
      return {
        start: lastBacktickBefore + 1,
        end: position,
        content: beforeCursor.slice(lastBacktickBefore + 1)
      };
    }
    return {
      start: lastBacktickBefore + 1,
      end: position + nextBacktickAfter,
      content: value.slice(lastBacktickBefore + 1, position + nextBacktickAfter)
    };
  };

  const handleInputChange = (value: string) => {
    setInputValue(value);
    onChange?.(value);

    const position = inputRef.current?.selectionStart ?? 0;
    setCursorPosition(position);
  };
  const handleSelect = (path: string) => {
    const context = getBacktickContext(inputValue, cursorPosition);
    if (context == null) return;

    // Get the current content between backticks and check if we're after a ||
    const currentContent = context.content;
    const cursorRelativePos = cursorPosition - context.start;

    // Split content by || and find which segment cursor is in
    const segments = currentContent.split('||').map((s) => s.trim());
    let currentPos = 0;
    let targetSegment = segments.length - 1; // Default to last segment

    for (let i = 0; i < segments.length; i++) {
      currentPos += segments[i].length;
      if (i < segments.length - 1) {
        currentPos += 2; // Add length of "||"
        if (cursorRelativePos <= currentPos) {
          targetSegment = i;
          break;
        }
      }
    }

    // If cursor is after last ||, append as new segment
    if (currentContent.trimEnd().endsWith('||')) {
      // Remove empty segments that may have been created by trailing ||
      while (
        segments.length > 0 &&
        segments[segments.length - 1].trim() === ''
      ) {
        segments.pop();
      }
      segments.push(path);
    } else {
      segments[targetSegment] = path;
    }

    const newContent = segments.join(' || ');

    // Reconstruct the full string
    const beforeBacktick = inputValue.slice(0, context.start - 1);
    const afterBacktick = inputValue.slice(context.end + 1);
    const newValue = beforeBacktick + '`' + newContent + '`' + afterBacktick;

    setInputValue(newValue);
    onChange?.(newValue);
    setOpen(false);

    // Focus back on input
    inputRef.current?.focus();
  };

  const filteredPaths = React.useMemo(() => {
    const context = getBacktickContext(inputValue, cursorPosition);
    if (context == null) return [];

    // Get current segment being typed
    const currentContent = context.content;
    const cursorRelativePos = cursorPosition - context.start;
    const segments = currentContent.split('||').map((s) => s.trim());
    let currentPos = 0;
    let currentSegment = '';

    for (let i = 0; i < segments.length; i++) {
      currentPos += segments[i].length;
      if (cursorRelativePos <= currentPos) {
        currentSegment = segments[i];
        break;
      }
      currentPos += 2; // Add length of "||"
    }

    // If cursor is after last ||, use empty string for filtering
    if (currentContent.trimEnd().endsWith('||')) {
      currentSegment = '';
    }

    setOpen(true);
    return paths.filter((path) =>
      path.toLowerCase().includes(currentSegment.toLowerCase())
    );
  }, [paths, inputValue, cursorPosition]);

  const inBackticks = getBacktickContext(inputValue, cursorPosition) !== null;
  return (
    <Popover open={open && inBackticks} onOpenChange={setOpen}>
      <Command shouldFilter={false}>
        <PopoverTrigger asChild>
          <CommandInputBlank
            ref={inputRef}
            value={inputValue}
            onValueChange={handleInputChange}
            className={cn(className)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !inBackticks) {
                e.preventDefault();
              }
            }}
            onFocus={() => setOpen(true)}
            onClick={(e) => {
              setCursorPosition(e.currentTarget.selectionStart ?? 0);
            }}
            onKeyUp={(e) => {
              setCursorPosition(e.currentTarget.selectionStart ?? 0);
            }}
            {...props}
          />
        </PopoverTrigger>
        <PopoverContent
          className="w-[var(--radix-popover-trigger-width)] p-0"
          onOpenAutoFocus={(e) => e.preventDefault()}
        >
          <CommandList>
            {inBackticks && <CommandEmpty>No fields found.</CommandEmpty>}
            <CommandGroup className="max-h-[200px] overflow-y-auto">
              {filteredPaths.map((path) => (
                <CommandItem
                  key={path}
                  value={path}
                  onSelect={() => handleSelect(path)}
                >
                  <Check
                    className={cn(
                      'mr-2 h-4 w-4',
                      inputValue.includes(path) ? 'opacity-100' : 'opacity-0'
                    )}
                  />
                  {path}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </PopoverContent>
      </Command>
    </Popover>
  );
}
