import DetectionHoverCard from '@/components/hover-cards/detection';
import DomainHoverCard from '@/components/hover-cards/domain';
import EndpointHoverCard from '@/components/hover-cards/endpoint';
import ExclusionHoverCard from '@/components/hover-cards/exclusion';
import FileHoverCard from '@/components/hover-cards/file';
import IntegrationHoverCard from '@/components/hover-cards/integration';
import IPAddressHoverCard from '@/components/hover-cards/ipAddress';
import LocationHoverCard from '@/components/hover-cards/location';
import ProcessHoverCard from '@/components/hover-cards/process';
import UserHoverCard from '@/components/hover-cards/user';
import UserAgentHoverCard from '@/components/hover-cards/userAgent';
import { HoverCardContent } from '@/components/ui/hover-card';
import { Skeleton } from '@/components/ui/skeleton';
import { localDateTime, useTimezone } from '@/lib/time';
import { ReactNode } from '@tanstack/react-router';
import { ENTITY_TYPE } from '@wire/shared';
import { CheckIcon, XIcon } from 'lucide-react';
import { Suspense, useMemo } from 'react';

export function HoverCardComponent(props: {
  entityType: ENTITY_TYPE;
  id: string;
}) {
  let component: React.ReactNode;
  switch (props.entityType) {
    case ENTITY_TYPE.USER:
      component = <UserHoverCard id={props.id} />;
      break;
    case ENTITY_TYPE.ENDPOINT:
      component = <EndpointHoverCard id={props.id} />;
      break;
    case ENTITY_TYPE.DOMAIN:
      component = <DomainHoverCard id={props.id} />;
      break;
    case ENTITY_TYPE.FILE:
      component = <FileHoverCard id={props.id} />;
      break;
    case ENTITY_TYPE.IP_ADDRESS:
      component = <IPAddressHoverCard id={props.id} />;
      break;
    case ENTITY_TYPE.LOCATION:
      component = <LocationHoverCard id={props.id} />;
      break;
    case ENTITY_TYPE.PROCESS:
      component = <ProcessHoverCard id={props.id} />;
      break;
    case ENTITY_TYPE.INTEGRATION:
      component = <IntegrationHoverCard id={props.id} />;
      break;
    case ENTITY_TYPE.DETECTION:
      component = <DetectionHoverCard id={props.id} />;
      break;
    case ENTITY_TYPE.EXCLUSION:
      component = <ExclusionHoverCard id={props.id} />;
      break;
    case ENTITY_TYPE.USER_AGENT:
      component = <UserAgentHoverCard id={props.id} />;
      break;
    default:
      return;
  }
  if (component != null) {
    return (
      <HoverCardContent className="max-w-screen lg:max-w-[500px] w-auto">
        <Suspense
          fallback={<Skeleton className="w-[300px] max-w-screen h-[200px]" />}
        >
          {component}
        </Suspense>
      </HoverCardContent>
    );
  }
}

export function HoverCardItem(
  props: React.PropsWithChildren<{
    title: ReactNode;
    date?: boolean;
    boolean?: boolean;
  }>
) {
  const { timezone } = useTimezone();
  const children = useMemo(() => {
    if (typeof props.children == 'string') {
      if (props.date) {
        return localDateTime(props.children, timezone);
      }
    }
    if (typeof props.children == 'boolean') {
      return props.children ? (
        <CheckIcon className="w-4 h-4 text-green-500" />
      ) : (
        <XIcon className="w-4 h-4 text-destructive" />
      );
    }
    return props.children;
  }, [props.children, props.date, props.boolean]);
  return (
    <div className="flex flex-col gap-1 text-xs">
      <div className="font-semibold text-xs">{props.title}</div>
      <div className="font-normal">{children ?? '-'}</div>
    </div>
  );
}
