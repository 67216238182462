import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from '@/components/ui/card';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Switch } from '@/components/ui/switch';
import { apiClient } from '@/lib/api';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import { useRouteContext, useRouter } from '@tanstack/react-router';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { z } from 'zod';

export default function AddNewClient(props: { onboarding?: boolean }) {
  const router = useRouter();
  const queryClient = useQueryClient();
  const { user } = useRouteContext({
    from: props.onboarding ? '/_onboarding' : '/_application'
  });
  const [inviteOwner, setInviteOwner] = React.useState(false);
  const teamSchema = React.useMemo(
    () =>
      z.object({
        teamName: z.string().min(1).max(512),
        email: inviteOwner ? z.string().email().min(1).max(512) : z.undefined(),
        firstName: inviteOwner ? z.string().min(1, {}).max(512) : z.undefined(),
        lastName: inviteOwner ? z.string().min(1, {}).max(512) : z.undefined(),
        domain: z.string().url(),
        demo: z.boolean().optional()
      }),
    [inviteOwner]
  );

  const teamForm = useForm<z.infer<typeof teamSchema>>({
    resolver: zodResolver(teamSchema),
    defaultValues: {
      teamName: '',
      firstName: undefined,
      lastName: undefined,
      email: undefined,
      domain: '',
      demo: false
    }
  });

  const [domainManuallyModified, setDomainManuallyModified] =
    React.useState(false);

  async function onTeamSubmit(values: z.infer<typeof teamSchema>) {
    const promise = apiClient.PUT('/team', {
      body: {
        name: values.teamName,
        ownerEmail: values.email ?? user.email,
        ownerFirstName: values.firstName,
        ownerLastName: values.lastName,
        domain: values.domain,
        demo: values.demo ?? false
      }
    });

    if (values.demo) {
      toast.promise(promise, {
        loading: 'Creating team, takes ~10s'
      });
      const response = await promise;
      if (response.error != null || response.response.status != 200) {
        toast.error(response.error?.message ?? 'Unknown error');
        return;
      }
      toast.success('Team created, switch to the team');
    } else {
      await promise;
      toast.success('Team created');
    }

    teamForm.reset();
    setDomainManuallyModified(false);

    // Refresh all data
    await queryClient.invalidateQueries({ queryKey: [] });
    await router.invalidate();
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Create Client</CardTitle>
        <CardDescription>
          Create a new client and optionally invite an admin
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Form {...teamForm}>
          <form
            onSubmit={teamForm.handleSubmit(onTeamSubmit)}
            className="col-span-5 mt-2 flex flex-col space-y-4"
          >
            <FormField
              control={teamForm.control}
              name="teamName"
              render={({ field }) => (
                <FormItem className="flex-1">
                  <FormLabel>Client Name</FormLabel>
                  <FormControl>
                    <Input placeholder="Wirespeed" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex items-center gap-2">
              <Label>Invite Client Owner</Label>
              <Switch checked={inviteOwner} onCheckedChange={setInviteOwner} />
            </div>
            {inviteOwner && (
              <>
                <div className="flex flex-col lg:flex-row gap-4">
                  <FormField
                    control={teamForm.control}
                    name="firstName"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>First Name</FormLabel>
                        <FormControl>
                          <Input placeholder="Jake" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={teamForm.control}
                    name="lastName"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>Last Name</FormLabel>
                        <FormControl>
                          <Input placeholder="Speed" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <FormField
                  control={teamForm.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem className="flex-1">
                      <FormLabel>Email</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="jake@wirespeed.co"
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            if (!domainManuallyModified) {
                              const emailValue = e.target.value;
                              const domainMatch = emailValue.match(/@(.+)$/);
                              if (domainMatch != null && domainMatch[1]) {
                                teamForm.setValue(
                                  'domain',
                                  `https://${domainMatch[1]}`
                                );
                              }
                            }
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </>
            )}
            <FormField
              control={teamForm.control}
              name="domain"
              render={({ field }) => (
                <FormItem className="flex-1">
                  <FormLabel>Client Domain</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="https://wirespeed.co"
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        setDomainManuallyModified(true);
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={teamForm.control}
              name="demo"
              render={({ field }) => (
                <FormItem className="flex flex-col gap-2 space-y-0">
                  <div className="flex items-center gap-2">
                    <FormLabel>Demo</FormLabel>
                    <FormControl>
                      <Switch
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                  </div>
                  <div className="text-xs text-muted-foreground">
                    Free to use, populates team with demo data
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="flex self-end">
              <Button
                disabled={teamForm.formState.isSubmitting}
                variant="outline"
                type="submit"
              >
                Create
              </Button>
            </div>
          </form>
        </Form>
      </CardContent>
    </Card>
  );
}
