import { Button } from '@/components/ui/button';
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot
} from '@/components/ui/input-otp';
import { Logo } from '@/components/ui/logo';
import { apiClient } from '@/lib/api';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { useState } from 'react';
import { toast } from 'sonner';

export const Route = createFileRoute(
  '/_authentication/register_/$token_/mfa_/confirm'
)({
  component: RegisterForm
});

export default function RegisterForm() {
  const navigate = useNavigate();
  const { token } = Route.useParams();
  const [mfaToken, setMFAToken] = useState<string>();

  async function testMFA() {
    if (mfaToken == null) {
      toast.error('Invalid MFA token');
      return;
    }
    const result = await apiClient.POST('/auth/register/{id}/totp/verify', {
      params: { path: { id: token } },
      body: { totpToken: mfaToken }
    });
    if (result.error != null || result.data == null || !result.data.valid) {
      toast.error('Invalid MFA token');
      return;
    }
    toast.success('Registration successful, please log in');
    await navigate({ to: '/login' });
  }

  return (
    <div className="flex min-h-full flex-1 flex-col items-stretch justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <Logo words />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight ">
          Confirm MFA
        </h2>
      </div>
      <div className="mt-10 flex flex-col items-center sm:mx-auto sm:w-full sm:max-w-sm">
        <InputOTP
          containerClassName="flex items-center justify-center"
          onComplete={testMFA}
          onChange={setMFAToken}
          maxLength={6}
        >
          <InputOTPGroup>
            <InputOTPSlot className="h-16 w-12" index={0} />
            <InputOTPSlot className="h-16 w-12" index={1} />
            <InputOTPSlot className="h-16 w-12" index={2} />
          </InputOTPGroup>
          <InputOTPSeparator />
          <InputOTPGroup>
            <InputOTPSlot className="h-16 w-12" index={3} />
            <InputOTPSlot className="h-16 w-12" index={4} />
            <InputOTPSlot className="h-16 w-12" index={5} />
          </InputOTPGroup>
        </InputOTP>
        <p className="mt-4 text-sm text-muted-foreground">
          Enter your MFA token
        </p>
        <Button size="lg" className="mt-10" onClick={() => testMFA()}>
          Login
        </Button>
      </div>
    </div>
  );
}
