import { apiClient } from '@/lib/api';

import { AppLayout } from '@/components/app-layout';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from '@/components/ui/card';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Switch } from '@/components/ui/switch';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import { createFileRoute, useRouter } from '@tanstack/react-router';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { z } from 'zod';

export const Route = createFileRoute('/_application/admin/team')({
  component: AdminTeamSettings
});

export default function AdminTeamSettings() {
  const router = useRouter();
  const queryClient = useQueryClient();
  const teamSchema = useMemo(
    () =>
      z
        .object({
          teamName: z.string().min(1).max(512),
          email: z.string().email().min(1).max(512),
          ownerFirstName: z.string().min(1, {}).max(512),
          ownerLastName: z.string().min(1, {}).max(512),
          domain: z.string().url(),
          demo: z.boolean().optional(),
          seed: z.number().optional(),
          serviceProvider: z.boolean().optional()
        })
        .refine((data) => !(data.demo && data.serviceProvider), {
          message: 'Team cannot be both a demo and service provider',
          path: ['demo']
        }),
    []
  );

  const teamForm = useForm<z.infer<typeof teamSchema>>({
    resolver: zodResolver(teamSchema),
    defaultValues: {
      teamName: '',
      ownerFirstName: '',
      ownerLastName: '',
      email: '',
      domain: '',
      demo: false,
      serviceProvider: false,
      seed: 42
    }
  });

  const [domainManuallyModified, setDomainManuallyModified] = useState(false);

  async function onTeamSubmit(values: z.infer<typeof teamSchema>) {
    const promise = apiClient.PUT('/admin/invite', {
      body: {
        name: values.teamName,
        ownerEmail: values.email,
        serviceProvider: values.serviceProvider ?? false,
        ownerFirstName: values.ownerFirstName,
        ownerLastName: values.ownerLastName,
        domain: values.domain,
        demo: values.demo ?? false,
        seed: values.seed
      }
    });

    if (values.demo) {
      toast.promise(promise, {
        loading: 'Creating team, takes ~10s'
      });
      const response = await promise;
      if (response.error != null || response.response.status != 200) {
        toast.error(response.error?.message ?? 'Unknown error');
        return;
      }
      toast.success('Team created, switch to the team');
    } else {
      await promise;
      toast.success('Team created');
    }

    teamForm.reset();
    setDomainManuallyModified(false);

    // Refresh all data
    await queryClient.invalidateQueries({ queryKey: [] });
    await router.invalidate();
  }

  return (
    <AppLayout>
      <Card>
        <CardHeader>
          <CardTitle>Create Team</CardTitle>
          <CardDescription>
            Create a new team and invite an admin
          </CardDescription>
        </CardHeader>
        <CardContent>
          <Form {...teamForm}>
            <form
              onSubmit={teamForm.handleSubmit(onTeamSubmit)}
              className="col-span-5 mt-2 flex flex-col space-y-4"
            >
              <FormField
                control={teamForm.control}
                name="teamName"
                render={({ field }) => (
                  <FormItem className="flex-1">
                    <FormLabel>Team Name</FormLabel>
                    <FormControl>
                      <Input placeholder="Wirespeed" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex flex-col lg:flex-row gap-4">
                <FormField
                  control={teamForm.control}
                  name="ownerFirstName"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <FormLabel>First Name</FormLabel>
                      <FormControl>
                        <Input placeholder="Jon" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={teamForm.control}
                  name="ownerLastName"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <FormLabel>Last Name</FormLabel>
                      <FormControl>
                        <Input placeholder="Speed" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <FormField
                control={teamForm.control}
                name="email"
                render={({ field }) => (
                  <FormItem className="flex-1">
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="jake@wirespeed.co"
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          if (!domainManuallyModified) {
                            const emailValue = e.target.value;
                            const domainMatch = emailValue.match(/@(.+)$/);
                            if (domainMatch != null && domainMatch[1]) {
                              teamForm.setValue(
                                'domain',
                                `https://${domainMatch[1]}`
                              );
                            }
                          }
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={teamForm.control}
                name="domain"
                render={({ field }) => (
                  <FormItem className="flex-1">
                    <FormLabel>Domain</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="https://wirespeed.co"
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          setDomainManuallyModified(true);
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex flex-row gap-4 flex-wrap">
                <FormField
                  control={teamForm.control}
                  name="serviceProvider"
                  render={({ field }) => (
                    <FormItem className="flex items-center space-y-0 gap-2">
                      <FormLabel>Service Provider</FormLabel>
                      <FormControl>
                        <Switch
                          checked={field.value}
                          onCheckedChange={(checked) => {
                            field.onChange(checked);
                            if (checked) {
                              teamForm.setValue('demo', false);
                            }
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={teamForm.control}
                  name="demo"
                  render={({ field }) => (
                    <FormItem className="flex items-center space-y-0 gap-2">
                      <FormLabel>Demo Team</FormLabel>
                      <FormControl>
                        <Switch
                          checked={field.value}
                          onCheckedChange={(checked) => {
                            field.onChange(checked);
                            if (checked) {
                              teamForm.setValue('serviceProvider', false);
                            }
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              {teamForm.watch('demo') && (
                <FormField
                  control={teamForm.control}
                  name="seed"
                  render={({ field }) => (
                    <FormItem className="flex-1">
                      <FormLabel>Demo Seed</FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          {...field}
                          onChange={(e) =>
                            field.onChange(Number(e.target.value))
                          }
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}
              <div className="flex self-end">
                <Button
                  disabled={teamForm.formState.isSubmitting}
                  variant="outline"
                  type="submit"
                >
                  Invite
                </Button>
              </div>
            </form>
          </Form>
        </CardContent>
      </Card>
    </AppLayout>
  );
}
