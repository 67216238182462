import ConnectwisePSAConfiguration from '@/components/integration/configuration/connectwise-psa-configuration';
import { components } from '@/lib/api.types';

export interface IntegrationConfigurationModalProps {
  onClose: () => void;
  integration: components['schemas']['IntegrationV2'];
}

export default function ConfigurationModal(
  props: IntegrationConfigurationModalProps
) {
  switch (props.integration.platform) {
    case 'connectwise-psa':
      return (
        <ConnectwisePSAConfiguration
          integration={props.integration}
          onClose={props.onClose}
        />
      );
  }
}
