import { logout } from '@/lib/api';

/**
 * Can't use shadcn components here because they rely on router context and errors can happen outside the router
 * @param props
 * @returns
 */
export function ErrorPage(props: { error: string }) {
  return (
    <div className="h-screen w-screen flex flex-col space-y-8 items-center justify-center">
      <img src="/illustrations/lost.svg" className="w-auto max-w-[40vw]" />
      <h1 className="text-2xl">Looks like something went wrong</h1>
      {props.error && (
        <pre className="max-w-[80vw] text-wrap">{props.error}</pre>
      )}
      <div className="flex space-x-4">
        <a
          href="/dashboard"
          className="inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2"
        >
          Go Home
        </a>
        <button
          onClick={() => logout()}
          className="inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-destructive text-destructive-foreground hover:bg-destructive/90 h-10 px-4 py-2 ml-4"
        >
          Logout
        </button>
      </div>
    </div>
  );
}
