import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from '@/components/ui/card';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/ui/select';
import { Skeleton } from '@/components/ui/skeleton';
import { apiClient } from '@/lib/api';
import { localDateTime, useTimezone } from '@/lib/time';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useRouteContext } from '@tanstack/react-router';
import { ROLE, ROLE_CONFIG, userHasRole } from '@wire/shared';
import copy from 'copy-to-clipboard';
import { Trash2 } from 'lucide-react';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';

export default function ApiKeys() {
  const { team } = useRouteContext({ from: '/_application' });
  const [confirmDelete, setConfirmDelete] = useState<string | null>(null);
  const { timezone } = useTimezone();
  const apiKeysQuery = useQuery({
    queryKey: ['api-keys', team.id],
    queryFn: async () => {
      const response = await apiClient.GET('/users/api-keys');
      return response.data!;
    },
    placeholderData: keepPreviousData
  });

  if (apiKeysQuery.isLoading || apiKeysQuery.data == null) {
    return <Skeleton className="w-full h-96 rounded-md" />;
  }

  return (
    <>
      <Card>
        <CardHeader className="flex flex-col lg:flex-row lg:justify-between lg:items-center">
          <div>
            <CardTitle>API Keys</CardTitle>
            <CardDescription>
              Manage your API keys for {team.name}
            </CardDescription>
          </div>
          <div>
            <CreateApiKey onComplete={apiKeysQuery.refetch} />
          </div>
        </CardHeader>
        <CardContent>
          {apiKeysQuery.data.length == 0 && (
            <div className="text-xs text-muted-foreground">
              Create an API key to get started
            </div>
          )}
          {apiKeysQuery.data.length > 0 && (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
              {apiKeysQuery.data.map((key) => (
                <div className="border rounded-md p-2" key={key.id}>
                  <div className="flex justify-between">
                    <div>
                      <div className="text-sm font-medium">{key.name}</div>
                      <div className="text-xs text-muted-foreground">
                        <div>Role | {ROLE_CONFIG[key.role].name}</div>
                        <div>
                          Last used |&nbsp;
                          {key.lastUsed != null
                            ? localDateTime(key.lastUsed, timezone)
                            : 'never'}
                        </div>
                        <div>
                          Expires |&nbsp;
                          {localDateTime(key.expiration, timezone)}
                        </div>
                      </div>
                    </div>
                    <div>
                      <DeleteApiKey
                        id={key.id}
                        onComplete={apiKeysQuery.refetch}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </CardContent>
      </Card>
    </>
  );
}

function DeleteApiKey(props: { id: string; onComplete: () => void }) {
  const [open, setOpen] = useState<boolean>(false);

  async function deleteApiKey() {
    await apiClient.DELETE('/users/api-keys/{id}', {
      params: {
        path: {
          id: props.id
        }
      }
    });
    toast.warning('API key deleted');
    setOpen(false);
    props.onComplete();
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outlineDestructive" size="icon">
          <Trash2 className="w-4 h-4" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Delete API Key</DialogTitle>
          <DialogDescription>
            Are you sure you want to delete this API key?
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button variant="outline" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button variant="destructive" onClick={() => deleteApiKey()}>
            Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

function CreateApiKey(props: { onComplete: () => void }) {
  const { user } = useRouteContext({ from: '/_application' });
  const [name, setName] = useState('');
  const [role, setRole] = useState<ROLE>(ROLE.VIEWER);
  const [expirationDays, setExpirationDays] = useState<number>(365);
  const [open, setOpen] = useState(false);
  const [creating, setCreating] = useState(false);

  const [apiKey, setApiKey] = useState<string | null>(null);

  async function createApiKey() {
    setCreating(true);
    const response = await apiClient.PUT('/users/api-keys', {
      body: {
        name,
        role,
        expirationDays
      }
    });
    setCreating(false);
    if (response.error != null) {
      toast.error(response.error.message);
    } else {
      setApiKey(response.data.token!);
    }
  }

  useEffect(() => {
    if (open) {
      setName('');
      setRole(ROLE.VIEWER);
      setApiKey(null);
      setCreating(false);
      setExpirationDays(365);
    }
  }, [open]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline">Create API Key</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create API Key</DialogTitle>
          <DialogDescription>
            Create a new API key to access the API.
          </DialogDescription>
        </DialogHeader>
        {apiKey != null && (
          <div>
            <Label>
              API Key{' '}
              <span className="text-xs text-muted-foreground">
                (Click to copy)
              </span>
            </Label>
            <Input
              type="password"
              value={apiKey}
              readOnly
              className="cursor-pointer"
              onClick={() => {
                copy(apiKey);
                toast.success('Copied to clipboard');
              }}
            />
            <p className="text-xs mt-1 text-muted-foreground">
              This will be your last chance to save this key
            </p>
          </div>
        )}
        {apiKey == null && (
          <div className="flex flex-col gap-2">
            <div>
              <Label>Name</Label>
              <Input value={name} onChange={(e) => setName(e.target.value)} />
            </div>
            <div>
              <Label>Role</Label>
              <Select
                value={role}
                onValueChange={(value) => setRole(value as ROLE)}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select a role" />
                </SelectTrigger>
                <SelectContent>
                  {Object.values(ROLE_CONFIG)
                    .filter((v) => userHasRole(user.role, v.role))
                    .map((role) => (
                      <SelectItem key={role.name} value={role.role}>
                        {role.name}
                      </SelectItem>
                    ))}
                </SelectContent>
              </Select>
            </div>
            <div>
              <Label>
                Expiration{' '}
                <span className="text-xs text-muted-foreground">(days)</span>
              </Label>
              <Input
                value={expirationDays}
                type="number"
                min={1}
                max={720}
                onChange={(e) => setExpirationDays(Number(e.target.value))}
              />
            </div>
          </div>
        )}
        <DialogFooter>
          {apiKey != null && (
            <div>
              {' '}
              <Button
                variant="outline"
                onClick={() => {
                  props.onComplete();
                  setOpen(false);
                }}
              >
                Finish
              </Button>
            </div>
          )}
          {apiKey == null && (
            <div className="flex gap-2">
              <>
                <Button variant="outline" onClick={() => setOpen(false)}>
                  Cancel
                </Button>
                <Button
                  disabled={
                    creating ||
                    name == null ||
                    name == '' ||
                    role == null ||
                    expirationDays == null
                  }
                  onClick={createApiKey}
                >
                  {creating ? 'Creating...' : 'Create'}
                </Button>
              </>
            </div>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
