import { AppLayout } from '@/components/app-layout';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from '@/components/ui/card';
import { UICategoryConfig } from '@/routes/_application/settings/verdicts/index';
import { Link, createFileRoute } from '@tanstack/react-router';
import {
  CategoryClass,
  CategoryClassConfig,
  CategoryConfig
} from '@wire/shared';
import { useMemo } from 'react';

export const Route = createFileRoute(
  '/_application/settings/verdicts/class/$class'
)({
  component: CategoryGroupComponent,
  loader: ({ context, params }) => {
    const groupConfig =
      CategoryClassConfig[
        params.class.toUpperCase() as unknown as CategoryClass
      ];
    context.title = groupConfig.name;
  }
});

function CategoryGroupComponent() {
  const { class: classLower } = Route.useParams();
  const group = classLower.toUpperCase() as unknown as CategoryClass;
  const groupConfig = CategoryClassConfig[group];
  const categories = useMemo(() => {
    return Object.values(CategoryConfig).filter((cat) => cat.class === group);
  }, [group]);

  return (
    <AppLayout>
      <Card>
        <CardHeader>
          <CardTitle className="flex flex-row items-center gap-1">
            {UICategoryConfig[group].icon} {groupConfig.name} Verdicts
          </CardTitle>
          <CardDescription>{groupConfig.description}</CardDescription>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-3 gap-4">
            {categories.map((category) => (
              <Link
                to="/settings/verdicts/category/$category"
                params={{
                  category: category.category.toLowerCase()
                }}
              >
                <div
                  key={category.category}
                  className="hover:bg-primary/20 rounded-md p-4"
                >
                  <h3 className="text-lg font-medium">{category.name}</h3>
                  <p className="text-sm text-muted-foreground">
                    {category.description}
                  </p>
                </div>
              </Link>
            ))}
          </div>
        </CardContent>
      </Card>
    </AppLayout>
  );
}
