import { TableCard } from '@/components/table-card';
import { Badge } from '@/components/ui/badge';
import {
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from '@/components/ui/card';
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger
} from '@/components/ui/hover-card';
import { Label } from '@/components/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/ui/select';
import { Separator } from '@/components/ui/separator';
import { Switch } from '@/components/ui/switch';
import { apiClient } from '@/lib/api';
import { components } from '@/lib/api.types';
import { getCaseStatusBadge, getVerdictBadgeVariant } from '@/lib/case';
import { getTimeZoneAbbreviation, useTimezone } from '@/lib/time';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import {
  keepPreviousData,
  queryOptions,
  useQuery
} from '@tanstack/react-query';
import {
  useNavigate,
  useRouteContext,
  useSearch
} from '@tanstack/react-router';
import {
  Category,
  CategoryClass,
  CategoryClassConfig,
  CategoryConfig,
  getCategoryTitle,
  getVerdictConfigByVerdict,
  Status,
  StatusConfig,
  Verdict,
  VerdictConfig
} from '@wire/shared';
import { CastleIcon, CrownIcon } from 'lucide-react';
import moment from 'moment';
import { useEffect, useState } from 'react';

async function getData(
  searchSettings: components['schemas']['SearchCasesDto']
) {
  const [cases] = await Promise.all([
    apiClient.POST('/cases', {
      body: searchSettings
    })
  ]);
  if (cases.error != null) {
    throw new Error('Error getting cases information');
  }
  return cases.data;
}

export type CaseSearchStatus = NonNullable<
  components['schemas']['SearchCasesDto']['statuses']
>[number];

export interface CasesSearchProps {
  filter?: CaseSearchStatus;
}

export const CASES_QUERY_KEY = 'case-settings';
export const getCasesOptions = (
  searchSettings: components['schemas']['SearchCasesDto']
) =>
  queryOptions({
    queryKey: [CASES_QUERY_KEY, searchSettings],
    queryFn: () => getData(searchSettings),
    placeholderData: keepPreviousData
  });

export function Cases(props: { search: CasesSearchProps }) {
  const { team } = useRouteContext({ from: '/_application' });
  const urlSearch = useSearch({ from: '/_application/cases/' });
  const { timezone } = useTimezone();
  const [hideDemoClients, setHideDemoClients] = useState(
    team.serviceProvider
      ? (localStorage.getItem('hideDemoClients') ?? 'true') == 'true'
      : false
  );
  const [hideExcluded, setHideExcluded] = useState(true);
  const [wasEscalated, setWasEscalated] = useState(false);

  const navigate = useNavigate();
  const [searchSettings, setSearchSettings] = useState<
    components['schemas']['SearchCasesDto']
  >({
    statuses: props.search.filter ? [props.search.filter] : [],
    page: urlSearch.page ?? 1,
    orderBy: urlSearch.orderBy ?? 'createdAt',
    orderDir: urlSearch.orderDir ?? 'desc',
    search: urlSearch.search ?? '',
    categoryClass: urlSearch.categoryClass,
    category: urlSearch.category,
    hideExcluded: hideExcluded,
    hideDemoClients
  });
  const casesQuery = useQuery(getCasesOptions(searchSettings));
  function updateSearchSettings(
    settings: components['schemas']['SearchCasesDto']
  ) {
    let search = settings.search ?? searchSettings.search;
    if (settings?.search?.length == 0) {
      search = undefined;
    }
    const orderBy = settings.orderBy;
    const orderDir = settings.orderDir;
    let page = settings.page;

    if (
      (settings.statuses != null && settings.statuses.length > 0) ||
      (search != null && search != searchSettings.search) ||
      orderBy != null ||
      orderDir != null
    ) {
      page = 1;
    }
    if (search != null && search.length == 0) search = undefined;
    setSearchSettings({
      ...searchSettings,
      ...settings,
      page,
      statuses: urlSearch.filter ? [urlSearch.filter as CaseSearchStatus] : []
    });
  }

  useEffect(() => {
    setSearchSettings({
      ...searchSettings,
      hideDemoClients
    });
    localStorage.setItem('hideDemoClients', JSON.stringify(hideDemoClients));
  }, [hideDemoClients]);

  useEffect(() => {
    setSearchSettings({
      ...searchSettings,
      hideExcluded
    });
    localStorage.setItem('hideExcluded', JSON.stringify(hideExcluded));
  }, [hideExcluded]);

  useEffect(() => {
    setSearchSettings({
      ...searchSettings,
      onlyWasEscalated: wasEscalated
    });
  }, [wasEscalated]);

  useEffect(() => {
    updateSearchSettings({
      statuses: urlSearch.filter ? [urlSearch.filter as CaseSearchStatus] : [],
      categoryClass: urlSearch.categoryClass ?? undefined,
      category: urlSearch.category ?? undefined
    });
  }, [urlSearch.filter, urlSearch.categoryClass, urlSearch.category]);

  return (
    <TableCard
      embedded
      query={casesQuery}
      onUpdate={updateSearchSettings}
      searchable
      storeStateInUrl
      onClick={
        team.serviceProvider
          ? (row) => {
              window.open(
                `/cases/${row.id}?switchTeamId=${row.teamId}`,
                team.serviceProvider ? '_blank' : undefined
              );
            }
          : undefined
      }
      onClickNavigate={
        team.serviceProvider
          ? undefined
          : (row) => ({
              to: '/cases/$caseId',
              params: { caseId: row.id },
              target: team.serviceProvider ? '_blank' : undefined
            })
      }
      headers={[
        {
          display: 'ID',
          key: 'sid',
          sortable: true
        },
        team.serviceProvider
          ? {
              display: 'Client',
              key: 'teamName'
            }
          : undefined,
        {
          display: 'Category',
          key: 'categories',
          format(value: Category[], row) {
            let hoverBadge: React.ReactNode = null;
            if (value != null && value.length > 1) {
              const subCats = value.slice(1);
              hoverBadge = (
                <HoverCard openDelay={0} closeDelay={50}>
                  <HoverCardTrigger>
                    <Badge
                      className="flex"
                      variant={getVerdictBadgeVariant(
                        row.verdict as any,
                        row.status as any
                      )}
                    >
                      +{subCats.length}
                    </Badge>
                  </HoverCardTrigger>
                  <HoverCardContent>
                    <p className="font-medium text-sm">Additional Categories</p>
                    <Separator className="my-2" />
                    <ul>
                      {subCats.map((v) => (
                        <li key={v}>{getCategoryTitle(v)}</li>
                      ))}
                    </ul>
                  </HoverCardContent>
                </HoverCard>
              );
            }

            const icons = [];
            if (row.containsVIP) {
              icons.push(
                <div key="vip" className="flex items-center gap-1">
                  <CrownIcon className="h-4 w-4" />
                  <span>Contains VIP Users</span>
                </div>
              );
            }

            if (row.containsHVA) {
              icons.push(
                <div key="hva" className="flex items-center gap-1">
                  <CastleIcon className="h-4 w-4" />
                  <span>Contains High Value Assets</span>
                </div>
              );
            }

            return (
              <div className="flex flex-row items-center gap-1">
                <HoverCard openDelay={0} closeDelay={50}>
                  <HoverCardTrigger>
                    <div className="flex items-center gap-1">
                      <Badge
                        className="flex items-center gap-1"
                        variant={getVerdictBadgeVariant(
                          row.verdict as any,
                          row.status as any
                        )}
                      >
                        {getCategoryTitle(value[0])}
                        {row.containsVIP && <CrownIcon className="h-4 w-4" />}
                        {row.containsHVA && <CastleIcon className="h-4 w-4" />}
                      </Badge>
                    </div>
                  </HoverCardTrigger>
                  <HoverCardContent className="flex flex-col gap-2">
                    <div>
                      <div className="text-sm font-semibold">Category</div>
                      <div>{getCategoryTitle(value[0])}</div>
                    </div>
                    {row.verdict && (
                      <div>
                        <div className="text-sm font-semibold">Verdict</div>
                        <div>
                          {getVerdictConfigByVerdict(row.verdict).display}
                        </div>
                      </div>
                    )}
                    {icons.length > 0 && (
                      <div>
                        <div className="text-sm font-semibold">Properties</div>
                        <div className="flex flex-col gap-1">{icons}</div>
                      </div>
                    )}
                  </HoverCardContent>
                </HoverCard>
                {hoverBadge}
              </div>
            );
          }
        },
        {
          display: 'Name',
          key: 'name',
          sortable: true,
          truncate: 100
        },
        {
          display: 'Status',
          key: 'status',
          sortable: true,
          format: (value) => getCaseStatusBadge(value)
        },
        !hideExcluded
          ? {
              display: 'Excluded',
              key: 'excluded'
            }
          : undefined,
        {
          display: `Original Event Time (${getTimeZoneAbbreviation(timezone)})`,
          key: 'firstDetectionSourceDetectedAt',
          sortable: true,
          dateTime: true
        },
        {
          display: `Ingested by Wirespeed (${getTimeZoneAbbreviation(timezone)})`,
          key: 'createdAt',
          sortable: true,
          dateTime: true
        },
        {
          display: 'MTTR',
          key: 'mttr',
          format(value, row) {
            if (value == null || row.status != Status.CLOSED) return '-';
            return moment.duration(value, 'seconds').humanize();
          }
        },
        {
          display: '',
          key: 'id',
          format(value, row) {
            let text = '';
            if (row.testMode) {
              text =
                'This case was ingested in test mode and may have exaggerated numbers for MTTR and MTTD.';
            } else if (row.reingested) {
              text =
                'This case was reingested and may have exaggerated numbers for MTTR and MTTD.';
            } else {
              return;
            }
            return (
              <div className="flex items-center justify-center">
                <HoverCard closeDelay={50} openDelay={0}>
                  <HoverCardTrigger className="ml-1">
                    <InformationCircleIcon className="h-6 w-6 text-red-500" />
                  </HoverCardTrigger>
                  <HoverCardContent className="whitespace-normal font-normal">
                    {text}
                  </HoverCardContent>
                </HoverCard>
              </div>
            );
          }
        }
      ]}
    >
      <>
        <CardHeader className="pb-2">
          <CardTitle>Cases</CardTitle>
          <CardDescription>Cases for your organization.</CardDescription>
        </CardHeader>
        <CardContent className="pb-2">
          <div className="flex w-full  lg:items-center flex-col gap-2 lg:flex-row">
            <div>
              <Select
                value={urlSearch.categoryClass ?? ''}
                onValueChange={(v) => {
                  navigate({
                    to: '.',
                    search: (s) => {
                      return {
                        ...s,
                        categoryClass:
                          v == 'any' ? undefined : (v as CategoryClass)
                      };
                    }
                  });
                }}
              >
                <SelectTrigger className="h-full bg-background font-medium">
                  <SelectValue key="select-value" placeholder="Class" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem key="any" value="any">
                    Any
                  </SelectItem>
                  {Object.entries(CategoryClassConfig).map(([k, v]) => (
                    <SelectItem key={k} value={k}>
                      {v.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div>
              <Select
                value={urlSearch.category ?? ''}
                onValueChange={(v) => {
                  navigate({
                    to: '.',
                    search: (s) => ({
                      ...s,
                      category: v == 'any' ? undefined : (v as Category)
                    })
                  });
                }}
              >
                <SelectTrigger className="h-full bg-background font-medium">
                  <SelectValue key="select-value" placeholder="Category" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem key="any" value="any">
                    Any
                  </SelectItem>
                  {Object.values(CategoryConfig)
                    .filter(
                      (v) =>
                        urlSearch.categoryClass == null ||
                        v.class == urlSearch.categoryClass
                    )
                    .map((v) => (
                      <SelectItem key={v.category} value={v.category}>
                        <div>
                          {v.name}
                          <div className="text-xs text-muted-foreground">
                            {CategoryClassConfig[v.class].name}
                          </div>
                        </div>
                      </SelectItem>
                    ))}
                </SelectContent>
              </Select>
            </div>
            <div>
              <Select
                value={urlSearch.filter ?? ''}
                onValueChange={(v) => {
                  navigate({
                    to: '.',
                    search: (s) => ({
                      ...s,
                      filter: v == 'any' ? undefined : v
                    })
                  });
                }}
              >
                <SelectTrigger className="h-full bg-background font-medium">
                  <SelectValue key="select-value" placeholder="Status" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem key="any" value="any">
                    Any
                  </SelectItem>
                  {Object.values(StatusConfig).map((v) => (
                    <SelectItem key={v.display} value={v.type}>
                      {v.display}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div>
              <Select
                value={searchSettings.verdict ?? ''}
                onValueChange={(v) => {
                  updateSearchSettings({
                    verdict: v == 'any' ? undefined : (v as Verdict)
                  });
                }}
              >
                <SelectTrigger className="h-full bg-background font-medium">
                  <SelectValue key="select-value" placeholder="Verdict" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem key="any" value="any">
                    Any
                  </SelectItem>
                  {Object.values(VerdictConfig).map((v) => (
                    <SelectItem key={v.display} value={v.type}>
                      {v.display}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="border rounded-md p-2 shadow-sm">
              <HoverCard>
                <HoverCardTrigger className="flex flex-row items-center gap-1">
                  <Label htmlFor="hide-excluded" className="cursor-pointer">
                    Show Excluded
                  </Label>
                  <Switch
                    id="hide-excluded"
                    checked={!hideExcluded}
                    onCheckedChange={(v) => setHideExcluded(!v)}
                  />
                </HoverCardTrigger>
                <HoverCardContent className="text-xs">
                  Show cases that were automatically closed by Exclusions you've
                  created.
                </HoverCardContent>
              </HoverCard>
            </div>{' '}
            <div className="border rounded-md p-2 shadow-sm">
              <HoverCard>
                <HoverCardTrigger className="flex flex-row items-center gap-1">
                  <Label htmlFor="was-escalated" className="cursor-pointer">
                    Was Escalated
                  </Label>
                  <Switch
                    id="was-escalated"
                    checked={wasEscalated}
                    onCheckedChange={(v) => setWasEscalated(v)}
                  />
                </HoverCardTrigger>
                <HoverCardContent className="text-xs">
                  Show any cases that were ever escalated.
                </HoverCardContent>
              </HoverCard>
            </div>
            {team.serviceProvider && (
              <div className="flex items-center flex-1 justify-end gap-1">
                <Switch
                  checked={hideDemoClients}
                  onCheckedChange={(v) => setHideDemoClients(v)}
                />{' '}
                <Label>Hide Demo Clients</Label>
              </div>
            )}
          </div>
        </CardContent>
      </>
    </TableCard>
  );
}
