/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as ChatOpsImport } from './routes/chat-ops'
import { Route as OnboardingImport } from './routes/_onboarding'
import { Route as ApplicationImport } from './routes/_application'
import { Route as IndexImport } from './routes/index'
import { Route as DetectionsDetectionIdImport } from './routes/detections/$detectionId'
import { Route as AuthenticationLoginImport } from './routes/_authentication/login'
import { Route as AuthenticationForgotImport } from './routes/_authentication/forgot'
import { Route as ApplicationSwitchImport } from './routes/_application/switch'
import { Route as ApplicationDashboardImport } from './routes/_application/dashboard'
import { Route as ApplicationClientsImport } from './routes/_application/clients'
import { Route as ApplicationEventsIndexImport } from './routes/_application/events/index'
import { Route as ApplicationCasesIndexImport } from './routes/_application/cases/index'
import { Route as OnboardingUserOnboardingStepImport } from './routes/_onboarding/user-onboarding.$step'
import { Route as OnboardingSpOnboardingStepImport } from './routes/_onboarding/sp-onboarding.$step'
import { Route as AuthenticationRegisterTokenImport } from './routes/_authentication/register_.$token'
import { Route as AuthenticationForgotTokenImport } from './routes/_authentication/forgot_.$token'
import { Route as ApplicationSettingsTeamImport } from './routes/_application/settings/team'
import { Route as ApplicationSettingsSystemLogImport } from './routes/_application/settings/system-log'
import { Route as ApplicationSettingsProfileImport } from './routes/_application/settings/profile'
import { Route as ApplicationSettingsNotificationsImport } from './routes/_application/settings/notifications'
import { Route as ApplicationSettingsContainmentImport } from './routes/_application/settings/containment'
import { Route as ApplicationSettingsChatOpsImport } from './routes/_application/settings/chat-ops'
import { Route as ApplicationSettingsAutomationImport } from './routes/_application/settings/automation'
import { Route as ApplicationSettingsAddNewClientImport } from './routes/_application/settings/add-new-client'
import { Route as ApplicationCasesCaseIdImport } from './routes/_application/cases/$caseId'
import { Route as ApplicationAdminTimImport } from './routes/_application/admin/tim'
import { Route as ApplicationAdminTeamImport } from './routes/_application/admin/team'
import { Route as ApplicationAdminQueuesImport } from './routes/_application/admin/queues'
import { Route as ApplicationAdminIntegrationsImport } from './routes/_application/admin/integrations'
import { Route as ApplicationAdminFileEnrichmentImport } from './routes/_application/admin/file-enrichment'
import { Route as ApplicationAdminExtractionQueueImport } from './routes/_application/admin/extraction-queue'
import { Route as ApplicationAdminQueueNameImport } from './routes/_application/admin/$queueName'
import { Route as ApplicationSettingsVerdictsIndexImport } from './routes/_application/settings/verdicts/index'
import { Route as ApplicationSettingsIntegrationsIndexImport } from './routes/_application/settings/integrations/index'
import { Route as ApplicationAssetsUsersIndexImport } from './routes/_application/assets/users/index'
import { Route as ApplicationAssetsUserAgentsIndexImport } from './routes/_application/assets/user-agents/index'
import { Route as ApplicationAssetsProcessesIndexImport } from './routes/_application/assets/processes/index'
import { Route as ApplicationAssetsLocationsIndexImport } from './routes/_application/assets/locations/index'
import { Route as ApplicationAssetsIpsIndexImport } from './routes/_application/assets/ips/index'
import { Route as ApplicationAssetsFilesIndexImport } from './routes/_application/assets/files/index'
import { Route as ApplicationAssetsEndpointsIndexImport } from './routes/_application/assets/endpoints/index'
import { Route as ApplicationAssetsDomainsIndexImport } from './routes/_application/assets/domains/index'
import { Route as ApplicationAdminCategorizationIndexImport } from './routes/_application/admin/categorization/index'
import { Route as ApplicationAdminAqlIndexImport } from './routes/_application/admin/aql/index'
import { Route as AuthenticationRegisterTokenMfaImport } from './routes/_authentication/register_.$token_.mfa'
import { Route as ApplicationSettingsIntegrationsIntegrationIdImport } from './routes/_application/settings/integrations/$integrationId'
import { Route as ApplicationAssetsUsersUserIdImport } from './routes/_application/assets/users/$userId'
import { Route as ApplicationAssetsUserAgentsUserAgentIdImport } from './routes/_application/assets/user-agents/$userAgentId'
import { Route as ApplicationAssetsProcessesProcessIdImport } from './routes/_application/assets/processes/$processId'
import { Route as ApplicationAssetsLocationsLocationIdImport } from './routes/_application/assets/locations/$locationId'
import { Route as ApplicationAssetsIpsIpIdImport } from './routes/_application/assets/ips/$ipId'
import { Route as ApplicationAssetsFilesFileIdImport } from './routes/_application/assets/files/$fileId'
import { Route as ApplicationAssetsEndpointsEndpointIdImport } from './routes/_application/assets/endpoints/$endpointId'
import { Route as ApplicationAssetsDomainsDomainIdImport } from './routes/_application/assets/domains/$domainId'
import { Route as ApplicationAdminCategorizationIntegrationImport } from './routes/_application/admin/categorization/$integration'
import { Route as ApplicationAdminAqlBatchIdImport } from './routes/_application/admin/aql/$batchId'
import { Route as ApplicationAdminQueueNameJobIdImport } from './routes/_application/admin/$queueName_.$jobId'
import { Route as AuthenticationRegisterTokenMfaConfirmImport } from './routes/_authentication/register_.$token_.mfa_.confirm'
import { Route as ApplicationSettingsVerdictsClassClassImport } from './routes/_application/settings/verdicts/class/$class'
import { Route as ApplicationSettingsVerdictsCategoryCategoryImport } from './routes/_application/settings/verdicts/category/$category'
import { Route as ApplicationSettingsIntegrationsTeamsFinishImport } from './routes/_application/settings/integrations/teams/finish'
import { Route as ApplicationAdminCategorizationIntegrationGroupImport } from './routes/_application/admin/categorization/$integration_.$group'

// Create/Update Routes

const ChatOpsRoute = ChatOpsImport.update({
  id: '/chat-ops',
  path: '/chat-ops',
  getParentRoute: () => rootRoute,
} as any)

const OnboardingRoute = OnboardingImport.update({
  id: '/_onboarding',
  getParentRoute: () => rootRoute,
} as any)

const ApplicationRoute = ApplicationImport.update({
  id: '/_application',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const DetectionsDetectionIdRoute = DetectionsDetectionIdImport.update({
  id: '/detections/$detectionId',
  path: '/detections/$detectionId',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticationLoginRoute = AuthenticationLoginImport.update({
  id: '/_authentication/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticationForgotRoute = AuthenticationForgotImport.update({
  id: '/_authentication/forgot',
  path: '/forgot',
  getParentRoute: () => rootRoute,
} as any)

const ApplicationSwitchRoute = ApplicationSwitchImport.update({
  id: '/switch',
  path: '/switch',
  getParentRoute: () => ApplicationRoute,
} as any)

const ApplicationDashboardRoute = ApplicationDashboardImport.update({
  id: '/dashboard',
  path: '/dashboard',
  getParentRoute: () => ApplicationRoute,
} as any)

const ApplicationClientsRoute = ApplicationClientsImport.update({
  id: '/clients',
  path: '/clients',
  getParentRoute: () => ApplicationRoute,
} as any)

const ApplicationEventsIndexRoute = ApplicationEventsIndexImport.update({
  id: '/events/',
  path: '/events/',
  getParentRoute: () => ApplicationRoute,
} as any)

const ApplicationCasesIndexRoute = ApplicationCasesIndexImport.update({
  id: '/cases/',
  path: '/cases/',
  getParentRoute: () => ApplicationRoute,
} as any)

const OnboardingUserOnboardingStepRoute =
  OnboardingUserOnboardingStepImport.update({
    id: '/user-onboarding/$step',
    path: '/user-onboarding/$step',
    getParentRoute: () => OnboardingRoute,
  } as any)

const OnboardingSpOnboardingStepRoute = OnboardingSpOnboardingStepImport.update(
  {
    id: '/sp-onboarding/$step',
    path: '/sp-onboarding/$step',
    getParentRoute: () => OnboardingRoute,
  } as any,
)

const AuthenticationRegisterTokenRoute =
  AuthenticationRegisterTokenImport.update({
    id: '/_authentication/register_/$token',
    path: '/register/$token',
    getParentRoute: () => rootRoute,
  } as any)

const AuthenticationForgotTokenRoute = AuthenticationForgotTokenImport.update({
  id: '/_authentication/forgot_/$token',
  path: '/forgot/$token',
  getParentRoute: () => rootRoute,
} as any)

const ApplicationSettingsTeamRoute = ApplicationSettingsTeamImport.update({
  id: '/settings/team',
  path: '/settings/team',
  getParentRoute: () => ApplicationRoute,
} as any)

const ApplicationSettingsSystemLogRoute =
  ApplicationSettingsSystemLogImport.update({
    id: '/settings/system-log',
    path: '/settings/system-log',
    getParentRoute: () => ApplicationRoute,
  } as any)

const ApplicationSettingsProfileRoute = ApplicationSettingsProfileImport.update(
  {
    id: '/settings/profile',
    path: '/settings/profile',
    getParentRoute: () => ApplicationRoute,
  } as any,
)

const ApplicationSettingsNotificationsRoute =
  ApplicationSettingsNotificationsImport.update({
    id: '/settings/notifications',
    path: '/settings/notifications',
    getParentRoute: () => ApplicationRoute,
  } as any)

const ApplicationSettingsContainmentRoute =
  ApplicationSettingsContainmentImport.update({
    id: '/settings/containment',
    path: '/settings/containment',
    getParentRoute: () => ApplicationRoute,
  } as any)

const ApplicationSettingsChatOpsRoute = ApplicationSettingsChatOpsImport.update(
  {
    id: '/settings/chat-ops',
    path: '/settings/chat-ops',
    getParentRoute: () => ApplicationRoute,
  } as any,
)

const ApplicationSettingsAutomationRoute =
  ApplicationSettingsAutomationImport.update({
    id: '/settings/automation',
    path: '/settings/automation',
    getParentRoute: () => ApplicationRoute,
  } as any)

const ApplicationSettingsAddNewClientRoute =
  ApplicationSettingsAddNewClientImport.update({
    id: '/settings/add-new-client',
    path: '/settings/add-new-client',
    getParentRoute: () => ApplicationRoute,
  } as any)

const ApplicationCasesCaseIdRoute = ApplicationCasesCaseIdImport.update({
  id: '/cases/$caseId',
  path: '/cases/$caseId',
  getParentRoute: () => ApplicationRoute,
} as any)

const ApplicationAdminTimRoute = ApplicationAdminTimImport.update({
  id: '/admin/tim',
  path: '/admin/tim',
  getParentRoute: () => ApplicationRoute,
} as any)

const ApplicationAdminTeamRoute = ApplicationAdminTeamImport.update({
  id: '/admin/team',
  path: '/admin/team',
  getParentRoute: () => ApplicationRoute,
} as any)

const ApplicationAdminQueuesRoute = ApplicationAdminQueuesImport.update({
  id: '/admin/queues',
  path: '/admin/queues',
  getParentRoute: () => ApplicationRoute,
} as any)

const ApplicationAdminIntegrationsRoute =
  ApplicationAdminIntegrationsImport.update({
    id: '/admin/integrations',
    path: '/admin/integrations',
    getParentRoute: () => ApplicationRoute,
  } as any)

const ApplicationAdminFileEnrichmentRoute =
  ApplicationAdminFileEnrichmentImport.update({
    id: '/admin/file-enrichment',
    path: '/admin/file-enrichment',
    getParentRoute: () => ApplicationRoute,
  } as any)

const ApplicationAdminExtractionQueueRoute =
  ApplicationAdminExtractionQueueImport.update({
    id: '/admin/extraction-queue',
    path: '/admin/extraction-queue',
    getParentRoute: () => ApplicationRoute,
  } as any)

const ApplicationAdminQueueNameRoute = ApplicationAdminQueueNameImport.update({
  id: '/admin/$queueName',
  path: '/admin/$queueName',
  getParentRoute: () => ApplicationRoute,
} as any)

const ApplicationSettingsVerdictsIndexRoute =
  ApplicationSettingsVerdictsIndexImport.update({
    id: '/settings/verdicts/',
    path: '/settings/verdicts/',
    getParentRoute: () => ApplicationRoute,
  } as any)

const ApplicationSettingsIntegrationsIndexRoute =
  ApplicationSettingsIntegrationsIndexImport.update({
    id: '/settings/integrations/',
    path: '/settings/integrations/',
    getParentRoute: () => ApplicationRoute,
  } as any)

const ApplicationAssetsUsersIndexRoute =
  ApplicationAssetsUsersIndexImport.update({
    id: '/assets/users/',
    path: '/assets/users/',
    getParentRoute: () => ApplicationRoute,
  } as any)

const ApplicationAssetsUserAgentsIndexRoute =
  ApplicationAssetsUserAgentsIndexImport.update({
    id: '/assets/user-agents/',
    path: '/assets/user-agents/',
    getParentRoute: () => ApplicationRoute,
  } as any)

const ApplicationAssetsProcessesIndexRoute =
  ApplicationAssetsProcessesIndexImport.update({
    id: '/assets/processes/',
    path: '/assets/processes/',
    getParentRoute: () => ApplicationRoute,
  } as any)

const ApplicationAssetsLocationsIndexRoute =
  ApplicationAssetsLocationsIndexImport.update({
    id: '/assets/locations/',
    path: '/assets/locations/',
    getParentRoute: () => ApplicationRoute,
  } as any)

const ApplicationAssetsIpsIndexRoute = ApplicationAssetsIpsIndexImport.update({
  id: '/assets/ips/',
  path: '/assets/ips/',
  getParentRoute: () => ApplicationRoute,
} as any)

const ApplicationAssetsFilesIndexRoute =
  ApplicationAssetsFilesIndexImport.update({
    id: '/assets/files/',
    path: '/assets/files/',
    getParentRoute: () => ApplicationRoute,
  } as any)

const ApplicationAssetsEndpointsIndexRoute =
  ApplicationAssetsEndpointsIndexImport.update({
    id: '/assets/endpoints/',
    path: '/assets/endpoints/',
    getParentRoute: () => ApplicationRoute,
  } as any)

const ApplicationAssetsDomainsIndexRoute =
  ApplicationAssetsDomainsIndexImport.update({
    id: '/assets/domains/',
    path: '/assets/domains/',
    getParentRoute: () => ApplicationRoute,
  } as any)

const ApplicationAdminCategorizationIndexRoute =
  ApplicationAdminCategorizationIndexImport.update({
    id: '/admin/categorization/',
    path: '/admin/categorization/',
    getParentRoute: () => ApplicationRoute,
  } as any)

const ApplicationAdminAqlIndexRoute = ApplicationAdminAqlIndexImport.update({
  id: '/admin/aql/',
  path: '/admin/aql/',
  getParentRoute: () => ApplicationRoute,
} as any)

const AuthenticationRegisterTokenMfaRoute =
  AuthenticationRegisterTokenMfaImport.update({
    id: '/_authentication/register_/$token_/mfa',
    path: '/register/$token/mfa',
    getParentRoute: () => rootRoute,
  } as any)

const ApplicationSettingsIntegrationsIntegrationIdRoute =
  ApplicationSettingsIntegrationsIntegrationIdImport.update({
    id: '/settings/integrations/$integrationId',
    path: '/settings/integrations/$integrationId',
    getParentRoute: () => ApplicationRoute,
  } as any)

const ApplicationAssetsUsersUserIdRoute =
  ApplicationAssetsUsersUserIdImport.update({
    id: '/assets/users/$userId',
    path: '/assets/users/$userId',
    getParentRoute: () => ApplicationRoute,
  } as any)

const ApplicationAssetsUserAgentsUserAgentIdRoute =
  ApplicationAssetsUserAgentsUserAgentIdImport.update({
    id: '/assets/user-agents/$userAgentId',
    path: '/assets/user-agents/$userAgentId',
    getParentRoute: () => ApplicationRoute,
  } as any)

const ApplicationAssetsProcessesProcessIdRoute =
  ApplicationAssetsProcessesProcessIdImport.update({
    id: '/assets/processes/$processId',
    path: '/assets/processes/$processId',
    getParentRoute: () => ApplicationRoute,
  } as any)

const ApplicationAssetsLocationsLocationIdRoute =
  ApplicationAssetsLocationsLocationIdImport.update({
    id: '/assets/locations/$locationId',
    path: '/assets/locations/$locationId',
    getParentRoute: () => ApplicationRoute,
  } as any)

const ApplicationAssetsIpsIpIdRoute = ApplicationAssetsIpsIpIdImport.update({
  id: '/assets/ips/$ipId',
  path: '/assets/ips/$ipId',
  getParentRoute: () => ApplicationRoute,
} as any)

const ApplicationAssetsFilesFileIdRoute =
  ApplicationAssetsFilesFileIdImport.update({
    id: '/assets/files/$fileId',
    path: '/assets/files/$fileId',
    getParentRoute: () => ApplicationRoute,
  } as any)

const ApplicationAssetsEndpointsEndpointIdRoute =
  ApplicationAssetsEndpointsEndpointIdImport.update({
    id: '/assets/endpoints/$endpointId',
    path: '/assets/endpoints/$endpointId',
    getParentRoute: () => ApplicationRoute,
  } as any)

const ApplicationAssetsDomainsDomainIdRoute =
  ApplicationAssetsDomainsDomainIdImport.update({
    id: '/assets/domains/$domainId',
    path: '/assets/domains/$domainId',
    getParentRoute: () => ApplicationRoute,
  } as any)

const ApplicationAdminCategorizationIntegrationRoute =
  ApplicationAdminCategorizationIntegrationImport.update({
    id: '/admin/categorization/$integration',
    path: '/admin/categorization/$integration',
    getParentRoute: () => ApplicationRoute,
  } as any)

const ApplicationAdminAqlBatchIdRoute = ApplicationAdminAqlBatchIdImport.update(
  {
    id: '/admin/aql/$batchId',
    path: '/admin/aql/$batchId',
    getParentRoute: () => ApplicationRoute,
  } as any,
)

const ApplicationAdminQueueNameJobIdRoute =
  ApplicationAdminQueueNameJobIdImport.update({
    id: '/admin/$queueName_/$jobId',
    path: '/admin/$queueName/$jobId',
    getParentRoute: () => ApplicationRoute,
  } as any)

const AuthenticationRegisterTokenMfaConfirmRoute =
  AuthenticationRegisterTokenMfaConfirmImport.update({
    id: '/_authentication/register_/$token_/mfa_/confirm',
    path: '/register/$token/mfa/confirm',
    getParentRoute: () => rootRoute,
  } as any)

const ApplicationSettingsVerdictsClassClassRoute =
  ApplicationSettingsVerdictsClassClassImport.update({
    id: '/settings/verdicts/class/$class',
    path: '/settings/verdicts/class/$class',
    getParentRoute: () => ApplicationRoute,
  } as any)

const ApplicationSettingsVerdictsCategoryCategoryRoute =
  ApplicationSettingsVerdictsCategoryCategoryImport.update({
    id: '/settings/verdicts/category/$category',
    path: '/settings/verdicts/category/$category',
    getParentRoute: () => ApplicationRoute,
  } as any)

const ApplicationSettingsIntegrationsTeamsFinishRoute =
  ApplicationSettingsIntegrationsTeamsFinishImport.update({
    id: '/settings/integrations/teams/finish',
    path: '/settings/integrations/teams/finish',
    getParentRoute: () => ApplicationRoute,
  } as any)

const ApplicationAdminCategorizationIntegrationGroupRoute =
  ApplicationAdminCategorizationIntegrationGroupImport.update({
    id: '/admin/categorization/$integration_/$group',
    path: '/admin/categorization/$integration/$group',
    getParentRoute: () => ApplicationRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/_application': {
      id: '/_application'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof ApplicationImport
      parentRoute: typeof rootRoute
    }
    '/_onboarding': {
      id: '/_onboarding'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof OnboardingImport
      parentRoute: typeof rootRoute
    }
    '/chat-ops': {
      id: '/chat-ops'
      path: '/chat-ops'
      fullPath: '/chat-ops'
      preLoaderRoute: typeof ChatOpsImport
      parentRoute: typeof rootRoute
    }
    '/_application/clients': {
      id: '/_application/clients'
      path: '/clients'
      fullPath: '/clients'
      preLoaderRoute: typeof ApplicationClientsImport
      parentRoute: typeof ApplicationImport
    }
    '/_application/dashboard': {
      id: '/_application/dashboard'
      path: '/dashboard'
      fullPath: '/dashboard'
      preLoaderRoute: typeof ApplicationDashboardImport
      parentRoute: typeof ApplicationImport
    }
    '/_application/switch': {
      id: '/_application/switch'
      path: '/switch'
      fullPath: '/switch'
      preLoaderRoute: typeof ApplicationSwitchImport
      parentRoute: typeof ApplicationImport
    }
    '/_authentication/forgot': {
      id: '/_authentication/forgot'
      path: '/forgot'
      fullPath: '/forgot'
      preLoaderRoute: typeof AuthenticationForgotImport
      parentRoute: typeof rootRoute
    }
    '/_authentication/login': {
      id: '/_authentication/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof AuthenticationLoginImport
      parentRoute: typeof rootRoute
    }
    '/detections/$detectionId': {
      id: '/detections/$detectionId'
      path: '/detections/$detectionId'
      fullPath: '/detections/$detectionId'
      preLoaderRoute: typeof DetectionsDetectionIdImport
      parentRoute: typeof rootRoute
    }
    '/_application/admin/$queueName': {
      id: '/_application/admin/$queueName'
      path: '/admin/$queueName'
      fullPath: '/admin/$queueName'
      preLoaderRoute: typeof ApplicationAdminQueueNameImport
      parentRoute: typeof ApplicationImport
    }
    '/_application/admin/extraction-queue': {
      id: '/_application/admin/extraction-queue'
      path: '/admin/extraction-queue'
      fullPath: '/admin/extraction-queue'
      preLoaderRoute: typeof ApplicationAdminExtractionQueueImport
      parentRoute: typeof ApplicationImport
    }
    '/_application/admin/file-enrichment': {
      id: '/_application/admin/file-enrichment'
      path: '/admin/file-enrichment'
      fullPath: '/admin/file-enrichment'
      preLoaderRoute: typeof ApplicationAdminFileEnrichmentImport
      parentRoute: typeof ApplicationImport
    }
    '/_application/admin/integrations': {
      id: '/_application/admin/integrations'
      path: '/admin/integrations'
      fullPath: '/admin/integrations'
      preLoaderRoute: typeof ApplicationAdminIntegrationsImport
      parentRoute: typeof ApplicationImport
    }
    '/_application/admin/queues': {
      id: '/_application/admin/queues'
      path: '/admin/queues'
      fullPath: '/admin/queues'
      preLoaderRoute: typeof ApplicationAdminQueuesImport
      parentRoute: typeof ApplicationImport
    }
    '/_application/admin/team': {
      id: '/_application/admin/team'
      path: '/admin/team'
      fullPath: '/admin/team'
      preLoaderRoute: typeof ApplicationAdminTeamImport
      parentRoute: typeof ApplicationImport
    }
    '/_application/admin/tim': {
      id: '/_application/admin/tim'
      path: '/admin/tim'
      fullPath: '/admin/tim'
      preLoaderRoute: typeof ApplicationAdminTimImport
      parentRoute: typeof ApplicationImport
    }
    '/_application/cases/$caseId': {
      id: '/_application/cases/$caseId'
      path: '/cases/$caseId'
      fullPath: '/cases/$caseId'
      preLoaderRoute: typeof ApplicationCasesCaseIdImport
      parentRoute: typeof ApplicationImport
    }
    '/_application/settings/add-new-client': {
      id: '/_application/settings/add-new-client'
      path: '/settings/add-new-client'
      fullPath: '/settings/add-new-client'
      preLoaderRoute: typeof ApplicationSettingsAddNewClientImport
      parentRoute: typeof ApplicationImport
    }
    '/_application/settings/automation': {
      id: '/_application/settings/automation'
      path: '/settings/automation'
      fullPath: '/settings/automation'
      preLoaderRoute: typeof ApplicationSettingsAutomationImport
      parentRoute: typeof ApplicationImport
    }
    '/_application/settings/chat-ops': {
      id: '/_application/settings/chat-ops'
      path: '/settings/chat-ops'
      fullPath: '/settings/chat-ops'
      preLoaderRoute: typeof ApplicationSettingsChatOpsImport
      parentRoute: typeof ApplicationImport
    }
    '/_application/settings/containment': {
      id: '/_application/settings/containment'
      path: '/settings/containment'
      fullPath: '/settings/containment'
      preLoaderRoute: typeof ApplicationSettingsContainmentImport
      parentRoute: typeof ApplicationImport
    }
    '/_application/settings/notifications': {
      id: '/_application/settings/notifications'
      path: '/settings/notifications'
      fullPath: '/settings/notifications'
      preLoaderRoute: typeof ApplicationSettingsNotificationsImport
      parentRoute: typeof ApplicationImport
    }
    '/_application/settings/profile': {
      id: '/_application/settings/profile'
      path: '/settings/profile'
      fullPath: '/settings/profile'
      preLoaderRoute: typeof ApplicationSettingsProfileImport
      parentRoute: typeof ApplicationImport
    }
    '/_application/settings/system-log': {
      id: '/_application/settings/system-log'
      path: '/settings/system-log'
      fullPath: '/settings/system-log'
      preLoaderRoute: typeof ApplicationSettingsSystemLogImport
      parentRoute: typeof ApplicationImport
    }
    '/_application/settings/team': {
      id: '/_application/settings/team'
      path: '/settings/team'
      fullPath: '/settings/team'
      preLoaderRoute: typeof ApplicationSettingsTeamImport
      parentRoute: typeof ApplicationImport
    }
    '/_authentication/forgot_/$token': {
      id: '/_authentication/forgot_/$token'
      path: '/forgot/$token'
      fullPath: '/forgot/$token'
      preLoaderRoute: typeof AuthenticationForgotTokenImport
      parentRoute: typeof rootRoute
    }
    '/_authentication/register_/$token': {
      id: '/_authentication/register_/$token'
      path: '/register/$token'
      fullPath: '/register/$token'
      preLoaderRoute: typeof AuthenticationRegisterTokenImport
      parentRoute: typeof rootRoute
    }
    '/_onboarding/sp-onboarding/$step': {
      id: '/_onboarding/sp-onboarding/$step'
      path: '/sp-onboarding/$step'
      fullPath: '/sp-onboarding/$step'
      preLoaderRoute: typeof OnboardingSpOnboardingStepImport
      parentRoute: typeof OnboardingImport
    }
    '/_onboarding/user-onboarding/$step': {
      id: '/_onboarding/user-onboarding/$step'
      path: '/user-onboarding/$step'
      fullPath: '/user-onboarding/$step'
      preLoaderRoute: typeof OnboardingUserOnboardingStepImport
      parentRoute: typeof OnboardingImport
    }
    '/_application/cases/': {
      id: '/_application/cases/'
      path: '/cases'
      fullPath: '/cases'
      preLoaderRoute: typeof ApplicationCasesIndexImport
      parentRoute: typeof ApplicationImport
    }
    '/_application/events/': {
      id: '/_application/events/'
      path: '/events'
      fullPath: '/events'
      preLoaderRoute: typeof ApplicationEventsIndexImport
      parentRoute: typeof ApplicationImport
    }
    '/_application/admin/$queueName_/$jobId': {
      id: '/_application/admin/$queueName_/$jobId'
      path: '/admin/$queueName/$jobId'
      fullPath: '/admin/$queueName/$jobId'
      preLoaderRoute: typeof ApplicationAdminQueueNameJobIdImport
      parentRoute: typeof ApplicationImport
    }
    '/_application/admin/aql/$batchId': {
      id: '/_application/admin/aql/$batchId'
      path: '/admin/aql/$batchId'
      fullPath: '/admin/aql/$batchId'
      preLoaderRoute: typeof ApplicationAdminAqlBatchIdImport
      parentRoute: typeof ApplicationImport
    }
    '/_application/admin/categorization/$integration': {
      id: '/_application/admin/categorization/$integration'
      path: '/admin/categorization/$integration'
      fullPath: '/admin/categorization/$integration'
      preLoaderRoute: typeof ApplicationAdminCategorizationIntegrationImport
      parentRoute: typeof ApplicationImport
    }
    '/_application/assets/domains/$domainId': {
      id: '/_application/assets/domains/$domainId'
      path: '/assets/domains/$domainId'
      fullPath: '/assets/domains/$domainId'
      preLoaderRoute: typeof ApplicationAssetsDomainsDomainIdImport
      parentRoute: typeof ApplicationImport
    }
    '/_application/assets/endpoints/$endpointId': {
      id: '/_application/assets/endpoints/$endpointId'
      path: '/assets/endpoints/$endpointId'
      fullPath: '/assets/endpoints/$endpointId'
      preLoaderRoute: typeof ApplicationAssetsEndpointsEndpointIdImport
      parentRoute: typeof ApplicationImport
    }
    '/_application/assets/files/$fileId': {
      id: '/_application/assets/files/$fileId'
      path: '/assets/files/$fileId'
      fullPath: '/assets/files/$fileId'
      preLoaderRoute: typeof ApplicationAssetsFilesFileIdImport
      parentRoute: typeof ApplicationImport
    }
    '/_application/assets/ips/$ipId': {
      id: '/_application/assets/ips/$ipId'
      path: '/assets/ips/$ipId'
      fullPath: '/assets/ips/$ipId'
      preLoaderRoute: typeof ApplicationAssetsIpsIpIdImport
      parentRoute: typeof ApplicationImport
    }
    '/_application/assets/locations/$locationId': {
      id: '/_application/assets/locations/$locationId'
      path: '/assets/locations/$locationId'
      fullPath: '/assets/locations/$locationId'
      preLoaderRoute: typeof ApplicationAssetsLocationsLocationIdImport
      parentRoute: typeof ApplicationImport
    }
    '/_application/assets/processes/$processId': {
      id: '/_application/assets/processes/$processId'
      path: '/assets/processes/$processId'
      fullPath: '/assets/processes/$processId'
      preLoaderRoute: typeof ApplicationAssetsProcessesProcessIdImport
      parentRoute: typeof ApplicationImport
    }
    '/_application/assets/user-agents/$userAgentId': {
      id: '/_application/assets/user-agents/$userAgentId'
      path: '/assets/user-agents/$userAgentId'
      fullPath: '/assets/user-agents/$userAgentId'
      preLoaderRoute: typeof ApplicationAssetsUserAgentsUserAgentIdImport
      parentRoute: typeof ApplicationImport
    }
    '/_application/assets/users/$userId': {
      id: '/_application/assets/users/$userId'
      path: '/assets/users/$userId'
      fullPath: '/assets/users/$userId'
      preLoaderRoute: typeof ApplicationAssetsUsersUserIdImport
      parentRoute: typeof ApplicationImport
    }
    '/_application/settings/integrations/$integrationId': {
      id: '/_application/settings/integrations/$integrationId'
      path: '/settings/integrations/$integrationId'
      fullPath: '/settings/integrations/$integrationId'
      preLoaderRoute: typeof ApplicationSettingsIntegrationsIntegrationIdImport
      parentRoute: typeof ApplicationImport
    }
    '/_authentication/register_/$token_/mfa': {
      id: '/_authentication/register_/$token_/mfa'
      path: '/register/$token/mfa'
      fullPath: '/register/$token/mfa'
      preLoaderRoute: typeof AuthenticationRegisterTokenMfaImport
      parentRoute: typeof rootRoute
    }
    '/_application/admin/aql/': {
      id: '/_application/admin/aql/'
      path: '/admin/aql'
      fullPath: '/admin/aql'
      preLoaderRoute: typeof ApplicationAdminAqlIndexImport
      parentRoute: typeof ApplicationImport
    }
    '/_application/admin/categorization/': {
      id: '/_application/admin/categorization/'
      path: '/admin/categorization'
      fullPath: '/admin/categorization'
      preLoaderRoute: typeof ApplicationAdminCategorizationIndexImport
      parentRoute: typeof ApplicationImport
    }
    '/_application/assets/domains/': {
      id: '/_application/assets/domains/'
      path: '/assets/domains'
      fullPath: '/assets/domains'
      preLoaderRoute: typeof ApplicationAssetsDomainsIndexImport
      parentRoute: typeof ApplicationImport
    }
    '/_application/assets/endpoints/': {
      id: '/_application/assets/endpoints/'
      path: '/assets/endpoints'
      fullPath: '/assets/endpoints'
      preLoaderRoute: typeof ApplicationAssetsEndpointsIndexImport
      parentRoute: typeof ApplicationImport
    }
    '/_application/assets/files/': {
      id: '/_application/assets/files/'
      path: '/assets/files'
      fullPath: '/assets/files'
      preLoaderRoute: typeof ApplicationAssetsFilesIndexImport
      parentRoute: typeof ApplicationImport
    }
    '/_application/assets/ips/': {
      id: '/_application/assets/ips/'
      path: '/assets/ips'
      fullPath: '/assets/ips'
      preLoaderRoute: typeof ApplicationAssetsIpsIndexImport
      parentRoute: typeof ApplicationImport
    }
    '/_application/assets/locations/': {
      id: '/_application/assets/locations/'
      path: '/assets/locations'
      fullPath: '/assets/locations'
      preLoaderRoute: typeof ApplicationAssetsLocationsIndexImport
      parentRoute: typeof ApplicationImport
    }
    '/_application/assets/processes/': {
      id: '/_application/assets/processes/'
      path: '/assets/processes'
      fullPath: '/assets/processes'
      preLoaderRoute: typeof ApplicationAssetsProcessesIndexImport
      parentRoute: typeof ApplicationImport
    }
    '/_application/assets/user-agents/': {
      id: '/_application/assets/user-agents/'
      path: '/assets/user-agents'
      fullPath: '/assets/user-agents'
      preLoaderRoute: typeof ApplicationAssetsUserAgentsIndexImport
      parentRoute: typeof ApplicationImport
    }
    '/_application/assets/users/': {
      id: '/_application/assets/users/'
      path: '/assets/users'
      fullPath: '/assets/users'
      preLoaderRoute: typeof ApplicationAssetsUsersIndexImport
      parentRoute: typeof ApplicationImport
    }
    '/_application/settings/integrations/': {
      id: '/_application/settings/integrations/'
      path: '/settings/integrations'
      fullPath: '/settings/integrations'
      preLoaderRoute: typeof ApplicationSettingsIntegrationsIndexImport
      parentRoute: typeof ApplicationImport
    }
    '/_application/settings/verdicts/': {
      id: '/_application/settings/verdicts/'
      path: '/settings/verdicts'
      fullPath: '/settings/verdicts'
      preLoaderRoute: typeof ApplicationSettingsVerdictsIndexImport
      parentRoute: typeof ApplicationImport
    }
    '/_application/admin/categorization/$integration_/$group': {
      id: '/_application/admin/categorization/$integration_/$group'
      path: '/admin/categorization/$integration/$group'
      fullPath: '/admin/categorization/$integration/$group'
      preLoaderRoute: typeof ApplicationAdminCategorizationIntegrationGroupImport
      parentRoute: typeof ApplicationImport
    }
    '/_application/settings/integrations/teams/finish': {
      id: '/_application/settings/integrations/teams/finish'
      path: '/settings/integrations/teams/finish'
      fullPath: '/settings/integrations/teams/finish'
      preLoaderRoute: typeof ApplicationSettingsIntegrationsTeamsFinishImport
      parentRoute: typeof ApplicationImport
    }
    '/_application/settings/verdicts/category/$category': {
      id: '/_application/settings/verdicts/category/$category'
      path: '/settings/verdicts/category/$category'
      fullPath: '/settings/verdicts/category/$category'
      preLoaderRoute: typeof ApplicationSettingsVerdictsCategoryCategoryImport
      parentRoute: typeof ApplicationImport
    }
    '/_application/settings/verdicts/class/$class': {
      id: '/_application/settings/verdicts/class/$class'
      path: '/settings/verdicts/class/$class'
      fullPath: '/settings/verdicts/class/$class'
      preLoaderRoute: typeof ApplicationSettingsVerdictsClassClassImport
      parentRoute: typeof ApplicationImport
    }
    '/_authentication/register_/$token_/mfa_/confirm': {
      id: '/_authentication/register_/$token_/mfa_/confirm'
      path: '/register/$token/mfa/confirm'
      fullPath: '/register/$token/mfa/confirm'
      preLoaderRoute: typeof AuthenticationRegisterTokenMfaConfirmImport
      parentRoute: typeof rootRoute
    }
  }
}

// Create and export the route tree

interface ApplicationRouteChildren {
  ApplicationClientsRoute: typeof ApplicationClientsRoute
  ApplicationDashboardRoute: typeof ApplicationDashboardRoute
  ApplicationSwitchRoute: typeof ApplicationSwitchRoute
  ApplicationAdminQueueNameRoute: typeof ApplicationAdminQueueNameRoute
  ApplicationAdminExtractionQueueRoute: typeof ApplicationAdminExtractionQueueRoute
  ApplicationAdminFileEnrichmentRoute: typeof ApplicationAdminFileEnrichmentRoute
  ApplicationAdminIntegrationsRoute: typeof ApplicationAdminIntegrationsRoute
  ApplicationAdminQueuesRoute: typeof ApplicationAdminQueuesRoute
  ApplicationAdminTeamRoute: typeof ApplicationAdminTeamRoute
  ApplicationAdminTimRoute: typeof ApplicationAdminTimRoute
  ApplicationCasesCaseIdRoute: typeof ApplicationCasesCaseIdRoute
  ApplicationSettingsAddNewClientRoute: typeof ApplicationSettingsAddNewClientRoute
  ApplicationSettingsAutomationRoute: typeof ApplicationSettingsAutomationRoute
  ApplicationSettingsChatOpsRoute: typeof ApplicationSettingsChatOpsRoute
  ApplicationSettingsContainmentRoute: typeof ApplicationSettingsContainmentRoute
  ApplicationSettingsNotificationsRoute: typeof ApplicationSettingsNotificationsRoute
  ApplicationSettingsProfileRoute: typeof ApplicationSettingsProfileRoute
  ApplicationSettingsSystemLogRoute: typeof ApplicationSettingsSystemLogRoute
  ApplicationSettingsTeamRoute: typeof ApplicationSettingsTeamRoute
  ApplicationCasesIndexRoute: typeof ApplicationCasesIndexRoute
  ApplicationEventsIndexRoute: typeof ApplicationEventsIndexRoute
  ApplicationAdminQueueNameJobIdRoute: typeof ApplicationAdminQueueNameJobIdRoute
  ApplicationAdminAqlBatchIdRoute: typeof ApplicationAdminAqlBatchIdRoute
  ApplicationAdminCategorizationIntegrationRoute: typeof ApplicationAdminCategorizationIntegrationRoute
  ApplicationAssetsDomainsDomainIdRoute: typeof ApplicationAssetsDomainsDomainIdRoute
  ApplicationAssetsEndpointsEndpointIdRoute: typeof ApplicationAssetsEndpointsEndpointIdRoute
  ApplicationAssetsFilesFileIdRoute: typeof ApplicationAssetsFilesFileIdRoute
  ApplicationAssetsIpsIpIdRoute: typeof ApplicationAssetsIpsIpIdRoute
  ApplicationAssetsLocationsLocationIdRoute: typeof ApplicationAssetsLocationsLocationIdRoute
  ApplicationAssetsProcessesProcessIdRoute: typeof ApplicationAssetsProcessesProcessIdRoute
  ApplicationAssetsUserAgentsUserAgentIdRoute: typeof ApplicationAssetsUserAgentsUserAgentIdRoute
  ApplicationAssetsUsersUserIdRoute: typeof ApplicationAssetsUsersUserIdRoute
  ApplicationSettingsIntegrationsIntegrationIdRoute: typeof ApplicationSettingsIntegrationsIntegrationIdRoute
  ApplicationAdminAqlIndexRoute: typeof ApplicationAdminAqlIndexRoute
  ApplicationAdminCategorizationIndexRoute: typeof ApplicationAdminCategorizationIndexRoute
  ApplicationAssetsDomainsIndexRoute: typeof ApplicationAssetsDomainsIndexRoute
  ApplicationAssetsEndpointsIndexRoute: typeof ApplicationAssetsEndpointsIndexRoute
  ApplicationAssetsFilesIndexRoute: typeof ApplicationAssetsFilesIndexRoute
  ApplicationAssetsIpsIndexRoute: typeof ApplicationAssetsIpsIndexRoute
  ApplicationAssetsLocationsIndexRoute: typeof ApplicationAssetsLocationsIndexRoute
  ApplicationAssetsProcessesIndexRoute: typeof ApplicationAssetsProcessesIndexRoute
  ApplicationAssetsUserAgentsIndexRoute: typeof ApplicationAssetsUserAgentsIndexRoute
  ApplicationAssetsUsersIndexRoute: typeof ApplicationAssetsUsersIndexRoute
  ApplicationSettingsIntegrationsIndexRoute: typeof ApplicationSettingsIntegrationsIndexRoute
  ApplicationSettingsVerdictsIndexRoute: typeof ApplicationSettingsVerdictsIndexRoute
  ApplicationAdminCategorizationIntegrationGroupRoute: typeof ApplicationAdminCategorizationIntegrationGroupRoute
  ApplicationSettingsIntegrationsTeamsFinishRoute: typeof ApplicationSettingsIntegrationsTeamsFinishRoute
  ApplicationSettingsVerdictsCategoryCategoryRoute: typeof ApplicationSettingsVerdictsCategoryCategoryRoute
  ApplicationSettingsVerdictsClassClassRoute: typeof ApplicationSettingsVerdictsClassClassRoute
}

const ApplicationRouteChildren: ApplicationRouteChildren = {
  ApplicationClientsRoute: ApplicationClientsRoute,
  ApplicationDashboardRoute: ApplicationDashboardRoute,
  ApplicationSwitchRoute: ApplicationSwitchRoute,
  ApplicationAdminQueueNameRoute: ApplicationAdminQueueNameRoute,
  ApplicationAdminExtractionQueueRoute: ApplicationAdminExtractionQueueRoute,
  ApplicationAdminFileEnrichmentRoute: ApplicationAdminFileEnrichmentRoute,
  ApplicationAdminIntegrationsRoute: ApplicationAdminIntegrationsRoute,
  ApplicationAdminQueuesRoute: ApplicationAdminQueuesRoute,
  ApplicationAdminTeamRoute: ApplicationAdminTeamRoute,
  ApplicationAdminTimRoute: ApplicationAdminTimRoute,
  ApplicationCasesCaseIdRoute: ApplicationCasesCaseIdRoute,
  ApplicationSettingsAddNewClientRoute: ApplicationSettingsAddNewClientRoute,
  ApplicationSettingsAutomationRoute: ApplicationSettingsAutomationRoute,
  ApplicationSettingsChatOpsRoute: ApplicationSettingsChatOpsRoute,
  ApplicationSettingsContainmentRoute: ApplicationSettingsContainmentRoute,
  ApplicationSettingsNotificationsRoute: ApplicationSettingsNotificationsRoute,
  ApplicationSettingsProfileRoute: ApplicationSettingsProfileRoute,
  ApplicationSettingsSystemLogRoute: ApplicationSettingsSystemLogRoute,
  ApplicationSettingsTeamRoute: ApplicationSettingsTeamRoute,
  ApplicationCasesIndexRoute: ApplicationCasesIndexRoute,
  ApplicationEventsIndexRoute: ApplicationEventsIndexRoute,
  ApplicationAdminQueueNameJobIdRoute: ApplicationAdminQueueNameJobIdRoute,
  ApplicationAdminAqlBatchIdRoute: ApplicationAdminAqlBatchIdRoute,
  ApplicationAdminCategorizationIntegrationRoute:
    ApplicationAdminCategorizationIntegrationRoute,
  ApplicationAssetsDomainsDomainIdRoute: ApplicationAssetsDomainsDomainIdRoute,
  ApplicationAssetsEndpointsEndpointIdRoute:
    ApplicationAssetsEndpointsEndpointIdRoute,
  ApplicationAssetsFilesFileIdRoute: ApplicationAssetsFilesFileIdRoute,
  ApplicationAssetsIpsIpIdRoute: ApplicationAssetsIpsIpIdRoute,
  ApplicationAssetsLocationsLocationIdRoute:
    ApplicationAssetsLocationsLocationIdRoute,
  ApplicationAssetsProcessesProcessIdRoute:
    ApplicationAssetsProcessesProcessIdRoute,
  ApplicationAssetsUserAgentsUserAgentIdRoute:
    ApplicationAssetsUserAgentsUserAgentIdRoute,
  ApplicationAssetsUsersUserIdRoute: ApplicationAssetsUsersUserIdRoute,
  ApplicationSettingsIntegrationsIntegrationIdRoute:
    ApplicationSettingsIntegrationsIntegrationIdRoute,
  ApplicationAdminAqlIndexRoute: ApplicationAdminAqlIndexRoute,
  ApplicationAdminCategorizationIndexRoute:
    ApplicationAdminCategorizationIndexRoute,
  ApplicationAssetsDomainsIndexRoute: ApplicationAssetsDomainsIndexRoute,
  ApplicationAssetsEndpointsIndexRoute: ApplicationAssetsEndpointsIndexRoute,
  ApplicationAssetsFilesIndexRoute: ApplicationAssetsFilesIndexRoute,
  ApplicationAssetsIpsIndexRoute: ApplicationAssetsIpsIndexRoute,
  ApplicationAssetsLocationsIndexRoute: ApplicationAssetsLocationsIndexRoute,
  ApplicationAssetsProcessesIndexRoute: ApplicationAssetsProcessesIndexRoute,
  ApplicationAssetsUserAgentsIndexRoute: ApplicationAssetsUserAgentsIndexRoute,
  ApplicationAssetsUsersIndexRoute: ApplicationAssetsUsersIndexRoute,
  ApplicationSettingsIntegrationsIndexRoute:
    ApplicationSettingsIntegrationsIndexRoute,
  ApplicationSettingsVerdictsIndexRoute: ApplicationSettingsVerdictsIndexRoute,
  ApplicationAdminCategorizationIntegrationGroupRoute:
    ApplicationAdminCategorizationIntegrationGroupRoute,
  ApplicationSettingsIntegrationsTeamsFinishRoute:
    ApplicationSettingsIntegrationsTeamsFinishRoute,
  ApplicationSettingsVerdictsCategoryCategoryRoute:
    ApplicationSettingsVerdictsCategoryCategoryRoute,
  ApplicationSettingsVerdictsClassClassRoute:
    ApplicationSettingsVerdictsClassClassRoute,
}

const ApplicationRouteWithChildren = ApplicationRoute._addFileChildren(
  ApplicationRouteChildren,
)

interface OnboardingRouteChildren {
  OnboardingSpOnboardingStepRoute: typeof OnboardingSpOnboardingStepRoute
  OnboardingUserOnboardingStepRoute: typeof OnboardingUserOnboardingStepRoute
}

const OnboardingRouteChildren: OnboardingRouteChildren = {
  OnboardingSpOnboardingStepRoute: OnboardingSpOnboardingStepRoute,
  OnboardingUserOnboardingStepRoute: OnboardingUserOnboardingStepRoute,
}

const OnboardingRouteWithChildren = OnboardingRoute._addFileChildren(
  OnboardingRouteChildren,
)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '': typeof OnboardingRouteWithChildren
  '/chat-ops': typeof ChatOpsRoute
  '/clients': typeof ApplicationClientsRoute
  '/dashboard': typeof ApplicationDashboardRoute
  '/switch': typeof ApplicationSwitchRoute
  '/forgot': typeof AuthenticationForgotRoute
  '/login': typeof AuthenticationLoginRoute
  '/detections/$detectionId': typeof DetectionsDetectionIdRoute
  '/admin/$queueName': typeof ApplicationAdminQueueNameRoute
  '/admin/extraction-queue': typeof ApplicationAdminExtractionQueueRoute
  '/admin/file-enrichment': typeof ApplicationAdminFileEnrichmentRoute
  '/admin/integrations': typeof ApplicationAdminIntegrationsRoute
  '/admin/queues': typeof ApplicationAdminQueuesRoute
  '/admin/team': typeof ApplicationAdminTeamRoute
  '/admin/tim': typeof ApplicationAdminTimRoute
  '/cases/$caseId': typeof ApplicationCasesCaseIdRoute
  '/settings/add-new-client': typeof ApplicationSettingsAddNewClientRoute
  '/settings/automation': typeof ApplicationSettingsAutomationRoute
  '/settings/chat-ops': typeof ApplicationSettingsChatOpsRoute
  '/settings/containment': typeof ApplicationSettingsContainmentRoute
  '/settings/notifications': typeof ApplicationSettingsNotificationsRoute
  '/settings/profile': typeof ApplicationSettingsProfileRoute
  '/settings/system-log': typeof ApplicationSettingsSystemLogRoute
  '/settings/team': typeof ApplicationSettingsTeamRoute
  '/forgot/$token': typeof AuthenticationForgotTokenRoute
  '/register/$token': typeof AuthenticationRegisterTokenRoute
  '/sp-onboarding/$step': typeof OnboardingSpOnboardingStepRoute
  '/user-onboarding/$step': typeof OnboardingUserOnboardingStepRoute
  '/cases': typeof ApplicationCasesIndexRoute
  '/events': typeof ApplicationEventsIndexRoute
  '/admin/$queueName/$jobId': typeof ApplicationAdminQueueNameJobIdRoute
  '/admin/aql/$batchId': typeof ApplicationAdminAqlBatchIdRoute
  '/admin/categorization/$integration': typeof ApplicationAdminCategorizationIntegrationRoute
  '/assets/domains/$domainId': typeof ApplicationAssetsDomainsDomainIdRoute
  '/assets/endpoints/$endpointId': typeof ApplicationAssetsEndpointsEndpointIdRoute
  '/assets/files/$fileId': typeof ApplicationAssetsFilesFileIdRoute
  '/assets/ips/$ipId': typeof ApplicationAssetsIpsIpIdRoute
  '/assets/locations/$locationId': typeof ApplicationAssetsLocationsLocationIdRoute
  '/assets/processes/$processId': typeof ApplicationAssetsProcessesProcessIdRoute
  '/assets/user-agents/$userAgentId': typeof ApplicationAssetsUserAgentsUserAgentIdRoute
  '/assets/users/$userId': typeof ApplicationAssetsUsersUserIdRoute
  '/settings/integrations/$integrationId': typeof ApplicationSettingsIntegrationsIntegrationIdRoute
  '/register/$token/mfa': typeof AuthenticationRegisterTokenMfaRoute
  '/admin/aql': typeof ApplicationAdminAqlIndexRoute
  '/admin/categorization': typeof ApplicationAdminCategorizationIndexRoute
  '/assets/domains': typeof ApplicationAssetsDomainsIndexRoute
  '/assets/endpoints': typeof ApplicationAssetsEndpointsIndexRoute
  '/assets/files': typeof ApplicationAssetsFilesIndexRoute
  '/assets/ips': typeof ApplicationAssetsIpsIndexRoute
  '/assets/locations': typeof ApplicationAssetsLocationsIndexRoute
  '/assets/processes': typeof ApplicationAssetsProcessesIndexRoute
  '/assets/user-agents': typeof ApplicationAssetsUserAgentsIndexRoute
  '/assets/users': typeof ApplicationAssetsUsersIndexRoute
  '/settings/integrations': typeof ApplicationSettingsIntegrationsIndexRoute
  '/settings/verdicts': typeof ApplicationSettingsVerdictsIndexRoute
  '/admin/categorization/$integration/$group': typeof ApplicationAdminCategorizationIntegrationGroupRoute
  '/settings/integrations/teams/finish': typeof ApplicationSettingsIntegrationsTeamsFinishRoute
  '/settings/verdicts/category/$category': typeof ApplicationSettingsVerdictsCategoryCategoryRoute
  '/settings/verdicts/class/$class': typeof ApplicationSettingsVerdictsClassClassRoute
  '/register/$token/mfa/confirm': typeof AuthenticationRegisterTokenMfaConfirmRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '': typeof OnboardingRouteWithChildren
  '/chat-ops': typeof ChatOpsRoute
  '/clients': typeof ApplicationClientsRoute
  '/dashboard': typeof ApplicationDashboardRoute
  '/switch': typeof ApplicationSwitchRoute
  '/forgot': typeof AuthenticationForgotRoute
  '/login': typeof AuthenticationLoginRoute
  '/detections/$detectionId': typeof DetectionsDetectionIdRoute
  '/admin/$queueName': typeof ApplicationAdminQueueNameRoute
  '/admin/extraction-queue': typeof ApplicationAdminExtractionQueueRoute
  '/admin/file-enrichment': typeof ApplicationAdminFileEnrichmentRoute
  '/admin/integrations': typeof ApplicationAdminIntegrationsRoute
  '/admin/queues': typeof ApplicationAdminQueuesRoute
  '/admin/team': typeof ApplicationAdminTeamRoute
  '/admin/tim': typeof ApplicationAdminTimRoute
  '/cases/$caseId': typeof ApplicationCasesCaseIdRoute
  '/settings/add-new-client': typeof ApplicationSettingsAddNewClientRoute
  '/settings/automation': typeof ApplicationSettingsAutomationRoute
  '/settings/chat-ops': typeof ApplicationSettingsChatOpsRoute
  '/settings/containment': typeof ApplicationSettingsContainmentRoute
  '/settings/notifications': typeof ApplicationSettingsNotificationsRoute
  '/settings/profile': typeof ApplicationSettingsProfileRoute
  '/settings/system-log': typeof ApplicationSettingsSystemLogRoute
  '/settings/team': typeof ApplicationSettingsTeamRoute
  '/forgot/$token': typeof AuthenticationForgotTokenRoute
  '/register/$token': typeof AuthenticationRegisterTokenRoute
  '/sp-onboarding/$step': typeof OnboardingSpOnboardingStepRoute
  '/user-onboarding/$step': typeof OnboardingUserOnboardingStepRoute
  '/cases': typeof ApplicationCasesIndexRoute
  '/events': typeof ApplicationEventsIndexRoute
  '/admin/$queueName/$jobId': typeof ApplicationAdminQueueNameJobIdRoute
  '/admin/aql/$batchId': typeof ApplicationAdminAqlBatchIdRoute
  '/admin/categorization/$integration': typeof ApplicationAdminCategorizationIntegrationRoute
  '/assets/domains/$domainId': typeof ApplicationAssetsDomainsDomainIdRoute
  '/assets/endpoints/$endpointId': typeof ApplicationAssetsEndpointsEndpointIdRoute
  '/assets/files/$fileId': typeof ApplicationAssetsFilesFileIdRoute
  '/assets/ips/$ipId': typeof ApplicationAssetsIpsIpIdRoute
  '/assets/locations/$locationId': typeof ApplicationAssetsLocationsLocationIdRoute
  '/assets/processes/$processId': typeof ApplicationAssetsProcessesProcessIdRoute
  '/assets/user-agents/$userAgentId': typeof ApplicationAssetsUserAgentsUserAgentIdRoute
  '/assets/users/$userId': typeof ApplicationAssetsUsersUserIdRoute
  '/settings/integrations/$integrationId': typeof ApplicationSettingsIntegrationsIntegrationIdRoute
  '/register/$token/mfa': typeof AuthenticationRegisterTokenMfaRoute
  '/admin/aql': typeof ApplicationAdminAqlIndexRoute
  '/admin/categorization': typeof ApplicationAdminCategorizationIndexRoute
  '/assets/domains': typeof ApplicationAssetsDomainsIndexRoute
  '/assets/endpoints': typeof ApplicationAssetsEndpointsIndexRoute
  '/assets/files': typeof ApplicationAssetsFilesIndexRoute
  '/assets/ips': typeof ApplicationAssetsIpsIndexRoute
  '/assets/locations': typeof ApplicationAssetsLocationsIndexRoute
  '/assets/processes': typeof ApplicationAssetsProcessesIndexRoute
  '/assets/user-agents': typeof ApplicationAssetsUserAgentsIndexRoute
  '/assets/users': typeof ApplicationAssetsUsersIndexRoute
  '/settings/integrations': typeof ApplicationSettingsIntegrationsIndexRoute
  '/settings/verdicts': typeof ApplicationSettingsVerdictsIndexRoute
  '/admin/categorization/$integration/$group': typeof ApplicationAdminCategorizationIntegrationGroupRoute
  '/settings/integrations/teams/finish': typeof ApplicationSettingsIntegrationsTeamsFinishRoute
  '/settings/verdicts/category/$category': typeof ApplicationSettingsVerdictsCategoryCategoryRoute
  '/settings/verdicts/class/$class': typeof ApplicationSettingsVerdictsClassClassRoute
  '/register/$token/mfa/confirm': typeof AuthenticationRegisterTokenMfaConfirmRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/_application': typeof ApplicationRouteWithChildren
  '/_onboarding': typeof OnboardingRouteWithChildren
  '/chat-ops': typeof ChatOpsRoute
  '/_application/clients': typeof ApplicationClientsRoute
  '/_application/dashboard': typeof ApplicationDashboardRoute
  '/_application/switch': typeof ApplicationSwitchRoute
  '/_authentication/forgot': typeof AuthenticationForgotRoute
  '/_authentication/login': typeof AuthenticationLoginRoute
  '/detections/$detectionId': typeof DetectionsDetectionIdRoute
  '/_application/admin/$queueName': typeof ApplicationAdminQueueNameRoute
  '/_application/admin/extraction-queue': typeof ApplicationAdminExtractionQueueRoute
  '/_application/admin/file-enrichment': typeof ApplicationAdminFileEnrichmentRoute
  '/_application/admin/integrations': typeof ApplicationAdminIntegrationsRoute
  '/_application/admin/queues': typeof ApplicationAdminQueuesRoute
  '/_application/admin/team': typeof ApplicationAdminTeamRoute
  '/_application/admin/tim': typeof ApplicationAdminTimRoute
  '/_application/cases/$caseId': typeof ApplicationCasesCaseIdRoute
  '/_application/settings/add-new-client': typeof ApplicationSettingsAddNewClientRoute
  '/_application/settings/automation': typeof ApplicationSettingsAutomationRoute
  '/_application/settings/chat-ops': typeof ApplicationSettingsChatOpsRoute
  '/_application/settings/containment': typeof ApplicationSettingsContainmentRoute
  '/_application/settings/notifications': typeof ApplicationSettingsNotificationsRoute
  '/_application/settings/profile': typeof ApplicationSettingsProfileRoute
  '/_application/settings/system-log': typeof ApplicationSettingsSystemLogRoute
  '/_application/settings/team': typeof ApplicationSettingsTeamRoute
  '/_authentication/forgot_/$token': typeof AuthenticationForgotTokenRoute
  '/_authentication/register_/$token': typeof AuthenticationRegisterTokenRoute
  '/_onboarding/sp-onboarding/$step': typeof OnboardingSpOnboardingStepRoute
  '/_onboarding/user-onboarding/$step': typeof OnboardingUserOnboardingStepRoute
  '/_application/cases/': typeof ApplicationCasesIndexRoute
  '/_application/events/': typeof ApplicationEventsIndexRoute
  '/_application/admin/$queueName_/$jobId': typeof ApplicationAdminQueueNameJobIdRoute
  '/_application/admin/aql/$batchId': typeof ApplicationAdminAqlBatchIdRoute
  '/_application/admin/categorization/$integration': typeof ApplicationAdminCategorizationIntegrationRoute
  '/_application/assets/domains/$domainId': typeof ApplicationAssetsDomainsDomainIdRoute
  '/_application/assets/endpoints/$endpointId': typeof ApplicationAssetsEndpointsEndpointIdRoute
  '/_application/assets/files/$fileId': typeof ApplicationAssetsFilesFileIdRoute
  '/_application/assets/ips/$ipId': typeof ApplicationAssetsIpsIpIdRoute
  '/_application/assets/locations/$locationId': typeof ApplicationAssetsLocationsLocationIdRoute
  '/_application/assets/processes/$processId': typeof ApplicationAssetsProcessesProcessIdRoute
  '/_application/assets/user-agents/$userAgentId': typeof ApplicationAssetsUserAgentsUserAgentIdRoute
  '/_application/assets/users/$userId': typeof ApplicationAssetsUsersUserIdRoute
  '/_application/settings/integrations/$integrationId': typeof ApplicationSettingsIntegrationsIntegrationIdRoute
  '/_authentication/register_/$token_/mfa': typeof AuthenticationRegisterTokenMfaRoute
  '/_application/admin/aql/': typeof ApplicationAdminAqlIndexRoute
  '/_application/admin/categorization/': typeof ApplicationAdminCategorizationIndexRoute
  '/_application/assets/domains/': typeof ApplicationAssetsDomainsIndexRoute
  '/_application/assets/endpoints/': typeof ApplicationAssetsEndpointsIndexRoute
  '/_application/assets/files/': typeof ApplicationAssetsFilesIndexRoute
  '/_application/assets/ips/': typeof ApplicationAssetsIpsIndexRoute
  '/_application/assets/locations/': typeof ApplicationAssetsLocationsIndexRoute
  '/_application/assets/processes/': typeof ApplicationAssetsProcessesIndexRoute
  '/_application/assets/user-agents/': typeof ApplicationAssetsUserAgentsIndexRoute
  '/_application/assets/users/': typeof ApplicationAssetsUsersIndexRoute
  '/_application/settings/integrations/': typeof ApplicationSettingsIntegrationsIndexRoute
  '/_application/settings/verdicts/': typeof ApplicationSettingsVerdictsIndexRoute
  '/_application/admin/categorization/$integration_/$group': typeof ApplicationAdminCategorizationIntegrationGroupRoute
  '/_application/settings/integrations/teams/finish': typeof ApplicationSettingsIntegrationsTeamsFinishRoute
  '/_application/settings/verdicts/category/$category': typeof ApplicationSettingsVerdictsCategoryCategoryRoute
  '/_application/settings/verdicts/class/$class': typeof ApplicationSettingsVerdictsClassClassRoute
  '/_authentication/register_/$token_/mfa_/confirm': typeof AuthenticationRegisterTokenMfaConfirmRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | ''
    | '/chat-ops'
    | '/clients'
    | '/dashboard'
    | '/switch'
    | '/forgot'
    | '/login'
    | '/detections/$detectionId'
    | '/admin/$queueName'
    | '/admin/extraction-queue'
    | '/admin/file-enrichment'
    | '/admin/integrations'
    | '/admin/queues'
    | '/admin/team'
    | '/admin/tim'
    | '/cases/$caseId'
    | '/settings/add-new-client'
    | '/settings/automation'
    | '/settings/chat-ops'
    | '/settings/containment'
    | '/settings/notifications'
    | '/settings/profile'
    | '/settings/system-log'
    | '/settings/team'
    | '/forgot/$token'
    | '/register/$token'
    | '/sp-onboarding/$step'
    | '/user-onboarding/$step'
    | '/cases'
    | '/events'
    | '/admin/$queueName/$jobId'
    | '/admin/aql/$batchId'
    | '/admin/categorization/$integration'
    | '/assets/domains/$domainId'
    | '/assets/endpoints/$endpointId'
    | '/assets/files/$fileId'
    | '/assets/ips/$ipId'
    | '/assets/locations/$locationId'
    | '/assets/processes/$processId'
    | '/assets/user-agents/$userAgentId'
    | '/assets/users/$userId'
    | '/settings/integrations/$integrationId'
    | '/register/$token/mfa'
    | '/admin/aql'
    | '/admin/categorization'
    | '/assets/domains'
    | '/assets/endpoints'
    | '/assets/files'
    | '/assets/ips'
    | '/assets/locations'
    | '/assets/processes'
    | '/assets/user-agents'
    | '/assets/users'
    | '/settings/integrations'
    | '/settings/verdicts'
    | '/admin/categorization/$integration/$group'
    | '/settings/integrations/teams/finish'
    | '/settings/verdicts/category/$category'
    | '/settings/verdicts/class/$class'
    | '/register/$token/mfa/confirm'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | ''
    | '/chat-ops'
    | '/clients'
    | '/dashboard'
    | '/switch'
    | '/forgot'
    | '/login'
    | '/detections/$detectionId'
    | '/admin/$queueName'
    | '/admin/extraction-queue'
    | '/admin/file-enrichment'
    | '/admin/integrations'
    | '/admin/queues'
    | '/admin/team'
    | '/admin/tim'
    | '/cases/$caseId'
    | '/settings/add-new-client'
    | '/settings/automation'
    | '/settings/chat-ops'
    | '/settings/containment'
    | '/settings/notifications'
    | '/settings/profile'
    | '/settings/system-log'
    | '/settings/team'
    | '/forgot/$token'
    | '/register/$token'
    | '/sp-onboarding/$step'
    | '/user-onboarding/$step'
    | '/cases'
    | '/events'
    | '/admin/$queueName/$jobId'
    | '/admin/aql/$batchId'
    | '/admin/categorization/$integration'
    | '/assets/domains/$domainId'
    | '/assets/endpoints/$endpointId'
    | '/assets/files/$fileId'
    | '/assets/ips/$ipId'
    | '/assets/locations/$locationId'
    | '/assets/processes/$processId'
    | '/assets/user-agents/$userAgentId'
    | '/assets/users/$userId'
    | '/settings/integrations/$integrationId'
    | '/register/$token/mfa'
    | '/admin/aql'
    | '/admin/categorization'
    | '/assets/domains'
    | '/assets/endpoints'
    | '/assets/files'
    | '/assets/ips'
    | '/assets/locations'
    | '/assets/processes'
    | '/assets/user-agents'
    | '/assets/users'
    | '/settings/integrations'
    | '/settings/verdicts'
    | '/admin/categorization/$integration/$group'
    | '/settings/integrations/teams/finish'
    | '/settings/verdicts/category/$category'
    | '/settings/verdicts/class/$class'
    | '/register/$token/mfa/confirm'
  id:
    | '__root__'
    | '/'
    | '/_application'
    | '/_onboarding'
    | '/chat-ops'
    | '/_application/clients'
    | '/_application/dashboard'
    | '/_application/switch'
    | '/_authentication/forgot'
    | '/_authentication/login'
    | '/detections/$detectionId'
    | '/_application/admin/$queueName'
    | '/_application/admin/extraction-queue'
    | '/_application/admin/file-enrichment'
    | '/_application/admin/integrations'
    | '/_application/admin/queues'
    | '/_application/admin/team'
    | '/_application/admin/tim'
    | '/_application/cases/$caseId'
    | '/_application/settings/add-new-client'
    | '/_application/settings/automation'
    | '/_application/settings/chat-ops'
    | '/_application/settings/containment'
    | '/_application/settings/notifications'
    | '/_application/settings/profile'
    | '/_application/settings/system-log'
    | '/_application/settings/team'
    | '/_authentication/forgot_/$token'
    | '/_authentication/register_/$token'
    | '/_onboarding/sp-onboarding/$step'
    | '/_onboarding/user-onboarding/$step'
    | '/_application/cases/'
    | '/_application/events/'
    | '/_application/admin/$queueName_/$jobId'
    | '/_application/admin/aql/$batchId'
    | '/_application/admin/categorization/$integration'
    | '/_application/assets/domains/$domainId'
    | '/_application/assets/endpoints/$endpointId'
    | '/_application/assets/files/$fileId'
    | '/_application/assets/ips/$ipId'
    | '/_application/assets/locations/$locationId'
    | '/_application/assets/processes/$processId'
    | '/_application/assets/user-agents/$userAgentId'
    | '/_application/assets/users/$userId'
    | '/_application/settings/integrations/$integrationId'
    | '/_authentication/register_/$token_/mfa'
    | '/_application/admin/aql/'
    | '/_application/admin/categorization/'
    | '/_application/assets/domains/'
    | '/_application/assets/endpoints/'
    | '/_application/assets/files/'
    | '/_application/assets/ips/'
    | '/_application/assets/locations/'
    | '/_application/assets/processes/'
    | '/_application/assets/user-agents/'
    | '/_application/assets/users/'
    | '/_application/settings/integrations/'
    | '/_application/settings/verdicts/'
    | '/_application/admin/categorization/$integration_/$group'
    | '/_application/settings/integrations/teams/finish'
    | '/_application/settings/verdicts/category/$category'
    | '/_application/settings/verdicts/class/$class'
    | '/_authentication/register_/$token_/mfa_/confirm'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  ApplicationRoute: typeof ApplicationRouteWithChildren
  OnboardingRoute: typeof OnboardingRouteWithChildren
  ChatOpsRoute: typeof ChatOpsRoute
  AuthenticationForgotRoute: typeof AuthenticationForgotRoute
  AuthenticationLoginRoute: typeof AuthenticationLoginRoute
  DetectionsDetectionIdRoute: typeof DetectionsDetectionIdRoute
  AuthenticationForgotTokenRoute: typeof AuthenticationForgotTokenRoute
  AuthenticationRegisterTokenRoute: typeof AuthenticationRegisterTokenRoute
  AuthenticationRegisterTokenMfaRoute: typeof AuthenticationRegisterTokenMfaRoute
  AuthenticationRegisterTokenMfaConfirmRoute: typeof AuthenticationRegisterTokenMfaConfirmRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  ApplicationRoute: ApplicationRouteWithChildren,
  OnboardingRoute: OnboardingRouteWithChildren,
  ChatOpsRoute: ChatOpsRoute,
  AuthenticationForgotRoute: AuthenticationForgotRoute,
  AuthenticationLoginRoute: AuthenticationLoginRoute,
  DetectionsDetectionIdRoute: DetectionsDetectionIdRoute,
  AuthenticationForgotTokenRoute: AuthenticationForgotTokenRoute,
  AuthenticationRegisterTokenRoute: AuthenticationRegisterTokenRoute,
  AuthenticationRegisterTokenMfaRoute: AuthenticationRegisterTokenMfaRoute,
  AuthenticationRegisterTokenMfaConfirmRoute:
    AuthenticationRegisterTokenMfaConfirmRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_application",
        "/_onboarding",
        "/chat-ops",
        "/_authentication/forgot",
        "/_authentication/login",
        "/detections/$detectionId",
        "/_authentication/forgot_/$token",
        "/_authentication/register_/$token",
        "/_authentication/register_/$token_/mfa",
        "/_authentication/register_/$token_/mfa_/confirm"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/_application": {
      "filePath": "_application.tsx",
      "children": [
        "/_application/clients",
        "/_application/dashboard",
        "/_application/switch",
        "/_application/admin/$queueName",
        "/_application/admin/extraction-queue",
        "/_application/admin/file-enrichment",
        "/_application/admin/integrations",
        "/_application/admin/queues",
        "/_application/admin/team",
        "/_application/admin/tim",
        "/_application/cases/$caseId",
        "/_application/settings/add-new-client",
        "/_application/settings/automation",
        "/_application/settings/chat-ops",
        "/_application/settings/containment",
        "/_application/settings/notifications",
        "/_application/settings/profile",
        "/_application/settings/system-log",
        "/_application/settings/team",
        "/_application/cases/",
        "/_application/events/",
        "/_application/admin/$queueName_/$jobId",
        "/_application/admin/aql/$batchId",
        "/_application/admin/categorization/$integration",
        "/_application/assets/domains/$domainId",
        "/_application/assets/endpoints/$endpointId",
        "/_application/assets/files/$fileId",
        "/_application/assets/ips/$ipId",
        "/_application/assets/locations/$locationId",
        "/_application/assets/processes/$processId",
        "/_application/assets/user-agents/$userAgentId",
        "/_application/assets/users/$userId",
        "/_application/settings/integrations/$integrationId",
        "/_application/admin/aql/",
        "/_application/admin/categorization/",
        "/_application/assets/domains/",
        "/_application/assets/endpoints/",
        "/_application/assets/files/",
        "/_application/assets/ips/",
        "/_application/assets/locations/",
        "/_application/assets/processes/",
        "/_application/assets/user-agents/",
        "/_application/assets/users/",
        "/_application/settings/integrations/",
        "/_application/settings/verdicts/",
        "/_application/admin/categorization/$integration_/$group",
        "/_application/settings/integrations/teams/finish",
        "/_application/settings/verdicts/category/$category",
        "/_application/settings/verdicts/class/$class"
      ]
    },
    "/_onboarding": {
      "filePath": "_onboarding.tsx",
      "children": [
        "/_onboarding/sp-onboarding/$step",
        "/_onboarding/user-onboarding/$step"
      ]
    },
    "/chat-ops": {
      "filePath": "chat-ops.tsx"
    },
    "/_application/clients": {
      "filePath": "_application/clients.tsx",
      "parent": "/_application"
    },
    "/_application/dashboard": {
      "filePath": "_application/dashboard.tsx",
      "parent": "/_application"
    },
    "/_application/switch": {
      "filePath": "_application/switch.tsx",
      "parent": "/_application"
    },
    "/_authentication/forgot": {
      "filePath": "_authentication/forgot.tsx"
    },
    "/_authentication/login": {
      "filePath": "_authentication/login.tsx"
    },
    "/detections/$detectionId": {
      "filePath": "detections/$detectionId.tsx"
    },
    "/_application/admin/$queueName": {
      "filePath": "_application/admin/$queueName.tsx",
      "parent": "/_application"
    },
    "/_application/admin/extraction-queue": {
      "filePath": "_application/admin/extraction-queue.tsx",
      "parent": "/_application"
    },
    "/_application/admin/file-enrichment": {
      "filePath": "_application/admin/file-enrichment.tsx",
      "parent": "/_application"
    },
    "/_application/admin/integrations": {
      "filePath": "_application/admin/integrations.tsx",
      "parent": "/_application"
    },
    "/_application/admin/queues": {
      "filePath": "_application/admin/queues.tsx",
      "parent": "/_application"
    },
    "/_application/admin/team": {
      "filePath": "_application/admin/team.tsx",
      "parent": "/_application"
    },
    "/_application/admin/tim": {
      "filePath": "_application/admin/tim.tsx",
      "parent": "/_application"
    },
    "/_application/cases/$caseId": {
      "filePath": "_application/cases/$caseId.tsx",
      "parent": "/_application"
    },
    "/_application/settings/add-new-client": {
      "filePath": "_application/settings/add-new-client.tsx",
      "parent": "/_application"
    },
    "/_application/settings/automation": {
      "filePath": "_application/settings/automation.tsx",
      "parent": "/_application"
    },
    "/_application/settings/chat-ops": {
      "filePath": "_application/settings/chat-ops.tsx",
      "parent": "/_application"
    },
    "/_application/settings/containment": {
      "filePath": "_application/settings/containment.tsx",
      "parent": "/_application"
    },
    "/_application/settings/notifications": {
      "filePath": "_application/settings/notifications.tsx",
      "parent": "/_application"
    },
    "/_application/settings/profile": {
      "filePath": "_application/settings/profile.tsx",
      "parent": "/_application"
    },
    "/_application/settings/system-log": {
      "filePath": "_application/settings/system-log.tsx",
      "parent": "/_application"
    },
    "/_application/settings/team": {
      "filePath": "_application/settings/team.tsx",
      "parent": "/_application"
    },
    "/_authentication/forgot_/$token": {
      "filePath": "_authentication/forgot_.$token.tsx"
    },
    "/_authentication/register_/$token": {
      "filePath": "_authentication/register_.$token.tsx"
    },
    "/_onboarding/sp-onboarding/$step": {
      "filePath": "_onboarding/sp-onboarding.$step.tsx",
      "parent": "/_onboarding"
    },
    "/_onboarding/user-onboarding/$step": {
      "filePath": "_onboarding/user-onboarding.$step.tsx",
      "parent": "/_onboarding"
    },
    "/_application/cases/": {
      "filePath": "_application/cases/index.tsx",
      "parent": "/_application"
    },
    "/_application/events/": {
      "filePath": "_application/events/index.tsx",
      "parent": "/_application"
    },
    "/_application/admin/$queueName_/$jobId": {
      "filePath": "_application/admin/$queueName_.$jobId.tsx",
      "parent": "/_application"
    },
    "/_application/admin/aql/$batchId": {
      "filePath": "_application/admin/aql/$batchId.tsx",
      "parent": "/_application"
    },
    "/_application/admin/categorization/$integration": {
      "filePath": "_application/admin/categorization/$integration.tsx",
      "parent": "/_application"
    },
    "/_application/assets/domains/$domainId": {
      "filePath": "_application/assets/domains/$domainId.tsx",
      "parent": "/_application"
    },
    "/_application/assets/endpoints/$endpointId": {
      "filePath": "_application/assets/endpoints/$endpointId.tsx",
      "parent": "/_application"
    },
    "/_application/assets/files/$fileId": {
      "filePath": "_application/assets/files/$fileId.tsx",
      "parent": "/_application"
    },
    "/_application/assets/ips/$ipId": {
      "filePath": "_application/assets/ips/$ipId.tsx",
      "parent": "/_application"
    },
    "/_application/assets/locations/$locationId": {
      "filePath": "_application/assets/locations/$locationId.tsx",
      "parent": "/_application"
    },
    "/_application/assets/processes/$processId": {
      "filePath": "_application/assets/processes/$processId.tsx",
      "parent": "/_application"
    },
    "/_application/assets/user-agents/$userAgentId": {
      "filePath": "_application/assets/user-agents/$userAgentId.tsx",
      "parent": "/_application"
    },
    "/_application/assets/users/$userId": {
      "filePath": "_application/assets/users/$userId.tsx",
      "parent": "/_application"
    },
    "/_application/settings/integrations/$integrationId": {
      "filePath": "_application/settings/integrations/$integrationId.tsx",
      "parent": "/_application"
    },
    "/_authentication/register_/$token_/mfa": {
      "filePath": "_authentication/register_.$token_.mfa.tsx"
    },
    "/_application/admin/aql/": {
      "filePath": "_application/admin/aql/index.tsx",
      "parent": "/_application"
    },
    "/_application/admin/categorization/": {
      "filePath": "_application/admin/categorization/index.tsx",
      "parent": "/_application"
    },
    "/_application/assets/domains/": {
      "filePath": "_application/assets/domains/index.tsx",
      "parent": "/_application"
    },
    "/_application/assets/endpoints/": {
      "filePath": "_application/assets/endpoints/index.tsx",
      "parent": "/_application"
    },
    "/_application/assets/files/": {
      "filePath": "_application/assets/files/index.tsx",
      "parent": "/_application"
    },
    "/_application/assets/ips/": {
      "filePath": "_application/assets/ips/index.tsx",
      "parent": "/_application"
    },
    "/_application/assets/locations/": {
      "filePath": "_application/assets/locations/index.tsx",
      "parent": "/_application"
    },
    "/_application/assets/processes/": {
      "filePath": "_application/assets/processes/index.tsx",
      "parent": "/_application"
    },
    "/_application/assets/user-agents/": {
      "filePath": "_application/assets/user-agents/index.tsx",
      "parent": "/_application"
    },
    "/_application/assets/users/": {
      "filePath": "_application/assets/users/index.tsx",
      "parent": "/_application"
    },
    "/_application/settings/integrations/": {
      "filePath": "_application/settings/integrations/index.tsx",
      "parent": "/_application"
    },
    "/_application/settings/verdicts/": {
      "filePath": "_application/settings/verdicts/index.tsx",
      "parent": "/_application"
    },
    "/_application/admin/categorization/$integration_/$group": {
      "filePath": "_application/admin/categorization/$integration_.$group.tsx",
      "parent": "/_application"
    },
    "/_application/settings/integrations/teams/finish": {
      "filePath": "_application/settings/integrations/teams/finish.tsx",
      "parent": "/_application"
    },
    "/_application/settings/verdicts/category/$category": {
      "filePath": "_application/settings/verdicts/category/$category.tsx",
      "parent": "/_application"
    },
    "/_application/settings/verdicts/class/$class": {
      "filePath": "_application/settings/verdicts/class/$class.tsx",
      "parent": "/_application"
    },
    "/_authentication/register_/$token_/mfa_/confirm": {
      "filePath": "_authentication/register_.$token_.mfa_.confirm.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
