import { Button } from '@/components/ui/button';
import { Card, CardContent, CardFooter } from '@/components/ui/card';
import Ellipsis from '@/components/ui/ellipsis';
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger
} from '@/components/ui/hover-card';
import {
  Pagination,
  PaginationContent,
  PaginationItem
} from '@/components/ui/pagination';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableHeader as UITableHeader
} from '@/components/ui/table';
import { components } from '@/lib/api.types';
import { localDateTime, useTimezone } from '@/lib/time';
import {
  cn,
  compareObjects,
  ellipsisTruncate,
  pluralize,
  useDebounce
} from '@/lib/utils';
import { WSPDURLParams } from '@/routes/_application';
import { CheckIcon, XMarkIcon } from '@heroicons/react/16/solid';
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  InformationCircleIcon
} from '@heroicons/react/24/outline';
import { UseQueryResult, UseSuspenseQueryResult } from '@tanstack/react-query';
import {
  NavigateOptions,
  useNavigate,
  useRouteContext,
  useRouter,
  useSearch
} from '@tanstack/react-router';
import { ROLE, userHasRole } from '@wire/shared';
import moment from 'moment';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { Checkbox } from './ui/checkbox';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from './ui/dialog';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger
} from './ui/dropdown-menu';
import { Input } from './ui/input';
import { Label } from './ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from './ui/select';
import { Skeleton } from './ui/skeleton';

interface RowAction<T> {
  name: string | ((row: T) => string);
  shouldDisplay?: (row: T) => boolean;
  onClick: (row: T) => Promise<void> | void | Promise<any>;
  disabled?: boolean;
  requiredRole?: ROLE;
  requiredSuperAdmin?: boolean;
  confirm?: boolean;
  confirmMessage?: string | ((row: T) => string);
}

interface TableHeader<T> {
  key: keyof T;
  display: string;
  info?: string | ReactNode;
  hiddenOnMobile?: boolean;
  truncate?: number;
  // Should we show the column value in a popover when hovered
  hover?: boolean;
  // Is this value a date time
  dateTime?: boolean;
  sortable?: boolean;
  format?: (value: any, row: T) => ReactNode | string;
}

export interface TableAction {
  display: string;
  showOnSearch?: boolean;
  requiredRole?: ROLE;
  onClick: (ids: string[], allSelected?: boolean) => Promise<void> | void;
}

interface SearchFilter {
  placeholder: string;
  defaultValue: string | null;
  label?: string;
  values: { key: string; display: string }[];
}

interface TableCardProps<T> {
  // Don't allow suspense query here, otherwise whenever it updates, it triggers the suspense and shows a loader
  query:
    | UseQueryResult<{ totalCount: number; data: readonly T[] }, Error>
    | UseSuspenseQueryResult<{ totalCount: number; data: readonly T[] }, Error>;
  onUpdate: (data: components['schemas']['PaginationDto']) => void;
  onClickNavigate?: (row: T) => NavigateOptions;
  onClick?: (row: T) => void;
  searchable?: boolean;
  defaultPage?: number;
  defaultSearch?: string;
  compact?: boolean;
  contentClassName?: string;
  footerClassName?: string;
  selectable?: boolean;
  className?: string;
  embedded?: boolean; // Hide the card styling
  searchFilter?: SearchFilter;
  tableActions?: TableAction[];
  rowActions?: RowAction<T>[];
  storeStateInUrl?: boolean;
  headers: (TableHeader<T> | undefined)[];
}

export function TableCard<T extends { id: string }>(
  props: React.PropsWithChildren<TableCardProps<T>>
) {
  const _urlSearch = useSearch({ from: '/_application' });
  const urlSearch: WSPDURLParams = useMemo(() => {
    if (props.storeStateInUrl) {
      return _urlSearch;
    } else {
      return {};
    }
  }, [_urlSearch, props.storeStateInUrl]);

  const [search, setSearch] = useState(urlSearch.search ?? props.defaultSearch);
  const { debounced: debouncedSearch } = useDebounce(250, search);

  const defaultTableState = useMemo(() => {
    return {
      page: urlSearch.page ?? props.defaultPage ?? 1,
      size: urlSearch.size ?? 20,
      orderBy: urlSearch.orderBy,
      orderDir: urlSearch.orderDir,
      filter: urlSearch.filter,
      search: debouncedSearch ?? urlSearch.search ?? props.defaultSearch
    };
  }, [urlSearch, debouncedSearch, props.defaultSearch, props.defaultPage]);

  const [tableState, _setTableState] = useState(defaultTableState);
  const debouncedTableState = useDebounce(50, tableState);
  const rowData = useMemo(() => {
    return props.query?.data?.data;
  }, [props.query?.data]);
  const navigate = useNavigate();
  const router = useRouter();
  const totalCount = useMemo(() => {
    return props.query.data?.totalCount ?? 0;
  }, [props.query.data?.totalCount]);
  const { user } = useRouteContext({ from: '/_application' });
  const [checkedIDs, setCheckedIDs] = useState<string[]>([]);
  const [allChecked, setAllChecked] = useState(false);

  const showPrevButton = useMemo(() => {
    return tableState.page > 1;
  }, [tableState.page]);
  const showNextButton = useMemo(() => {
    return (
      (props.query.data?.totalCount ?? 0) > tableState.page * tableState.size
    );
  }, [tableState.page, props.query.data, tableState.size]);
  const [selectedAction, setSelectedAction] = useState<RowAction<T>>();

  useEffect(() => {
    if (props.defaultPage != null && tableState.page != props.defaultPage) {
      setTableState({ page: props.defaultPage! });
    }
  }, [props.defaultPage]);

  useEffect(() => {
    if (props.defaultSearch != null && search != props.defaultSearch) {
      setSearch(props.defaultSearch);
    } else if (search != urlSearch.search) {
      setSearch(urlSearch.search ?? '');
    }
  }, [props.defaultSearch, urlSearch.search]);

  useEffect(() => {
    setTableState(urlSearch);
  }, [urlSearch]);

  useEffect(() => {
    if (
      (debouncedSearch == null || debouncedSearch == '') &&
      urlSearch.search != ''
    ) {
      setTableState({ search: undefined });
    }
    if (
      debouncedSearch != null &&
      debouncedSearch != urlSearch.search &&
      debouncedSearch != ''
    ) {
      setTableState({ search: debouncedSearch });
    }
  }, [debouncedSearch]);

  useEffect(() => {
    if (debouncedTableState.debounced != null && props.storeStateInUrl) {
      if (
        compareObjects(debouncedTableState.debounced, urlSearch) ||
        compareObjects(debouncedTableState.debounced, defaultTableState)
      ) {
        return;
      }
      navigate({
        to: '.',
        replace: Object.values(urlSearch).every((v) => v == undefined),
        search: {
          ...urlSearch,
          page: debouncedTableState.debounced.page ?? urlSearch.page,
          size: debouncedTableState.debounced.size ?? urlSearch.size,
          orderBy: debouncedTableState.debounced.orderBy ?? urlSearch.orderBy,
          orderDir:
            debouncedTableState.debounced.orderDir ?? urlSearch.orderDir,
          filter: debouncedTableState.debounced.filter ?? urlSearch.filter,
          search: debouncedTableState.debounced.search ?? urlSearch.search
        }
      });
    }
  }, [debouncedTableState.debounced]);

  function setTableState(v: components['schemas']['PaginationDto']) {
    if (Object.values(v).every((v) => v == undefined)) {
      v = defaultTableState;
    } else if (
      ((v.search != null && v.search != urlSearch.search) ||
        v.orderBy != null ||
        v.orderDir != null ||
        v.filter != null) &&
      v.page == null
    ) {
      v.page = 1;
    }
    if (v.page === undefined) {
      v.page = defaultTableState.page;
    }
    if (v.size === undefined) {
      v.size = defaultTableState.size;
    }
    props.onUpdate?.(v);
    _setTableState((prev) => ({ ...prev, ...v }));
  }

  function toggleRow(id: string) {
    const index = checkedIDs.findIndex((v) => v == id);
    if (index > -1) {
      setCheckedIDs(checkedIDs.filter((v) => v != id));
    } else {
      setCheckedIDs([...checkedIDs, id]);
    }
  }

  const rowActions = useMemo(() => {
    return props.rowActions?.filter((action) => {
      if (action.requiredSuperAdmin && !user.superAdmin) {
        return false;
      }
      if (action.requiredRole != null) {
        return userHasRole(user.role, action.requiredRole);
      }
      return true;
    });
  }, [props.rowActions]);

  const tableActions = useMemo(() => {
    const out: ReactNode[] = [];
    const validActions = props.tableActions
      ?.filter((action) => {
        if (action.requiredRole != null) {
          return userHasRole(user.role, action.requiredRole);
        }
        return true;
      })
      .filter(
        (v) =>
          !v.showOnSearch ||
          !!debouncedSearch ||
          checkedIDs.length > 0 ||
          allChecked
      );

    if (validActions == null || validActions.length == 0) {
      return null;
    }

    out.push(
      ...validActions.slice(0, 2).map((action) => (
        <Button
          variant="outline"
          key={action.display}
          onClick={() => {
            void action.onClick(
              checkedIDs,
              checkedIDs.length == props.query.data?.totalCount
            );
          }}
        >
          {action.display}
        </Button>
      ))
    );
    const rest = validActions.slice(2);
    if (rest.length == 0) {
      return out;
    }

    const actions: ReactNode[] = [];

    actions.push(
      ...rest.map((action) => (
        <DropdownMenuItem
          key={action.display}
          requiredRole={action.requiredRole}
          onClick={() => {
            void action.onClick(
              checkedIDs,
              checkedIDs.length == props.query.data?.totalCount
            );
          }}
        >
          {action.display}
        </DropdownMenuItem>
      ))
    );

    if (actions.length == 0) {
      return out;
    }
    return [
      ...out,
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline">More Actions</Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>{actions}</DropdownMenuContent>
      </DropdownMenu>
    ];
  }, [props.tableActions, checkedIDs, allChecked, debouncedSearch]);

  const headers = useMemo(() => {
    return props.headers?.filter((v) => v != null);
  }, [props.headers]);
  return (
    <Card
      className={cn(
        'w-full',
        {
          'p-0 shadow-none h-full  rounded-none min-h-full border-none flex flex-col flex-1':
            props.embedded
        },
        props.className
      )}
    >
      {props.children}
      <CardContent className={cn('flex-1', props.contentClassName)}>
        {props.query.isLoading && (
          <Skeleton className="h-full min-h-[256px] w-full" />
        )}
        {!props.query.isLoading && props.searchable && (
          <div className="flex flex-col mb-2 lg:items-end space-y-4 lg:space-y-0 lg:flex-row lg:space-x-4">
            {props.searchFilter != null && (
              <div key={props.searchFilter.label ?? `search-filter`}>
                {props.searchFilter.label != null && (
                  <Label className="text-xs font-normal text-muted-foreground">
                    {props.searchFilter.label}
                  </Label>
                )}
                <Select
                  value={
                    urlSearch.filter ??
                    props.searchFilter.defaultValue ??
                    undefined
                  }
                  onValueChange={(e) => {
                    setTableState({ filter: e });
                    setCheckedIDs([]);
                  }}
                >
                  <SelectTrigger className="w-auto min-w-[180px]">
                    <SelectValue placeholder={props.searchFilter.placeholder} />
                  </SelectTrigger>
                  <SelectContent>
                    {props.searchFilter.values.map((v) => (
                      <SelectItem key={v.key} value={v.key}>
                        {v.display}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            )}
            <Input
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              placeholder="Search"
            />
          </div>
        )}
        {rowData != null &&
          rowData.length == 0 &&
          (props.query.isFetched || props.query.isFetching) && (
            <div className="flex flex-col items-center text-muted-foreground">
              <img
                src="/illustrations/empty-adventure.svg"
                className="w-auto max-w-[256px] h-auto mt-4"
              />
              <h3 className="text-xl mt-8">No results found</h3>
            </div>
          )}
        {rowData != null && rowData.length > 0 && (
          <div className={cn('border rounded-md shadow-sm')}>
            <Table>
              <UITableHeader>
                <TableRow>
                  {props.selectable && (
                    <TableHead key="checkbox">
                      <Checkbox
                        className="border-muted"
                        checked={allChecked}
                        onCheckedChange={(checked) =>
                          setAllChecked(checked.valueOf() as boolean)
                        }
                      />
                    </TableHead>
                  )}
                  {headers.map((v) => {
                    return (
                      <TableHeader<T>
                        sortedColumn={tableState.orderBy}
                        defaultDir={
                          tableState.orderBy == v.key
                            ? tableState.orderDir
                            : undefined
                        }
                        key={v.display}
                        header={v}
                        onUpdate={setTableState}
                      />
                    );
                  })}
                  {props.rowActions != null && props.rowActions.length > 0 && (
                    <TableHead key="actions" className="w-8"></TableHead>
                  )}
                </TableRow>
              </UITableHeader>
              <TableBody>
                {rowData.map((row) => (
                  <TableRow
                    onMouseEnter={async () => {
                      if (props.onClickNavigate != null) {
                        await router.preloadRoute(props.onClickNavigate(row));
                      }
                    }}
                    onClick={async () => {
                      if (props.onClickNavigate != null) {
                        await navigate(props.onClickNavigate(row));
                      } else {
                        props.onClick?.(row);
                      }
                    }}
                    className={cn('group', {
                      'cursor-pointer':
                        props.onClick != null || props.onClickNavigate != null
                    })}
                    key={row.id}
                  >
                    {props.selectable && (
                      <TableCell
                        key={`${row['id']}-checkbox`}
                        className={'font-medium'}
                      >
                        <Checkbox
                          checked={checkedIDs.includes(row.id) || allChecked}
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleRow(row.id);
                          }}
                          className="border-muted"
                        />
                      </TableCell>
                    )}
                    {headers.map((header) => {
                      // TODO: Abstract all this into a better place

                      return (
                        <TableCellValue
                          hover={header.hover}
                          dateTime={header.dateTime}
                          key={`${header.display}-${row.id}`}
                          className={cn(
                            { 'whitespace-nowrap': props.compact },
                            { 'hidden lg:table-cell': header.hiddenOnMobile }
                          )}
                          value={
                            header.format != null
                              ? header.format(row[header.key], row)
                              : (row[header.key] ?? '-')
                          }
                          truncate={header.truncate}
                        />
                      );
                    })}
                    {rowActions != null && rowActions.length > 0 && (
                      <TableRowAction
                        row={row}
                        rowActions={rowActions}
                        selectedAction={selectedAction}
                        setSelectedAction={setSelectedAction}
                        key={`${row.id}-actions`}
                      />
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )}
      </CardContent>
      <CardFooter className={props.footerClassName}>
        {totalCount > 0 && (
          <div className="text-xs text-muted-foreground">
            Showing{' '}
            <strong>
              {(tableState.page - 1) * tableState.size + 1}-
              {(tableState.page - 1) * tableState.size +
                (props.query.data?.data.length ?? 0)}
            </strong>{' '}
            of <strong>{totalCount}</strong> {pluralize(totalCount, 'item')}
          </div>
        )}
        <div className="md:ml-auto mr-0 w-full md:w-auto flex flex-col space-y-2 md:space-y-0 md:space-x-2 md:flex-row">
          <div className="flex flex-col md:flex-row gap-2">{tableActions}</div>
          <DropdownMenu>
            <DropdownMenuTrigger asChild className="hidden lg:block">
              <Button variant="outline">
                <div className="flex items-center gap-1">
                  <span>{tableState.size} per page</span>
                  <ChevronDownIcon className="h-4 w-4" />
                </div>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuLabel>Page Size</DropdownMenuLabel>
              {[10, 20, 50, 100, 250].map((size) => (
                <DropdownMenuItem
                  key={size}
                  onClick={() => {
                    setTableState({
                      size
                    });
                  }}
                >
                  {size} items
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
          <Pagination className="justify-end">
            <PaginationContent>
              <PaginationItem>
                <Button
                  onClick={() => setTableState({ page: tableState.page - 1 })}
                  disabled={!showPrevButton}
                  size="icon"
                  variant="outline"
                >
                  <ChevronLeftIcon className="h-3.5 w-3.5" />
                  <span className="sr-only">Previous Item</span>
                </Button>
              </PaginationItem>
              <PaginationItem>
                <Button
                  disabled={!showNextButton}
                  onClick={() => setTableState({ page: tableState.page + 1 })}
                  size="icon"
                  variant="outline"
                >
                  <ChevronRightIcon className="h-3.5 w-3.5" />
                  <span className="sr-only">Next Item</span>
                </Button>
              </PaginationItem>
            </PaginationContent>
          </Pagination>
        </div>
      </CardFooter>
    </Card>
  );
}

function TableHeader<T>(props: {
  header: TableHeader<T>;
  sortedColumn?: string;
  defaultDir?: 'asc' | 'desc';
  onUpdate: TableCardProps<T>['onUpdate'];
}) {
  const [sortDir, setSortDir] = useState<'asc' | 'desc' | undefined>(
    props.defaultDir
  );

  useEffect(() => {
    if (props.sortedColumn != props.header.key) {
      setSortDir(undefined);
    }
  }, [props.sortedColumn]);

  useEffect(() => {
    setSortDir(props.defaultDir);
  }, [props.defaultDir]);

  function sort() {
    let dir = sortDir;
    if (dir == null) {
      dir = 'desc';
    } else if (sortDir == 'desc') {
      dir = 'asc';
    } else if (sortDir == 'asc') {
      dir = undefined;
    }
    setSortDir(dir);
    if (dir == null) {
      props.onUpdate({ orderBy: undefined, orderDir: undefined });
    } else {
      props.onUpdate({ orderBy: props.header.key as string, orderDir: dir });
    }
  }

  return (
    <TableHead
      className={cn({
        'hidden lg:table-cell': props.header.hiddenOnMobile,
        'hover:bg-muted cursor-pointer': props.header.sortable
      })}
      key={props.header.display}
      onClick={props.header.sortable ? () => sort() : undefined}
    >
      <div className="flex whitespace-nowrap items-center">
        {sortDir && (
          <ChevronUpIcon
            className={cn('h-4 w-4', { 'rotate-180': sortDir == 'desc' })}
          />
        )}
        {props.header.display}{' '}
        {props.header.info != null && (
          <HoverCard openDelay={0} closeDelay={50}>
            <HoverCardTrigger className="ml-1">
              <InformationCircleIcon className="h-4 w-4" />
            </HoverCardTrigger>
            <HoverCardContent className="whitespace-normal font-normal">
              {props.header.info}
            </HoverCardContent>
          </HoverCard>
        )}
      </div>
    </TableHead>
  );
}

function TableCellValue(props: {
  value: any;
  className?: string;
  dateTime?: boolean;
  hover?: boolean;
  truncate?: number;
}) {
  const { timezone } = useTimezone();
  let value = props.value;
  const className = '';
  if (typeof value == 'boolean') {
    if (value) {
      value = <CheckIcon className="text-green-500 h-4 w-4" />;
    } else {
      value = <XMarkIcon className="text-red-500 h-4 w-4" />;
    }
  }
  if (Array.isArray(value)) {
    value = value.join(', ');
  }
  if (props.dateTime) {
    if (moment(value).isValid()) {
      value = localDateTime(value, timezone, {
        format: 'MM/DD/YYYY @ h:mm:ss a'
      });
    } else {
      value = '-';
    }
  }
  const truncatedValue =
    props.truncate != null && props.truncate
      ? ellipsisTruncate(value, props.truncate)
      : value;

  if (
    typeof value == 'string' &&
    ((props.truncate != null &&
      props.truncate &&
      value.length > props.truncate) ||
      props.hover)
  ) {
    // This way lists are displayed separated by newlines on hover
    let hoverValue: string | ReactNode = value;
    if (Array.isArray(props.value)) {
      hoverValue = props.value.map((v) => (
        <React.Fragment key={`hover-list-item-${v}`}>
          {v}
          <br />
        </React.Fragment>
      ));
    }
    value = (
      <HoverCard openDelay={50} closeDelay={50}>
        <HoverCardTrigger asChild>
          <span>{truncatedValue}</span>
        </HoverCardTrigger>
        <HoverCardContent className="w-auto max-w-[350px] text-wrap break-all">
          {hoverValue}
        </HoverCardContent>
      </HoverCard>
    );
  }
  return (
    <TableCell className={cn('whitespace-nowrap', className, props.className)}>
      <div className="pr-2">{value}</div>
    </TableCell>
  );
}

function TableRowAction<T>(props: {
  rowActions: RowAction<T>[];
  row: T;
  setSelectedAction: (row?: RowAction<T>) => void;
  selectedAction?: RowAction<T>;
}) {
  const [open, setOpen] = useState(false);

  return (
    <TableCell
      onClick={(e) => e.stopPropagation()}
      className={cn('group-hover:sticky group-hover:right-0', {
        'sticky right-0': open
      })}
    >
      <Dialog>
        <DropdownMenu onOpenChange={setOpen}>
          <DropdownMenuTrigger asChild>
            <div className="border p-2 rounded-md bg-background hover:bg-muted">
              <Ellipsis className="h-auto w-4" />
              <span className="sr-only">Actions</span>
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent updatePositionStrategy="always" align="end">
            <DropdownMenuLabel>Actions</DropdownMenuLabel>
            {props.rowActions
              .map((action) => {
                const actionName =
                  typeof action.name == 'function'
                    ? action.name(props.row)
                    : action.name;
                if (
                  action.shouldDisplay != null &&
                  !action.shouldDisplay(props.row)
                )
                  return;
                if (action.confirm) {
                  return (
                    <DialogTrigger key={actionName} asChild>
                      <DropdownMenuItem
                        onClick={(e) => {
                          props.setSelectedAction(action);
                        }}
                      >
                        {actionName}
                      </DropdownMenuItem>
                    </DialogTrigger>
                  );
                } else {
                  return (
                    <DropdownMenuItem
                      key={actionName}
                      onClick={(e) => {
                        action.onClick(props.row);
                      }}
                    >
                      {actionName}
                    </DropdownMenuItem>
                  );
                }
              })
              .filter(Boolean)}
          </DropdownMenuContent>
        </DropdownMenu>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Are you sure?</DialogTitle>
            <DialogDescription>
              {typeof props.selectedAction?.confirmMessage == 'function'
                ? props.selectedAction.confirmMessage(props.row)
                : props.selectedAction?.confirmMessage}
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <DialogClose asChild>
              <Button variant="outline">No</Button>
            </DialogClose>
            <DialogClose
              onClick={() => {
                props.selectedAction?.onClick(props.row);
                props.setSelectedAction(undefined);
              }}
              asChild
            >
              <Button>Yes</Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </TableCell>
  );
}
