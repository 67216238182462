import { AppLayout } from '@/components/app-layout';
import { TableCard } from '@/components/table-card';
import { Badge } from '@/components/ui/badge';
import {
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from '@/components/ui/card';
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger
} from '@/components/ui/hover-card';
import { Label } from '@/components/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/ui/select';
import { Switch } from '@/components/ui/switch';
import { apiClient } from '@/lib/api';
import { components } from '@/lib/api.types';
import { getCaseStatusBadge, getVerdictBadgeVariant } from '@/lib/case';
import { getTimeZoneAbbreviation, useTimezone } from '@/lib/time';
import { cn } from '@/lib/utils';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import {
  Category,
  CategoryClass,
  CategoryClassConfig,
  CategoryConfig,
  getCategoryTitle,
  Status,
  StatusConfig,
  Verdict,
  VerdictConfig
} from '@wire/shared';
import moment from 'moment';
import { useEffect, useState } from 'react';

export const Route = createFileRoute('/_application/admin/tim')({
  component: Tim
});

function CaseFilterItem(
  props: React.PropsWithChildren<{
    activeFilter: Status | undefined;
    filter?: Status;
    onClick?: () => void;
  }>
) {
  return (
    <li
      onClick={props.onClick}
      className={cn(
        'px-3 text-sm text-muted-foreground font-medium rounded-md',
        {
          'bg-background font-semibold text-foreground':
            props.filter == props.activeFilter
        }
      )}
    >
      {props.children}
    </li>
  );
}

async function getAdminCases(dto: components['schemas']['SearchCasesDto']) {
  const result = await apiClient.POST('/admin/cases/search', {
    body: dto
  });
  if (result.error != null) {
    throw result.error;
  }
  return result.data;
}

export default function Tim() {
  const { timezone } = useTimezone();
  const [searchSettings, setSearchSettings] = useState<
    components['schemas']['SearchCasesDto']
  >({ orderBy: 'createdAt', orderDir: 'desc' });
  const [status, setStatus] = useState<Status | undefined>(undefined);
  const [wasEscalated, setWasEscalated] = useState(false);
  const [category, setCategory] = useState<Category>();
  const [categoryClass, setCategoryClass] = useState<CategoryClass>();

  const casesQuery = useQuery({
    queryKey: ['ADMIN-CASES', searchSettings],
    queryFn: () => getAdminCases(searchSettings),
    placeholderData: keepPreviousData
  });
  const [hideExcluded, setHideExcluded] = useState(true);

  useEffect(() => {
    setSearchSettings({
      ...searchSettings,
      hideExcluded: hideExcluded,
      category: category,
      categoryClass: categoryClass,
      onlyWasEscalated: wasEscalated,
      statuses: status ? [status] : undefined
    });
  }, [
    status,
    hideExcluded,
    wasEscalated,
    category,
    categoryClass,
    searchSettings
  ]);

  return (
    <AppLayout>
      <TableCard
        onUpdate={(v) => setSearchSettings({ ...searchSettings, ...v })}
        query={casesQuery}
        onClick={(row) => {
          window.open(`/cases/${row.sid}?switchTeamId=${row.teamId}`, '_blank');
        }}
        headers={[
          {
            display: 'ID',
            key: 'sid',
            sortable: true
          },
          {
            display: 'Client',
            key: 'teamName'
          },
          {
            display: 'Category',
            key: 'categories',
            format(value: Category[], row) {
              let hoverBadge: React.ReactNode = null;
              if (value != null && value.length > 1) {
                const cats = value.slice(1);
                hoverBadge = (
                  <HoverCard openDelay={0} closeDelay={50}>
                    <HoverCardTrigger>
                      <Badge
                        variant={getVerdictBadgeVariant(
                          row.verdict as any,
                          row.status as any
                        )}
                      >
                        +{cats.length}
                      </Badge>
                    </HoverCardTrigger>
                    <HoverCardContent>
                      <ul>
                        {cats.map((v) => (
                          <li key={v}>{getCategoryTitle(v)}</li>
                        ))}
                      </ul>
                    </HoverCardContent>
                  </HoverCard>
                );
              }
              return (
                <div className="flex flex-row gap-1">
                  <Badge
                    variant={getVerdictBadgeVariant(
                      row.verdict as any,
                      row.status as any
                    )}
                  >
                    {getCategoryTitle(value[0])}
                  </Badge>
                  {hoverBadge}
                </div>
              );
            }
          },
          {
            display: 'Name',
            key: 'name',
            sortable: true
          },
          {
            display: 'Status',
            key: 'status',
            sortable: true,
            format: (value) => getCaseStatusBadge(value)
          },
          {
            display: `Detected At (${getTimeZoneAbbreviation(timezone)})`,
            key: 'firstDetectionSourceDetectedAt',
            sortable: true,
            dateTime: true
          },
          {
            display: `Ingested At (${getTimeZoneAbbreviation(timezone)})`,
            key: 'createdAt',
            sortable: true,
            dateTime: true
          },
          {
            display: 'MTTR',
            key: 'mttr',
            format(value, row) {
              if (value == null || row.status != Status.CLOSED) return '-';
              return moment.duration(value, 'seconds').humanize();
            }
          },
          {
            display: '',
            key: 'id',
            format(value, row) {
              let text = '';
              if (row.testMode) {
                text =
                  'This case was ingested in test mode and may have exaggerated numbers for MTTR and MTTD.';
              } else if (row.reingested) {
                text =
                  'This case was reingested and may have exaggerated numbers for MTTR and MTTD.';
              } else {
                return;
              }
              return (
                <div className="flex items-center justify-center">
                  <HoverCard closeDelay={50} openDelay={0}>
                    <HoverCardTrigger className="ml-1">
                      <InformationCircleIcon className="h-6 w-6 text-red-500" />
                    </HoverCardTrigger>
                    <HoverCardContent className="whitespace-normal font-normal">
                      {text}
                    </HoverCardContent>
                  </HoverCard>
                </div>
              );
            }
          }
        ]}
      >
        <>
          <CardHeader className="pb-2">
            <CardTitle>Cases</CardTitle>
            <CardDescription>
              Cases from all clients in your portfolio.
            </CardDescription>
          </CardHeader>
          <CardContent className="pb-2">
            <div className="flex w-full lg:items-stretch flex-col gap-2 lg:flex-row">
              <div>
                <Select
                  value={categoryClass ?? ''}
                  onValueChange={(v) => {
                    setCategoryClass(
                      v == 'any' ? undefined : (v as CategoryClass)
                    );
                  }}
                >
                  <SelectTrigger className="h-full bg-background font-medium">
                    <SelectValue key="select-value" placeholder="Class" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem key="any" value="any">
                      Any
                    </SelectItem>
                    {Object.entries(CategoryClassConfig).map(([k, v]) => (
                      <SelectItem key={k} value={k}>
                        {v.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <div>
                <Select
                  value={category ?? ''}
                  onValueChange={(v) => {
                    setCategory(v == 'any' ? undefined : (v as Category));
                  }}
                >
                  <SelectTrigger className="h-full bg-background font-medium">
                    <SelectValue key="select-value" placeholder="Category" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem key="any" value="any">
                      Any
                    </SelectItem>
                    {Object.values(CategoryConfig)
                      .filter(
                        (v) => categoryClass == null || v.class == categoryClass
                      )
                      .map((v) => (
                        <SelectItem key={v.category} value={v.category}>
                          <div>
                            {v.name}
                            <div className="text-xs text-muted-foreground">
                              {CategoryClassConfig[v.class].name}
                            </div>
                          </div>
                        </SelectItem>
                      ))}
                  </SelectContent>
                </Select>
              </div>
              <div>
                <Select
                  value={status ?? ''}
                  onValueChange={(v) => {
                    setStatus(v == 'any' ? undefined : (v as Status));
                  }}
                >
                  <SelectTrigger className="h-full bg-background font-medium">
                    <SelectValue key="select-value" placeholder="Status" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem key="any" value="any">
                      Any
                    </SelectItem>
                    {Object.values(StatusConfig).map((v) => (
                      <SelectItem key={v.display} value={v.type}>
                        {v.display}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <div>
                <Select
                  onValueChange={(v) =>
                    setSearchSettings({
                      ...searchSettings,
                      verdict: v == 'any' ? undefined : (v as Verdict)
                    })
                  }
                >
                  <SelectTrigger className="h-full bg-background font-medium min-w-[180px]">
                    <SelectValue
                      key="select-value"
                      placeholder="Filter By Verdict"
                    />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem key="any" value="any">
                      Any
                    </SelectItem>
                    {Object.values(VerdictConfig).map((v) => (
                      <SelectItem key={v.display} value={v.type}>
                        {v.display}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <div className="border rounded-md p-2 shadow-sm">
                <HoverCard>
                  <HoverCardTrigger className="flex flex-row items-center gap-1">
                    <Label htmlFor="hide-excluded" className="cursor-pointer">
                      Show Excluded
                    </Label>
                    <Switch
                      id="hide-excluded"
                      checked={!hideExcluded}
                      onCheckedChange={(v) => setHideExcluded(!v)}
                    />
                  </HoverCardTrigger>
                  <HoverCardContent className="text-xs">
                    Show cases that were automatically closed by Exclusions
                    you've created.
                  </HoverCardContent>
                </HoverCard>
              </div>

              <div className="border rounded-md p-2 shadow-sm">
                <HoverCard>
                  <HoverCardTrigger className="flex flex-row items-center gap-1">
                    <Label htmlFor="was-escalated" className="cursor-pointer">
                      Was Escalated
                    </Label>
                    <Switch
                      id="was-escalated"
                      checked={wasEscalated}
                      onCheckedChange={(v) => setWasEscalated(v)}
                    />
                  </HoverCardTrigger>
                  <HoverCardContent className="text-xs">
                    Show any cases that were ever escalated.
                  </HoverCardContent>
                </HoverCard>
              </div>
            </div>
          </CardContent>
        </>
      </TableCard>
    </AppLayout>
  );
}
