import { apiClient } from '@/lib/api';
import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/detections/$detectionId')({
  component: () => <div>Loading...</div>,
  preload: false,
  loader: async ({ params, context, deps }) => {
    const detection = await getDetection(params.detectionId);
    if (detection == null || detection.caseId == null) {
      throw redirect({
        to: '/dashboard'
      });
    }
    throw redirect({
      to: '/cases/$caseId',
      params: { caseId: detection.caseId },
      search: { detectionId: detection.id }
    });
  }
});

async function getDetection(detectionId?: string) {
  if (detectionId == null) {
    return null;
  }
  const response = await apiClient.GET('/detection/{idOrSid}', {
    params: { path: { idOrSid: detectionId } }
  });
  if (response.error != null || response.data == null) {
    throw new Error('Error getting detection');
  }
  return response.data;
}
