import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Logo } from '@/components/ui/logo';
import { Skeleton } from '@/components/ui/skeleton';
import { apiClient } from '@/lib/api';
import { app } from '@microsoft/teams-js';
import { SearchSchemaInput, createFileRoute } from '@tanstack/react-router';
import { ChatOpsMessagePart, DOC_LINKS } from '@wire/shared';
import { useEffect, useMemo, useState } from 'react';

export const Route = createFileRoute('/chat-ops')({
  component: ChatOps,
  validateSearch: (
    search: {
      jwt?: string;
      parts?: string;
    } & SearchSchemaInput
  ) => {
    return {
      jwt: search.jwt,
      parts: search.parts
    };
  }
});
function parseJwt(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

function ChatOps() {
  const { jwt, parts } = Route.useSearch();
  const [loading, setLoading] = useState(true);
  const [messageParts, setMessageParts] = useState<ChatOpsMessagePart[][]>([]);
  const [welcomeMessage, setWelcomeMessage] = useState<string | null>(null);
  const [inputValues, setInputValues] = useState<Record<string, string>>({});

  useEffect(() => {
    (async () => {
      try {
        await app.initialize();
      } catch (err) {
        //
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (jwt != null && jwt.length > 0) {
        const response = await apiClient.GET('/chat-ops/message-parts', {
          params: {
            query: {
              jwt
            }
          }
        });
        if (response.error != null) {
          alert(response.error.message);
          return;
        }
        setMessageParts([response.data.data as any]);
      } else if (parts != null && parts.length > 0) {
        const response = await apiClient.POST('/integration/verify/jwt', {
          params: {
            query: {
              jwt: parts
            }
          }
        });
        if (response.error != null) {
          alert(response.error.message);
          return;
        }
        setMessageParts(parseJwt(parts));
      } else {
        setMessageParts([
          [
            {
              type: 'text',
              message:
                'Wirespeed is an application that keeps your company safe from hackers by occasionally asking you questions about your activity. You can learn more about us here https://wirespeed.co and ask your security team for more information.'
            }
          ]
        ]);
      }
      setLoading(false);
    })().catch(alert);
  }, []);

  async function submitChatOps(choice: string) {
    const response = await apiClient.POST('/integration/chat-ops', {
      headers: {
        Authorization: `Bearer ${choice}`
      },
      body: inputValues as any
    });
    if (response.error != null) {
      alert(response.error.message);
      return;
    }
    const newParts = [...messageParts];
    const data = response.data.data as any;
    if (data.updatePreviousMessage != null) {
      newParts.pop();
      newParts.push(data.updatePreviousMessage);
    }
    if (data.message != null) {
      newParts.push(data.message);
    }

    if (data.welcomeMessage != null) {
      setWelcomeMessage(data.welcomeMessage);
    }
    setMessageParts(newParts);
  }

  const handleInputChange = (id: string, value: string) => {
    setInputValues((prev) => ({ ...prev, [id]: value }));
  };

  const handleSubmit = async (choice: string) => {
    await submitChatOps(choice);
  };

  const footer = useMemo(() => {
    if (welcomeMessage != null) {
      return welcomeMessage;
    }
    return (
      <>
        This webpage is part of{' '}
        <a
          className="text-blue-500 underline"
          href="https://wirespeed.co"
          target="_blank"
        >
          Wirespeed's
        </a>{' '}
        <a
          className="text-blue-500 underline"
          href={DOC_LINKS.CHAT_OPS_RECEIVED}
          target="_blank"
        >
          ChatOps
        </a>
        . Please contact your security team if you have any questions. Wirespeed
        will never ask for your password or personal information.
      </>
    );
  }, [welcomeMessage]);

  if (loading) {
    return <Skeleton className="max-w-2xl mx-auto h-96 mt-24" />;
  }

  return (
    <div className="max-w-2xl mx-auto flex p-4 pt-12 lg:pt-24 lg:p-0">
      <div className="flex flex-col gap-4">
        <Logo words className="mb-8" />
        {messageParts.map((v, idx) => (
          <MessageParts
            key={idx}
            messageParts={v}
            inputValues={inputValues}
            handleInputChange={handleInputChange}
            handleSubmit={handleSubmit}
          />
        ))}
        <p className="text-xs text-muted-foreground">{footer}</p>
      </div>
    </div>
  );
}

function MessageParts(props: {
  messageParts: ChatOpsMessagePart[];
  inputValues: Record<string, string>;
  handleInputChange: (id: string, value: string) => void;
  handleSubmit: (value: string) => void;
}) {
  const renderMessagePart = (part: ChatOpsMessagePart, key: string) => {
    switch (part.type) {
      case 'title':
        return (
          <h1 className="text-2xl font-bold" key={part.id + key}>
            {part.message}
          </h1>
        );
      case 'text':
        return (
          <p
            className=" [&_a]:text-blue-500 normal-lists"
            dangerouslySetInnerHTML={{ __html: part.message }}
            key={part.id + key}
          />
        );
      case 'input':
        return (
          <Input
            key={part.id + key}
            type="text"
            placeholder={part.placeholder}
            value={props.inputValues[part.id!] ?? ''}
            onChange={(e) => props.handleInputChange(part.id!, e.target.value)}
          />
        );
      case 'buttons':
        return (
          <div key={'button-group' + key} className="flex gap-2">
            {part.buttons.map((button) => (
              <Button
                key={button.id + key}
                onClick={() => props.handleSubmit(button.value)}
              >
                {button.text}
              </Button>
            ))}
          </div>
        );
    }
  };

  return props.messageParts.map((v, idx) => renderMessagePart(v, `${idx}`));
}
