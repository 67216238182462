import { apiClient } from '@/lib/api';

import { SETTINGS_TEAM_MEMBERS_QUERY } from '@/components/team/team-settings';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/ui/select';
import { zodResolver } from '@hookform/resolvers/zod';
import { DialogTrigger } from '@radix-ui/react-dialog';
import { PlusIcon } from '@radix-ui/react-icons';
import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from '@tanstack/react-router';
import { ROLE, ROLE_CONFIG } from '@wire/shared';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { z } from 'zod';
import { Button } from '../ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle
} from '../ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '../ui/form';
import { Input } from '../ui/input';

export default function AddTeamMember() {
  const [open, setOpen] = useState(false);
  const router = useRouter();
  const queryClient = useQueryClient();
  const teamMemberSchema = useMemo(
    () =>
      z.object({
        email: z.string().email().min(1).max(512),
        firstName: z.string().min(1, {}).max(512),
        lastName: z.string().min(1, {}).max(512),
        role: z.nativeEnum(ROLE)
      }),
    []
  );

  const teamMemberForm = useForm<z.infer<typeof teamMemberSchema>>({
    resolver: zodResolver(teamMemberSchema),
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      role: undefined
    }
  });

  async function onTeamMemberSubmit(values: z.infer<typeof teamMemberSchema>) {
    const response = await apiClient.PUT('/team/invite', {
      body: {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        role: values.role
      }
    });
    if (response.error != null) {
      toast.error(response.error.message);
      return;
    }

    await queryClient.invalidateQueries({
      queryKey: [SETTINGS_TEAM_MEMBERS_QUERY]
    });
    await router.invalidate();

    toast.success('User invited');
    setOpen(false);
    teamMemberForm.reset();
  }

  return (
    <>
      <Dialog requiredRole={ROLE.ADMIN} open={open} onOpenChange={setOpen}>
        <DialogTrigger asChild>
          <Button>
            <PlusIcon className="h-5 w-5 mr-2" />
            Invite Team Member
          </Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            {' '}
            <DialogTitle>Invite Team Member</DialogTitle>
            <DialogDescription>
              Invite members of your security team to collaborate on cases.
            </DialogDescription>
          </DialogHeader>
          <Form {...teamMemberForm}>
            <form
              onSubmit={teamMemberForm.handleSubmit(onTeamMemberSubmit)}
              className="col-span-5 mt-2 flex flex-col space-y-4"
            >
              <div className="flex flex-col lg:flex-row gap-4">
                <FormField
                  control={teamMemberForm.control}
                  name="firstName"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <FormLabel>First Name</FormLabel>
                      <FormControl>
                        <Input placeholder="Jake" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={teamMemberForm.control}
                  name="lastName"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <FormLabel>Last Name</FormLabel>
                      <FormControl>
                        <Input placeholder="Speed" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <FormField
                control={teamMemberForm.control}
                name="email"
                render={({ field }) => (
                  <FormItem className="flex-1">
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input placeholder="jake@wirespeed.co" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={teamMemberForm.control}
                name="role"
                render={({ field }) => (
                  <FormItem className="flex-1">
                    <FormLabel>Role</FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue
                            defaultValue={field.value}
                            placeholder="Select role"
                          >
                            {ROLE_CONFIG[field.value]?.name ?? 'Select role'}
                          </SelectValue>
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {Object.values(ROLE).map((role) => (
                          <SelectItem key={role} value={role}>
                            {ROLE_CONFIG[role].name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex self-end">
                <Button
                  disabled={teamMemberForm.formState.isSubmitting}
                  variant="outline"
                  type="submit"
                >
                  Invite
                </Button>
              </div>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </>
  );
}
