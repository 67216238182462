import {
  CaseSearchStatus,
  Cases,
  getCasesOptions
} from '@/components/cases/cases';
import { WSPDURLParams } from '@/routes/_application';
import { createFileRoute } from '@tanstack/react-router';
import { Category, CategoryClass } from '@wire/shared';

type TCaseSearch = WSPDURLParams & {
  categoryClass?: CategoryClass;
  category?: Category;
  filter?: CaseSearchStatus;
};
export const Route = createFileRoute('/_application/cases/')({
  validateSearch: (search: TCaseSearch) => {
    const out: TCaseSearch = {
      ...search,
      categoryClass: search?.categoryClass,
      category: search?.category,
      filter: search?.filter
    };
    return out;
  },
  loaderDeps: ({ search }) => {
    return search;
  },
  loader: async ({ context, deps }) => {
    await context.queryClient.ensureQueryData(
      getCasesOptions({
        statuses: deps.filter != null ? [deps.filter] : [],
        categoryClass: deps.categoryClass,
        category: deps.category,
        page: deps.page,
        search: deps.search,
        orderBy: deps.orderBy,
        orderDir: deps.orderDir
      })
    );
  },
  component: () => {
    const search = Route.useSearch();
    return (
      <Cases
        search={{ ...search, filter: search.filter as CaseSearchStatus }}
      />
    );
  }
});
