'use client';

import { Label } from '@/components/ui/label';
import { getTimeZoneAbbreviation, useTimezone } from '@/lib/time';
import * as React from 'react';
import { DateAfter, DateBefore } from 'react-day-picker';
import { TimePeriodSelect } from './time-period-select';
import { TimePickerInput } from './time-picker-input';
import { Period } from './time-picker-utils';

interface TimePickerProps {
  date: Date | undefined;
  setDate: (date: Date | undefined) => void;
}

export function TimePicker({
  date,
  setDate,
  disabled
}: TimePickerProps & { disabled?: Partial<DateAfter & DateBefore>[] }) {
  const [period, setPeriod] = React.useState<Period>('PM');
  const { timezone } = useTimezone();
  const minuteRef = React.useRef<HTMLInputElement>(null);
  const hourRef = React.useRef<HTMLInputElement>(null);
  const secondRef = React.useRef<HTMLInputElement>(null);
  const periodRef = React.useRef<HTMLButtonElement>(null);

  const isTimeDisabled = React.useMemo(() => {
    if (disabled == null || date == null) return false;
    return disabled.some((matcher) => {
      if (matcher.before != null && date != null) {
        return date < matcher.before;
      }
      if (matcher.after != null && date != null) {
        return date > matcher.after;
      }
      return false;
    });
  }, [
    date,
    disabled,
    minuteRef.current,
    hourRef.current,
    secondRef.current,
    periodRef.current,
    period
  ]);

  return (
    <div className="flex flex-col gap-1">
      <div className="flex  gap-2">
        <div className="grid gap-1 text-center">
          <Label htmlFor="hours" className="text-xs">
            Hours
          </Label>
          <TimePickerInput
            picker="12hours"
            period={period}
            date={date}
            setDate={setDate}
            ref={hourRef}
            onRightFocus={() => minuteRef.current?.focus()}
          />
        </div>
        <div className="grid gap-1 text-center">
          <Label htmlFor="minutes" className="text-xs">
            Minutes
          </Label>
          <TimePickerInput
            picker="minutes"
            id="minutes12"
            date={date}
            setDate={setDate}
            ref={minuteRef}
            onLeftFocus={() => hourRef.current?.focus()}
            onRightFocus={() => secondRef.current?.focus()}
          />
        </div>
        <div className="grid gap-1 text-center">
          <Label htmlFor="seconds" className="text-xs">
            Seconds
          </Label>
          <TimePickerInput
            picker="seconds"
            id="seconds12"
            date={date}
            setDate={setDate}
            ref={secondRef}
            onLeftFocus={() => minuteRef.current?.focus()}
            onRightFocus={() => periodRef.current?.focus()}
          />
        </div>
        <div className="grid gap-1 text-center">
          <Label htmlFor="period" className="text-xs">
            Period
          </Label>
          <TimePeriodSelect
            period={period}
            setPeriod={setPeriod}
            date={date}
            setDate={setDate}
            ref={periodRef}
            onLeftFocus={() => secondRef.current?.focus()}
          />
        </div>
      </div>
      <div className="flex items-center justify-end gap-1">
        {isTimeDisabled && (
          <span className="text-xs text-red-500">
            Time outside allowed range
          </span>
        )}
        <span className="text-xs text-muted-foreground">
          {getTimeZoneAbbreviation(timezone)}
        </span>
      </div>
    </div>
  );
}
