import { AppLayout } from '@/components/app-layout';
import CopyToClipboard from '@/components/copy-to-clipboard';
import { EventsTable } from '@/components/events/events-table';
import { HoverCardComponent } from '@/components/hover-cards/util';
import ManageExclusion from '@/components/manage-exclusion';
import { RECENT_CASES_QUERY_KEY } from '@/components/nav/nav-cases';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Badge, BadgeProps } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle
} from '@/components/ui/card';
import { Checkbox } from '@/components/ui/checkbox';
import { DateTimePicker } from '@/components/ui/date-time-picker';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu';
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger
} from '@/components/ui/hover-card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { Skeleton } from '@/components/ui/skeleton';
import { Switch } from '@/components/ui/switch';
import { Textarea } from '@/components/ui/textarea';
import { apiClient } from '@/lib/api';
import { components } from '@/lib/api.types';
import {
  getCaseStatusBadge,
  getCaseStatusBadgeVariant,
  getVerdictBadgeVariant
} from '@/lib/case';
import { entityRouteMappings } from '@/lib/entityMappings';
import {
  calculateTTRMilliseconds,
  calculateTTRSeconds,
  getTimeZoneAbbreviation,
  localDateTime,
  useTimezone,
  utcDateTime
} from '@/lib/time';
import { cn, getDetailsFromRaw, useDebounce } from '@/lib/utils';
import {
  ArrowRightIcon,
  ExclamationTriangleIcon,
  HandThumbDownIcon,
  HandThumbUpIcon
} from '@heroicons/react/24/outline';
import {
  queryOptions,
  useQuery,
  useQueryClient,
  useSuspenseQuery
} from '@tanstack/react-query';
import {
  Link,
  SearchSchemaInput,
  createFileRoute,
  redirect,
  useNavigate,
  useRouteContext
} from '@tanstack/react-router';
import {
  AQLDefectReasonConfig,
  AQLQuestionConfig,
  AQL_DEFECT_REASON,
  DefectLevel,
  ENTITY_TYPE,
  EntityTypeConfig,
  ROLE,
  Status,
  TAQLQuestionConfig,
  Verdict,
  getDefectLevelConfig,
  getMaxDefectLevel,
  getStatusConfigByStatus,
  getVerdictConfigByVerdict
} from '@wire/shared';
import copy from 'copy-to-clipboard';
import { CastleIcon, CrownIcon } from 'lucide-react';
import moment from 'moment';
import numeral from 'numeral';
import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'sonner';

export const Route = createFileRoute('/_application/cases/$caseId')({
  component: Case,
  validateSearch: (
    search: {
      detectionId?: string;
    } & SearchSchemaInput
  ) => {
    return {
      detectionId: search.detectionId
    };
  },
  loaderDeps: ({ search }) => {
    return {
      detectionId: search.detectionId
    };
  },
  loader: async ({ params, context, deps }) => {
    let caseId = params.caseId;
    let detectionId = deps.detectionId;
    if (params.caseId.startsWith('WSPD-')) {
      const caseData = await getCaseData(params.caseId);
      caseId = caseData.id;
    }
    if (deps.detectionId?.startsWith('DTN-')) {
      const detectionData = await getDetection(deps.detectionId);
      if (detectionData != null) {
        detectionId = detectionData.id;
      }
    }
    if (caseId != params.caseId || detectionId != deps.detectionId) {
      throw redirect({
        to: '/cases/$caseId',
        params: { caseId: caseId },
        search: { detectionId: detectionId }
      });
    }
    const [detectionsResponse, caseResponse] = await Promise.all([
      context.queryClient.ensureQueryData(getDetectionsOptions(params.caseId)),
      context.queryClient.ensureQueryData(getCaseOptions(params.caseId))
    ]);
    if (detectionsResponse?.data != null) {
      const assetDetectionId =
        deps.detectionId ?? detectionsResponse.data[0].id;
      // This simulates the logic in the component, in a complex case we don't initially load any detection ID
      const detectionDetectionId =
        deps.detectionId ??
        (detectionsResponse.data.length == 1
          ? detectionsResponse.data[0].id
          : undefined);
      await Promise.all([
        context.queryClient.ensureQueryData(
          getAssetOptions(params.caseId, assetDetectionId)
        ),
        context.queryClient.ensureQueryData(
          getDetectionOptions(detectionDetectionId)
        )
      ]);
    }
    context.title = caseResponse?.sid;
  }
});

async function getAssets(caseId?: string, detectionId?: string) {
  if (detectionId != null) {
    const response = await apiClient.GET('/asset/detection/{id}', {
      params: { path: { id: detectionId } }
    });
    if (response.error != null || response.data == null) {
      throw new Error('Error getting assets');
    }
    return response.data;
  } else if (caseId != null) {
    const response = await apiClient.GET('/asset/case/{id}', {
      params: { path: { id: caseId } }
    });
    if (response.error != null || response.data == null) {
      throw new Error('Error getting assets');
    }
    return response.data;
  } else {
    throw new Error('No case or detection id provided');
  }
}

export const ASSET_QUERY_KEY = 'case-asset';
const getAssetOptions = (caseId: string, detectionId?: string) =>
  queryOptions({
    queryKey: [ASSET_QUERY_KEY, caseId, detectionId],
    queryFn: () => getAssets(caseId, detectionId)
  });

async function getDetection(detectionId?: string) {
  if (detectionId == null) {
    return null;
  }
  const response = await apiClient.GET('/detection/{idOrSid}', {
    params: { path: { idOrSid: detectionId } }
  });
  if (response.error != null || response.data == null) {
    throw new Error(response.error?.message ?? 'Error getting detection');
  }
  return response.data;
}

export const DETECTION_QUERY_KEY = 'case-detection';
const getDetectionOptions = (detectionId?: string) =>
  queryOptions({
    queryKey: [DETECTIONS_QUERY_KEY, detectionId],
    queryFn: () => getDetection(detectionId),
    refetchInterval: 5_000
  });

async function searchCaseDetections(caseId: string) {
  const response = await apiClient.POST('/detection', {
    body: {
      caseIdOrSid: caseId,
      size: 250,
      orderBy: 'sourceDetectedAt',
      orderDir: 'asc'
    }
  });
  if (response.error != null || response.data == null) {
    throw new Error('Error getting detections');
  }
  return response.data;
}

export const DETECTIONS_QUERY_KEY = 'case-detections';
const getDetectionsOptions = (caseId: string) =>
  queryOptions({
    queryKey: [DETECTIONS_QUERY_KEY, caseId],
    queryFn: () => searchCaseDetections(caseId),
    refetchInterval: 5_000
  });

async function getCaseData(caseId: string) {
  const [caseMatch] = await Promise.all([
    apiClient.GET('/cases/{idOrSid}', {
      params: { path: { idOrSid: caseId } }
    })
  ]);
  if (caseMatch.error != null) {
    throw new Error('Error getting cases information');
  }
  return caseMatch.data;
}

export const CASES_QUERY_KEY = 'case-settings';
const getCaseOptions = (caseId: string) =>
  queryOptions({
    queryKey: [CASES_QUERY_KEY, caseId],
    queryFn: () => getCaseData(caseId),
    refetchInterval: 5_000
  });

/**
 * This takes the what happened or next steps HTML and injects hover cards for the link components
 * @param htmlContent
 * @returns
 */
function HoverCards(props: { htmlContent: string }) {
  // Create a temporary DOM parser
  const parser = new DOMParser();
  const doc = parser.parseFromString(props.htmlContent, 'text/html');
  // Convert the DOM tree into React elements
  function domToReact(node: Node): React.ReactNode {
    if (node.nodeType === Node.TEXT_NODE) {
      return node.textContent;
    }

    if (node.nodeType === Node.ELEMENT_NODE) {
      const element = node as Element;
      const entityId = element.getAttribute('wspd-data-id');
      const entityType: ENTITY_TYPE | null = element.getAttribute(
        'wspd-data-type'
      ) as ENTITY_TYPE | null;

      // Handle elements with wspd-data-id
      if (entityId != null && entityType != null) {
        const routeMapping = entityRouteMappings[entityType];
        if (routeMapping == null) {
          return element.textContent;
        }
        return (
          <HoverCard openDelay={50} closeDelay={50} key={entityId}>
            <HoverCardTrigger asChild>
              <Link
                to={routeMapping.to}
                params={
                  routeMapping.paramKey != null
                    ? { [routeMapping.paramKey]: entityId }
                    : {}
                }
                className="text-blue-500"
              >
                {element.textContent}
              </Link>
            </HoverCardTrigger>
            <HoverCardComponent entityType={entityType} id={entityId} />
          </HoverCard>
        );
      }

      // Handle regular elements
      const props: any = {
        className: element.className
      };

      // Convert child nodes recursively
      const children = Array.from(element.childNodes).map((child, index) => (
        <React.Fragment key={index}>{domToReact(child)}</React.Fragment>
      ));

      if (element.tagName.toLowerCase() == 'br') {
        return React.createElement(element.tagName.toLowerCase());
      }

      return React.createElement(
        element.tagName.toLowerCase(),
        props,
        children
      );
    }

    return null;
  }

  // Convert the body content to React elements
  const reactElements = Array.from(doc.body.childNodes).map((node, index) => (
    <React.Fragment key={index}>{domToReact(node)}</React.Fragment>
  ));

  return (
    <div className="[&_a]:text-blue-500 text-sm md:text-base break-all lg:break-normal normal-lists">
      {reactElements}
    </div>
  );
}

function Case() {
  const { caseId } = Route.useParams();
  const { user, integrationMetadata } = Route.useRouteContext();
  const { detectionId } = Route.useSearch();
  const queryClient = useQueryClient();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [showEditNotes, setShowEditNotes] = useState(false);
  const [showCreateExclusion, setShowCreateExclusion] = useState(false);
  const [showAQL, setShowAQL] = useState(false);
  const whatHappenedRef = useRef<HTMLDivElement>(null);
  const { timezone } = useTimezone();
  const [respondedAt, setRespondedAt] = useState<string | undefined>();
  const nextStepsRef = useRef<HTMLDivElement>(null);
  const [showDebugLogs, setShowDebugLogs] = useState(false);
  const [actionsOpen, setActionsOpen] = useState(false);
  const { data: caseData } = useSuspenseQuery(getCaseOptions(caseId));
  const { data: detectionsData } = useSuspenseQuery(
    getDetectionsOptions(caseId)
  );
  const { data: assets } = useSuspenseQuery(
    getAssetOptions(caseId, detectionId)
  );
  const complexCase = useMemo(() => {
    return detectionsData?.data.length > 1;
  }, [detectionsData]);

  const [selectedDetection, setSelectedDetection] = useState<
    components['schemas']['Detection'] | null
  >(getDefaultSelectedDetection());
  const [notes, setNotes] = useState<string | null | undefined>(
    selectedDetection?.notes
  );

  const viewingComplexCaseDetection = useMemo(() => {
    return selectedDetection != null && complexCase;
  }, [selectedDetection, complexCase]);

  const [updateAllDetections, setUpdateAllDetections] = useState(
    !viewingComplexCaseDetection
  );
  const { data: detectionData } = useSuspenseQuery(
    getDetectionOptions(selectedDetection?.id)
  );
  const navigate = useNavigate();

  function getDefaultSelectedDetection() {
    if (!complexCase) {
      return detectionsData.data[0];
    }
    if (detectionId != null) {
      return detectionsData.data.find((v) => v.id == detectionId) ?? null;
    }
    return null;
  }

  useEffect(() => {
    if (selectedDetection == null) {
      setUpdateAllDetections(true);
    } else if (viewingComplexCaseDetection) {
      setUpdateAllDetections(false);
    }
    if (selectedDetection != null) {
      setNotes(selectedDetection.notes);
    }
  }, [selectedDetection]);

  useEffect(() => {
    setSelectedDetection(getDefaultSelectedDetection());
  }, [detectionId, detectionsData]);

  useEffect(() => {
    setRespondedAt(selectedDetection?.respondedAt);
  }, [selectedDetection]);

  // If they're clicking case links in the timeline, we want to scroll to the top
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [caseId]);

  async function updateRespondedAt(
    respondedAt: string = new Date().toISOString()
  ) {
    if (complexCase && selectedDetection == null) {
      for (const detection of detectionsData.data) {
        await updateDetection({ respondedAt }, detection.id);
      }
    } else {
      await updateDetection({ respondedAt });
    }
  }

  const whatHappened = useMemo(() => {
    if (
      (viewingComplexCaseDetection || !complexCase) &&
      detectionData != null
    ) {
      return <HoverCards htmlContent={detectionData.whatHappened!} />;
    }

    return (
      <div>
        On {utcDateTime(caseData.firstDetectionSourceDetectedAt)} Wirespeed
        observed multiple related detections that have been grouped into a
        single case.
      </div>
    );
  }, [
    complexCase,
    detectionData,
    viewingComplexCaseDetection,
    caseData.firstDetectionSourceDetectedAt
  ]);

  const assetSearchSettings = useMemo(() => {
    let search = '';
    const andParts: string[] = [];
    const orParts: string[] = [];
    if (detectionData?.sourceDetectedAt != null) {
      const started = moment(detectionData.sourceDetectedAt).subtract(
        6,
        'hours'
      );
      const ended = moment(detectionData.sourceIngestedAt).add(6, 'hour');
      andParts.push(
        `time >= "${started.toISOString().replace(/Z$/, '')}" AND time <= "${ended.toISOString().replace(/Z$/, '')}"`
      );
    }
    if (assets.directory.length > 0) {
      const users = assets.directory
        .filter((v) => v.email != null)
        .map((v) => {
          return `"${v.email}"`;
        })
        .filter(Boolean);
      orParts.push(...users);
    }

    if (assets.domains.length > 0) {
      const domains = assets.domains
        .filter((v) => v.name != null)
        .map((v) => {
          return `"${v.name}"`;
        })
        .filter(Boolean);
      orParts.push(...domains);
    }
    if (assets.endpoints.length > 0) {
      const endpoints = assets.endpoints
        .filter((v) => v.name != null)
        .map((v) => {
          return `"${v.name}"`;
        })
        .filter(Boolean);
      orParts.push(...endpoints);
    }

    if (assets.ips.length > 0) {
      const ips = assets.ips
        .filter((v) => v.ipv4 != null || v.ipv6 != null)
        .map((v) => {
          return `"${v.ipv4 ?? v.ipv6}"`;
        })
        .filter(Boolean);
      orParts.push(...ips);
    }
    if (orParts.length > 0) {
      const joined = `(${orParts.join(' OR ')})`;
      andParts.push(joined);
    }
    if (andParts.length > 0) {
      search = `${andParts.join(' AND ')}`;
    }
    return search;
  }, [assets, detectionData]);

  const nextSteps = useMemo(() => {
    if (
      (viewingComplexCaseDetection || !complexCase) &&
      detectionData != null
    ) {
      return <HoverCards htmlContent={detectionData.nextSteps!} />;
    }
    return (
      <div>
        <p>Review each of the following detections as part of this case</p>
        <ul>
          {detectionsData.data.map((detection, idx) => (
            <HoverCard
              openDelay={50}
              closeDelay={50}
              key={`complex-detection-${detection.id}`}
            >
              <HoverCardTrigger asChild>
                <Link
                  key={detection.id}
                  to="/cases/$caseId"
                  params={{ caseId: caseData.id }}
                  search={{ detectionId: detection.id }}
                >
                  <li className="cursor-pointer text-muted-foreground flex flex-row flex-wrap items-center gap-1 list-disc list-inside">
                    {getCaseStatusBadge(detection.status as Status, 'text-xs')}
                    <span className="text-blue-500 hover:underline">
                      {detection.sid}
                    </span>
                    -<span className="text-foreground">{detection.title}</span>-
                    <span className="text-muted-foreground text-xs">
                      {detection.sourceName}
                    </span>
                  </li>
                </Link>
              </HoverCardTrigger>
              <HoverCardComponent
                entityType={ENTITY_TYPE.DETECTION}
                id={detection.id}
              />
            </HoverCard>
          ))}
        </ul>
      </div>
    );
  }, [
    detectionsData,
    selectedDetection,
    complexCase,
    caseId,
    viewingComplexCaseDetection
  ]);

  const timeline = useMemo(() => {
    let logs: components['schemas']['Detection']['logs'] = [];
    if (detectionsData.totalCount == 1) {
      logs = detectionsData.data[0]?.logs;
    } else if (selectedDetection != null) {
      logs = selectedDetection.logs;
    } else {
      logs = detectionsData.data.flatMap((v) =>
        [...v.logs].map((l) => ({
          log: v.sid + ' - ' + l.log,
          timestamp: l.timestamp,
          debug: l.debug
        }))
      );
    }
    return [...logs]
      .filter((v) => (showDebugLogs ? true : !v.debug))

      .sort(
        (a, b) =>
          new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
      );
  }, [detectionsData, selectedDetection, showDebugLogs]);

  async function reingestCaseOrDetection() {
    if (viewingComplexCaseDetection) {
      const promise = apiClient.POST('/detection/{id}/reingest', {
        params: { path: { id: selectedDetection!.id } }
      });
      toast.promise(promise, {
        loading: 'Reingesting detection...'
      });
      const response = await promise;
      if (response.error != null) {
        toast.error('Error reingesting detection');
        return;
      }
      await navigate({
        to: '/cases/$caseId',
        params: { caseId: response.data.caseId! },
        search: { detectionId: response.data.id }
      });
      toast.success('Detection reingested, page has been updated');
    } else {
      const promise = apiClient.POST('/cases/{id}/reingest', {
        params: { path: { id: caseData.id } }
      });
      toast.promise(promise, {
        loading: 'Reingesting case...'
      });
      const response = await promise;
      if (response.error != null) {
        toast.error('Error reingesting case');
        return;
      }
      await navigate({
        to: '/cases/$caseId',
        params: { caseId: response.data.id }
      });
      toast.success('Case reingested, page has been updated');
    }
  }

  async function deleteCaseOrDetection() {
    if (viewingComplexCaseDetection) {
      const response = await apiClient.DELETE('/detection/{id}', {
        params: { path: { id: selectedDetection!.id } }
      });
      if (response.error != null) {
        toast.error('Error deleting detection');
        return;
      }
      toast.warning('Detection deleted');
      await queryClient.invalidateQueries({ queryKey: [DETECTIONS_QUERY_KEY] });
      await navigate({ to: '/cases/$caseId', params: { caseId: caseData.id } });
    } else {
      const response = await apiClient.DELETE('/cases/{id}', {
        params: { path: { id: caseData.id } }
      });
      if (response.error != null) {
        toast.error('Error deleting case');
        return;
      }
      toast.warning('Case deleted');
      await navigate({ to: '/cases' });
    }
    setConfirmDelete(false);
  }

  async function updateStatusAndVerdict(dto: {
    status?: Status;
    verdict?: Verdict;
  }) {
    if (updateAllDetections) {
      await Promise.all(
        detectionsData.data.map((detection) =>
          updateDetection({
            ...dto
          })
        )
      );
      await updateCase(dto);
    } else {
      await updateDetection(dto);
    }
    await queryClient.invalidateQueries({ queryKey: [RECENT_CASES_QUERY_KEY] });
  }

  async function updateDetection(
    dto: components['schemas']['UpdateDetectionDto'],
    id: string | undefined = selectedDetection?.id
  ) {
    if (id == null) {
      return;
    }
    if (dto.notes == '') {
      dto.notes = null;
    }
    if (dto.respondedAt != null) {
      const detection = detectionsData.data.find((v) => v.id == id)!;

      if (new Date(dto.respondedAt) < new Date(detection.verdictedAt!)) {
        toast.error('Responded at cannot be before the verdicted at date');
        return;
      }
    }
    const response = await apiClient.PATCH('/detection/{id}', {
      params: { path: { id: id } },
      body: dto
    });
    if (response.error != null) {
      toast.error('Error updating detection');
      return;
    }
    await queryClient.invalidateQueries({ queryKey: [DETECTIONS_QUERY_KEY] });
    await queryClient.invalidateQueries({ queryKey: [CASES_QUERY_KEY] });
    if (dto.status && dto.status == Status.CLOSED) {
      toast.success('🧑‍⚖️ Detection Closed');
    } else {
      toast.success('Detection Updated');
    }
  }

  async function updateCase(dto: components['schemas']['UpdateCaseDto']) {
    const response = await apiClient.PATCH('/cases/{id}', {
      params: { path: { id: caseData.id } },
      body: dto
    });
    if (response.error != null) {
      toast.error('Error updating case');
      return;
    }
    await queryClient.invalidateQueries({ queryKey: [CASES_QUERY_KEY] });
    if (dto.status) {
      if (dto.status == Status.CLOSED) {
        toast.success('🧑‍⚖️ Case Closed');
      } else {
        toast.success('Case Updated');
      }
    }
  }

  async function copyDetectionToClipboard() {
    if (selectedDetection == null) {
      return;
    }
    const data = apiClient.GET('/detection/{idOrSid}', {
      params: { path: { idOrSid: selectedDetection.id } }
    });
    toast.promise(data, {
      loading: 'Copying detection to clipboard...',
      success: 'Copied detection to clipboard'
    });
    const response = await data;

    copy(JSON.stringify(response.data));
  }

  const minRespondedAt = useMemo(() => {
    if (selectedDetection != null) {
      return selectedDetection.verdictedAt;
    }
    const maxVerdictedAt = detectionsData?.data.reduce((max, detection) => {
      const verdictedAt = new Date(detection.verdictedAt!);
      return verdictedAt > max ? verdictedAt : max;
    }, new Date(0));
    return maxVerdictedAt?.toISOString();
  }, [detectionsData?.data, selectedDetection]);

  const rawDetails = useMemo(() => {
    if (
      selectedDetection == null ||
      selectedDetection.raw == null ||
      Object.values(selectedDetection.raw).length == 0
    ) {
      return 'No detection details available';
    }
    const details = getDetailsFromRaw(selectedDetection?.raw, timezone);
    const out: ReactNode[] = details.map((v) => (
      <div className="flex gap-2 justify-between overflow-hidden" key={v.key}>
        <h2 className="font-mono  text-muted-foreground flex-shrink-0">
          {v.key}
        </h2>
        <span className="truncate whitespace-nowrap text-ellipsis">
          {v.value}
        </span>
      </div>
    ));
    return out;
  }, [selectedDetection]);

  return (
    <AppLayout>
      <ManageExclusion
        title="Create Exclusion"
        detectionSid={selectedDetection?.sid}
        description="Automatically close future detections that match this detection"
        open={showCreateExclusion}
        onClose={() => setShowCreateExclusion(false)}
      />
      <Dialog open={showEditNotes} onOpenChange={setShowEditNotes}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              {selectedDetection?.notes == null ? 'Add Note' : 'Edit Note'}
            </DialogTitle>
            <DialogDescription>
              Provide information about this detection
            </DialogDescription>
          </DialogHeader>
          <div>
            <Textarea
              rows={4}
              value={notes ?? ''}
              onChange={(e) => setNotes(e.target.value)}
            />
          </div>
          <DialogFooter>
            <Button
              variant={
                notes != selectedDetection?.notes
                  ? 'outlineDestructive'
                  : 'outline'
              }
              onClick={() => {
                setShowEditNotes(false);
                setNotes(selectedDetection?.notes);
              }}
            >
              {notes != selectedDetection?.notes ? 'Cancel' : 'Close'}
            </Button>
            <Button
              onClick={async () => {
                await updateDetection({ notes });
                setShowEditNotes(false);
              }}
            >
              Save
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog open={confirmDelete} onOpenChange={setConfirmDelete}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              Are you sure you want to delete this{' '}
              {viewingComplexCaseDetection ? 'detection' : 'case'}?
            </DialogTitle>
            <DialogDescription>This action cannot be undone</DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <DialogClose asChild>
              <Button variant="outline">Cancel</Button>
            </DialogClose>
            <Button onClick={deleteCaseOrDetection} variant="destructive">
              Delete
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <main className="grid pb-4 grid-cols-1 items-start gap-4">
        {detectionData?.duplicateDetectionId != null && (
          <Alert variant="warning">
            <ExclamationTriangleIcon className="h-4 w-4" />
            <AlertTitle>Heads up!</AlertTitle>
            <AlertDescription>
              This case was identified as a duplicate. Click{' '}
              <Link
                className="text-blue-500"
                to="/detections/$detectionId"
                params={{ detectionId: detectionData.duplicateDetectionId }}
              >
                here
              </Link>{' '}
              to view the original detection.
            </AlertDescription>
          </Alert>
        )}
        {!assets.files?.every((v) => v.enrichedViaIntegration) && (
          <Alert variant="warning">
            <ExclamationTriangleIcon className="h-4 w-4" />
            <AlertTitle>Heads up!</AlertTitle>
            <AlertDescription>
              We were unable to enrich some files for this case with our
              enrichment integrations. We are taking the source integration's
              information as authoritative. This may increase false positives.
            </AlertDescription>
          </Alert>
        )}

        <div className="lg:col-span-3 flex flex-col gap-4">
          <Card className="w-full hyphens-auto">
            <CardHeader className="flex bg-muted/50 flex-col gap-2 lg:gap-0 lg:flex-row items-start lg:justify-between">
              <div className="flex flex-row items-start">
                {viewingComplexCaseDetection ? (
                  <Link to="/cases/$caseId" params={{ caseId: caseData.id }}>
                    <ArrowRightIcon className="h-8 w-8 mr-2 cursor-pointer rotate-180 rounded-md p-2 border hover:bg-muted" />
                  </Link>
                ) : null}
                <div className="break-all lg:break-normal">
                  <CardTitle className="flex gap-1 text-2xl flex-row items-end">
                    <CopyToClipboard text={caseData.sid} />
                  </CardTitle>
                  <>
                    <CardDescription>
                      {selectedDetection?.sid ?? caseData.sid} -{' '}
                      {selectedDetection?.title ?? caseData.name} -{' '}
                      {selectedDetection?.sourceName ?? caseData.title}
                    </CardDescription>
                    <div className="flex mt-1 flex-wrap gap-2">
                      <Statistics
                        assets={assets}
                        caseData={caseData}
                        detections={detectionsData.data}
                        selectedDetection={selectedDetection}
                      />
                    </div>
                  </>
                </div>
              </div>

              <div className="flex gap-4 flex-row flex-wrap">
                {(selectedDetection?.ocsfDetectionFinding as any)?.finding_info
                  ?.src_url != null && (
                  <Link
                    target="_blank"
                    to={
                      (selectedDetection?.ocsfDetectionFinding as any)
                        ?.finding_info?.src_url
                    }
                  >
                    <Button variant="outline">
                      View in{' '}
                      {
                        integrationMetadata.find(
                          (i) =>
                            i.slug === selectedDetection?.integrationPlatform
                        )?.name
                      }
                    </Button>
                  </Link>
                )}
                <DropdownMenu
                  open={actionsOpen}
                  onOpenChange={setActionsOpen}
                  requiredRole={ROLE.ANALYST}
                >
                  <DropdownMenuTrigger asChild>
                    <Button variant="outline">Actions</Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className="max-w-128">
                    <DropdownMenuItem
                      key="notes"
                      disabled={selectedDetection == null}
                      className="cursor-pointer"
                      onClick={() => setShowEditNotes(true)}
                    >
                      <div>
                        <h4 className="font-semibold">
                          {selectedDetection == null ||
                          selectedDetection.notes == null
                            ? 'Add Note'
                            : 'Edit Note'}
                        </h4>
                        <p className="text-muted-foreground">
                          {selectedDetection == null
                            ? 'Please select a detection to add a note'
                            : selectedDetection.notes == null
                              ? 'Add a note to this detection'
                              : 'Edit the note for this detection'}
                        </p>
                      </div>
                    </DropdownMenuItem>
                    <DropdownMenuSeparator />
                    {selectedDetection?.respondedAt == null ? (
                      <DropdownMenuItem
                        className="font-semibold"
                        requiredRole={ROLE.ANALYST}
                        onClick={() => updateRespondedAt()}
                      >
                        Mark as Responded To
                      </DropdownMenuItem>
                    ) : (
                      <DropdownMenuSub requiredRole={ROLE.ANALYST}>
                        <DropdownMenuSubTrigger className="font-semibold">
                          Update MTTR
                        </DropdownMenuSubTrigger>
                        <DropdownMenuSubContent>
                          <div className="flex flex-row items-end">
                            <div className="flex flex-col gap-1">
                              <Label>Responded At</Label>
                              <DateTimePicker
                                date={respondedAt}
                                notAfter={new Date().toISOString()}
                                notBefore={minRespondedAt}
                                setDate={setRespondedAt}
                              />
                            </div>

                            <Button
                              onClick={async () => {
                                await updateRespondedAt(respondedAt);
                                setActionsOpen(false);
                              }}
                            >
                              Update
                            </Button>
                          </div>
                        </DropdownMenuSubContent>
                      </DropdownMenuSub>
                    )}

                    <DropdownMenuSeparator />
                    <DropdownMenuItem
                      key="exclusion"
                      disabled={selectedDetection == null}
                      className="cursor-pointer"
                      onClick={() => setShowCreateExclusion(true)}
                    >
                      <div>
                        <h4 className="font-semibold">Create Exclusion</h4>
                        <p className="text-muted-foreground">
                          {selectedDetection == null
                            ? 'Please select a detection to create an exclusion'
                            : 'Create an exclusion for this detection'}
                        </p>
                      </div>
                    </DropdownMenuItem>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem
                      key="reingest"
                      className="cursor-pointer"
                      onClick={reingestCaseOrDetection}
                    >
                      <div>
                        <h4 className="font-semibold">Reingest</h4>
                        <p className="text-muted-foreground hidden lg:block">
                          Reprocess this{' '}
                          {viewingComplexCaseDetection ? 'detection' : 'case'}{' '}
                          and potentially interact with users again
                        </p>
                      </div>
                    </DropdownMenuItem>

                    <ContainmentDropdown
                      caseId={caseData.id}
                      detectionId={selectedDetection?.id}
                      assets={assets}
                    />
                    <DropdownMenuSeparator />
                    {caseData.status != Status.CLOSED ? (
                      <DropdownMenuSub requiredRole={ROLE.ANALYST}>
                        <DropdownMenuSubTrigger>
                          <div>
                            <h4 className="font-semibold">
                              Close {updateAllDetections ? 'Case' : 'Detection'}
                            </h4>
                            <p className="text-muted-foreground hidden lg:block">
                              Close and provide a verdict.
                            </p>
                          </div>
                        </DropdownMenuSubTrigger>
                        <DropdownMenuPortal>
                          <DropdownMenuSubContent>
                            <DropdownMenuItem
                              key="close-actionable"
                              className="cursor-pointer"
                              onClick={() =>
                                updateStatusAndVerdict({
                                  verdict: Verdict.MALICIOUS,
                                  status: Status.CLOSED
                                })
                              }
                            >
                              <div>
                                <h4 className="font-semibold">
                                  Close as Malicious
                                </h4>
                                <p className="text-muted-foreground">
                                  {updateAllDetections ? 'Case' : 'Detection'}{' '}
                                  resulted in containment
                                </p>
                              </div>
                            </DropdownMenuItem>
                            <DropdownMenuSeparator />

                            <DropdownMenuItem
                              key="close-non"
                              className="cursor-pointer"
                              onClick={() =>
                                updateStatusAndVerdict({
                                  verdict: Verdict.BENIGN,
                                  status: Status.CLOSED
                                })
                              }
                            >
                              <div>
                                <h4 className="font-semibold">
                                  Close as Benign
                                </h4>
                                <p className="text-muted-foreground">
                                  {updateAllDetections ? 'Case' : 'Detection'}{' '}
                                  did not result in containment
                                </p>
                              </div>
                            </DropdownMenuItem>
                            <DropdownMenuSeparator />

                            <DropdownMenuItem
                              key="close-unsure"
                              className="cursor-pointer"
                              onClick={() =>
                                updateStatusAndVerdict({
                                  verdict: Verdict.SUSPICIOUS,
                                  status: Status.CLOSED
                                })
                              }
                            >
                              <div>
                                <h4 className="font-semibold">
                                  Close as Suspicious
                                </h4>
                                <p className="text-muted-foreground">
                                  {updateAllDetections ? 'Case' : 'Detection'}{' '}
                                  was handled in a unique manner
                                </p>
                              </div>
                            </DropdownMenuItem>
                            {viewingComplexCaseDetection && (
                              <>
                                <DropdownMenuSeparator />
                                <div className="p-2 flex items-center justify-between">
                                  <Label className="text-xs text-muted-foreground">
                                    Update all detections for case
                                  </Label>
                                  <Switch
                                    checked={updateAllDetections}
                                    onCheckedChange={setUpdateAllDetections}
                                  />
                                </div>
                              </>
                            )}
                          </DropdownMenuSubContent>
                        </DropdownMenuPortal>
                      </DropdownMenuSub>
                    ) : (
                      <DropdownMenuItem
                        requiredRole={ROLE.ANALYST}
                        onClick={() =>
                          updateStatusAndVerdict({ status: Status.ESCALATED })
                        }
                      >
                        <div>
                          <h4 className="font-semibold">Reopen Case</h4>
                          <p className="text-muted-foreground hidden lg:block">
                            Reopen to change status or continue working this
                            case
                          </p>
                        </div>
                      </DropdownMenuItem>
                    )}
                    <DropdownMenuSeparator />
                    <DropdownMenuItem
                      key="clipboard"
                      disabled={selectedDetection == null}
                      className="cursor-pointer"
                      onClick={copyDetectionToClipboard}
                    >
                      <div>
                        <h4 className="font-semibold">
                          Copy Data to Clipboard
                        </h4>
                        <p className="text-muted-foreground">
                          {selectedDetection == null
                            ? 'Please select a detection to copy data'
                            : 'Export a JSON object of this detection'}
                        </p>
                      </div>
                    </DropdownMenuItem>
                    {user.superAdmin && (
                      <>
                        <DropdownMenuSeparator />

                        <DropdownMenuItem
                          key="delete"
                          className="cursor-pointer"
                          onClick={() => setConfirmDelete(true)}
                        >
                          <div>
                            <h4 className="font-semibold">Delete</h4>
                            <p className="text-muted-foreground hidden lg:block">
                              Permanently delete this{' '}
                              {viewingComplexCaseDetection == null
                                ? 'detection'
                                : 'case'}{' '}
                              and all associated data
                            </p>
                          </div>
                        </DropdownMenuItem>
                      </>
                    )}
                    {user.superAdmin && detectionData != null && (
                      <>
                        <DropdownMenuSeparator />
                        <DropdownMenuItem onClick={() => setShowAQL(true)}>
                          <h4 className="font-semibold">AQL</h4>
                        </DropdownMenuItem>
                        <DropdownMenuSeparator />
                      </>
                    )}
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </CardHeader>
            <CardContent className="pt-4 grid overflow-hidden text-wrap border-t">
              <h2 className="font-semibold">What Happened</h2>
              <div ref={whatHappenedRef}>{whatHappened}</div>
              {selectedDetection?.sourceDescription != null && (
                <>
                  <h2 className="font-semibold mt-4">
                    {
                      integrationMetadata.find(
                        (i) => i.slug === selectedDetection?.integrationPlatform
                      )?.name
                    }{' '}
                    Description
                  </h2>
                  <div className="text-sm lg:text-base break-all lg:break-normal hyphens-auto">
                    {selectedDetection?.sourceDescription}
                  </div>
                </>
              )}
              <h2 className="font-semibold mt-4">Next Steps</h2>
              <div ref={nextStepsRef}>{nextSteps}</div>
              {selectedDetection?.notes != null && (
                <>
                  <h2 className="font-semibold mt-4">Notes</h2>
                  {selectedDetection?.notes}
                </>
              )}
              <h2 className="font-semibold mt-4">Assets</h2>
              <AssetsList assets={assets} />
            </CardContent>
          </Card>

          <Card className="flex-1">
            <CardHeader className="flex gap-4 bg-muted/50 flex-col lg:flex-row lg:items-center lg:justify-between">
              <div>
                <CardTitle>Timeline</CardTitle>
                <CardDescription>
                  Below is a log of every decision made by Wirespeed
                </CardDescription>
              </div>
              <div className="flex gap-2">
                {showAQL && detectionData != null && (
                  <AQL
                    detectionId={detectionData.id}
                    onClose={() => setShowAQL(false)}
                  />
                )}

                {detectionData != null ? (
                  <>
                    <HoverCard openDelay={500}>
                      <HoverCardTrigger asChild>
                        <Button
                          onClick={() =>
                            updateDetection({ handledCorrectly: true })
                          }
                          variant="outline"
                          className="p-2"
                        >
                          <HandThumbUpIcon className="h-4 w-4" />
                        </Button>
                      </HoverCardTrigger>
                      <HoverCardContent className="text-sm">
                        This detection was handled correctly.
                      </HoverCardContent>
                    </HoverCard>
                    <HoverCard openDelay={500}>
                      <HoverCardTrigger asChild>
                        <Button
                          onClick={() =>
                            updateDetection({ handledCorrectly: false })
                          }
                          variant="outline"
                          className="p-2"
                        >
                          <HandThumbDownIcon className="h-4 w-4" />
                        </Button>
                      </HoverCardTrigger>
                      <HoverCardContent className="text-sm">
                        This detection was handled incorrectly.
                      </HoverCardContent>
                    </HoverCard>
                  </>
                ) : null}
              </div>
            </CardHeader>
            <CardContent className="pt-4 max-h-[512px] border-t overflow-auto">
              <ul role="list" className="space-y-6 break-all lg:break-normal">
                {timeline.map((log, idx) => (
                  <li
                    key={`case-${caseData.id}-logs-${idx}`}
                    className="relative hover:font-semibold transition-all flex gap-x-4"
                  >
                    <div
                      className={cn(
                        idx === timeline.length - 1 ? 'h-6' : '-bottom-6',
                        'absolute left-0 top-0 flex w-6 justify-center'
                      )}
                    >
                      <div className="w-px bg-muted" />
                    </div>

                    <>
                      <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-background">
                        <div className="h-1.5 w-1.5 rounded-full bg-muted ring-1 ring-gray-300" />
                      </div>
                      <div className="flex  flex-col  lg:flex-row lg:justify-between w-full">
                        <div className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                          <LogItem caseId={caseData.id} log={log.log} />
                        </div>
                        <time
                          dateTime={log.timestamp}
                          className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                        >
                          {localDateTime(log.timestamp, timezone)}
                        </time>
                      </div>
                    </>
                  </li>
                ))}
              </ul>
            </CardContent>
            <CardFooter className="flex flex-row items-center justify-between border-t bg-muted/50 px-6 py-3">
              <div className="text-xs text-muted-foreground">
                All times {getTimeZoneAbbreviation(timezone)}
              </div>
              <div className="flex items-center gap-1">
                <Label className="text-xs text-muted-foreground">
                  Show debug logs
                </Label>
                <Switch
                  checked={showDebugLogs}
                  onCheckedChange={setShowDebugLogs}
                />
              </div>
            </CardFooter>
          </Card>
          {detectionData != null && assetSearchSettings.length > 0 && (
            <EventsTable
              key={detectionData.id}
              description="Search across all events associated with assets from this case. Default filtering is a 12-hour window surrounding the event creation and alerting time."
              defaultSearch={assetSearchSettings}
            />
          )}

          {selectedDetection != null && (
            <Card className="overflow-hidden">
              <CardHeader className="flex flex-row bg-muted/50 items-start">
                <CardTitle>Original Detection Details</CardTitle>
              </CardHeader>
              <CardContent className="p-6 overflow-y-auto text-sm border-t">
                <div className="grid gap-y-2 xl:grid-cols-2 gap-x-8 grid-cols-1">
                  {rawDetails}
                </div>
              </CardContent>
              <CardFooter className="flex flex-row items-center border-t bg-muted/50 px-6 py-3">
                <div className="text-xs text-muted-foreground">
                  <span>All times {getTimeZoneAbbreviation(timezone)}</span>
                </div>
              </CardFooter>
            </Card>
          )}
        </div>
      </main>
    </AppLayout>
  );
}

function Statistics(props: {
  selectedDetection: components['schemas']['Detection'] | null;
  caseData: components['schemas']['Case'];
  detections: components['schemas']['Detection'][];
  assets: components['schemas']['Assets'];
}) {
  const {
    containsVIP,
    containsHVA,
    testMode,
    reingested,
    verdict,
    sourceDetectedAt,
    sourceIngestedAt,
    status,
    verdictedAt,
    respondedAt,
    integrationPlatform,
    createdAt
  } = useMemo(() => {
    return {
      containsVIP:
        props.selectedDetection != null
          ? props.assets.directory.some((v) => v.vip)
          : props.caseData.containsVIP,
      containsHVA:
        props.selectedDetection != null
          ? props.assets.endpoints.some((v) => v.hva)
          : props.caseData.containsHVA,
      detectionCount: props.detections.length,
      testMode: props.selectedDetection?.testMode ?? props.caseData.testMode,
      reingested:
        props.selectedDetection?.reingested ?? props.caseData.reingested,
      status: props.selectedDetection?.status ?? props.caseData.status,
      verdictedAt:
        props.selectedDetection?.verdictedAt ?? props.caseData.verdictedAt,
      createdAt: props.selectedDetection?.createdAt ?? props.caseData.createdAt,
      verdict: props.selectedDetection?.verdict ?? props.caseData.verdict,
      integrationPlatform: props.selectedDetection?.integrationPlatform,
      sourceDetectedAt:
        props.selectedDetection?.sourceDetectedAt ??
        props.caseData.firstDetectionSourceDetectedAt,
      sourceIngestedAt:
        props.selectedDetection?.sourceIngestedAt ??
        props.caseData.firstDetectionSourceIngestedAt,
      categories:
        props.selectedDetection?.category != null
          ? [props.selectedDetection.category]
          : props.caseData.categories,
      respondedAt: props.selectedDetection?.respondedAt
    };
  }, [props.caseData, props.selectedDetection]);

  const { integrationMetadata } = useRouteContext({ from: '/_application' });
  const statisticsText = useMemo(() => {
    const parts: ReactNode[] = [];
    if (integrationMetadata.find((i) => i.slug === integrationPlatform)?.beta) {
      parts.push(
        <HoverBadge
          variant="outlineInfo"
          key="beta"
          text={`Beta`}
          hoverText={`This integration is in beta, data may be incomplete.`}
        />
      );
    }
    parts.push(
      <HoverBadge
        variant={getCaseStatusBadgeVariant(status as Status)}
        key="status"
        text={getStatusConfigByStatus(status).display}
        hoverText={`The status of this case`}
      />
    );
    if (verdict != null) {
      parts.push(
        <HoverBadge
          variant={getVerdictBadgeVariant(verdict as Verdict)}
          key="verdict"
          text={getVerdictConfigByVerdict(verdict).display}
          hoverText={`The verdict of this case`}
        />
      );
    }
    if (testMode) {
      parts.push(
        <HoverBadge
          variant="outlineWarning"
          key="test-mode"
          text={`Test Mode`}
          hoverText={
            'This case was ingested in test mode, timeline information and statistics will be inaccurate.'
          }
        />
      );
    }
    if (reingested) {
      parts.push(
        <HoverBadge
          variant="outlineWarning"
          key="reingested"
          text={`Reingested`}
          hoverText={
            'This case was reingested, timeline information and statistics will be inaccurate.'
          }
        />
      );
    }

    if (containsVIP) {
      parts.push(
        <HoverBadge
          variant="outlineDestructive"
          key="vip"
          text={<CrownIcon className="h-4 w-4" />}
          hoverText={`Involves VIP users`}
        />
      );
    }

    if (containsHVA) {
      parts.push(
        <HoverBadge
          variant="outlineDestructive"
          key="hva"
          text={<CastleIcon className="h-4 w-4" />}
          hoverText={`Involves High Value Assets`}
        />
      );
    }

    if (
      sourceDetectedAt &&
      sourceIngestedAt &&
      sourceDetectedAt != sourceIngestedAt
    ) {
      const sourceDelay = moment.duration(
        calculateTTRSeconds(sourceDetectedAt, sourceIngestedAt) * 1000
      );
      let sourceVariant: 'destructive' | 'outlineSuccess' | 'outlineWarning' =
        'destructive';
      if (sourceDelay.asMinutes() < 5) {
        sourceVariant = 'outlineSuccess';
      } else if (sourceDelay.asMinutes() < 10) {
        sourceVariant = 'outlineWarning';
      }

      parts.push(
        <HoverBadge
          variant={sourceVariant}
          key="source-delay"
          text={`Source delay | ${sourceDelay.humanize()}`}
          hoverText={
            <>
              The time between when the source detects an event and when it is
              published to their API.
              <br />
              <br />
              {numeral(
                calculateTTRSeconds(sourceDetectedAt, sourceIngestedAt)
              ).format()}{' '}
              seconds
            </>
          }
        />
      );
    }
    /**
     * When showing the RAG colors in the UI for our MTTD|MTTR
     * we want to show them the actual time, but color code based on when the event actually went live in their API.
     * E.g. if it wasn't available for 5 minutes, we don't deserve a red color, that's available in the source delay
     */
    const delayToIngestion =
      moment.duration(
        calculateTTRMilliseconds(sourceDetectedAt, sourceIngestedAt)
      ) ?? 0;

    if (sourceDetectedAt && verdictedAt) {
      const mttdDuration = moment.duration(
        calculateTTRMilliseconds(sourceDetectedAt, verdictedAt)
      );
      const actualMTTD =
        mttdDuration.asMilliseconds() - delayToIngestion.asMilliseconds();
      let variant: 'outlineSuccess' | 'outlineWarning' | 'destructive' =
        'destructive';
      if (actualMTTD < 30_000) {
        variant = 'outlineSuccess';
      } else if (actualMTTD < 90_000) {
        variant = 'outlineWarning';
      }
      parts.push(
        <HoverBadge
          key="mttd"
          variant={variant}
          text={`MTTD | ${mttdDuration.humanize()}`}
          hoverText={`${numeral(mttdDuration.asSeconds()).format()} seconds`}
        />
      );
    }

    if (verdictedAt) {
      const verdictDuration = moment.duration(
        calculateTTRMilliseconds(createdAt, verdictedAt)
      );
      let variant: 'outlineSuccess' | 'outlineWarning' | 'destructive' =
        'destructive';
      if (verdictDuration.asSeconds() < 30) {
        variant = 'outlineSuccess';
      } else if (verdictDuration.asSeconds() < 90) {
        variant = 'outlineWarning';
      }
      let verdictText = `${verdictDuration.asMilliseconds()}ms`;
      if (verdictDuration.asSeconds() > 10) {
        verdictText = `${verdictDuration.asSeconds()}s`;
      }
      parts.push(
        <HoverBadge
          key="ttv"
          variant={variant}
          text={<>Time to Verdict | {verdictText}</>}
          hoverText={
            <>
              <span>
                Time taken from ingestion into Wirespeed to a verdict being
                made.
              </span>
              <br />
              <br />
              {verdictDuration.asMilliseconds()}ms
            </>
          }
        />
      );
    }
    if (sourceDetectedAt && respondedAt != null) {
      const mttrDuration = moment.duration(
        calculateTTRMilliseconds(sourceDetectedAt, respondedAt)
      );
      let variant: 'outlineSuccess' | 'outlineWarning' | 'destructive' =
        'destructive';
      const actualMTTR =
        mttrDuration.asMilliseconds() - delayToIngestion.asMilliseconds();

      if (actualMTTR < 30_000) {
        variant = 'outlineSuccess';
      } else if (actualMTTR < 90_000) {
        variant = 'outlineWarning';
      }
      parts.push(
        <HoverBadge
          variant={variant}
          key="mttr"
          text={`MTTR | ${mttrDuration.humanize()}`}
          hoverText={`${numeral(mttrDuration.asSeconds()).format()} seconds`}
        />
      );
    }

    return parts;
  }, [props.caseData, props.selectedDetection]);

  return statisticsText;
}

function ContainmentDropdown(props: {
  assets: components['schemas']['Assets'];
  caseId: string;
  detectionId?: string;
}) {
  const [containAll, setContainAll] = useState(true);
  const [selectedUsers, setSelectedUsers] = useState<string[]>(
    props.assets.directory.map((v) => v.id)
  );
  const [selectedEndpoints, setSelectedEndpoints] = useState<string[]>(
    props.assets.endpoints.map((v) => v.id)
  );

  async function contain() {
    const response = await apiClient.POST('/asset/bulk/contain', {
      body: {
        caseId: props.caseId,
        detectionId: props.detectionId,
        endpointIds: selectedEndpoints,
        directoryIds: selectedUsers
      }
    });
    if (response.error != null) {
      toast.error(response.error.message);
      return;
    }
    toast.success('Assets contained');
  }

  async function uncontain() {
    const response = await apiClient.POST('/asset/bulk/uncontain', {
      body: {
        caseId: props.caseId,
        detectionId: props.detectionId,
        endpointIds: selectedEndpoints,
        directoryIds: selectedUsers
      }
    });
    if (response.error != null) {
      toast.error(response.error.message);
      return;
    }
    toast.success('Assets uncontained');
  }

  function toggleContainAll() {
    if (containAll) {
      setSelectedUsers([]);
      setSelectedEndpoints([]);
    } else {
      setSelectedUsers(props.assets.directory.map((v) => v.id));
      setSelectedEndpoints(props.assets.endpoints.map((v) => v.id));
    }
    setContainAll(!containAll);
  }

  useEffect(() => {
    if (
      selectedUsers.length == props.assets.directory.length &&
      selectedEndpoints.length == props.assets.endpoints.length
    ) {
      setContainAll(true);
    } else {
      setContainAll(false);
    }
  }, [
    selectedUsers,
    selectedEndpoints,
    props.assets.directory,
    props.assets.endpoints
  ]);

  function toggleSelection(id: string, type: 'user' | 'endpoint') {
    if (type == 'user') {
      if (selectedUsers.includes(id)) {
        setSelectedUsers(selectedUsers.filter((v) => v != id));
      } else {
        setSelectedUsers([...selectedUsers, id]);
      }
    } else {
      if (selectedEndpoints.includes(id)) {
        setSelectedEndpoints(selectedEndpoints.filter((v) => v != id));
      } else {
        setSelectedEndpoints([...selectedEndpoints, id]);
      }
    }
  }

  const parts = useMemo(() => {
    const out: ReactNode[] = [];
    if (props.assets.directory.length > 0) {
      out.push(
        <DropdownMenuGroup key="directory-group">
          <DropdownMenuLabel>Users</DropdownMenuLabel>
          {props.assets.directory.map((user) => (
            <DropdownMenuItem
              onClick={(e) => {
                e.preventDefault();
                toggleSelection(user.id, 'user');
              }}
              className="flex items-center cursor-pointer gap-2"
              key={user.id}
            >
              <Checkbox
                id={user.id}
                checked={selectedUsers.includes(user.id)}
                onCheckedChange={(checked) => toggleSelection(user.id, 'user')}
              />
              <Label htmlFor={user.id}>{user.displayName}</Label>
            </DropdownMenuItem>
          ))}
        </DropdownMenuGroup>
      );
    }
    if (
      props.assets.endpoints.length > 0 &&
      props.assets.directory.length > 0
    ) {
      out.push(<DropdownMenuSeparator key="directory-endpoint-separator" />);
    }

    if (props.assets.endpoints.length > 0) {
      out.push(
        <DropdownMenuGroup key="endpoint-group">
          <DropdownMenuLabel>Endpoints</DropdownMenuLabel>
          {props.assets.endpoints.map((endpoint) => (
            <DropdownMenuItem
              onClick={(e) => {
                e.preventDefault();
                toggleSelection(endpoint.id, 'endpoint');
              }}
              className="flex items-center cursor-pointer gap-2"
              key={endpoint.id}
            >
              <Checkbox
                id={endpoint.id}
                checked={selectedEndpoints.includes(endpoint.id)}
                onCheckedChange={(checked) =>
                  toggleSelection(endpoint.id, 'endpoint')
                }
              />
              <Label htmlFor={endpoint.id}>{endpoint.displayName}</Label>
            </DropdownMenuItem>
          ))}
        </DropdownMenuGroup>
      );
    }
    return out;
  }, [props.assets, selectedEndpoints, selectedUsers]);

  if (
    props.assets.directory.length == 0 &&
    props.assets.endpoints.length == 0
  ) {
    return;
  }

  return (
    <>
      <DropdownMenuSeparator />
      <DropdownMenuSub requiredRole={ROLE.ANALYST}>
        <DropdownMenuSubTrigger>
          <div>
            <h4 className="font-semibold">Contain</h4>
            <p className="text-muted-foreground hidden lg:block">
              Contain or uncontain users and endpoints
            </p>
          </div>
        </DropdownMenuSubTrigger>
        <DropdownMenuPortal>
          <DropdownMenuSubContent className="max-w-96">
            <div className="p-2 flex items-center justify-between">
              <Label className="text-xs text-muted-foreground">
                Select All
              </Label>
              <Switch checked={containAll} onCheckedChange={toggleContainAll} />
            </div>
            <DropdownMenuSeparator key="separator-1" />
            {parts}
            <DropdownMenuSeparator key="separator-2" />
            <div className="flex" key="actions">
              <Button
                className="rounded-none flex-1 -m-1 border-none border-r"
                variant="outlineDestructive"
                disabled={
                  selectedUsers.length == 0 && selectedEndpoints.length == 0
                }
                onClick={contain}
              >
                Contain
              </Button>
              <Button
                onClick={uncontain}
                disabled={
                  selectedUsers.length == 0 && selectedEndpoints.length == 0
                }
                className="rounded-none flex-1 -m-1 border-none"
                variant="outline"
              >
                Uncontain
              </Button>
            </div>
          </DropdownMenuSubContent>
        </DropdownMenuPortal>
      </DropdownMenuSub>
    </>
  );
}

function HoverBadge(props: {
  text: string | ReactNode;
  hoverText: string | ReactNode;
  variant: BadgeProps['variant'];
}) {
  return (
    <HoverCard openDelay={50} closeDelay={50}>
      <HoverCardTrigger>
        <Badge className="cursor-pointer flex" variant={props.variant}>
          {props.text}
        </Badge>
      </HoverCardTrigger>
      <HoverCardContent>{props.hoverText}</HoverCardContent>
    </HoverCard>
  );
}

function AssetCard(props: {
  type: ENTITY_TYPE;
  id: string;
  displayName: string;
}) {
  return (
    <HoverCard openDelay={50} closeDelay={50}>
      <HoverCardTrigger asChild>
        <li
          key={props.displayName}
          className="p-2 text-xs border rounded-md hover:bg-muted flex flex-col"
        >
          <span className="font-semibold whitespace-nowrap truncate max-w-[128px] lg:max-w-[256px] overflow-hidden">
            {props.displayName}
          </span>
          <span className="text-muted-foreground">
            {EntityTypeConfig[props.type].display}
          </span>
        </li>
      </HoverCardTrigger>
      <HoverCardComponent entityType={props.type} id={props.id} />
    </HoverCard>
  );
}

function AssetsList(props: { assets: components['schemas']['Assets'] }) {
  const sections: ReactNode[] = [];
  for (const user of props.assets.directory) {
    sections.push(
      <Link
        key={user.id}
        to="/assets/users/$userId"
        params={{ userId: user.id }}
      >
        <AssetCard
          key={user.id}
          id={user.id}
          type={ENTITY_TYPE.USER}
          displayName={user.displayName}
        />
      </Link>
    );
  }
  for (const endpoint of props.assets.endpoints) {
    sections.push(
      <Link
        key={endpoint.id}
        to="/assets/endpoints/$endpointId"
        params={{ endpointId: endpoint.id }}
      >
        <AssetCard
          key={endpoint.id}
          id={endpoint.id}
          type={ENTITY_TYPE.ENDPOINT}
          displayName={endpoint.displayName}
        />
      </Link>
    );
  }

  for (const userAgent of props.assets.userAgents) {
    sections.push(
      <Link
        key={userAgent.id}
        to="/assets/user-agents/$userAgentId"
        params={{ userAgentId: userAgent.id }}
      >
        <AssetCard
          key={userAgent.id}
          id={userAgent.id}
          type={ENTITY_TYPE.USER_AGENT}
          displayName={userAgent.displayName}
        />
      </Link>
    );
  }

  for (const file of props.assets.files) {
    sections.push(
      <Link
        key={file.id}
        to="/assets/files/$fileId"
        params={{ fileId: file.id }}
      >
        <AssetCard
          key={file.id}
          id={file.id}
          type={ENTITY_TYPE.FILE}
          displayName={file.displayName}
        />
      </Link>
    );
  }

  for (const process of props.assets.processes) {
    sections.push(
      <Link
        key={process.id}
        to="/assets/processes/$processId"
        params={{ processId: process.id }}
      >
        <AssetCard
          key={process.id}
          id={process.id}
          type={ENTITY_TYPE.PROCESS}
          displayName={process.displayName}
        />
      </Link>
    );
  }

  for (const domain of props.assets.domains) {
    sections.push(
      <Link
        key={domain.id}
        to="/assets/domains/$domainId"
        params={{ domainId: domain.id }}
      >
        <AssetCard
          key={domain.id}
          id={domain.id}
          type={ENTITY_TYPE.DOMAIN}
          displayName={domain.displayName}
        />
      </Link>
    );
  }

  for (const location of props.assets.locations) {
    sections.push(
      <Link
        key={location.id}
        to="/assets/locations/$locationId"
        params={{ locationId: location.id }}
      >
        <AssetCard
          key={location.id}
          id={location.id}
          type={ENTITY_TYPE.LOCATION}
          displayName={location.displayName}
        />
      </Link>
    );
  }

  for (const ip of props.assets.ips) {
    sections.push(
      <Link key={ip.id} to="/assets/ips/$ipId" params={{ ipId: ip.id }}>
        <AssetCard
          key={ip.id}
          id={ip.id}
          type={ENTITY_TYPE.IP_ADDRESS}
          displayName={ip.displayName}
        />
      </Link>
    );
  }

  if (sections.length == 0) {
    return <p className="text-muted-foreground">No assets found</p>;
  }

  return (
    <ul className="flex flex-row divide-x divide-muted gap-2 flex-wrap">
      {sections}
    </ul>
  );
}

async function getAQLQuestions(caseId: string) {
  const caseMatch = await apiClient.GET('/detection/{id}/aql-questions', {
    params: { path: { id: caseId } }
  });
  if (caseMatch.error != null) {
    throw new Error('Error getting AQL information');
  }
  return caseMatch.data;
}

function AQL(props: { detectionId: string; onClose: () => void }) {
  const [open, setOpen] = useState(true);
  const queryClient = useQueryClient();
  const { data: aql, isLoading } = useQuery({
    queryKey: ['detection-aql', props.detectionId],
    queryFn: () => getAQLQuestions(props.detectionId)
  });
  const [questions, setQuestions] = useState<TAQLQuestionConfigWithDefect[]>(
    []
  );

  useEffect(() => {
    if (!open) {
      props.onClose();
    }
  }, [open]);

  useEffect(() => {
    const newQuestions = Object.values(AQLQuestionConfig).map((v) => {
      const match = aql?.find((q) => q.question === v.question);
      return {
        ...v,
        comment: match?.comment,
        defect: match?.defect,
        defectReason: match?.defectReason as AQL_DEFECT_REASON
      };
    });
    setQuestions(newQuestions);
  }, [aql]);

  async function submitCaseAQL() {
    const response = await apiClient.PUT('/aql/detection/{id}/submit', {
      params: { path: { id: props.detectionId } }
    });
    if (response.error != null) {
      toast.error(response.error.message);
      return;
    }
    await queryClient.invalidateQueries({ queryKey: [CASES_QUERY_KEY] });

    toast.success('AQL submitted');
    setOpen(false);
  }

  async function quickComplete() {
    const response = await apiClient.PUT('/aql/detection/{id}/no-defects', {
      params: { path: { id: props.detectionId } }
    });
    if (response.error != null) {
      toast.error(response.error.message);
      return;
    }
    toast.success('AQL submitted');
    setOpen(false);
  }

  function handleQuestionUpdated(question: TAQLQuestionConfig) {
    setQuestions((prev) =>
      prev.map((q) => {
        if (q.question === question.question) {
          return question;
        }
        return q;
      })
    );
  }

  const defectBadge = useMemo(() => {
    const maxDefectLevel = getMaxDefectLevel(
      questions
        .filter((v) => v.defect && v.defectReason != null)
        .map((q) => AQLDefectReasonConfig[q.defectReason!].defectLevel)
    );
    if (maxDefectLevel == DefectLevel.NONE) {
      return null;
    }
    let badgeVariant: BadgeProps['variant'] = 'outline';
    switch (maxDefectLevel) {
      case DefectLevel.CRITICAL:
        badgeVariant = 'destructive';
        break;
      case DefectLevel.MAJOR:
        badgeVariant = 'warning';
        break;
      default:
        badgeVariant = 'outline';
    }
    return (
      <Badge variant={badgeVariant}>
        {getDefectLevelConfig(maxDefectLevel).display}
      </Badge>
    );
  }, [questions]);

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger asChild>
        <Button onClick={() => setOpen(true)} variant="outline">
          AQL
        </Button>
      </DialogTrigger>
      <DialogContent className="overflow-auto max-h-[80vh]">
        <DialogHeader>
          <DialogTitle className="flex items-center gap-1">
            AQL {defectBadge}
          </DialogTitle>
          <DialogDescription>
            Provide feedback on the quality of this case
          </DialogDescription>
        </DialogHeader>
        <div>
          {isLoading ? (
            <Skeleton className="w-full h-[512px]" />
          ) : (
            <ul className="flex flex-col gap-4">
              {questions.map((q) => (
                <AQLQuestion
                  key={q.question}
                  detectionId={props.detectionId}
                  questionUpdated={handleQuestionUpdated}
                  question={q}
                  defect={q.defect}
                  comment={q.comment}
                  defectReason={q.defectReason as AQL_DEFECT_REASON}
                />
              ))}
            </ul>
          )}
        </div>
        <DialogFooter className="flex flex-col md:flex-row gap-2">
          <Button
            className="flex-1 md:flex-auto"
            variant="outline"
            onClick={() => quickComplete()}
          >
            Complete w/ no more defects
          </Button>
          <Button
            className="flex-1 md:flex-auto"
            disabled={questions.some((q) => q.defect == undefined)}
            variant="outline"
            onClick={() => submitCaseAQL()}
          >
            Complete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

type TAQLQuestionConfigWithDefect = TAQLQuestionConfig & {
  defect?: boolean;
  defectReason?: AQL_DEFECT_REASON;
  comment?: string;
};

function AQLQuestion(props: {
  detectionId: string;
  question: TAQLQuestionConfig;
  comment?: string;
  defect?: boolean;
  questionUpdated: (question: TAQLQuestionConfigWithDefect) => void;
  defectReason: AQL_DEFECT_REASON;
}) {
  const [hasDefect, setHasDefect] = useState<boolean | undefined>(props.defect);
  const [reason, setReason] = useState<AQL_DEFECT_REASON | undefined>(
    props.defectReason
  );
  const [comment, setComment] = useState<string | undefined>(
    props.comment ?? ''
  );
  const debouncedComment = useDebounce(1000, comment);

  useEffect(() => {
    setHasDefect(props.defect);
    setReason(props.defectReason);
    setComment(props.comment);
  }, [props.defect, props.defectReason, props.comment]);

  const reasons = useMemo(() => {
    if (!hasDefect) {
      return;
    }
    if (props.question.reasons.length == 1) {
      setReason(props.question.reasons[0]);
      return;
    }
    return (
      <RadioGroup
        defaultValue={reason}
        onValueChange={(value) => setReason(value as AQL_DEFECT_REASON)}
      >
        {props.question.reasons.map((reason) => (
          <div key={reason} className="flex items-center space-x-2">
            <RadioGroupItem value={reason} id={reason} />
            <Label htmlFor={reason}>{AQLDefectReasonConfig[reason].text}</Label>
          </div>
        ))}
      </RadioGroup>
    );
  }, [props.question.reasons, reason, hasDefect]);

  useEffect(() => {
    (async () => {
      if (
        hasDefect == props.defect &&
        reason == props.defectReason &&
        (comment == props.comment || (comment == '' && !props.comment))
      ) {
        return;
      }
      if (hasDefect == undefined || (hasDefect && !reason)) {
        return;
      }

      const response = await apiClient.PUT('/aql/detection/{id}/question', {
        params: { path: { id: props.detectionId } },
        body: {
          defectReason: hasDefect ? reason : undefined,
          comment: debouncedComment.debounced,
          defect: hasDefect,
          question: props.question.question
        }
      });
      if (response.error != null) {
        toast.error(response.error.message);
      }
      props.questionUpdated({
        ...props.question,
        comment: comment,
        defect: hasDefect,
        defectReason: reason
      });
    })();
  }, [reason, hasDefect, debouncedComment.debounced]);

  const showComment = useMemo(() => {
    return reason != undefined && hasDefect;
  }, [reason, hasDefect]);

  return (
    <div>
      <div className="flex flex-row flex-wrap justify-between items-center gap-2">
        <h4 className="font-semibold">{props.question.text}</h4>
        <div className="flex flex-row w-full md:w-auto gap-1">
          <Button
            variant="outline"
            className={cn('flex-1 md:flex-auto', {
              'bg-primary text-background': hasDefect == true
            })}
            onClick={() => setHasDefect(true)}
          >
            No
          </Button>
          <Button
            variant="outline"
            className={cn('flex-1 md:flex-auto', {
              'bg-primary text-background': hasDefect == false
            })}
            onClick={() => setHasDefect(false)}
          >
            Yes
          </Button>
        </div>
      </div>
      {reasons}
      {showComment && (
        <Input
          value={comment}
          placeholder="Customer note (optional)"
          className="mt-2"
          onChange={(e) => setComment(e.target.value)}
        />
      )}
    </div>
  );
}

function DefectOption(props: {
  defectLevel: string;
  description: string;
  onClick: () => void;
}) {
  return (
    <DropdownMenuItem className="cursor-pointer" onClick={props.onClick}>
      <div>
        <h4 className="font-semibold">{props.defectLevel}</h4>
        <p className="text-muted-foreground">{props.description}</p>
      </div>
    </DropdownMenuItem>
  );
}
function LogItem(props: { log: string; caseId: string }) {
  const regex = /(WSPD-\d+|DTN-\d+|EXCL-\d+)/g;
  const parts = props.log.split(regex);
  const matches = props.log.match(regex) ?? [];

  const getEntityType = (id: string) => {
    if (id.startsWith('WSPD')) return ENTITY_TYPE.CASE;
    if (id.startsWith('DTN')) return ENTITY_TYPE.DETECTION;
    if (id.startsWith('EXCL')) return ENTITY_TYPE.EXCLUSION;
    return undefined;
  };

  const getLinkProps = (id: string) => {
    const baseProps = { className: 'text-blue-500' };

    if (id.startsWith('WSPD')) {
      return {
        ...baseProps,
        to: '/cases/$caseId',
        params: { caseId: id }
      };
    }
    if (id.startsWith('DTN')) {
      return {
        ...baseProps,
        to: '/detections/$detectionId',
        params: { caseId: props.caseId, detectionId: id }
      };
    }
    return {
      ...baseProps,
      to: '/settings/automation',
      params: { caseId: props.caseId }
    };
  };

  return parts.map((part, index) => {
    const match = matches.find((m) => part.includes(m));
    if (!match) return part;

    const type = getEntityType(match);
    if (type == null) return part;

    return (
      <HoverCard openDelay={50} closeDelay={50} key={index}>
        <HoverCardTrigger asChild>
          <Link {...getLinkProps(match)}>{match}</Link>
        </HoverCardTrigger>
        <HoverCardComponent entityType={type} id={match} />
      </HoverCard>
    );
  });
}
