import { apiClient } from '@/lib/api';
import { keepPreviousData } from '@tanstack/react-query';

export const NOTIFICATION_SETTINGS_QUERY = 'settings-notifications';
async function getNotificationSubscriptions() {
  const response = await apiClient.GET('/notification/subscription');
  if (response.error != null) {
    throw new Error('Error getting notfication subscriptions');
  }
  return response.data;
}

export function getNotificationQueryOptions() {
  return {
    queryKey: [NOTIFICATION_SETTINGS_QUERY],
    queryFn: getNotificationSubscriptions,
    placeholderData: keepPreviousData
  };
}
